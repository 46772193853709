.center-text {
    text-align: center;
    max-width: 960px;

    h2 {
        margin: 0;
    }

    p {
        max-width: 615px;
        margin: auto;
        margin-top: 40px;
    }

    a {
        color: inherit;
    }

    @include device-lg {
        max-width: 700px;
        p {
            max-width: 700px;
        }
    }

    @include device-md() {
        p {
            margin-bottom: 0 !important;
            margin-top: 32px;
        }
    }
    
    &.left_text {
        @include device-md() {
            text-align: left;
            p {
                text-align: left;
                margin-top: 40px;
            }
        }
    }
    &.--expand-w{
        max-width: 1060px;
        p {
            max-width: 840px;
            @include device-xl {
                max-width: 576px;
            }
        }

        
    }
}
