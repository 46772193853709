.section-divider {
    @include font-size(20, 34, 700);

    @include device-xl {
        @include font-size(18, 26, 700);
    }

    @include device-sm {
        @include font-size(16, 28, 700);
    }

    border-top: 1px solid $black;
    width: 100%;
    padding-top: 16px;
    @include device-md("min") {
        padding-top: 32px;
    }

    &.--bg-black {
        color: $white;
        border-color: $white;
    }

    &__hr{
        border: 0 !important;
        height: 0 !important;
        margin: 0 !important;
    } // Add for accessibility/semantic propose
}

.--bg-black {
    .section-divider {
        color: $white;
        border-color: $white;
    }
}
