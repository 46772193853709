:root {
    —-app-height: 100%;
}

.onboarding {

    $general-padding: 1.2rem;

    &__nav {
        position: absolute;
        left: $general-padding;
        top: $general-padding;
        z-index: 300;

        button.circle {
            width: 2.5rem;
            height: 2.5rem;
            border: none;
            border-radius: 50%;
            background: $black;
            display: grid;
            place-items: center;
            cursor: pointer;

            svg {
                display: none;
            }
        }
    }

    &__intro {

        &.--hidden {
            opacity: 0;
            pointer-events: none;
        }

        &__curtain {
            position: absolute;
            left: 0;
            top: 100%;
            right: 0;
            bottom: 0;
            z-index: 200;

            &.black {
                background-color: $black;
            }

            &.yellow {
                background-color: $yellow;
            }

            &.red {
                background-color: $red;
            }
        }

        &__white_ball {
            background-color: $white;
            position: absolute;
            width: 2.5rem;
            height: 2.5rem;
            z-index: 600;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
        }
    }

    &__screen {
        height: 100vh;
        // height: var(--app-height);
        max-height: var(--app-height);
        width: 100vw;
        position: absolute;
        left: 0;
        top: 0;
        padding: 6.5rem $general-padding 0;
        
        opacity: 0;
        transition: opacity .6s ease-in-out;
        pointer-events: none;

        &.--visible {
            opacity: 1;
            pointer-events: all;
        }

        &.--dark {
            background: #1A1F20;
            color: $white;
        }

        &__content {
            position: relative;
            z-index: 200;
            
            h2 {
                font-size: 2rem;
                line-height: 2.5rem;

                strong {
                    display: inline-block;
                    border-bottom: 3px solid $yellow;
                }
            }

            p {
                margin: 1rem 0;
                font-size: 1rem;
                line-height: 1.75rem;
            }
        }

        &__bottom {
            position: absolute;
            padding: $general-padding;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            z-index: 100;

            background-repeat: no-repeat;
            background-size: cover;
            background-position-x: 50%;

            &.--buzo, &.--mate, &.--perro-laptop, &.--adventure-time {
                height: 259px;
            }

            &.--remera {
                height: 280px;
            }

            &.--bosque {
                height: 300px;

                &::after {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: 0;
                    right: 0;
                    height: 14rem;
                    background-image: linear-gradient(#1A1F20 10%, rgba(#1A1F20, 0));
                    
                }
            }

            &.--pines {
                height: 367px;
            }

            button {
                width: 100%;
            }
        }

        &.--final {
            overflow: hidden;

            .dog_slide {
                width: 100%;
                aspect-ratio: 1.69;
                background-size: cover;
                margin: 10rem 0 2.5rem;
            }

            .explanation {
                background-color: $gray-lighter;
                padding: 2rem $general-padding .2rem;
                margin: 0 - $general-padding;

                h3 {
                    font-size: 1rem;
                    font-weight: 700;
                    line-height: 1.75rem;
                    border-top: 1px solid $black;
                    padding-top: 1rem;
                }

                p.item {
                    background-color: $white;
                    padding: 1rem;
                    border-radius: .5rem;
                    margin: 1rem 0;
                    font-size: 0.875rem;
                    line-height: 1.375rem;

                    &.yellow {
                        background-color: $yellow;
                    }
                }
            }
        }

    }
}