.ego-modal {
    display: none;
    position: fixed;
    z-index: 401;
    inset: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 0;
    background-color: rgba(0, 0, 0, .5);
    overflow: hidden;
    padding: 0;

    $modal: &;

    &__wrapper {
        display: grid;
        align-items: center;
        width: 100%;
        min-height: 100%;
        padding: 0;
    }

    &__box {
        position: relative;
        inset: 0;
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        background-color: #fff;
        cursor: default;
        transition: top .2s ease-in-out;

        @include font-size(18, 24);

        &__close-btn {
            position: absolute;
            z-index: 10;
            inset: 35px 32px auto auto;
            border: 0;
            outline: none;
            padding: 5px 8px;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            background-color: transparent;
            transition: background-color .2s ease-in-out;
            @include font-size(20, 20);

            span {
                text-decoration: underline;
            }

            .times {
                width: 20px;
                height: 20px;

                * {
                    stroke: $black;
                }
            }

            &:hover {
                background-color: $yellow;
                color: $black;

                span {
                    text-decoration: none;
                }

                .times {
                    * {
                        stroke: $black;
                    }
                }
            }
        }

        &__body {
            position: fixed;
            width: 100%;
            height: 100vh;
            padding: 120px 40px;
            overflow: auto;

            &-wrapper {
                display: block;
                width: 100%;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                min-height: 100%;

                &.--vcenter {
                    display: flex;
                    flex-flow: column nowrap;

                    // This avoids overflowing content to become inaccesible
                    > * {
                        margin: auto;
                    }
                }
    
                @include device-md {
                    max-width: 570px;
                }
            }
        }
    }

    &__content-block {
        margin-bottom: 30px;
    }

    $modal: &;

    &.--open {
        #{$modal}__box {
            top: 0;
        }
    }

    @include device-md {
        padding: 0;

        &__wrapper {
            align-items: end;
        }

        &__box {
            display: grid;
            grid-template-rows: 1fr auto;

            &__close-btn {
                inset: 14px 14px auto auto;
            }

            &__body {
                padding: 120px 16px;
            }
        }
    }

    @include device-sm {
        &__box {
            &__body {
                padding: 60px 16px;
            }
        }
    }

    &.--dark-mode {
        #{$modal}__box {
            background-color: $black;
            color: $white;

            &__close-btn {
                color: $white;
                transition: color $timming ease-in-out;

                .times {
                    * {
                        stroke: $white;
                        transition: stroke $timming ease-in-out;
                    }
                }

                &:hover {
                    color: $black;
                    .times {
                        * {
                            stroke: $black;
                        }
                    }
                }
            }
        }
    }
}
