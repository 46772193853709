.toyota-arg {
    // DISCOVERY SECTION
    &-discovery {
        p {
            margin-bottom: 0;
        }
        &__box {
            @include main-grid-cases;
            align-content: space-between;
        }
        &__thumbs {
            width: 100%;
            object-fit: cover;
            height: 100%;

            &:nth-of-type(1) {
                grid-column: 1 / -1;

                @include device-md("min") {
                    grid-column: 1 / span 11;
                    grid-row: 1 / 3;
                }
            }

            &:nth-of-type(2) {
                grid-column: 1 / -1;
                grid-row: 1;

                @include device-md("min") {
                    grid-column: 13 / -1;
                }
            }

            &:nth-of-type(3) {
                grid-column: 1 / -1;

                @include device-md("min") {
                    grid-column: 13 / -1;
                    grid-row: 2;
                }
            }
        }

        .cases-header-section {
            @include device-md() {
                padding-top: 75px;
            }
        }
    }
    // JOURNEY SECTION
    &-journey {
        .swiper-wrapper {
            transition-timing-function: ease-out;
        }
        p {
            margin-bottom: 0;
        }

        &__box {
            @include main-grid-cases;
        }

        &__thumbs {
            width: 100%;
            object-fit: cover;
            height: 100%;

            &:nth-child(1) {
                grid-column: 1 / span 3;
                grid-row: 2 / 3;

                @include device-md("min") {
                    grid-column: 1 / span 7;
                    grid-row: 1 / 2;
                }
            }

            &:nth-child(2) {
                grid-column: 1 / -1;
                grid-row: 1 / 2;
                @include device-md("min") {
                    grid-column: 9 / span 7;
                    grid-row: 1 / 2;
                }
            }

            &:nth-child(3) {
                grid-column: 5 / -1;
                grid-row: 2 / 3;

                @include device-md("min") {
                    grid-column: 17 / -1;
                    grid-row: 1 / 2;
                }
            }

            &:nth-child(4) {
                grid-column: 1 / -1;
                grid-row: 3 / 4;
                @include device-md("min") {
                    grid-column: 1 / -1;
                    grid-row: 2 / 3;
                }
            }
        }
        &__levels {
            .section-divider {
                padding-top: 16px;
                padding-bottom: 16px;
            }
            @include device-md("min") {
                .section-divider {
                    padding-top: 24px;
                    padding-bottom: 24px;
                }
            }
            grid-column: 1 / -1;

            @include device-md("min") {
                grid-column: 16 / -1;
                grid-row: 3 / 4;

                .section-divider {
                    padding-top: 24px;
                    padding-bottom: 24px;
                }
            }
        }

        .cases-header-section {
            @include device-md() {
                padding-top: 75px;
            }
        }
    }
    // WEBSITE SECTION
    &-site {
        padding-bottom: 120px;
        &__thumb {
            width: 100%;
            margin-bottom: 40px;

            @include device-md() {
                margin-bottom: 32px;
            }
        }
        header {
            padding-top: 76px;

            @include device-md("min") {
                padding-top: 146px;
            }
            @include device-xl("min") {
                padding-top: 152px;
            }
        }
        .list-item {
            @include main-grid-cases;
            @include main-grid-columns(
                (
                    "xl": 4,
                    "lg": 4,
                    "md": 2,
                    "sm": 2,
                )
            );
            align-items: start;
            &-container {
                @include device-md() {
                    padding-top: 13px;
                }
                &__title {
                    @include font-size(12, 20, 700);
                    @include device-md("min") {
                        @include font-size(14, 24, 700);
                    }
                    @include device-xl("min") {
                        @include font-size(16, 28, 700);
                    }
                }
                &__description {
                    @include font-size(12, 20, 400);
                    @include device-md("min") {
                        @include font-size(14, 24, 400);
                    }
                    @include device-xl("min") {
                        @include font-size(16, 28, 400);
                    }
                }
            }
        }
    }
    &-design {
        &.--bg-black {
            background-color: #1f1f1f;
        }

        &__box {
            @include main-grid-cases;
            align-items: unset;
        }
        &__thumb {
            max-width: 100%;
            display: block;
            &:nth-of-type(1) {
                grid-column: 1 / -1;
                grid-row: 1 / 2;
                object-fit: cover;
                min-height: 100%;

                @include device-md("min") {
                    grid-column: 1 / span 11;
                    grid-row: 1 / 3;
                }
            }
            &:nth-of-type(2) {
                grid-column: 1 / span 3;
                grid-row: 2 / 3;

                @include device-md("min") {
                    grid-column: 13 / -1;
                    grid-row: 1 / 2;
                }
            }
            &:nth-of-type(3) {
                grid-column: 5 / -1;
                grid-row: 2 / 3;

                @include device-md("min") {
                    grid-column: 13 / -1;
                }
            }
            &:nth-of-type(4) {
                grid-column: 1 / -1;
                grid-row: 3 / 4;
            }
            &:nth-of-type(5) {
                grid-column: 1 / -1;
                grid-row: 4 / 5;
                @include device-md("min") {
                    grid-column: 13 / -1;
                }
            }
            &:nth-of-type(6) {
                grid-column: 1 / -1;
                grid-row: 6 /7;
                @include device-md("min") {
                    grid-row: 5 / 6;
                }
            }
            &:nth-of-type(7) {
                grid-column: 1 / -1;
                grid-row: 7 / 8;
                @include device-md("min") {
                    grid-column: 13 / -1;
                    grid-row: 6 / 7;
                }
            }
            &:nth-of-type(8) {
                grid-column: 1 / -1;
                grid-row: 8 / 9;
                @include device-md("min") {
                    grid-row: 7 / 8;
                }
            }
            &:nth-of-type(9) {
                grid-column: 1 / -1;
                grid-row: 9 / 10;

                @include device-md("min") {
                    grid-column: 1 / span 11;
                    grid-row: 8 / 9;
                }
            }
            &:nth-of-type(10) {
                grid-column: 1 / -1;
                grid-row: 12 / 13;
                @include device-md("min") {
                    grid-column: 13 / -1;
                    grid-row: 8 / 9;
                }
            }
            &:nth-of-type(11) {
                grid-column: 1 / span 3;
                grid-row: 11 / 12;
                @include device-md("min") {
                    grid-column: 1 / span 11;
                    grid-row: 9 / 10;
                }
            }
            &:nth-of-type(12) {
                grid-column: 5 / -1;
                grid-row: 11 / 12;
                @include device-md("min") {
                    grid-column: 13 / -1;
                    grid-row: 9 / 10;
                }
            }
        }
        &__description {
            p {
                max-width: 400px;
                width: 100%;
                margin-bottom: 40px;
            }
            color: $white;
            border-top: 1px solid $white;
            padding-top: 16px;
            @include device-md("min") {
                padding-top: 24px;
            }

            &:nth-of-type(1) {
                grid-column: 1 / -1;
                grid-row: 5/6;
                @include device-md("min") {
                    grid-column: 1 / span 11;
                    grid-row: 4/5;
                }
            }
            &:nth-of-type(2) {
                grid-column: 1 / -1;
                grid-row: 10 / 11;
                @include device-md("min") {
                    grid-column: 1 / span 11;
                    grid-row: 6 / 7;
                }
            }
        }

        @include device-md {
            padding-top: 60px;
            padding-bottom: 60px;
            margin-bottom: 40px;

            .cases-header-section {
                padding-top: 75px;
            }
        }
    }
}
