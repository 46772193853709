.toyota-cl {
    &__container {
        @include main-grid-cases;
        align-items: unset;
        margin-top: 32px;

        padding-bottom: 60px;

        @include device-md("min") {
            padding-bottom: 120px;
        }

        background-color: #f1f1f1;
        @include device-md {
            padding-left: 16px;
            padding-right: 16px;
            margin-top: 16px;
        }
    }
    &__inner {
        grid-column: 1 / -1;
        @include device-md("min") {
            grid-column: 5 / 20;
        }
    }
    // NEEDS SECTION
    &-needs {
        p {
            margin-bottom: 0;
        }
        &__features {
            @include main-grid-cases;
            @include main-grid-columns(
                (
                    "xl": 4,
                    "lg": 4,
                    "md": 1,
                    "sm": 1,
                )
            );
            align-items: unset;
            row-gap: 32px;
            &-items {
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding-top: 32px;
                border-top: 1px solid $black;
                &__content {
                    strong {
                        font-weight: 700;
                        margin-bottom: 16px;
                        display: block;
                    }
                }

                @include device-md {
                    svg {
                        flex-shrink: 0;
                        max-width: 60px;
                    }
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                    gap: 20px;
                }
            }
        }
        .cases-header-section {
            @include device-md() {
                padding-top: 75px;
            }
        }
    }
    // TECH STACK SECTION
    &-stack {
        &__tech {
            grid-column: 1 / -1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            width: 100%;
            @include rwd(535) {
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
            img {
                max-width: 100%;
                height: auto;
                display: block;
                min-width: 0;
                @include rwd(535) {
                    max-width: 147px;
                }
            }
            @include device-md("min") {
                grid-column: 3 / 22;
            }
        }
        &__description {
            b {
                font-weight: 700;
            }
            margin-bottom: 44px;
            margin-top: 44px;
            @include device-md("min") {
                margin-bottom: 80px;
                margin-top: 80px;
            }
            @include device-lg("min") {
                margin-bottom: 120px;
                margin-top: 120px;
            }
            p:last-of-type {
                margin-bottom: 0;
            }
        }
        &__companies {
            border-top: 1px solid $black;
            p {
                margin-bottom: 60px;
                padding-top: 16px;
                @include device-md() {
                    margin-bottom: 32px;
                }
            }
            &-logos {
                @include flex-center;
                justify-content: space-between;
                gap: 25px;
                svg {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .cases-header-section {
            @include device-md() {
                padding-bottom: 44px;
            }
        }
    }
    // EXPERIENCE SECTION
    &-experience {
        .section-divider {
            @include device-md() {
                padding-top: 21px;
            }
        }
        img {
            max-width: 100%;
        }
        .toyota-cl__container {
            background-color: #ffc500;
            padding-bottom: 0;
            @include device-md() {
                margin-top: 21px;
            }
        }
        .cases-header-section {
            @include device-md() {
                padding-bottom: 60px;
            }
        }
        .toyota-cl-experience-img-dekstop{
            @include device-md() {
                display: none;
            }
        }
        .toyota-cl-experience-img-mobile{
            display: none;
            margin-top: -2px;
            @include device-md() {
                display: block;
            }
        }
        @include device-md() {
            padding-bottom: 120px;
        }
    }
    // PLATFORM SECTION
    &-platform {
        &.--bg-black {
            background-color: #1d1d1d;
        }
        img {
            @include image-responsive;
        }
        &__custom {
            @include main-grid-cases;
            @include main-grid-columns(
                (
                    "xl": 2,
                    "lg": 2,
                    "md": 1,
                    "sm": 1,
                )
            );
            align-items: unset;

            @include device-sm() {
                img:nth-of-type(1) {
                    grid-column: 1 / 4;
                }
                img:nth-of-type(2) {
                    grid-column: 5 / -1;
                }
            }
        }
        &__payment {
            @include main-grid-cases;
            align-items: unset;
            picture, img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            &__1 {
                grid-column: 1 / -1;
                grid-row: 1 / 2;

                @include device-md("min") {
                    grid-column: 1 / span 11;
                    grid-row: 1 / 3;
                }
            }
            &__2 {
                grid-column: 1 / -1;
                grid-row: 2 / 3;

                @include device-md("min") {
                    grid-column: 13 / -1;
                    grid-row: 1 / 2;
                }
            }
            &__3{
                grid-column: 1 / -1;
                grid-row: 3 / 4;

                @include device-md("min") {
                    grid-column: 13 / -1;
                    grid-row: 2 / 3;
                }
            }
            &__4 {
                grid-column: 1 / -1;
                grid-row: 4 / 5;
                @include device-md("min") {
                    grid-column: 1 / span 11;
                    grid-row: 3 / 4;
                }
            }
            &__5 {
                grid-column: 1 / -1;
                grid-row: 5 / 6;
                @include device-md("min") {
                    grid-column: 13 / -1;
                    grid-row: 3 / 5;
                }
            }
        }
        &__after-sales {
            header {
                padding-bottom: 60px;
                @include device-md("min") {
                    padding-bottom: 80px;
                }
            }
            &-features {
                padding-bottom: 16px;
                @include device-md("min") {
                    padding-bottom: 20px;
                }
                @include device-xl("min") {
                    padding-bottom: 40px;
                }
                @include main-grid-cases;
                @include main-grid-columns(
                    (
                        "xl": 4,
                        "lg": 4,
                        "md": 2,
                        "sm": 2,
                    )
                );
                i {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    svg {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            &-grid {
                @include main-grid-cases;
                @include main-grid-columns(
                    (
                        "xl": 2,
                        "lg": 2,
                        "md": 1,
                        "sm": 1,
                    )
                );
                align-items: unset;
                @include device-sm() {
                    img:nth-of-type(2) {
                        grid-column: 1 / 4;
                    }
                    img:nth-of-type(3) {
                        grid-column: 5 / -1;
                    }
                }
            }
        }
        @include device-md() {
            padding-top: 120px;
            padding-bottom: 120px;
            .section-divider {
                margin-bottom: 15px;
            }
            &__after-sales {
                padding-top: 60px;
            }
        }
    }
    // EXPANDING PLATFORM SECTION
    &__expanding-platform {
        padding-top: 0;
        padding-bottom: 0;
        img {
            @include image-responsive;
        }
        .toyota-cl__container {
            background-color: #06121f;
            color: $white;
            padding-bottom: 0;
        }
        // &__intro {
        //     padding-bottom: 16px;

        //     @include device-md("min") {
        //         padding-bottom: 20px;
        //     }
        //     @include device-xl("min") {
        //         padding-bottom: 40px;
        //     }
        // }
        &__box {
            @include main-grid-cases;
            @include main-grid-columns(
                (
                    "xl": 1,
                    "lg": 1,
                    "md": 1,
                    "sm": 1,
                )
            );
            @include device-md() {
                .case-impact {
                    margin-top: 104px;
                    margin-bottom: 40px;
                }
            }
        }
        &__reserve,
        &__racing {
            @include main-grid-cases;
            @include main-grid-columns(
                (
                    "xl": 2,
                    "lg": 2,
                    "md": 1,
                    "sm": 1,
                )
            );
            .cases-header-section {
                padding: 0;
                padding-bottom: 16px;
            }
            & > div {
                @include device-md {
                    order: -1;
                    padding-top: 44px !important;
                }
            }
        }

        &__maintenance {
            color: $white;
            background-color: #285065;
            @include main-grid-cases;
            & > * {
                grid-column: 1 / -1;
            }
            div {
                padding: 60px 16px 16px 16px;
                @include device-md("min") {
                    padding: 120px 16px 120px 16px;
                }
            }

            @include device-md("min") {
                img, picture {
                    grid-column: 13 / -1;
                }
                div {
                    grid-column: 3 / 11;
                }
            }
        }
        &__kinto {
            @include main-grid-cases;
            color: $white;
            background-color: #00708d;
            // & > img {
            //     grid-column: 1 / -1;
            // }
            &-thumbs {
                display: grid;
                grid-column: 1 / -1;
                max-width: 100%;
                display: block;
                
                // grid-template-columns: 1fr 1fr;
            }
            @include device-md {
                
                .cases-header-section {
                    padding-bottom: 32px;
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }
        }
    }
}
