.info-box {
    $info-box: &;

    position: relative;
    border-top: 1px solid $black;
    transition: background-color $timming ease-in-out,
        border-top $timming ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    gap: 16px;

    @include device-xl("min") {
        gap: 70px;
    }

    .info-box_content {
        @include device-xl("min") {
            padding-right: 48px;
        }
        transition: all $timming ease-in-out;
        position: relative;
        width: 100%;
        margin-top: 0;
    }

    color: $black;
    text-decoration: none;

    h4 {
        margin-top: 32px;
        margin-bottom: 32px;
        @include device-xl("min") {
            margin-top: 28px;
            margin-bottom: 40px;
        }

        width: 100%;
    }

    p,
    .info-box_text {
        margin: 0;
        width: 100%;
    }

    &_more_arrow,
    &_more_text {
        @include device-xl("min") {
            display: flex;
            position: absolute;
            bottom: 20px;
        }
    }

    &_more_arrow {
        height: 20px;
        margin-bottom: 8px;
        padding: 0;
        display: none;
        transition: all $timming ease-in-out;

        svg {
            width: 32px;
        }
    }

    &_more_text {
        text-decoration: underline;
        transition: all $timming ease-in-out;
    }
}

.info-box:hover, .info-box:focus-within  {
    @include device-xl("min") {
        .info-box_content {
            padding-left: 24px;
            padding-right: 24px;
        }
        .info-box_more_text {
            display: none;
        }
        .info-box_more_arrow {
            display: flex;
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}

.red:hover, .red:focus-within {
    @include device-xl("min") {
        background-color: $red;
        border-top: 1px solid $red;
        h4,
        p,
        .info-box_text {
            color: $white;
        }
        .info-box_more_arrow {
            filter: invert(1);
        }
    }
}

.yellow:hover, .yellow:focus-within {
    @include device-xl("min") {
        border-top: 1px solid $yellow;
        background-color: $yellow;
    }
}

.black:hover, .black:focus-within {
    @include device-xl("min") {
        background-color: $black;
        border-top: 1px solid $black;
        h4,
        p,
        .info-box_text {
            color: $white;
        }
        .info-box_more_arrow {
            filter: invert(1);
        }
    }
}
