.storybook {
    .grid-system {
        .grid {
            @include main-grid(600px);
        
            span {
                height: 100%;
                border: 1px dashed red;
    
                &:nth-child(1) {
                    grid-column: 1/span 1;
                }
                &:nth-child(2) {
                    grid-column: 3/span 1;
                }
                &:nth-child(3) {
                    grid-column: 5/span 1;
                }
                &:nth-child(4) {
                    grid-column: 7/span 1;
                }
                &:nth-child(5) {
                    grid-column: 9/span 1;
                }
                &:nth-child(6) {
                    grid-column: 11/span 1;
                }
                &:nth-child(7) {
                    grid-column: 13/span 1;
                }
                &:nth-child(8) {
                    grid-column: 15/span 1;
                }
                &:nth-child(9) {
                    grid-column: 17/span 1;
                }
                &:nth-child(10) {
                    grid-column: 19/span 1;
                }
                &:nth-child(11) {
                    grid-column: 21/span 1;
                }
                &:nth-child(12) {
                    grid-column: 23/span 1;
                }
            }

            @include device-md {
                @include main-grid(300px);

                span:nth-child(n+5) {
                    display: none;
                }
            }
        }
    }
}

.test-custom-grid {
    @include main-grid;
    row-gap: 50px;
    @include main-grid-columns(("xl": 6, "lg": 4, "md": 2, "sm": 1));

    span {
        display: block;
        background: red;
        height: 150px;
    }
}