.la-nacion {

    // ------  Archivo: roadmap / Sección: la-nacion-roadmap ------
    &-roadmap {
        .section-divider {
            margin-top: -60px;
        }
        &-container {
            background-color: #F1F1F1;
            padding-top: 120px;
            padding-bottom: 165px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 32px;

            .la-nacion-center-text {
                margin-bottom: 120px;
            }

            &-boxes {
                @include main-grid-cases();
                text-align: left;

                .boxes-title {
                    @include font-size(20, 24, 700, 0);
                    align-self: end;
                    padding-top: 20px;
                }

                .boxes-tags {
                    @include font-size(10, 10, 600, 0);
                    width: max-content;
                    height: max-content;
                    padding: 4px 8px;
                    border-radius: 10px;
                    background-color: #ffffff;
                    align-self: start;
                }

                .boxes-description {
                    @include font-size(16, 28, 400, 0);
                    padding-top: 16px;
                }

                .box-1, .box-2, .box-3, .box-4 {
                    border-top: 1px solid $black;
                    height: 220px;
                    padding-top: 35px;
                    display: grid;
                    grid-template-rows: repeat(4, max-content);

                    p {
                        margin: 0;
                    }

                    img {
                    align-self: center;
                    }
                }

                .box-1 {
                    grid-column: 5 / span 3;
                }
                .box-2 {
                    grid-column: 9 / span 3;
                }
                .box-3 {
                    grid-column: 13 / span 3;
                }
                .box-4 {
                    grid-column: 17 / span 3;
                    color: #FF0000;
                    border-color: #FF0000;
                }
            }
        }

        @include rwd(1024) {
            &-container {
                padding-bottom: 120px;
                margin-top: 26px;

                &-title, &-text {
                padding-left: 75px;
                padding-right: 75px;
                }

                &-boxes {
                    .boxes-title {
                        @include font-size(18, 26, 700, 0);
                    }

                    .boxes-description {
                        @include font-size(14, 26, 400, 0);
                    }
                }
            }
        }

        @include rwd(768) {
            &-container {
                &-boxes {
                    .box-1 {
                        grid-column: 4 / span 7;
                        grid-row: 1;
                    }

                    .box-2 {
                        grid-column: 14 / span 7;
                        grid-row: 1;
                    }
                    
                    .box-3 {
                        grid-column: 4 / span 7;
                        grid-row: 2;
                    }

                    .box-4 {
                        grid-column: 14 / span 7;
                        grid-row: 2;
                    }
                }
            }
        }

        @include device-md() {
            padding-bottom: 40px;
            &-container {
                padding: 60px 16px;
                margin-top: 16px;

                .la-nacion-center-text {
                padding-left: 0px;
                padding-right: 0px;
                text-align: left;
                margin-bottom: 60px;
                }

                &-boxes {
                    .box-1 {
                        grid-column: 1 / span 3;
                        grid-row: 1;
                    }

                    .box-2 {
                        grid-column: 5 / span 3;
                        grid-row: 1;
                    }
                    
                    .box-3 {
                        grid-column: 1 / span 3;
                        grid-row: 2;
                    }

                    .box-4 {
                        grid-column: 5 / span 3;
                        grid-row: 2;
                    }

                    .boxes-title {
                        @include font-size(16, 28, 700, 0);
                    }

                    .boxes-description {
                        @include font-size(12, 20, 400, 0);
                    }
                }
            }
        }
    }

    &-designing {
        // ------ Archivo: designing-rules / Sección: la-nacion-designing-rules ------
        &-rules {

            .la-nacion-center-text {
                margin-top: 150px;
                margin-bottom: 150px;

                @include device-md() {
                    margin-top: 72px;
                    margin-bottom: 60px;
                    text-align: left;
                }
            }

            &-container {
                padding-top: 120px;
                padding-bottom: 120px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 32px;
            }

            &-down {
                .section-divider {
                    border-color: #F1F1F1;
                }
                &-container {
                    @include main-grid-cases();
                    row-gap: 0px;

                    .box-1 {
                        grid-column: 1 / span 11;
                    }

                    .box-2 {
                        grid-column: 13 / span 11;
                    }

                    .box-1, .box-2 {
                        margin-top: 32px;
                        padding-top: 70px;
                        padding-bottom: 60px;
                        padding-left: 31px;
                        padding-right: 31px;
                        background-color: #F1F1F1;
                        width: 100%;
                        display: grid;
                        place-content: center;
                        text-align: center;

                        img {
                            justify-self: center;
                            width: 100px;
                            height: 100px;
                            margin-bottom: 34px;

                            @include rwd(1024) {
                                width: 95px;
                                height: 95px;
                            }

                            @include device-md() {
                                margin: 0;
                                width: 50px;
                                height: 50px;
                            }
                        }

                        p {
                            @include font-size(26, 36, 400, -3);
                            max-width: 380px; 

                            @include rwd(1024) {
                                @include font-size(24, 34, 400, 0);
                            }

                            @include device-md() {
                                @include font-size(20, 26, 400, 0);
                                text-align: left;
                                margin-left: 16px;
                                margin-bottom: 0;
                                strong {
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    @include rwd(1024) {
                        .box-1, .box-2 {
                            padding-left: 31px;
                            padding-right: 31px;
                        }
                    }

                    @include device-md() {
                        grid-template-rows: 1fr 1fr;

                        .box-1 {
                            grid-column: 1 / span 7;
                            grid-row: 1;
                        }
    
                        .box-2 {
                            grid-column: 1 / span 7;
                            grid-row: 2;
                        }
                        
                        .box-1, .box-2 {
                            margin-top: 16px;
                            padding: 16px;
                            display: flex;
                        }
                    }
                }
            }
            
            @include device-md() {
                padding-bottom: 120px;
            }
        }

        // ------ Archivo: designing-stunning / Sección: la-nacion-designing-stunning ------
        &-stunning {
            background-color: #1D1D1D;
            color: #ffffff;
            padding-bottom: 120px;
            
            &-container {
                margin-top: 17px;
                padding-bottom: 120px;
                text-align: center;
                display: grid;
                justify-items: center;

                .la-nacion-center-text-small {
                    margin-top: 25px;
                }

                img {
                    margin-bottom: 25px;
                    width: 94px;
                    height: 66px;
                }
                @include device-md() {
                    margin-top: 0;
                    img {
                        width: 56px;
                        height: 40px;
                    }
                }
            }

            &-photos {
                margin: 0;

                .browser-nav {
                    margin-bottom: 24px;
                    width: 90%;
                    margin-left: 5%;
                    margin-right: 5%;

                    @include rwd(1024) {
                        margin-bottom: 20px;
                    }

                    @include device-md() {
                        display: none;
                    }
                }

                img {
                    width: 100%;
                }
            }

            @include device-md() {
                padding-top: 70px;
                padding-bottom: 60px;
                &-container {
                    padding-bottom: 60px;

                    .la-nacion-center-text-small {
                        margin-top: 15px;
                    }
                }
            }
        }

        // ------ Archivo: designing-components / Sección: la-nacion-designing-components ------
        &-components {
            background-color: #F1F1F1;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .black-box {
                background-color: #000000;
                color: #ffffff;
                margin-top: -20px;
                margin-bottom: 100px;
                padding-bottom: 60px;

                @include device-md() {
                    margin-bottom: 40px;
                    padding-top: 60px;

                    p {
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                }
            }

            .nav {
                margin-top: 20px;
                margin-bottom: 20px;
                @include device-md() {
                    margin-bottom: 0px;
                    margin-top: 0;
                }
            }

            .browser-nav {
                margin-top: 100px;
                margin-bottom: 32px;
                width: 90%;
                margin-left: 5%;
                margin-right: 5%;
                @include device-md {
                    display: none;
                }
            }

            .macri {
                @include device-md {
                    margin-top: 60px;
                }
            }

            .red-box {
                @include main-grid-cases();
                margin-top: 40px;
                margin-bottom: 40px;

                .red-img1 {
                    grid-column: 1 / span 7;
                }

                .red-img2 {
                    grid-column: 9 / span 15;
                }

                @include device-md() {
                    grid-template-rows: repeat(2, auto);
                    margin-top: 16px;
                    margin-bottom: 16px;
                    
                    .red-img1 {
                        grid-column: 1 / span 7;
                        grid-row: 1;
                    }

                    .red-img2 {
                        grid-column: 1 / span 7;
                        grid-row: 2;
                    }
                }

            }

            .grid-img-mobile {
                display: none;
                
                @include device-md() {
                    @include main-grid-cases();
                    
                    .img-mobile1 {
                        grid-column: 1 / span 7;
                        grid-row: 1;
                    }

                    .img-mobile2 {
                        grid-column: 1 / span 7;
                        grid-row: 2;
                    }
                }
            }

            .grid-img-dekstop {
                @include device-md() {
                    display: none;
                }
            }

            .blue-pink-box {
                @include main-grid-cases();
                margin-top: 40px;
                margin-bottom: 40px;

                .blue-pink-img1 {
                    grid-column: 1 / span 11;
                }

                .blue-pink-img2 {
                    grid-column: 13 / span 11;
                }

                @include device-md() {
                    margin-top: 16px;

                    .blue-pink-img1 {
                        grid-column: 1 / span 7;
                        grid-row: 1;
                    }
    
                    .blue-pink-img2 {
                        grid-column: 1 / span 7;
                        grid-row: 2;
                    }
                }
            }

            @include device-md() {
                padding-top: 60px;
                padding-bottom: 15px;
            }
        }

        // ------ Archivo: designing-quote / Sección: la-nacion-designing-quote ------
        &-quote {
            background-color: #F1F1F1;
            padding-bottom: 240px;
            
            @include device-md() {
                padding-bottom: 120px;
            }
            .case-quote__box {
                margin: auto;
            }
        }

        // ------ Archivo: designing-fascinating / Sección: la-nacion-designing-fascinating ------
        &-fascinating {
            background-color: #133F61;
            color: #ffffff;
            padding-bottom: 0px;
            // margin-top: 120px;
            
            &-container2 {
                @include main-grid-cases();
                @include device-md() {
                    row-gap: 0px;
                }
            }

            &-container, &-container2 {
                // @include main-grid-cases();
                margin-top: 17px;
                padding-bottom: 120px;
                display: grid;
                justify-items: center;

                .la-nacion-center-text-small {
                    row-gap: 0px !important;
                }

                .person-icon {
                    width: 128px;
                    height: 93px;
                    margin-bottom: 39px;

                    @include device-md() {
                        width: 56px;
                        height: 40px;
                        margin-left: 0px;
                    }
                }
                &-text {
                    grid-column: 5 / span 15;
                    text-align: center;
                    margin-bottom: 0;
                }
                .boxes-icon {
                    grid-column: 5 / span 15;
                    width: 100px;
                    height: 100px;
                    margin-bottom: 37px;
                }

                @include rwd(1024) {
                    &-text {
                        grid-column: 3 / span 19;
                    }
                    .boxes-icon {
                        grid-column: 3 / span 19;
                    }
                }

                @include device-md() {
                    margin-top: 0;
                    padding-bottom: 60px;
                    &-text {
                        grid-column: 1 / -1;
                        text-align: left;
                    }
                    .boxes-icon {
                        grid-column: 1;
                        width: 54px;
                        height: 54px;
                    }
                }
            }

            &-photos {
                margin: 0;

                .browser-nav {
                    margin-bottom: 24px;
                    width: 90%;
                    margin-left: 5%;
                    margin-right: 5%;
    
                    @include rwd(1024) {
                        margin-bottom: 20px;
                    }
    
                    @include device-md() {
                        display: none;
                    }
                }

                img {
                    margin-top: -10px;
                    width: 100%;
                    @include device-md() {
                        margin-top: 0;
                    }
                }
            }

            &-down-photos {
                @include main-grid-cases();
                grid-row: repeat(4, 1fr);
                row-gap: 40px;
                align-items: start;
                justify-items: center;
                margin-bottom: 130px;
                margin-top: 35px;
                
                picture, img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                .img1{
                    grid-row: 1;
                    grid-column: 1 / span 5;
                }
                .img2{
                    grid-row: 2;
                    grid-column: 1 / span 5;
                }
                .img3{
                    grid-row: 3 / span 2;
                    grid-column: 1 / span 5;
                }
                .img4{
                    grid-row: 1 / span 2;
                    grid-column: 7 / span 5;
                }
                .img5{
                    grid-row: 3 / span 2;
                    grid-column: 7 / span 5;
                }
                .img6{
                    grid-row: 1 / span 4;
                    grid-column: 13 / span 11;
                }

                @include device-md() {
                    row-gap: 16px;
                }
            }

            &-last-photos {
                @include main-grid-cases();
                row-gap: 40px;
                justify-items: center;
                align-items: center;

                picture, img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                .img1{
                    grid-row: 1;
                    grid-column: 1 / -1;
                    width: 100%;
                }
                .img2{
                    grid-row: 2;
                    grid-column: 1 / span 11;
                    width: 100%;
                }
                .img3{
                    grid-row: 2;
                    grid-column: 13 / span 11;
                    width: 100%;
                }
                .img4{
                    grid-row: 3;
                    grid-column: 1 / -1;
                    max-height: 760px;
                }
                @include device-md(){
                    row-gap: 16px;
                    .img4{
                        height: 214px;
                        width: 343px;
                    }
                }
            }

            @include device-md() {
                margin-top: 0px;
                padding-top: 60px;
                &-container {
                    justify-items: start;
                    padding-bottom: 60px;

                    .la-nacion-center-text-small {
                        text-align: left;
                    }

                    &-text {
                        margin-bottom: 0;
                    }

                    .boxes-icon {
                        width: 50px;
                        height: 50px;
                    }
                }

                &-down-photos {
                    margin-top: 16px;
                    margin-bottom: 60px;
                    .img1{
                        grid-row: 1;
                        grid-column: 1 / span 3;
                    }
                    .img2{
                        grid-row: 2;
                        grid-column: 1 / span 3;
                    }
                    .img3{
                        grid-row: 1 / span 2;
                        grid-column: 5 / span 3;
                    }
                    .img4{
                        grid-row: 3;
                        grid-column: 1 / span 3;
                    }
                    .img5{
                        grid-row: 3;
                        grid-column: 5 / span 3;
                    }
                    .img6{
                        grid-row: 4;
                        grid-column: 1 / -1;
                    }
                }
            }
        }

    }
    // ------ Archivo: designing-fascinating / Sección: la-nacion-designing-fascinating ------
    &-teamwork {
        .la-nacion-roadmap-container {
            text-align: left;

            .box-4 {
                color: #000000;
                border-color: #000000;
            }
    
            .boxes-title {
                @include font-size(20, 24, 700, 0);
                display: flex;
                flex-direction: column;
                align-self: flex-start;
                margin-top: 20px;
            }

            .boxes-tags {
                margin-top: 8px;
            }

            .boxes-img {
                width: 50px;
                height: 50px;
            }
    
            .box-1, .box-2, .box-3, .box-4 {
                grid-template-rows: repeat(2, min-content);
            }
        }

        @include rwd(1024) {
            padding-bottom: 0;
        }

        @include device-md() {
            padding-top: 120px;
            margin-bottom: 40px;
            
            .la-nacion-roadmap-container {
                .la-nacion-roadmap-container-boxes {
                    row-gap: 0px;
                }

                .boxes-title {
                    margin-top: 0px;
                }

                .boxes-tags {
                    margin-top: 4px;
                }
            }
        }
    }

    // ------  Clase: la-nacion-width ------
    &-width {
        max-width: 490px;
        @include device-md(){
            letter-spacing: -0.02rem;
        }
    }

    // ------  Archivo: varios / Clase: la-nacion-center-text ------
    &-center-text, &-center-text-small  {
        text-align: center;
        @include main-grid-cases();
        row-gap: 0px;
        
        h3 {
            margin-bottom: 32px;
            grid-column: 5 / span 15;
            @include rwd(1024) {
                grid-column: 3 / span 19;
            }
            @include rwd(768) {
                grid-column: 1 / -1;
                padding-left: 16px;
                padding-right: 16px;
            }
        }
        
        p {
            @include font-size(20, 34, 400, 0);
            margin: 0;
            grid-column: 5 / span 15;
            
            @include rwd(1024) {
                @include font-size(18, 32, 400, 0);
                grid-column: 3 / span 19;
            }

            @include rwd(768) {
                @include font-size(16, 28, 400, 0);
                grid-column: 1 / -1;
                padding-left: 16px;
                padding-right: 16px;
            }

            @include device-md() {
                padding: 0;
            }
        }
    }
    &-center-text-small {
        h3 {
            @include font-size(60, 70, 400, -3);

            @include rwd(1024) {
                @include font-size(40, 50, 400, -2);
            }

            @include device-md() {
                @include font-size(24, 30, 400, -2);
            }
        }
    }
    &-mobile-without-padding {
        @include device-md() {
            h3, p {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
}
.text-align-left {
    text-align: left;
}