.planet_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    margin-top: 280px;
    margin-bottom: 320px;
    padding-bottom: 0 !important;

    .primer-hr {
        margin: 0;
        border: none;
        border-top: 1px solid
    }
    
    hr {
        margin-bottom: 0;
    }

    .wrapper {
        padding-left: 0;
    }

    .planet_img-horizontal {
        display: none;
    }

    .planet_img-vertical {
        position: sticky;
        top: 0;
        max-width: 100%;
    }

    .planet_text {
        margin: 350px 0;

        h2 {
            margin-bottom: 40px;
        }

        &_p {
            margin-bottom: 40px;
        }

        .block-list {
            .block-list-container {
                hr {
                    display: none;
                }

                border-bottom: 1px $white solid;
            }
        }
    }

    @include rwd(1024){
        grid-template-columns: 1fr;
        padding-left: 100px;
        padding-right: 100px;
        margin-bottom: 240px;
        margin-top: 240px;

        .wrapper {
            padding-right: 120px;
            padding-left: 120px;
        }

        .planet_img-vertical {
            display: none;
        }
        .planet_img-horizontal {
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .planet_text {
            padding-top: 88px;
        }
    }

    @include rwd(864) {
        padding-left: 0;
        padding-right: 0;
    }

    @include device-md(){
        margin-bottom: 120px;
        margin-top: 120px;

        .planet_text {
            margin: 200px 0 240px;

            h2, .planet_text_p {
                margin-bottom: 32px;
            }
        }
        .wrapper {
            padding-right: 16px;
            padding-left: 16px;
        }
    }

    @include rwd(375){
        .planet_text {
            margin: 90px 0 120px;

            h2 {
                font-size: 43px;
            }
        }
    }
}
