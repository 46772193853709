.section-list-item-careers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 220px);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding-top: 60px;
    
    .--richtext, p {
        margin-bottom: 0 !important;
    }
    
    .item_1 { grid-area: 1 / 1 / 2 / 2; }
    .item_2 { grid-area: 2 / 1 / 3 / 2; }
    .item_3 { grid-area: 3 / 1 / 4 / 2; }
    .item_4 { grid-area: 4 / 1 / 5 / 2; }
    .item_5 { grid-area: 5 / 1 / 6 / 2; }
    .item_6 { grid-area: 1 / 2 / 3 / 4; }
    .item_7 { grid-area: 3 / 2 / 4 / 3; }
    .item_8 { grid-area: 4 / 2 / 5 / 3; }
    .item_9 { grid-area: 5 / 2 / 6 / 3; }
    .item_10 { grid-area: 3 / 3 / 5 / 4; }
    .item_11 { grid-area: 5 / 3 / 6 / 4; }

    @include device-sm() {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(11, max-content);
        padding-top: 44px;
        padding-bottom: 40px;

        .item_1 { grid-area: 1 / 1 / 2 / 2; }
        .item_2 { grid-area: 2 / 1 / 3 / 2; }
        .item_3 { grid-area: 3 / 1 / 4 / 2; }
        .item_4 { grid-area: 4 / 1 / 5 / 2; }
        .item_5 { grid-area: 5 / 1 / 6 / 2; }
        .item_6 { grid-area: 6 / 1 / 7 / 2; }
        .item_7 { grid-area: 7 / 1 / 8 / 2; }
        .item_8 { grid-area: 8 / 1 / 9 / 2; }
        .item_9 { grid-area: 9 / 1 / 10 / 2; }
        .item_10 { grid-area: 10 / 1 / 11 / 2; }
        .item_11 { grid-area: 11 / 1 / 12 / 2; }
    }
}

