@charset "UTF-8";
.footer {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
}
.footer-grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.7222222222;
  letter-spacing: normal;
  align-items: flex-start;
  border-top: 1px solid #000;
  grid-template-areas: "legal nav" "slogan nav" "social nav";
}
@media (max-width: 991px) {
  .footer-grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .footer-grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.footer-grid__slogan {
  grid-area: slogan;
  grid-column: 1/span 11;
}
.footer-grid__legal {
  grid-area: legal;
  grid-column: 1/span 11;
  width: 100%;
  max-width: 420px;
  padding-top: 24px;
}
.footer-grid__social {
  grid-area: social;
  grid-column: 1/span 11;
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
  margin-top: 37px;
}
.footer-grid__nav {
  grid-area: nav;
  grid-column: 13/span 11;
}
.footer-grid__nav .btn__list {
  border-width: 0;
}
@media (max-width: 767px) {
  .footer-grid {
    border: 0;
    grid-template-areas: "slogan" "nav" "legal" "social";
  }
  .footer-grid__slogan {
    grid-column: 1/span 7;
    margin-bottom: 14px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
  .footer-grid__legal {
    grid-column: 1/span 7;
    max-width: none;
    padding: 0;
    margin-top: 44px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.7142857143;
    letter-spacing: normal;
  }
  .footer-grid__social {
    grid-column: 1/span 7;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.7142857143;
    letter-spacing: normal;
  }
  .footer-grid__nav {
    grid-column: 1/span 7;
  }
  .footer-grid__nav .btn__list {
    border-width: 1px;
  }
}
.footer.--bg-black, .footer.--bg-red {
  color: #fff;
}
.footer.--bg-black .footer-grid, .footer.--bg-red .footer-grid {
  border-color: #fff;
}
.footer.--bg-black .footer-grid__nav .btn__list, .footer.--bg-red .footer-grid__nav .btn__list {
  border-color: #fff;
}
.footer.--bg-black .footer-grid__nav .btn__list .btn-arrow, .footer.--bg-red .footer-grid__nav .btn__list .btn-arrow {
  border-color: #fff;
  color: #fff;
}
.footer.--bg-black .footer-grid__nav .btn__list .btn-arrow .icon svg *, .footer.--bg-red .footer-grid__nav .btn__list .btn-arrow .icon svg * {
  fill: #fff;
}
.footer.--bg-black .footer-grid__nav .btn__list .btn-arrow:hover, .footer.--bg-black .footer-grid__nav .btn__list .btn-arrow:focus-within, .footer.--bg-red .footer-grid__nav .btn__list .btn-arrow:hover, .footer.--bg-red .footer-grid__nav .btn__list .btn-arrow:focus-within {
  color: #000;
}

.editable-modal {
  display: block;
  width: auto;
  max-width: 620px;
  margin: 0 auto;
  padding-right: 1rem;
  text-align: left;
}
.editable-modal__description {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.6923076923;
  letter-spacing: normal;
}
.editable-modal__title {
  margin-bottom: 1em;
}
.editable-modal__title code {
  position: relative;
  display: inline-block;
}
.editable-modal__title code::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120%;
  height: 140%;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: url("/static/website/images/word-mark.svg") no-repeat center center;
  background-size: contain;
}
.editable-modal__title p {
  margin-bottom: 0;
}
.editable-modal__content s {
  text-decoration: none;
  border-bottom: 1px solid #FFFF00;
}
@media (max-width: 767px) {
  .editable-modal__description {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .editable-modal {
    padding-right: 0;
  }
  .editable-modal__description {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}

.youtube-video-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 !important;
}
.youtube-video-modal #modalVideoEmbed {
  aspect-ratio: 16/9;
  width: 100%;
}

.tnd-badge, .apply-badge {
  max-width: 320px;
  max-height: 320px;
}
.tnd-badge .shadow, .apply-badge .shadow {
  fill: #000;
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}
.tnd-badge .badge, .apply-badge .badge {
  transition: transform 0.2s ease-in-out;
  transform-origin: center;
}
.tnd-badge .badge .title, .apply-badge .badge .title {
  transition: transform 0.2s ease-in-out;
  transform-origin: center;
}
.tnd-badge .badge .text-circle, .apply-badge .badge .text-circle {
  animation: rotation 15s infinite linear;
  transform-origin: center;
}
.tnd-badge:hover .badge, .apply-badge:hover .badge {
  transform: translate(-0.5em, -0.5em);
}
.tnd-badge:hover .badge .title, .apply-badge:hover .badge .title {
  transform: rotate(-10deg);
}
.tnd-badge:hover .shadow, .apply-badge:hover .shadow {
  transform: scale(1.05);
}

.smile-badge {
  width: 137px;
  height: 137px;
}
@media (min-width: 992px) {
  .smile-badge {
    width: 180px;
    height: 180px;
  }
}
.smile-badge .text-circle {
  animation: rotation 15s infinite linear;
  transform-origin: center;
}

.apply-badge {
  overflow: visible;
  width: 400px;
  height: 400px;
  margin-top: 120px;
}
@media (max-width: 575px) {
  .apply-badge {
    width: 340px;
    height: 340px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.--bg-black .tnd-badge .shadow,
.--bg-red .tnd-badge .shadow {
  fill: #fff;
}
.--bg-black .smile-badge__back,
.--bg-red .smile-badge__back {
  fill: #fff;
}

.ego-modal {
  display: none;
  position: fixed;
  z-index: 401;
  inset: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 0;
}
.ego-modal__wrapper {
  display: grid;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 0;
}
.ego-modal__box {
  position: relative;
  inset: 0;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: #fff;
  cursor: default;
  transition: top 0.2s ease-in-out;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}
.ego-modal__box__close-btn {
  position: absolute;
  z-index: 10;
  inset: 35px 32px auto auto;
  border: 0;
  outline: none;
  padding: 5px 8px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
}
.ego-modal__box__close-btn span {
  text-decoration: underline;
}
.ego-modal__box__close-btn .times {
  width: 20px;
  height: 20px;
}
.ego-modal__box__close-btn .times * {
  stroke: #000;
}
.ego-modal__box__close-btn:hover {
  background-color: #FFFF00;
  color: #000;
}
.ego-modal__box__close-btn:hover span {
  text-decoration: none;
}
.ego-modal__box__close-btn:hover .times * {
  stroke: #000;
}
.ego-modal__box__body {
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 120px 40px;
  overflow: auto;
}
.ego-modal__box__body-wrapper {
  display: block;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-height: 100%;
}
.ego-modal__box__body-wrapper.--vcenter {
  display: flex;
  flex-flow: column nowrap;
}
.ego-modal__box__body-wrapper.--vcenter > * {
  margin: auto;
}
@media (max-width: 767px) {
  .ego-modal__box__body-wrapper {
    max-width: 570px;
  }
}
.ego-modal__content-block {
  margin-bottom: 30px;
}
.ego-modal.--open .ego-modal__box {
  top: 0;
}
@media (max-width: 767px) {
  .ego-modal {
    padding: 0;
  }
  .ego-modal__wrapper {
    align-items: end;
  }
  .ego-modal__box {
    display: grid;
    grid-template-rows: 1fr auto;
  }
  .ego-modal__box__close-btn {
    inset: 14px 14px auto auto;
  }
  .ego-modal__box__body {
    padding: 120px 16px;
  }
}
@media (max-width: 575px) {
  .ego-modal__box__body {
    padding: 60px 16px;
  }
}
.ego-modal.--dark-mode .ego-modal__box {
  background-color: #000;
  color: #fff;
}
.ego-modal.--dark-mode .ego-modal__box__close-btn {
  color: #fff;
  transition: color 0.2s ease-in-out;
}
.ego-modal.--dark-mode .ego-modal__box__close-btn .times * {
  stroke: #fff;
  transition: stroke 0.2s ease-in-out;
}
.ego-modal.--dark-mode .ego-modal__box__close-btn:hover {
  color: #000;
}
.ego-modal.--dark-mode .ego-modal__box__close-btn:hover .times * {
  stroke: #000;
}

.ego-form {
  width: 100%;
  max-width: 620px;
  margin: 0 auto;
}
.ego-form__title {
  display: block;
  margin-bottom: 15px;
}
.ego-form__title h2 {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .ego-form__title h2 {
    font-size: 34px;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.1764705882;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  .ego-form__title h2 {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: normal;
  }
}
.ego-form__title h2 img {
  width: 1em;
  vertical-align: baseline;
}
.ego-form__title h2 strong {
  font-weight: 600;
}
.ego-form__title h2:empty {
  display: none;
}
.ego-form__title h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 0;
}
.ego-form__title h3:empty {
  display: none;
}
.ego-form__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "." "footer";
  column-gap: 40px;
}
.ego-form__grid__column {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
}
.ego-form.--two-columns {
  max-width: 840px;
}
.ego-form.--two-columns .ego-form__grid {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: ". ." "footer footer";
}
.ego-form__step {
  min-height: 100%;
  display: none;
  flex-flow: column nowrap;
  gap: 25px;
}
.ego-form__step.--active {
  display: flex;
}
.ego-form__step .step {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 10;
  letter-spacing: 0.1em;
  display: block;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.ego-form__columns {
  height: 100%;
}
.ego-form__alert-wrapper {
  grid-area: alerts;
}
.ego-form__field {
  position: relative;
}
.ego-form__field-grid {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  column-gap: 15px;
}
.ego-form__field__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 45px;
}
.ego-form__field__select::-ms-expand {
  display: none;
}
.ego-form__field__input, .ego-form__field__select, .ego-form__field__radio-label, .ego-form__field__file {
  font-family: "Switzer", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  position: relative;
  z-index: 0;
  width: 100%;
  background-color: #f1f1f1;
  border: 1px solid transparent;
  outline: 1px solid transparent;
  padding: 1.8rem 1rem 0.8rem 1rem;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, outline-color 0.2s ease-in-out;
}
.ego-form__field__input:hover, .ego-form__field__select:hover, .ego-form__field__radio-label:hover, .ego-form__field__file:hover {
  border-color: #000;
}
.ego-form__field__input:focus, .ego-form__field__select:focus, .ego-form__field__radio-label:focus, .ego-form__field__file:focus {
  border-color: #000;
  background-color: #fff;
  z-index: 1;
  outline-color: #000;
}
.ego-form__field__input:focus + label, .ego-form__field__select:focus + label, .ego-form__field__radio-label:focus + label, .ego-form__field__file:focus + label {
  top: 0.7rem;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
}
@media (max-width: 767px) {
  .ego-form__field__input:focus + label, .ego-form__field__select:focus + label, .ego-form__field__radio-label:focus + label, .ego-form__field__file:focus + label {
    top: 0.5rem;
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
  }
}
.ego-form__field__textarea {
  padding-top: 1.4rem;
  height: 100%;
  resize: none;
}
.ego-form__field__textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-style: italic;
}
.ego-form__field__textarea::-moz-placeholder { /* Firefox 19+ */
  font-style: italic;
}
.ego-form__field__textarea:-moz-placeholder { /* Firefox 18- */
  font-style: italic;
}
.ego-form__field.--radio {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 1px;
}
.ego-form__field.--radio .ego-form__field__search {
  text-align: center;
}
.ego-form__field.--radio .ego-form__field__radio {
  cursor: pointer;
}
.ego-form__field.--radio .ego-form__field__radio-label {
  text-align: center;
  outline: none;
  padding: 0.8rem;
}
.ego-form__field.--radio .ego-form__field__radio:hover-label {
  border-color: #000;
}
.ego-form__field.--radio .ego-form__field__radio input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.ego-form__field.--radio .ego-form__field__radio input:checked + .ego-form__field__radio-label {
  border-color: #000;
  background-color: #fff;
  z-index: 1;
}
.ego-form__field.--radio .ego-form__field__radio.--hidden {
  display: none;
}
.ego-form__field.--file .ego-form__field__file {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding: 1.3rem 1rem 1.3rem 1rem;
  cursor: pointer;
}
.ego-form__field.--file .ego-form__field__file .attachment-control {
  display: flex;
  align-items: center;
  min-height: 22px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info {
  display: grid;
  grid-template-columns: auto minmax(60px, 1fr);
  align-items: center;
  gap: 10px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-name {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  color: #6F6F6F;
  min-width: 60px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size i {
  color: #ff0000;
  text-transform: uppercase;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size:empty:before {
  content: attr(data-placeholder);
}
.ego-form__field.--file .ego-form__field__file .icon__clip {
  height: 1rem;
}
.ego-form__field.--file .ego-form__field__file .attachment-remove {
  display: none;
  cursor: pointer;
  border: 0;
  background: none;
  outline: none;
  padding: 0;
  margin: 0 0 0 auto;
  font-size: 23px;
  line-height: 0.8;
}
.ego-form__field.--file.--has-file .ego-form__field__file {
  border-color: #000;
  background-color: #fff;
  z-index: 1;
}
.ego-form__field.--file.--has-file .ego-form__field__file .icon__clip {
  display: none;
}
.ego-form__field.--file.--has-file .ego-form__field__file .attachment-remove {
  display: block;
}
.ego-form__field.--file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.ego-form__field__label {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  left: 1rem;
  top: 1.3rem;
  position: absolute;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.ego-form__field__error {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  position: absolute;
  inset: 1px 1px auto auto;
  z-index: 3;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  height: calc(100% - 2px);
  max-width: 50%;
  text-align: right;
  color: #000;
  background-color: #fff;
  padding: 0 1.2rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.ego-form__field__error strong {
  font-weight: 400;
}
.ego-form__field__error.--active {
  opacity: 1;
}
.ego-form__field.--filled .ego-form__field__input,
.ego-form__field.--filled .ego-form__field__select, .ego-form__field.--filled.--textarea {
  border-color: #000;
  background-color: #fff;
  z-index: 1;
}
.ego-form__field.--filled .ego-form__field__label {
  top: 0.7rem;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
}
@media (max-width: 767px) {
  .ego-form__field.--filled .ego-form__field__label {
    top: 0.5rem;
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
  }
}
.ego-form__field.--optional::after, .ego-form__field.--custom-assistive::after {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.1em;
  content: "OPTIONAL";
  position: absolute;
  right: 1rem;
  top: 1.9rem;
  color: #000;
  pointer-events: none;
}
@media (max-width: 767px) {
  .ego-form__field.--optional::after, .ego-form__field.--custom-assistive::after {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.1em;
    right: 1rem;
    top: 1.3rem;
  }
}
.ego-form__field.--optional::after {
  content: "OPTIONAL";
}
.ego-form__field.--custom-assistive::after {
  content: attr(data-assistive);
}
.ego-form__field.--has-error .ego-form__field__input,
.ego-form__field.--has-error .ego-form__field__select,
.ego-form__field.--has-error .ego-form__field__file, .ego-form__field.--has-error.--textarea {
  background-color: #fff;
  border-color: #ff0000;
}
.ego-form__field.--has-error .ego-form__field__error {
  opacity: 1;
}
.ego-form__field.--has-error.--optional:after {
  display: none !important;
}
.ego-form__field.--full-height {
  height: 100%;
}
.ego-form__field.--textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  background-color: #f1f1f1;
  border: 1px solid transparent;
  outline: 1px solid transparent;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, outline-color 0.2s ease-in-out;
}
.ego-form__field.--textarea:hover {
  border-color: #000;
}
.ego-form__field.--textarea:focus-within {
  border-color: #000;
  background-color: #fff;
  z-index: 1;
  outline-color: #000;
}
.ego-form__field.--textarea textarea {
  display: block;
  font-family: "Switzer", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  margin: 1.8rem 1rem 0 1rem;
  border: 0;
  background: transparent;
  outline: none !important;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.ego-form__field.--textarea textarea.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.ego-form__field.--textarea.--big {
  min-height: 280px;
}
.ego-form__field.--textarea .ego-form__field__label {
  opacity: 0.5;
  font-style: italic;
}
.ego-form__field.--textarea .ego-form__field__error {
  inset: 1px 1px auto auto;
  height: auto;
  width: fit-content;
  background-color: none;
  max-width: none;
  min-height: 70px;
  max-height: 50%;
}
.ego-form__field.--textarea .ego-form__field__footer {
  position: relative;
  height: auto;
  width: calc(100% - 2px);
  max-width: none;
  min-height: 50px;
  max-height: 50%;
  padding: 0 1rem 1rem;
  display: grid;
  grid-template-columns: auto 95px;
  align-items: end;
  gap: 20px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment {
  min-width: 0;
  position: relative;
  z-index: 1;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-control {
  display: flex;
  align-items: center;
  min-height: 22px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info {
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info .attachment-file-name {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  max-width: 100%;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info .attachment-file-size {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  color: #6F6F6F;
  min-width: 60px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info .attachment-file-size i {
  color: #ff0000;
  text-transform: uppercase;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info .attachment-file-size:empty:before {
  content: attr(data-placeholder);
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .icon__clip {
  display: block;
  width: 8px;
  height: 16px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-remove {
  display: none;
  cursor: pointer;
  border: 0;
  background: none;
  outline: none;
  padding: 0;
  margin: 0 0 0 auto;
  font-size: 23px;
  line-height: 0.6;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment.--has-file .icon__clip {
  display: none;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment.--has-file .attachment-remove {
  display: block;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__length-counter {
  position: relative;
  z-index: 1;
  text-align: right;
  margin-left: auto;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__progress-bar {
  content: "";
  position: absolute;
  inset: 0 auto 0 0;
  width: 0%;
  background-color: #FFFF00;
}
.ego-form__field.--select::after {
  content: "↓";
  position: absolute;
  z-index: 2;
  right: 1.2rem;
  top: calc(1.3rem + 10px);
  font-weight: 600;
  line-height: 1;
  font-size: 16px;
}
.ego-form__field__toggle-visibility {
  position: absolute;
  z-index: 1;
  right: calc(1.1rem - 5px);
  top: calc(1.3rem + 5px);
  border: 0;
  background: none;
  outline: none;
  padding: 4px;
  margin: 0;
  cursor: pointer;
}
.ego-form__field__toggle-visibility .show {
  width: 15px;
  display: none;
}
.ego-form__field__toggle-visibility .hide {
  width: 15px;
  display: block;
}
.ego-form__field__toggle-visibility.--hide .hide {
  display: none;
}
.ego-form__field__toggle-visibility.--hide .show {
  display: block;
}
.ego-form__field__instruction {
  display: block;
  padding: 0 1rem;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875;
  letter-spacing: normal;
}
.ego-form__field__search {
  position: relative;
}
.ego-form__field__search .placeholder {
  position: absolute;
  inset: 0;
  width: 100%;
  padding: 0.8rem;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Switzer", sans-serif;
  color: #6F6F6F;
  pointer-events: none;
}
.ego-form__field__search .placeholder svg {
  display: inline-block;
  margin-left: 5px;
}
.ego-form__field__search .placeholder svg * {
  fill: #6F6F6F;
}
.ego-form__field__search input {
  text-align: center;
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #f1f1f1;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Switzer", sans-serif;
}
.ego-form__field__search input::-webkit-input-placeholder {
  color: transparent;
}
.ego-form__field__search input::-moz-placeholder {
  color: transparent;
}
.ego-form__field__search input:-moz-placeholder {
  color: transparent;
}
.ego-form__field__search input:focus + .placeholder, .ego-form__field__search input:not(:placeholder-shown) + .placeholder {
  display: none;
}
.ego-form__field__search .empty {
  display: none;
  margin-bottom: 0;
  padding: 1rem;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: #6F6F6F;
}
.ego-form__field__search .empty.--show {
  display: block;
}
.ego-form__footer {
  grid-area: footer;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 15px;
  text-align: right;
}
.ego-form__footer__privacy {
  grid-column: 1/3;
  text-align: left;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .ego-form__footer__privacy {
    margin-bottom: 80px;
  }
}
.ego-form__footer__privacy p {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 0;
  color: #000;
}
.ego-form__footer__privacy p a {
  color: inherit;
}
@media (max-width: 575px) {
  .ego-form__footer__privacy p {
    font-size: 14px;
    line-height: 21px;
  }
}
@media (min-width: 1440px) {
  .ego-form__footer .--small-form {
    grid-column: 1;
  }
}
.ego-form__footer .col1 {
  grid-column: 1;
}
.ego-form__footer .col2 {
  grid-column: 2;
}
.ego-form.--two-columns .ego-form__footer {
  grid-template-columns: repeat(4, 1fr);
}
.ego-form.--two-columns .ego-form__footer .col1 {
  grid-column: 1;
}
.ego-form.--two-columns .ego-form__footer .col2 {
  grid-column: 2;
}
.ego-form.--two-columns .ego-form__footer .col3 {
  grid-column: 3;
}
.ego-form.--two-columns .ego-form__footer .col4 {
  grid-column: 4;
}
.ego-form__message {
  display: none;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  inset: 0;
  z-index: 3;
  background-color: #fff;
}
.ego-form__message.--visible {
  display: flex;
}
.ego-form__message img {
  margin-bottom: 40px;
  max-width: 132px;
}
.ego-form__message p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
  max-width: 650px;
  margin-bottom: 1em;
}
.ego-form__message p:last-of-type {
  margin-bottom: 2em;
}
@media (max-width: 767px) {
  .ego-form {
    max-width: 570px;
  }
  .ego-form__title {
    margin-bottom: 0px;
  }
  .ego-form__title h2 {
    font-size: 34px;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.1764705882;
    letter-spacing: -0.02em;
  }
  .ego-form__title h3 {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
  .ego-form__grid {
    row-gap: 8px;
  }
  .ego-form__grid__column {
    gap: 8px;
  }
  .ego-form.--two-columns .ego-form__grid {
    grid-template-columns: 1fr;
    grid-template-areas: "." "footer";
    row-gap: 8px;
  }
  .ego-form.--two-columns .ego-form__footer {
    grid-template-columns: repeat(2, 1fr);
  }
  .ego-form.--two-columns .ego-form__footer .col1,
  .ego-form.--two-columns .ego-form__footer .col2 {
    grid-column: 1;
  }
  .ego-form.--two-columns .ego-form__footer .col3,
  .ego-form.--two-columns .ego-form__footer .col4 {
    grid-column: 2;
  }
  .ego-form__field__input, .ego-form__field__textarea, .ego-form__field__select, .ego-form__field__radio-label, .ego-form__field__file {
    padding: 1.13rem 0.9rem 0.4rem 0.9rem;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
  }
  .ego-form__field__textarea {
    padding-top: 0.8rem;
  }
  .ego-form__field.--textarea textarea {
    margin-top: 0.8rem;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
  }
  .ego-form__field.--textarea .ego-form__field__error {
    min-height: 48px;
  }
  .ego-form__field.--textarea .ego-form__field__footer {
    grid-template-columns: auto 80px;
  }
  .ego-form__field.--textarea .ego-form__field__footer .ego-form__field__length-counter {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
  }
  .ego-form__field[data-type=file] .ego-form__field__file {
    padding: 0.765rem 0.9rem 0.765rem 0.9rem;
  }
  .ego-form__field.--optional::after {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
    top: 1.25rem;
    right: 1rem;
  }
  .ego-form__field__label {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
    top: 0.8rem;
  }
  .ego-form__field__instruction {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    letter-spacing: normal;
    margin-top: 8px;
  }
  .ego-form__message p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .ego-form {
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 25px;
  }
  .ego-form__title {
    margin-bottom: 0;
  }
  .ego-form__title h2 {
    font-size: 33px;
    font-size: 2.0625rem;
    font-weight: 400;
    line-height: 1.0909090909;
    letter-spacing: normal;
  }
  .ego-form__title h3 {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
  .ego-form__field.--textarea textarea {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
  }
  .ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info {
    gap: 5px;
    flex-direction: column;
  }
  .ego-form__footer {
    margin-top: auto;
    gap: 1rem;
  }
  .ego-form__footer .col1,
  .ego-form__footer .col2 {
    grid-column: 1/-1;
  }
  .ego-form__footer .col1:only-child,
  .ego-form__footer .col2:only-child {
    grid-column: 1/span 2;
  }
  .ego-form.--two-columns .ego-form__footer {
    grid-template-columns: 1fr;
  }
  .ego-form.--two-columns .ego-form__footer .col1,
  .ego-form.--two-columns .ego-form__footer .col2,
  .ego-form.--two-columns .ego-form__footer .col3,
  .ego-form.--two-columns .ego-form__footer .col4 {
    grid-column: auto;
  }
  .ego-form__message br {
    display: none;
  }
  .ego-form__message p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.ego-form__subscribe {
  min-height: 100vh;
  background-color: #000;
  padding-top: 120px;
  z-index: -15;
  position: relative;
}
.ego-form__subscribe__content {
  display: none;
}
.ego-form__subscribe__content.--show {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 120px;
  right: 50%;
  transform: translate(50%);
  z-index: -10;
}
.ego-form__subscribe__title {
  color: #fff;
}
.ego-form__subscribe__form {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 620px;
  row-gap: 16px;
}
@media (max-width: 1024px) {
  .ego-form__subscribe__form {
    width: 422px;
  }
}
@media (max-width: 768px) {
  .ego-form__subscribe__form {
    width: 100%;
  }
}
.ego-form__subscribe__form-input {
  width: 100%;
  height: 70px;
  background-color: #F1F1F1;
  border: none;
  margin-bottom: 16px;
  padding-left: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
}
.ego-form__subscribe__form-input:focus {
  outline: none;
}
.ego-form__subscribe__form-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
@media (max-width: 768px) {
  .ego-form__subscribe__form-button-container {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }
}
.ego-form__subscribe__form-button-container p {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
}
@media (max-width: 1024px) {
  .ego-form__subscribe__form-button-container p {
    line-height: 130%;
  }
}
@media (max-width: 768px) {
  .ego-form__subscribe__form-button-container p {
    margin-bottom: 80px;
  }
}
.ego-form__subscribe__form-button-container p a {
  color: #fff;
}
.ego-form__subscribe__form-button {
  width: 290px;
  height: 60px;
  background-color: #F1F1F1;
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
}
@media (max-width: 1024px) {
  .ego-form__subscribe__form-button {
    width: 200px;
  }
}
@media (max-width: 768px) {
  .ego-form__subscribe__form-button {
    width: 100%;
  }
}
.ego-form__subscribe.--bg-white {
  background-color: #fff;
}
.ego-form__subscribe.--bg-white .ego-form__subscribe__title {
  color: #000;
}
.ego-form__subscribe.--bg-white .ego-form__subscribe__policy {
  color: #000;
}
.ego-form__subscribe.--bg-white .ego-form__subscribe__policy a {
  color: #000;
}
.ego-form__subscribe.--show-static {
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 40px;
  min-height: auto;
}
.ego-form__subscribe.--show-static .ego-form__subscribe__content {
  display: flex;
  flex-direction: column;
}
.ego-form__subscribe.--show-static .ego-form__subscribe__title {
  color: #000;
  border-top: 1px solid #000;
  padding-top: 120px;
}
@media (max-width: 1024px) {
  .ego-form__subscribe.--show-static .ego-form__subscribe__title {
    margin-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .ego-form__subscribe.--show-static .ego-form__subscribe__title {
    padding-top: 60px;
  }
}
.ego-form__subscribe.--show-static .ego-form__subscribe__form-button-container p {
  color: #000;
}
.ego-form__subscribe.--show-static .ego-form__subscribe__form-button-container p a {
  color: #000;
}

.list-item {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 32px;
  align-items: flex-start;
}
@media (max-width: 991px) {
  .list-item {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .list-item {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .list-item > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .list-item > *:nth-child(4n+1) {
    grid-column: 1/span 1;
  }
  .list-item > *:nth-child(4n+2) {
    grid-column: 3/span 1;
  }
  .list-item > *:nth-child(4n+3) {
    grid-column: 5/span 1;
  }
  .list-item > *:nth-child(4n) {
    grid-column: 7/span 1;
  }
}
@media (min-width: 768px) {
  .list-item > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .list-item > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .list-item > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .list-item > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
@media (min-width: 992px) {
  .list-item > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .list-item > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .list-item > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .list-item > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
.list-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 32px;
  gap: 16px;
  border-top: 2px solid #000;
}
@media (min-width: 768px) {
  .list-item-container {
    gap: 16px;
    padding-top: 16px;
  }
}
@media (min-width: 992px) {
  .list-item-container {
    gap: 24px;
  }
}
.list-item-container:last-of-type {
  padding-right: 0;
}
.list-item-container__title {
  font-weight: 700;
}
@media (max-width: 991px) {
  .list-item-container__title {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.4444444444;
    letter-spacing: 0em;
  }
}
@media (max-width: 575px) {
  .list-item-container__title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}
.list-item-container__description a {
  text-decoration: underline;
  color: #000;
}
.list-item-container__description > p:last-child {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .list-item-container__description {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 575px) {
  .list-item-container__description {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}

.list-item-icon-container {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .list-item-icon-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.list-item-icon.--desk {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 0;
  align-items: flex-start;
}
@media (max-width: 991px) {
  .list-item-icon.--desk {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .list-item-icon.--desk {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .list-item-icon.--desk > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .list-item-icon.--desk > *:nth-child(4n+1) {
    grid-column: 1/span 1;
  }
  .list-item-icon.--desk > *:nth-child(4n+2) {
    grid-column: 3/span 1;
  }
  .list-item-icon.--desk > *:nth-child(4n+3) {
    grid-column: 5/span 1;
  }
  .list-item-icon.--desk > *:nth-child(4n) {
    grid-column: 7/span 1;
  }
}
@media (min-width: 768px) {
  .list-item-icon.--desk > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .list-item-icon.--desk > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .list-item-icon.--desk > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .list-item-icon.--desk > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
@media (min-width: 992px) {
  .list-item-icon.--desk > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .list-item-icon.--desk > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .list-item-icon.--desk > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .list-item-icon.--desk > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
@media (max-width: 575px) {
  .list-item-icon.--desk {
    display: none;
  }
}
@media (min-width: 576px) {
  .list-item-icon.--mobile {
    display: none;
  }
}
.list-item-icon.--mobile.wrapper {
  padding-left: 0;
  padding-right: 0;
}
.list-item-icon.--mobile .swiper-slide {
  height: 100%;
}
.list-item-icon__box {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  min-height: 240px;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .list-item-icon__box {
    gap: 100px;
  }
}
@media (min-width: 576px) {
  .list-item-icon__box:nth-of-type(n+5) {
    /* Tus estilos CSS aquí */
    border-top: 0px;
  }
}
.list-item-icon__box img {
  display: block;
  max-width: 100%;
  height: auto;
  max-width: 24px;
}
.list-item-icon__title {
  font-weight: 700;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .list-item-icon__title {
    margin-bottom: 8px;
  }
}
@media (max-width: 991px) {
  .list-item-icon__title {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.4444444444;
    letter-spacing: 0em;
  }
}
@media (max-width: 575px) {
  .list-item-icon__title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}
.list-item-icon__description {
  margin-bottom: 0;
}
.list-item-icon__description a {
  text-decoration: underline;
  color: #000;
}
@media (max-width: 1024px) {
  .list-item-icon__description {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 575px) {
  .list-item-icon__description {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}

.portfolio-list {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 40px;
  align-items: unset;
}
@media (max-width: 991px) {
  .portfolio-list {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .portfolio-list {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .portfolio-list > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .portfolio-list > *:nth-child(2n+1) {
    grid-column: 1/span 3;
  }
  .portfolio-list > *:nth-child(2n) {
    grid-column: 5/span 3;
  }
}
@media (min-width: 768px) {
  .portfolio-list > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .portfolio-list > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .portfolio-list > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .portfolio-list > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 1200px) {
  .portfolio-list {
    row-gap: 80px;
  }
}

.portfolio-box {
  color: #000;
  text-decoration: none;
  display: block;
}
.portfolio-box__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  transition: all 0.2s ease-in-out;
  border-top: 1px solid #000;
  padding-top: 16px;
}
@media (min-width: 768px) {
  .portfolio-box__container {
    gap: 60px;
    padding-top: 23px;
  }
}
@media (min-width: 1200px) {
  .portfolio-box__container {
    gap: 91px;
  }
}
.portfolio-box__container header {
  padding-right: 32px;
  max-width: 100%;
}
@media (min-width: 1200px) {
  .portfolio-box__container header {
    padding-right: 48px;
  }
}
.portfolio-box__container:hover, .portfolio-box__container:focus-within {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  background-color: #000;
  color: #fff;
}
@media (min-width: 1200px) {
  .portfolio-box__container:hover, .portfolio-box__container:focus-within {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }
}
.portfolio-box__container:hover h3, .portfolio-box__container:focus-within h3 {
  color: #FFFF00;
}
.portfolio-box__container:hover .portfolio-box__thumb, .portfolio-box__container:focus-within .portfolio-box__thumb {
  height: 244px;
}
@media (min-width: 992px) {
  .portfolio-box__container:hover .portfolio-box__thumb, .portfolio-box__container:focus-within .portfolio-box__thumb {
    height: 424px;
  }
}
@media (min-width: 1200px) {
  .portfolio-box__container:hover .portfolio-box__thumb, .portfolio-box__container:focus-within .portfolio-box__thumb {
    height: 596px;
  }
}
.portfolio-box__container:hover .portfolio-box__client, .portfolio-box__container:focus-within .portfolio-box__client {
  opacity: 0.5;
}
.portfolio-box__container:hover header, .portfolio-box__container:focus-within header {
  padding-right: 0;
}
.portfolio-box__title {
  margin-bottom: 0;
}
.portfolio-box__client {
  font-size: 16px;
  line-height: 28px;
}
@media (min-width: 768px) {
  .portfolio-box__client {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (min-width: 1200px) {
  .portfolio-box__client {
    font-size: 20px;
    line-height: 34px;
  }
}
.portfolio-box__thumb {
  position: relative;
  height: 260px;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 992px) {
  .portfolio-box__thumb {
    height: 440px;
  }
}
@media (min-width: 1200px) {
  .portfolio-box__thumb {
    height: 620px;
  }
}
.portfolio-box__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.--bg-black .portfolio-box__container {
  color: #fff;
  border-color: #fff;
}
.--bg-black .portfolio-box__container:hover, .--bg-black .portfolio-box__container:focus-within {
  background-color: #FFFF00;
  border-color: #FFFF00;
  color: #000;
}
.--bg-black .portfolio-box__container:hover h3, .--bg-black .portfolio-box__container:focus-within h3 {
  color: #000;
}
.--bg-black .portfolio-box__container:hover .portfolio-box__client, .--bg-black .portfolio-box__container:focus-within .portfolio-box__client {
  opacity: 1;
}
.--bg-black .portfolio-box__client {
  opacity: 0.5;
}

.--coming-soon {
  pointer-events: none;
}

.main-header {
  overflow: hidden;
  padding-bottom: 0;
}
.main-header__h1-seo-friendly {
  margin-bottom: 32px;
  font-size: 180px;
  font-size: 11.25rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.04em;
  margin-bottom: 0.3em;
}
@media (max-width: 1199px) {
  .main-header__h1-seo-friendly {
    font-size: 120px;
    font-size: 7.5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 767px) {
  .main-header__h1-seo-friendly {
    font-size: 70px;
    font-size: 4.375rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 575px) {
  .main-header__h1-seo-friendly {
    font-size: 56px;
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 767px) {
  .main-header__h1-seo-friendly {
    margin-bottom: 16px;
  }
}
.main-header__h1-seo-friendly img {
  height: 0.8em;
  vertical-align: baseline;
}
.main-header h1 {
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .main-header h1 {
    margin-bottom: 16px;
  }
}
.main-header h1 img {
  height: 0.8em;
  vertical-align: baseline;
}
.main-header a {
  color: #000;
}
.main-header p:last-of-type {
  margin-bottom: 0;
}
.main-header_text, .main-header_text_link {
  margin: 0;
  width: 100%;
}
.main-header_text p,
.main-header_text ul,
.main-header_text ol,
.main-header_text a, .main-header_text_link p,
.main-header_text_link ul,
.main-header_text_link ol,
.main-header_text_link a {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: normal;
}
@media (min-width: 576px) {
  .main-header_text p,
  .main-header_text ul,
  .main-header_text ol,
  .main-header_text a, .main-header_text_link p,
  .main-header_text_link ul,
  .main-header_text_link ol,
  .main-header_text_link a {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: normal;
  }
}
@media (min-width: 1200px) {
  .main-header_text p,
  .main-header_text ul,
  .main-header_text ol,
  .main-header_text a, .main-header_text_link p,
  .main-header_text_link ul,
  .main-header_text_link ol,
  .main-header_text_link a {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 400;
    line-height: 1.6923076923;
    letter-spacing: normal;
  }
}
@media (min-width: 768px) {
  .main-header_text, .main-header_text_link {
    max-width: 616px;
  }
}
.main-header_link {
  color: #000;
}
.main-header_separator {
  margin-top: 80px;
  position: relative;
  margin-bottom: 0;
  color: #000;
}
@media (max-width: 767px) {
  .main-header_separator {
    margin-top: 60px;
    position: unset;
  }
}
.main-header_separator hr {
  border: none;
  border-top: 2px solid #000;
  height: 2px;
}
.main-header_separator .tnd-badge {
  display: none;
}
@media (min-width: 992px) {
  .main-header_separator .tnd-badge {
    display: unset;
    position: absolute;
    bottom: -100px;
    right: 0;
    transform: translateX(44%);
  }
}
@media (min-width: 1200px) {
  .main-header_separator .tnd-badge {
    transform: translateX(-15%);
  }
}
.main-header_extra {
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .main-header_extra {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.block-list-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: flex-start;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #000;
}
@media (max-width: 991px) {
  .block-list-container {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .block-list-container {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.block-list-container:last-child {
  border-bottom: 0;
}
.block-list-container__title {
  grid-column: 1/-1;
  font-weight: 700 !important;
  line-height: 1.7;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .block-list-container__title {
    margin-bottom: 0px;
    grid-column: 1/span 9;
  }
}
@media (min-width: 768px) {
  .block-list-container__title--show-arrows {
    grid-column: 1/span 9;
  }
}
.block-list-container__title p:last-child {
  margin-bottom: 0 !important;
}
.block-list-container__description {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .block-list-container__description {
    margin-bottom: 0px;
    grid-column: 13/-1;
  }
}
@media (min-width: 768px) {
  .block-list-container__description--show-arrows {
    grid-column: 11/-1;
  }
}
.block-list-container__description ul {
  list-style: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7214 4.61415L11.5263 0.138499C11.2872 -0.0672882 10.9279 -0.0406405 10.723 0.197719C10.5167 0.436078 10.5434 0.794345 10.7809 1.00014L14.7643 4.43043L0.570138 4.42895C0.255376 4.42895 0 4.68359 0 4.99745C0 5.31131 0.255376 5.56596 0.570138 5.56596L14.9068 5.56596L12.6367 7.58681L10.8639 8.98586C10.6174 9.18128 10.5743 9.53807 10.7703 9.78385C10.8832 9.92598 11.0495 10 11.2187 10C11.342 10 11.4667 9.96003 11.5721 9.87712L13.3716 8.45733L16.7315 5.46973C16.8533 5.36165 16.9231 5.20472 16.9231 5.04039C16.9171 4.87606 16.8459 4.72209 16.7212 4.61401L16.7214 4.61415Z' fill='black'/%3E%3C/svg%3E%0A");
}
@media (max-width: 767px) {
  .block-list-container__description ul {
    padding-left: 25px;
  }
}
.block-list-container__description ul li {
  line-height: 1.7;
  margin-bottom: 24px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .block-list-container__description ul li {
    padding-left: 20px;
  }
}
@media (min-width: 992px) {
  .block-list-container__description ul li {
    padding-left: 40px;
  }
}
.block-list-container__description *:last-child {
  margin-bottom: 0 !important;
}

.cta-box {
  border-top: 1px solid #000;
  max-width: 400px;
  height: 450px;
  min-height: 280px;
  position: relative;
}
.cta-box p {
  margin: 0;
}
.cta-box a {
  background-color: #fff;
  color: #000;
  text-decoration: none;
  margin: 0;
}
.cta-box a .cta-box_arrow {
  margin: 0;
  margin-top: 75px;
  height: 69px;
  width: auto;
  visibility: hidden;
  transition: visibility 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .cta-box a .cta-box_arrow {
    visibility: visible;
  }
}
.cta-box a h4 {
  font-size: 56px;
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 0.8571428571;
  letter-spacing: -0.06em;
  font-style: italic;
  position: absolute;
  bottom: 60px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .cta-box a h4 {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.06em;
  }
}
.cta-box a .cta-box-underline {
  width: 100%;
  background-image: linear-gradient(transparent calc(100% - 3px), #FFFF00 3px);
  background-repeat: no-repeat;
  background-size: 0% 90%;
  transition: background-size 1.5s;
}
.cta-box a .cta-box_text {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0em;
  text-decoration: none;
  margin: 0;
  margin-top: 24px;
  position: absolute;
  bottom: 0;
}
@media (max-width: 767px) {
  .cta-box a .cta-box_text {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 2.5714285714;
    letter-spacing: 0em;
    text-decoration: underline;
  }
}
@media (max-width: 767px) {
  .cta-box {
    height: 280px;
  }
  .cta-box a .cta-box_arrow {
    margin-top: 20px;
    height: 45px;
  }
  .cta-box a h4 {
    bottom: 44px;
  }
  .cta-box a .cta-box_text {
    margin-top: 8px;
  }
}

.cta-box:hover .cta-box_arrow, .cta-box:focus-within .cta-box_arrow {
  visibility: visible;
}
.cta-box:hover .cta-box-h4, .cta-box:hover .cta-box-underline, .cta-box:focus-within .cta-box-h4, .cta-box:focus-within .cta-box-underline {
  background-size: 100% 90%;
}

.info-box {
  position: relative;
  border-top: 1px solid #000;
  transition: background-color 0.2s ease-in-out, border-top 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 16px;
  color: #000;
  text-decoration: none;
}
@media (min-width: 1200px) {
  .info-box {
    gap: 70px;
  }
}
.info-box .info-box_content {
  transition: all 0.2s ease-in-out;
  position: relative;
  width: 100%;
  margin-top: 0;
}
@media (min-width: 1200px) {
  .info-box .info-box_content {
    padding-right: 48px;
  }
}
.info-box h4 {
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
}
@media (min-width: 1200px) {
  .info-box h4 {
    margin-top: 28px;
    margin-bottom: 40px;
  }
}
.info-box p,
.info-box .info-box_text {
  margin: 0;
  width: 100%;
}
@media (min-width: 1200px) {
  .info-box_more_arrow, .info-box_more_text {
    display: flex;
    position: absolute;
    bottom: 20px;
  }
}
.info-box_more_arrow {
  height: 20px;
  margin-bottom: 8px;
  padding: 0;
  display: none;
  transition: all 0.2s ease-in-out;
}
.info-box_more_arrow svg {
  width: 32px;
}
.info-box_more_text {
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 1200px) {
  .info-box:hover .info-box_content, .info-box:focus-within .info-box_content {
    padding-left: 24px;
    padding-right: 24px;
  }
  .info-box:hover .info-box_more_text, .info-box:focus-within .info-box_more_text {
    display: none;
  }
  .info-box:hover .info-box_more_arrow, .info-box:focus-within .info-box_more_arrow {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1200px) {
  .red:hover, .red:focus-within {
    background-color: #ff0000;
    border-top: 1px solid #ff0000;
  }
  .red:hover h4,
  .red:hover p,
  .red:hover .info-box_text, .red:focus-within h4,
  .red:focus-within p,
  .red:focus-within .info-box_text {
    color: #fff;
  }
  .red:hover .info-box_more_arrow, .red:focus-within .info-box_more_arrow {
    filter: invert(1);
  }
}

@media (min-width: 1200px) {
  .yellow:hover, .yellow:focus-within {
    border-top: 1px solid #FFFF00;
    background-color: #FFFF00;
  }
}

@media (min-width: 1200px) {
  .black:hover, .black:focus-within {
    background-color: #000;
    border-top: 1px solid #000;
  }
  .black:hover h4,
  .black:hover p,
  .black:hover .info-box_text, .black:focus-within h4,
  .black:focus-within p,
  .black:focus-within .info-box_text {
    color: #fff;
  }
  .black:hover .info-box_more_arrow, .black:focus-within .info-box_more_arrow {
    filter: invert(1);
  }
}

.tag-boxed {
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: normal;
  background-color: #f1f1f1;
  padding: 4px 8px;
}
@media (min-width: 768px) {
  .tag-boxed {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
    padding: 8px 12px;
  }
}
@media (min-width: 992px) {
  .tag-boxed {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: normal;
    padding: 8px 16px;
  }
}
.tag-rounded {
  display: inline-block;
  vertical-align: 0.5em;
  margin: 0 5px;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  border-radius: 12px;
  padding: 5px 10px;
  background-color: rgba(196, 196, 196, 0.2);
  color: #6F6F6F;
  transition: background-color 0.2s ease-in-out;
}
.tag-rounded.--with-flag {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 0px;
}
.tag-rounded.--with-flag::after {
  content: "";
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background: transparent url("../images/icons/flags/ar.svg") center center no-repeat;
  background-size: cover;
}
.tag-rounded.--with-flag[data-flag=ao]::after {
  background-image: url("../images/icons/flags/ao.svg");
}
.tag-rounded.--with-flag[data-flag=bf]::after {
  background-image: url("../images/icons/flags/bf.svg");
}
.tag-rounded.--with-flag[data-flag=bi]::after {
  background-image: url("../images/icons/flags/bi.svg");
}
.tag-rounded.--with-flag[data-flag=bj]::after {
  background-image: url("../images/icons/flags/bj.svg");
}
.tag-rounded.--with-flag[data-flag=bw]::after {
  background-image: url("../images/icons/flags/bw.svg");
}
.tag-rounded.--with-flag[data-flag=cd]::after {
  background-image: url("../images/icons/flags/cd.svg");
}
.tag-rounded.--with-flag[data-flag=cf]::after {
  background-image: url("../images/icons/flags/cf.svg");
}
.tag-rounded.--with-flag[data-flag=cg]::after {
  background-image: url("../images/icons/flags/cg.svg");
}
.tag-rounded.--with-flag[data-flag=ci]::after {
  background-image: url("../images/icons/flags/ci.svg");
}
.tag-rounded.--with-flag[data-flag=cm]::after {
  background-image: url("../images/icons/flags/cm.svg");
}
.tag-rounded.--with-flag[data-flag=cv]::after {
  background-image: url("../images/icons/flags/cv.svg");
}
.tag-rounded.--with-flag[data-flag=dj]::after {
  background-image: url("../images/icons/flags/dj.svg");
}
.tag-rounded.--with-flag[data-flag=dz]::after {
  background-image: url("../images/icons/flags/dz.svg");
}
.tag-rounded.--with-flag[data-flag=eg]::after {
  background-image: url("../images/icons/flags/eg.svg");
}
.tag-rounded.--with-flag[data-flag=eh]::after {
  background-image: url("../images/icons/flags/eh.svg");
}
.tag-rounded.--with-flag[data-flag=er]::after {
  background-image: url("../images/icons/flags/er.svg");
}
.tag-rounded.--with-flag[data-flag=et]::after {
  background-image: url("../images/icons/flags/et.svg");
}
.tag-rounded.--with-flag[data-flag=ga]::after {
  background-image: url("../images/icons/flags/ga.svg");
}
.tag-rounded.--with-flag[data-flag=gh]::after {
  background-image: url("../images/icons/flags/gh.svg");
}
.tag-rounded.--with-flag[data-flag=gm]::after {
  background-image: url("../images/icons/flags/gm.svg");
}
.tag-rounded.--with-flag[data-flag=gn]::after {
  background-image: url("../images/icons/flags/gn.svg");
}
.tag-rounded.--with-flag[data-flag=gq]::after {
  background-image: url("../images/icons/flags/gq.svg");
}
.tag-rounded.--with-flag[data-flag=gw]::after {
  background-image: url("../images/icons/flags/gw.svg");
}
.tag-rounded.--with-flag[data-flag=ke]::after {
  background-image: url("../images/icons/flags/ke.svg");
}
.tag-rounded.--with-flag[data-flag=km]::after {
  background-image: url("../images/icons/flags/km.svg");
}
.tag-rounded.--with-flag[data-flag=lr]::after {
  background-image: url("../images/icons/flags/lr.svg");
}
.tag-rounded.--with-flag[data-flag=ls]::after {
  background-image: url("../images/icons/flags/ls.svg");
}
.tag-rounded.--with-flag[data-flag=ly]::after {
  background-image: url("../images/icons/flags/ly.svg");
}
.tag-rounded.--with-flag[data-flag=ma]::after {
  background-image: url("../images/icons/flags/ma.svg");
}
.tag-rounded.--with-flag[data-flag=mg]::after {
  background-image: url("../images/icons/flags/mg.svg");
}
.tag-rounded.--with-flag[data-flag=ml]::after {
  background-image: url("../images/icons/flags/ml.svg");
}
.tag-rounded.--with-flag[data-flag=mr]::after {
  background-image: url("../images/icons/flags/mr.svg");
}
.tag-rounded.--with-flag[data-flag=mu]::after {
  background-image: url("../images/icons/flags/mu.svg");
}
.tag-rounded.--with-flag[data-flag=mw]::after {
  background-image: url("../images/icons/flags/mw.svg");
}
.tag-rounded.--with-flag[data-flag=mz]::after {
  background-image: url("../images/icons/flags/mz.svg");
}
.tag-rounded.--with-flag[data-flag=na]::after {
  background-image: url("../images/icons/flags/na.svg");
}
.tag-rounded.--with-flag[data-flag=ne]::after {
  background-image: url("../images/icons/flags/ne.svg");
}
.tag-rounded.--with-flag[data-flag=ng]::after {
  background-image: url("../images/icons/flags/ng.svg");
}
.tag-rounded.--with-flag[data-flag=re]::after {
  background-image: url("../images/icons/flags/re.svg");
}
.tag-rounded.--with-flag[data-flag=rw]::after {
  background-image: url("../images/icons/flags/rw.svg");
}
.tag-rounded.--with-flag[data-flag=sc]::after {
  background-image: url("../images/icons/flags/sc.svg");
}
.tag-rounded.--with-flag[data-flag=sd]::after {
  background-image: url("../images/icons/flags/sd.svg");
}
.tag-rounded.--with-flag[data-flag=sh]::after {
  background-image: url("../images/icons/flags/sh.svg");
}
.tag-rounded.--with-flag[data-flag=sl]::after {
  background-image: url("../images/icons/flags/sl.svg");
}
.tag-rounded.--with-flag[data-flag=sn]::after {
  background-image: url("../images/icons/flags/sn.svg");
}
.tag-rounded.--with-flag[data-flag=so]::after {
  background-image: url("../images/icons/flags/so.svg");
}
.tag-rounded.--with-flag[data-flag=st]::after {
  background-image: url("../images/icons/flags/st.svg");
}
.tag-rounded.--with-flag[data-flag=sz]::after {
  background-image: url("../images/icons/flags/sz.svg");
}
.tag-rounded.--with-flag[data-flag=td]::after {
  background-image: url("../images/icons/flags/td.svg");
}
.tag-rounded.--with-flag[data-flag=tg]::after {
  background-image: url("../images/icons/flags/tg.svg");
}
.tag-rounded.--with-flag[data-flag=tn]::after {
  background-image: url("../images/icons/flags/tn.svg");
}
.tag-rounded.--with-flag[data-flag=tz]::after {
  background-image: url("../images/icons/flags/tz.svg");
}
.tag-rounded.--with-flag[data-flag=ug]::after {
  background-image: url("../images/icons/flags/ug.svg");
}
.tag-rounded.--with-flag[data-flag=yt]::after {
  background-image: url("../images/icons/flags/yt.svg");
}
.tag-rounded.--with-flag[data-flag=za]::after {
  background-image: url("../images/icons/flags/za.svg");
}
.tag-rounded.--with-flag[data-flag=zm]::after {
  background-image: url("../images/icons/flags/zm.svg");
}
.tag-rounded.--with-flag[data-flag=zw]::after {
  background-image: url("../images/icons/flags/zw.svg");
}
.tag-rounded.--with-flag[data-flag=ae]::after {
  background-image: url("../images/icons/flags/ae.svg");
}
.tag-rounded.--with-flag[data-flag=af]::after {
  background-image: url("../images/icons/flags/af.svg");
}
.tag-rounded.--with-flag[data-flag=am]::after {
  background-image: url("../images/icons/flags/am.svg");
}
.tag-rounded.--with-flag[data-flag=ap]::after {
  background-image: url("../images/icons/flags/ap.svg");
}
.tag-rounded.--with-flag[data-flag=az]::after {
  background-image: url("../images/icons/flags/az.svg");
}
.tag-rounded.--with-flag[data-flag=bd]::after {
  background-image: url("../images/icons/flags/bd.svg");
}
.tag-rounded.--with-flag[data-flag=bh]::after {
  background-image: url("../images/icons/flags/bh.svg");
}
.tag-rounded.--with-flag[data-flag=bn]::after {
  background-image: url("../images/icons/flags/bn.svg");
}
.tag-rounded.--with-flag[data-flag=bt]::after {
  background-image: url("../images/icons/flags/bt.svg");
}
.tag-rounded.--with-flag[data-flag=cc]::after {
  background-image: url("../images/icons/flags/cc.svg");
}
.tag-rounded.--with-flag[data-flag=cn]::after {
  background-image: url("../images/icons/flags/cn.svg");
}
.tag-rounded.--with-flag[data-flag=cx]::after {
  background-image: url("../images/icons/flags/cx.svg");
}
.tag-rounded.--with-flag[data-flag=cy]::after {
  background-image: url("../images/icons/flags/cy.svg");
}
.tag-rounded.--with-flag[data-flag=ge]::after {
  background-image: url("../images/icons/flags/ge.svg");
}
.tag-rounded.--with-flag[data-flag=hk]::after {
  background-image: url("../images/icons/flags/hk.svg");
}
.tag-rounded.--with-flag[data-flag=id]::after {
  background-image: url("../images/icons/flags/id.svg");
}
.tag-rounded.--with-flag[data-flag=il]::after {
  background-image: url("../images/icons/flags/il.svg");
}
.tag-rounded.--with-flag[data-flag=in]::after {
  background-image: url("../images/icons/flags/in.svg");
}
.tag-rounded.--with-flag[data-flag=io]::after {
  background-image: url("../images/icons/flags/io.svg");
}
.tag-rounded.--with-flag[data-flag=iq]::after {
  background-image: url("../images/icons/flags/iq.svg");
}
.tag-rounded.--with-flag[data-flag=ir]::after {
  background-image: url("../images/icons/flags/ir.svg");
}
.tag-rounded.--with-flag[data-flag=jo]::after {
  background-image: url("../images/icons/flags/jo.svg");
}
.tag-rounded.--with-flag[data-flag=jp]::after {
  background-image: url("../images/icons/flags/jp.svg");
}
.tag-rounded.--with-flag[data-flag=kg]::after {
  background-image: url("../images/icons/flags/kg.svg");
}
.tag-rounded.--with-flag[data-flag=kh]::after {
  background-image: url("../images/icons/flags/kh.svg");
}
.tag-rounded.--with-flag[data-flag=kp]::after {
  background-image: url("../images/icons/flags/kp.svg");
}
.tag-rounded.--with-flag[data-flag=kr]::after {
  background-image: url("../images/icons/flags/kr.svg");
}
.tag-rounded.--with-flag[data-flag=kw]::after {
  background-image: url("../images/icons/flags/kw.svg");
}
.tag-rounded.--with-flag[data-flag=kz]::after {
  background-image: url("../images/icons/flags/kz.svg");
}
.tag-rounded.--with-flag[data-flag=la]::after {
  background-image: url("../images/icons/flags/la.svg");
}
.tag-rounded.--with-flag[data-flag=lb]::after {
  background-image: url("../images/icons/flags/lb.svg");
}
.tag-rounded.--with-flag[data-flag=lk]::after {
  background-image: url("../images/icons/flags/lk.svg");
}
.tag-rounded.--with-flag[data-flag=mm]::after {
  background-image: url("../images/icons/flags/mm.svg");
}
.tag-rounded.--with-flag[data-flag=mn]::after {
  background-image: url("../images/icons/flags/mn.svg");
}
.tag-rounded.--with-flag[data-flag=mo]::after {
  background-image: url("../images/icons/flags/mo.svg");
}
.tag-rounded.--with-flag[data-flag=mv]::after {
  background-image: url("../images/icons/flags/mv.svg");
}
.tag-rounded.--with-flag[data-flag=my]::after {
  background-image: url("../images/icons/flags/my.svg");
}
.tag-rounded.--with-flag[data-flag=np]::after {
  background-image: url("../images/icons/flags/np.svg");
}
.tag-rounded.--with-flag[data-flag=om]::after {
  background-image: url("../images/icons/flags/om.svg");
}
.tag-rounded.--with-flag[data-flag=ph]::after {
  background-image: url("../images/icons/flags/ph.svg");
}
.tag-rounded.--with-flag[data-flag=pk]::after {
  background-image: url("../images/icons/flags/pk.svg");
}
.tag-rounded.--with-flag[data-flag=ps]::after {
  background-image: url("../images/icons/flags/ps.svg");
}
.tag-rounded.--with-flag[data-flag=qa]::after {
  background-image: url("../images/icons/flags/qa.svg");
}
.tag-rounded.--with-flag[data-flag=sa]::after {
  background-image: url("../images/icons/flags/sa.svg");
}
.tag-rounded.--with-flag[data-flag=sg]::after {
  background-image: url("../images/icons/flags/sg.svg");
}
.tag-rounded.--with-flag[data-flag=sy]::after {
  background-image: url("../images/icons/flags/sy.svg");
}
.tag-rounded.--with-flag[data-flag=th]::after {
  background-image: url("../images/icons/flags/th.svg");
}
.tag-rounded.--with-flag[data-flag=tj]::after {
  background-image: url("../images/icons/flags/tj.svg");
}
.tag-rounded.--with-flag[data-flag=tl]::after {
  background-image: url("../images/icons/flags/tl.svg");
}
.tag-rounded.--with-flag[data-flag=tm]::after {
  background-image: url("../images/icons/flags/tm.svg");
}
.tag-rounded.--with-flag[data-flag=tw]::after {
  background-image: url("../images/icons/flags/tw.svg");
}
.tag-rounded.--with-flag[data-flag=uz]::after {
  background-image: url("../images/icons/flags/uz.svg");
}
.tag-rounded.--with-flag[data-flag=vn]::after {
  background-image: url("../images/icons/flags/vn.svg");
}
.tag-rounded.--with-flag[data-flag=ye]::after {
  background-image: url("../images/icons/flags/ye.svg");
}
.tag-rounded.--with-flag[data-flag=ad]::after {
  background-image: url("../images/icons/flags/ad.svg");
}
.tag-rounded.--with-flag[data-flag=al]::after {
  background-image: url("../images/icons/flags/al.svg");
}
.tag-rounded.--with-flag[data-flag=at]::after {
  background-image: url("../images/icons/flags/at.svg");
}
.tag-rounded.--with-flag[data-flag=ax]::after {
  background-image: url("../images/icons/flags/ax.svg");
}
.tag-rounded.--with-flag[data-flag=ba]::after {
  background-image: url("../images/icons/flags/ba.svg");
}
.tag-rounded.--with-flag[data-flag=be]::after {
  background-image: url("../images/icons/flags/be.svg");
}
.tag-rounded.--with-flag[data-flag=bg]::after {
  background-image: url("../images/icons/flags/bg.svg");
}
.tag-rounded.--with-flag[data-flag=by]::after {
  background-image: url("../images/icons/flags/by.svg");
}
.tag-rounded.--with-flag[data-flag=ch]::after {
  background-image: url("../images/icons/flags/ch.svg");
}
.tag-rounded.--with-flag[data-flag=cz]::after {
  background-image: url("../images/icons/flags/cz.svg");
}
.tag-rounded.--with-flag[data-flag=de]::after {
  background-image: url("../images/icons/flags/de.svg");
}
.tag-rounded.--with-flag[data-flag=dk]::after {
  background-image: url("../images/icons/flags/dk.svg");
}
.tag-rounded.--with-flag[data-flag=ee]::after {
  background-image: url("../images/icons/flags/ee.svg");
}
.tag-rounded.--with-flag[data-flag=es]::after {
  background-image: url("../images/icons/flags/es.svg");
}
.tag-rounded.--with-flag[data-flag=eu]::after {
  background-image: url("../images/icons/flags/eu.svg");
}
.tag-rounded.--with-flag[data-flag=fi]::after {
  background-image: url("../images/icons/flags/fi.svg");
}
.tag-rounded.--with-flag[data-flag=fo]::after {
  background-image: url("../images/icons/flags/fo.svg");
}
.tag-rounded.--with-flag[data-flag=fr]::after {
  background-image: url("../images/icons/flags/fr.svg");
}
.tag-rounded.--with-flag[data-flag=fx]::after {
  background-image: url("../images/icons/flags/fx.svg");
}
.tag-rounded.--with-flag[data-flag=gb]::after {
  background-image: url("../images/icons/flags/gb.svg");
}
.tag-rounded.--with-flag[data-flag=gg]::after {
  background-image: url("../images/icons/flags/gg.svg");
}
.tag-rounded.--with-flag[data-flag=gi]::after {
  background-image: url("../images/icons/flags/gi.svg");
}
.tag-rounded.--with-flag[data-flag=gr]::after {
  background-image: url("../images/icons/flags/gr.svg");
}
.tag-rounded.--with-flag[data-flag=hr]::after {
  background-image: url("../images/icons/flags/hr.svg");
}
.tag-rounded.--with-flag[data-flag=hu]::after {
  background-image: url("../images/icons/flags/hu.svg");
}
.tag-rounded.--with-flag[data-flag=ie]::after {
  background-image: url("../images/icons/flags/ie.svg");
}
.tag-rounded.--with-flag[data-flag=im]::after {
  background-image: url("../images/icons/flags/im.svg");
}
.tag-rounded.--with-flag[data-flag=is]::after {
  background-image: url("../images/icons/flags/is.svg");
}
.tag-rounded.--with-flag[data-flag=it]::after {
  background-image: url("../images/icons/flags/it.svg");
}
.tag-rounded.--with-flag[data-flag=je]::after {
  background-image: url("../images/icons/flags/je.svg");
}
.tag-rounded.--with-flag[data-flag=li]::after {
  background-image: url("../images/icons/flags/li.svg");
}
.tag-rounded.--with-flag[data-flag=lt]::after {
  background-image: url("../images/icons/flags/lt.svg");
}
.tag-rounded.--with-flag[data-flag=lu]::after {
  background-image: url("../images/icons/flags/lu.svg");
}
.tag-rounded.--with-flag[data-flag=lv]::after {
  background-image: url("../images/icons/flags/lv.svg");
}
.tag-rounded.--with-flag[data-flag=mc]::after {
  background-image: url("../images/icons/flags/mc.svg");
}
.tag-rounded.--with-flag[data-flag=md]::after {
  background-image: url("../images/icons/flags/md.svg");
}
.tag-rounded.--with-flag[data-flag=me]::after {
  background-image: url("../images/icons/flags/me.svg");
}
.tag-rounded.--with-flag[data-flag=mk]::after {
  background-image: url("../images/icons/flags/mk.svg");
}
.tag-rounded.--with-flag[data-flag=mt]::after {
  background-image: url("../images/icons/flags/mt.svg");
}
.tag-rounded.--with-flag[data-flag=nl]::after {
  background-image: url("../images/icons/flags/nl.svg");
}
.tag-rounded.--with-flag[data-flag=no]::after {
  background-image: url("../images/icons/flags/no.svg");
}
.tag-rounded.--with-flag[data-flag=pl]::after {
  background-image: url("../images/icons/flags/pl.svg");
}
.tag-rounded.--with-flag[data-flag=pt]::after {
  background-image: url("../images/icons/flags/pt.svg");
}
.tag-rounded.--with-flag[data-flag=ro]::after {
  background-image: url("../images/icons/flags/ro.svg");
}
.tag-rounded.--with-flag[data-flag=rs]::after {
  background-image: url("../images/icons/flags/rs.svg");
}
.tag-rounded.--with-flag[data-flag=ru]::after {
  background-image: url("../images/icons/flags/ru.svg");
}
.tag-rounded.--with-flag[data-flag=se]::after {
  background-image: url("../images/icons/flags/se.svg");
}
.tag-rounded.--with-flag[data-flag=si]::after {
  background-image: url("../images/icons/flags/si.svg");
}
.tag-rounded.--with-flag[data-flag=sj]::after {
  background-image: url("../images/icons/flags/sj.svg");
}
.tag-rounded.--with-flag[data-flag=sk]::after {
  background-image: url("../images/icons/flags/sk.svg");
}
.tag-rounded.--with-flag[data-flag=sm]::after {
  background-image: url("../images/icons/flags/sm.svg");
}
.tag-rounded.--with-flag[data-flag=tr]::after {
  background-image: url("../images/icons/flags/tr.svg");
}
.tag-rounded.--with-flag[data-flag=ua]::after {
  background-image: url("../images/icons/flags/ua.svg");
}
.tag-rounded.--with-flag[data-flag=va]::after {
  background-image: url("../images/icons/flags/va.svg");
}
.tag-rounded.--with-flag[data-flag=ag]::after {
  background-image: url("../images/icons/flags/ag.svg");
}
.tag-rounded.--with-flag[data-flag=ai]::after {
  background-image: url("../images/icons/flags/ai.svg");
}
.tag-rounded.--with-flag[data-flag=an]::after {
  background-image: url("../images/icons/flags/an.svg");
}
.tag-rounded.--with-flag[data-flag=aw]::after {
  background-image: url("../images/icons/flags/aw.svg");
}
.tag-rounded.--with-flag[data-flag=bb]::after {
  background-image: url("../images/icons/flags/bb.svg");
}
.tag-rounded.--with-flag[data-flag=bl]::after {
  background-image: url("../images/icons/flags/bl.svg");
}
.tag-rounded.--with-flag[data-flag=bm]::after {
  background-image: url("../images/icons/flags/bm.svg");
}
.tag-rounded.--with-flag[data-flag=bs]::after {
  background-image: url("../images/icons/flags/bs.svg");
}
.tag-rounded.--with-flag[data-flag=bz]::after {
  background-image: url("../images/icons/flags/bz.svg");
}
.tag-rounded.--with-flag[data-flag=ca]::after {
  background-image: url("../images/icons/flags/ca.svg");
}
.tag-rounded.--with-flag[data-flag=cr]::after {
  background-image: url("../images/icons/flags/cr.svg");
}
.tag-rounded.--with-flag[data-flag=cu]::after {
  background-image: url("../images/icons/flags/cu.svg");
}
.tag-rounded.--with-flag[data-flag=dm]::after {
  background-image: url("../images/icons/flags/dm.svg");
}
.tag-rounded.--with-flag[data-flag=do]::after {
  background-image: url("../images/icons/flags/do.svg");
}
.tag-rounded.--with-flag[data-flag=gd]::after {
  background-image: url("../images/icons/flags/gd.svg");
}
.tag-rounded.--with-flag[data-flag=gl]::after {
  background-image: url("../images/icons/flags/gl.svg");
}
.tag-rounded.--with-flag[data-flag=gp]::after {
  background-image: url("../images/icons/flags/gp.svg");
}
.tag-rounded.--with-flag[data-flag=gt]::after {
  background-image: url("../images/icons/flags/gt.svg");
}
.tag-rounded.--with-flag[data-flag=hn]::after {
  background-image: url("../images/icons/flags/hn.svg");
}
.tag-rounded.--with-flag[data-flag=ht]::after {
  background-image: url("../images/icons/flags/ht.svg");
}
.tag-rounded.--with-flag[data-flag=jm]::after {
  background-image: url("../images/icons/flags/jm.svg");
}
.tag-rounded.--with-flag[data-flag=kn]::after {
  background-image: url("../images/icons/flags/kn.svg");
}
.tag-rounded.--with-flag[data-flag=ky]::after {
  background-image: url("../images/icons/flags/ky.svg");
}
.tag-rounded.--with-flag[data-flag=lc]::after {
  background-image: url("../images/icons/flags/lc.svg");
}
.tag-rounded.--with-flag[data-flag=mf]::after {
  background-image: url("../images/icons/flags/mf.svg");
}
.tag-rounded.--with-flag[data-flag=mq]::after {
  background-image: url("../images/icons/flags/mq.svg");
}
.tag-rounded.--with-flag[data-flag=ms]::after {
  background-image: url("../images/icons/flags/ms.svg");
}
.tag-rounded.--with-flag[data-flag=mx]::after {
  background-image: url("../images/icons/flags/mx.svg");
}
.tag-rounded.--with-flag[data-flag=ni]::after {
  background-image: url("../images/icons/flags/ni.svg");
}
.tag-rounded.--with-flag[data-flag=pa]::after {
  background-image: url("../images/icons/flags/pa.svg");
}
.tag-rounded.--with-flag[data-flag=pm]::after {
  background-image: url("../images/icons/flags/pm.svg");
}
.tag-rounded.--with-flag[data-flag=pr]::after {
  background-image: url("../images/icons/flags/pr.svg");
}
.tag-rounded.--with-flag[data-flag=sv]::after {
  background-image: url("../images/icons/flags/sv.svg");
}
.tag-rounded.--with-flag[data-flag=tc]::after {
  background-image: url("../images/icons/flags/tc.svg");
}
.tag-rounded.--with-flag[data-flag=tt]::after {
  background-image: url("../images/icons/flags/tt.svg");
}
.tag-rounded.--with-flag[data-flag=us]::after {
  background-image: url("../images/icons/flags/us.svg");
}
.tag-rounded.--with-flag[data-flag=vc]::after {
  background-image: url("../images/icons/flags/vc.svg");
}
.tag-rounded.--with-flag[data-flag=vg]::after {
  background-image: url("../images/icons/flags/vg.svg");
}
.tag-rounded.--with-flag[data-flag=vi]::after {
  background-image: url("../images/icons/flags/vi.svg");
}
.tag-rounded.--with-flag[data-flag=as]::after {
  background-image: url("../images/icons/flags/as.svg");
}
.tag-rounded.--with-flag[data-flag=au]::after {
  background-image: url("../images/icons/flags/au.svg");
}
.tag-rounded.--with-flag[data-flag=ck]::after {
  background-image: url("../images/icons/flags/ck.svg");
}
.tag-rounded.--with-flag[data-flag=fj]::after {
  background-image: url("../images/icons/flags/fj.svg");
}
.tag-rounded.--with-flag[data-flag=fm]::after {
  background-image: url("../images/icons/flags/fm.svg");
}
.tag-rounded.--with-flag[data-flag=gu]::after {
  background-image: url("../images/icons/flags/gu.svg");
}
.tag-rounded.--with-flag[data-flag=ki]::after {
  background-image: url("../images/icons/flags/ki.svg");
}
.tag-rounded.--with-flag[data-flag=mh]::after {
  background-image: url("../images/icons/flags/mh.svg");
}
.tag-rounded.--with-flag[data-flag=mp]::after {
  background-image: url("../images/icons/flags/mp.svg");
}
.tag-rounded.--with-flag[data-flag=nc]::after {
  background-image: url("../images/icons/flags/nc.svg");
}
.tag-rounded.--with-flag[data-flag=nf]::after {
  background-image: url("../images/icons/flags/nf.svg");
}
.tag-rounded.--with-flag[data-flag=nr]::after {
  background-image: url("../images/icons/flags/nr.svg");
}
.tag-rounded.--with-flag[data-flag=nu]::after {
  background-image: url("../images/icons/flags/nu.svg");
}
.tag-rounded.--with-flag[data-flag=nz]::after {
  background-image: url("../images/icons/flags/nz.svg");
}
.tag-rounded.--with-flag[data-flag=pf]::after {
  background-image: url("../images/icons/flags/pf.svg");
}
.tag-rounded.--with-flag[data-flag=pg]::after {
  background-image: url("../images/icons/flags/pg.svg");
}
.tag-rounded.--with-flag[data-flag=pn]::after {
  background-image: url("../images/icons/flags/pn.svg");
}
.tag-rounded.--with-flag[data-flag=pw]::after {
  background-image: url("../images/icons/flags/pw.svg");
}
.tag-rounded.--with-flag[data-flag=sb]::after {
  background-image: url("../images/icons/flags/sb.svg");
}
.tag-rounded.--with-flag[data-flag=tk]::after {
  background-image: url("../images/icons/flags/tk.svg");
}
.tag-rounded.--with-flag[data-flag=to]::after {
  background-image: url("../images/icons/flags/to.svg");
}
.tag-rounded.--with-flag[data-flag=tv]::after {
  background-image: url("../images/icons/flags/tv.svg");
}
.tag-rounded.--with-flag[data-flag=um]::after {
  background-image: url("../images/icons/flags/um.svg");
}
.tag-rounded.--with-flag[data-flag=vu]::after {
  background-image: url("../images/icons/flags/vu.svg");
}
.tag-rounded.--with-flag[data-flag=wf]::after {
  background-image: url("../images/icons/flags/wf.svg");
}
.tag-rounded.--with-flag[data-flag=ws]::after {
  background-image: url("../images/icons/flags/ws.svg");
}
.tag-rounded.--with-flag[data-flag=ar]::after {
  background-image: url("../images/icons/flags/ar.svg");
}
.tag-rounded.--with-flag[data-flag=bo]::after {
  background-image: url("../images/icons/flags/bo.svg");
}
.tag-rounded.--with-flag[data-flag=br]::after {
  background-image: url("../images/icons/flags/br.svg");
}
.tag-rounded.--with-flag[data-flag=cl]::after {
  background-image: url("../images/icons/flags/cl.svg");
}
.tag-rounded.--with-flag[data-flag=co]::after {
  background-image: url("../images/icons/flags/co.svg");
}
.tag-rounded.--with-flag[data-flag=ec]::after {
  background-image: url("../images/icons/flags/ec.svg");
}
.tag-rounded.--with-flag[data-flag=fk]::after {
  background-image: url("../images/icons/flags/fk.svg");
}
.tag-rounded.--with-flag[data-flag=gf]::after {
  background-image: url("../images/icons/flags/gf.svg");
}
.tag-rounded.--with-flag[data-flag=gy]::after {
  background-image: url("../images/icons/flags/gy.svg");
}
.tag-rounded.--with-flag[data-flag=pe]::after {
  background-image: url("../images/icons/flags/pe.svg");
}
.tag-rounded.--with-flag[data-flag=py]::after {
  background-image: url("../images/icons/flags/py.svg");
}
.tag-rounded.--with-flag[data-flag=sr]::after {
  background-image: url("../images/icons/flags/sr.svg");
}
.tag-rounded.--with-flag[data-flag=uy]::after {
  background-image: url("../images/icons/flags/uy.svg");
}
.tag-rounded.--with-flag[data-flag=ve]::after {
  background-image: url("../images/icons/flags/ve.svg");
}

.tags-list.--horizontal {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
@media (max-width: 767px) {
  .tags-list.--horizontal {
    gap: 8px;
  }
}

.section-divider {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: normal;
  border-top: 1px solid #000;
  width: 100%;
  padding-top: 16px;
}
@media (max-width: 1199px) {
  .section-divider {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.4444444444;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .section-divider {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
@media (min-width: 768px) {
  .section-divider {
    padding-top: 32px;
  }
}
.section-divider.--bg-black {
  color: #fff;
  border-color: #fff;
}
.section-divider__hr {
  border: 0 !important;
  height: 0 !important;
  margin: 0 !important;
}

.--bg-black .section-divider {
  color: #fff;
  border-color: #fff;
}

.to-manifesto_section {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  max-width: 100vw;
  height: 1280px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-top: 80px;
  margin-bottom: 240px;
  padding-bottom: 148px;
}
@media (max-width: 991px) {
  .to-manifesto_section {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .to-manifesto_section {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.to-manifesto_section div {
  grid-column: 14/span 8;
  position: absolute;
  bottom: 100px;
  padding-bottom: 48px;
}
@media (max-width: 991px) {
  .to-manifesto_section div {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 767px) {
  .to-manifesto_section div {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.to-manifesto_section h2 {
  margin-bottom: 40px;
}
.to-manifesto_section p {
  width: 397px;
  margin-bottom: 40px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
}
.to-manifesto_section a {
  color: inherit;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0em;
}
@media (max-width: 1024px) {
  .to-manifesto_section {
    background-size: contain;
    height: 1025px;
    padding-bottom: 124px;
  }
  .to-manifesto_section p, .to-manifesto_section a {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
  .to-manifesto_section div {
    padding-bottom: 15px;
    margin-top: 0;
  }
}
@media (max-width: 950px) {
  .to-manifesto_section p {
    width: auto;
  }
  .to-manifesto_section div {
    right: 10%;
  }
}
@media (max-width: 768px) {
  .to-manifesto_section {
    margin-bottom: 120px;
    margin-top: 60px;
    padding-top: 0;
    clip-path: circle(100%);
    background-size: 150%;
    background-position-x: center;
    position: relative;
    padding-bottom: 120px;
    height: 1160px;
  }
  .to-manifesto_section div {
    padding-bottom: 120px;
    margin-top: 0;
    position: absolute;
    top: 700px;
    grid-column: 1/-1;
  }
  .to-manifesto_section h2 {
    margin-bottom: 32px;
  }
  .to-manifesto_section p, .to-manifesto_section a {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
    padding-bottom: 0;
  }
  .to-manifesto_section p {
    margin-bottom: 32px;
    width: 100%;
  }
}
@media (max-width: 660px) {
  .to-manifesto_section {
    height: 870px;
  }
  .to-manifesto_section div {
    top: auto;
    bottom: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .to-manifesto_section div {
    padding-bottom: 120px;
  }
}

.center-text {
  text-align: center;
  max-width: 960px;
}
.center-text h2 {
  margin: 0;
}
.center-text p {
  max-width: 615px;
  margin: auto;
  margin-top: 40px;
}
.center-text a {
  color: inherit;
}
@media (max-width: 991px) {
  .center-text {
    max-width: 700px;
  }
  .center-text p {
    max-width: 700px;
  }
}
@media (max-width: 767px) {
  .center-text p {
    margin-bottom: 0 !important;
    margin-top: 32px;
  }
}
@media (max-width: 767px) {
  .center-text.left_text {
    text-align: left;
  }
  .center-text.left_text p {
    text-align: left;
    margin-top: 40px;
  }
}
.center-text.--expand-w {
  max-width: 1060px;
}
.center-text.--expand-w p {
  max-width: 840px;
}
@media (max-width: 1199px) {
  .center-text.--expand-w p {
    max-width: 576px;
  }
}

.planet_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  margin-top: 280px;
  margin-bottom: 320px;
  padding-bottom: 0 !important;
}
.planet_section .primer-hr {
  margin: 0;
  border: none;
  border-top: 1px solid;
}
.planet_section hr {
  margin-bottom: 0;
}
.planet_section .wrapper {
  padding-left: 0;
}
.planet_section .planet_img-horizontal {
  display: none;
}
.planet_section .planet_img-vertical {
  position: sticky;
  top: 0;
  max-width: 100%;
}
.planet_section .planet_text {
  margin: 350px 0;
}
.planet_section .planet_text h2 {
  margin-bottom: 40px;
}
.planet_section .planet_text_p {
  margin-bottom: 40px;
}
.planet_section .planet_text .block-list .block-list-container {
  border-bottom: 1px #fff solid;
}
.planet_section .planet_text .block-list .block-list-container hr {
  display: none;
}
@media (max-width: 1024px) {
  .planet_section {
    grid-template-columns: 1fr;
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 240px;
    margin-top: 240px;
  }
  .planet_section .wrapper {
    padding-right: 120px;
    padding-left: 120px;
  }
  .planet_section .planet_img-vertical {
    display: none;
  }
  .planet_section .planet_img-horizontal {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .planet_section .planet_text {
    padding-top: 88px;
  }
}
@media (max-width: 864px) {
  .planet_section {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .planet_section {
    margin-bottom: 120px;
    margin-top: 120px;
  }
  .planet_section .planet_text {
    margin: 200px 0 240px;
  }
  .planet_section .planet_text h2, .planet_section .planet_text .planet_text_p {
    margin-bottom: 32px;
  }
  .planet_section .wrapper {
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (max-width: 375px) {
  .planet_section .planet_text {
    margin: 90px 0 120px;
  }
  .planet_section .planet_text h2 {
    font-size: 43px;
  }
}

.img-box {
  border-top: 1px solid #000;
  max-width: 100%;
  height: 438px;
  position: relative;
  overflow: hidden;
  margin-bottom: 80px;
}
.img-box_title {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0em;
  margin-top: 16px;
  margin-bottom: 0;
}
.img-box_anchor-img {
  margin-top: 16px;
}
.img-box_subtitle {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875;
  letter-spacing: 0em;
  margin-top: 0;
  margin-bottom: 50px;
}
.img-box_img-container {
  max-width: 400px;
  height: 320px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  transition: all 0.2s ease-in-out;
}
.img-box_img-container .img-box_img {
  height: 380px;
  width: auto;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -30px;
  margin-bottom: -30px;
  transition: all 0.2s ease-in-out;
}
.img-box_img-container .img-box_plus {
  display: none;
}
.img-box_text-container {
  border-bottom: 6px solid #FFFF00;
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.5384615385;
  letter-spacing: 0em;
  position: absolute;
  z-index: -2;
  bottom: 0;
  width: 100%;
  transition: transform 0.2s ease-in-out;
}
.img-box_text-container .--richtext {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.5384615385;
  letter-spacing: 0em;
}
.img-box_text {
  margin-bottom: 27px;
}

.quote_visible .img-box_img-container {
  height: 0px;
}
.quote_visible .img-box_text-container {
  cursor: pointer;
  transform: translateY(0);
}

.img_visible .img-box_img-container {
  height: 320px;
}
@media (max-width: 767px) {
  .img_visible .img-box_img-container {
    height: 200px;
  }
}
.img_visible .img-box_text-container {
  transform: translateY(100px);
}

.img-box:hover .img-box_img-container .img-box_img, .img-box:focus-within .img-box_img-container .img-box_img {
  cursor: pointer;
  height: 340px;
  width: auto;
  margin: 0;
}

@media (max-width: 1140px) {
  .img-box_img-container {
    height: 100%;
    width: 100%;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  .img-box_img-container .img-box_img {
    height: 100%;
    width: 106%;
    margin-left: -4%;
    margin-right: -4%;
    object-fit: cover;
    margin-top: 0;
  }
  .img-box:hover .img-box_img-container .img-box_img, .img-box:focus-within .img-box_img-container .img-box_img {
    height: 100%;
    width: 106%;
    margin-left: -4%;
    margin-right: -4%;
    object-fit: cover;
    margin-top: 0;
  }
}
@media (max-width: 1024px) {
  .img-box_img-container .img-box_plus {
    display: block;
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
  }
  .img-box_text-container {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
  .img-box_text-container .--richtext {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 850px) {
  .img-box_title {
    line-height: 1.2;
  }
}
@media (max-width: 767px) {
  .img-box {
    height: 320px;
  }
  .img-box .img-box_anchor-img {
    margin-top: 16px;
  }
  .img-box_title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 0em;
  }
  .img-box_subtitle {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    letter-spacing: 0em;
    margin-bottom: 0;
  }
  .img-box_text-container {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0em;
    border-bottom: 4px solid #FFFF00;
  }
  .img-box_text-container .--richtext {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0em;
  }
  .img-box_img-container .img-box_plus {
    width: 20px;
    height: 20px;
    bottom: 8px;
    left: 8px;
  }
}
.img-cta-section {
  padding-top: 46px;
  margin-bottom: 240px;
}
@media (max-width: 1024px) {
  .img-cta-section {
    padding-top: 90px;
  }
}
@media (max-width: 767px) {
  .img-cta-section {
    padding-top: 110px;
    margin-bottom: 110px;
  }
}

.img-cta-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: 1fr auto;
  column-gap: 40px;
  margin-bottom: 0px;
  justify-content: space-between;
}
.img-cta-container a {
  color: inherit;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.img-cta-container a .img-box-anchor-img {
  height: 34px;
  padding-left: 8px;
  opacity: 0;
  margin-top: 16px;
}
@media (max-width: 1024px) {
  .img-cta-container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }
}
@media (max-width: 780px) {
  .img-cta-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575px) {
  .img-cta-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  }
  .img-cta-container .img-box-anchor-img {
    height: 20px;
    align-self: center;
  }
}
@media (max-width: 343px) {
  .img-cta-container .img-box_text {
    margin-bottom: 10px;
  }
}

.hr-text hr {
  margin-bottom: 32px;
  border: 0;
  border-top: 1px solid #000;
  height: 1px;
}
.hr-text p {
  margin-bottom: 32px;
  font-weight: 700;
}

.certified-b {
  padding: 0;
  margin: 0 auto;
  margin-top: 100px;
  width: 300px;
  height: 300px;
}
.certified-b__container {
  overflow: hidden;
}
.certified-b__container .center-text {
  opacity: 0;
  margin-top: 80px;
}
@media (max-width: 575px) {
  .certified-b__container .center-text {
    margin-top: 32px;
  }
}
.certified-b-top {
  position: relative;
  top: -80px;
}
.certified-b-logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
@media (max-width: 575px) {
  .certified-b-logo {
    height: 160px;
  }
}
.certified-b__logo-box {
  position: relative;
  text-align: center;
  margin: auto;
  margin-top: 110px;
  height: 300px;
}
@media (max-width: 575px) {
  .certified-b__logo-box {
    height: 160px;
  }
}
.certified-b__box {
  margin-top: 80px;
  background-color: #EF4044;
  padding-bottom: 80px;
}
@media (max-width: 575px) {
  .certified-b {
    width: 165px;
    height: 165px;
  }
  .certified-b img {
    width: 165px;
    height: 165px;
  }
}

.join-us {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  margin-top: 60px;
  padding-top: 140px;
  padding-bottom: 240px;
}
@media (max-width: 991px) {
  .join-us {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .join-us {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .join-us {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}
.join-us h3 {
  grid-column: 2/23;
  grid-row: 1;
}
@media (max-width: 575px) {
  .join-us h3 {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.join-us p {
  margin-top: 100px;
  margin-bottom: 39px;
  grid-column: 14/23;
  grid-row: 2;
}
@media (max-width: 1024px) {
  .join-us p {
    margin-top: 240px;
  }
}
@media (max-width: 575px) {
  .join-us p {
    margin-top: 120px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.join-us hr {
  width: 90%;
  grid-column: 1/24;
  grid-row: 3;
  margin: 0 auto;
}
.join-us .btn__list {
  grid-column: 14/23;
  grid-row: 4;
  border: none;
}
@media (max-width: 575px) {
  .join-us .btn__list {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.join-us a {
  color: inherit;
}

.center-list_section {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 991px) {
  .center-list_section {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .center-list_section {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.center-list_section .center-list_text {
  grid-column: 9/span 7;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 22px;
  margin-top: 0px;
}
@media (max-width: 1024px) {
  .center-list_section .center-list_text {
    grid-column: 7/span 11;
  }
}
@media (max-width: 767px) {
  .center-list_section .center-list_text {
    grid-column: 1/-1;
    margin-top: 64px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}
.center-list_section .btn__list {
  grid-column: 5/span 15;
  margin-bottom: 130px;
}
@media (max-width: 767px) {
  .center-list_section .btn__list {
    grid-column: 1/-1;
    margin-bottom: 0;
  }
}

.onu-programme {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: end;
}
@media (max-width: 991px) {
  .onu-programme {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .onu-programme {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 767px) {
  .onu-programme {
    row-gap: 20px;
  }
}
.onu-programme__thumb {
  position: relative;
  overflow: hidden;
}
.onu-programme__thumb__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.onu-programme__thumb__mask--red {
  background-color: #EF4044;
}
.onu-programme__thumb__mask--white {
  background-color: #fff;
}
.onu-programme__thumb__mask.--animated-init {
  animation: mask-onu-animation-init 0.2s ease-in-out forwards;
}
.onu-programme__thumb img {
  display: block;
  max-width: 100%;
  height: auto;
}
.onu-programme__thumb:nth-of-type(1) {
  grid-column: 1/span 3;
  grid-row: 1/span 1;
  margin-bottom: 70px;
}
@media (min-width: 768px) {
  .onu-programme__thumb:nth-of-type(1) {
    grid-column: 1/span 7;
    grid-row: 1/span 1;
  }
}
.onu-programme__thumb:nth-of-type(2) {
  grid-column: 3/span 3;
  grid-row: 2/span 1;
}
@media (min-width: 768px) {
  .onu-programme__thumb:nth-of-type(2) {
    grid-column: 9/span 7;
    grid-row: 1/span 1;
    transform: translateY(70%);
  }
}
@media (min-width: 992px) {
  .onu-programme__thumb:nth-of-type(2) {
    transform: translateY(73%);
  }
}
.onu-programme__thumb:nth-of-type(3) {
  grid-column: 5/-1;
  grid-row: 1/span 1;
}
@media (min-width: 768px) {
  .onu-programme__thumb:nth-of-type(3) {
    grid-column: 17/-1;
    align-self: center;
    grid-row: 1/span 1;
  }
}
.onu-programme__thumb:nth-of-type(4) {
  grid-column: span 3;
  grid-row: 3/span 1;
  margin-bottom: 75%;
}
@media (min-width: 768px) {
  .onu-programme__thumb:nth-of-type(4) {
    grid-column: 3/span 5;
    grid-row: 2/span 1;
    margin-top: 207px;
    margin-bottom: unset;
  }
}
@media (min-width: 1200px) {
  .onu-programme__thumb:nth-of-type(4) {
    margin-top: 304px;
  }
}
.onu-programme__thumb:nth-of-type(5) {
  grid-column: 3/span 1;
  grid-row: 4/span 1;
}
@media (min-width: 768px) {
  .onu-programme__thumb:nth-of-type(5) {
    grid-column: 5/span 3;
    grid-row: 3/span 1;
    transform: translateY(50%);
  }
}
@media (min-width: 1200px) {
  .onu-programme__thumb:nth-of-type(5) {
    transform: unset;
  }
}
.onu-programme__thumb:nth-of-type(6) {
  grid-column: 5/-1;
  grid-row: 3/span 1;
}
@media (min-width: 768px) {
  .onu-programme__thumb:nth-of-type(6) {
    grid-column: 19/span 5;
    grid-row: 3/span 1;
    margin-top: 40px;
  }
}
.onu-programme__copyright {
  grid-column: 5/-1;
  align-self: start;
  grid-row: 1/span 1;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
}
@media (min-width: 768px) {
  .onu-programme__copyright {
    grid-column: 17/-1;
    align-self: start;
    grid-row: 1/span 1;
  }
}
@media (min-width: 1200px) {
  .onu-programme__copyright {
    grid-column: 19/-1;
  }
}
.onu-programme--culture {
  z-index: 20;
}

@keyframes mask-onu-animation-init {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.right-list {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
.right-list.section:first-of-type {
  padding-top: 0;
}
@media (max-width: 991px) {
  .right-list {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .right-list {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.right-list_title {
  margin-top: 0px;
  margin-bottom: 30px;
  grid-column: 13/-1;
  grid-row: 2;
}
.right-list_title p:last-of-type {
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .right-list_title {
    grid-column: 1/-1;
  }
}
.right-list hr {
  margin: 0;
  height: 1px;
  border: none;
  border-top: 1px solid #000;
}
.right-list .btn__list {
  grid-column: 13/-1;
  grid-row: 4;
  border: none;
}
@media (max-width: 767px) {
  .right-list .btn__list {
    grid-column: 1/-1;
  }
}
.right-list .btn-arrow__title {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
}
@media (max-width: 1024px) {
  .right-list .btn-arrow__title {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: 0em;
  }
}
@media (max-width: 575px) {
  .right-list .btn-arrow__title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}
.right-list a {
  color: inherit;
}
.right-list_separator {
  width: 100%;
  grid-column: 1/-1;
  grid-row: 3;
  margin: 0 auto;
}
.right-list .tnd-badge:nth-child(1),
.right-list .smile-badge:nth-child(1) {
  grid-column: 1/-1;
  margin: auto;
  margin-bottom: 120px;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .right-list .tnd-badge:nth-child(1),
  .right-list .smile-badge:nth-child(1) {
    display: none;
  }
}
.right-list .tnd-badge:nth-child(2),
.right-list .smile-badge:nth-child(2) {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
}
@media (max-width: 767px) {
  .right-list .tnd-badge:nth-child(2),
  .right-list .smile-badge:nth-child(2) {
    display: none;
  }
}
@media (min-width: 768px) {
  .right-list .tnd-badge:nth-child(2),
  .right-list .smile-badge:nth-child(2) {
    bottom: unset;
    right: unset;
    transform: translate(0, -50%);
    max-width: 240px;
    max-height: 240px;
  }
}
@media (min-width: 992px) {
  .right-list .tnd-badge:nth-child(2),
  .right-list .smile-badge:nth-child(2) {
    transform: translate(-10px, -50%);
    max-width: 320px;
    max-height: 320px;
  }
}
@media (min-width: 1200px) {
  .right-list .tnd-badge:nth-child(2),
  .right-list .smile-badge:nth-child(2) {
    transform: translate(150px, -50%);
  }
}

@media (max-width: 575px) {
  .--has-badge.section {
    padding-bottom: 40px !important;
  }
}
@media (min-width: 768px) {
  .--has-badge .right-list_separator {
    position: relative;
  }
}

.--bg-black .right-list hr {
  border-top: 1px solid #fff;
}

.text-with-tag-container {
  padding-top: 44px;
  padding-bottom: 240px;
}
@media (max-width: 1024px) {
  .text-with-tag-container {
    padding-top: 0;
    padding-bottom: 60px;
  }
}

.text-with-tag {
  display: flex;
  align-items: center;
}
.text-with-tag h4 {
  letter-spacing: -0.02em;
}
@media (max-width: 991px) {
  .text-with-tag h4 {
    width: 100%;
  }
}
.text-with-tag .tag-rounded {
  padding-right: 8px;
  padding-left: 8px;
}
@media (max-width: 575px) {
  .text-with-tag {
    align-items: flex-start;
    margin-bottom: 16px;
  }
  .text-with-tag .tag-rounded {
    margin-top: 5px;
  }
  .text-with-tag .tag-rounded i {
    width: max-content;
  }
}

.text-with-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .text-with-button {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.text-with-button h2 {
  font-size: 100px;
  font-size: 6.25rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.04em;
  margin-bottom: 40;
}
@media (max-width: 1199px) {
  .text-with-button h2 {
    font-size: 80px;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 575px) {
  .text-with-button h2 {
    font-size: 50px;
    font-size: 3.125rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
  }
}
@media (max-width: 575px) {
  .text-with-button {
    text-align: center;
  }
}

.big-paragraph {
  font-size: 80px;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.03em;
  padding: 80px;
}
.big-paragraph p {
  margin-bottom: 64px;
}
@media (max-width: 1024px) {
  .big-paragraph {
    font-size: 64px;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.15625;
    letter-spacing: -0.03em;
  }
  .big-paragraph p {
    margin-bottom: 58px;
  }
}
@media (max-width: 991px) {
  .big-paragraph {
    padding: 32px;
  }
}
@media (max-width: 575px) {
  .big-paragraph {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
    padding: 16px;
    margin-top: 16px;
  }
  .big-paragraph p {
    margin-bottom: 32px;
  }
}

.spinner {
  display: none;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  font-size: 10px;
  margin: -2.5em auto 2.5em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.12s;
  animation-delay: -0.12s;
}

.spinner,
.spinner::before,
.spinner::after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation: load7 1.2s infinite ease-in-out;
  animation: load7 1.2s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.spinner::before,
.spinner::after {
  content: "";
  position: absolute;
  top: 0;
}

.spinner::before {
  left: -1.5em;
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

.spinner::after {
  left: 1.5em;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

@-webkit-keyframes load7 {
  0%, 90%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  30% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%, 90%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  30% {
    box-shadow: 0 2.5em 0 0;
  }
}
.feature-list {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: start;
  row-gap: 32px;
}
@media (max-width: 991px) {
  .feature-list {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .feature-list {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .feature-list.desk-cols-2 > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .feature-list.desk-cols-2 > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .feature-list.desk-cols-2 > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .feature-list.desk-cols-2 > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .feature-list.desk-cols-2 > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .feature-list.desk-cols-2 > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (max-width: 575px) {
  .feature-list.desk-cols-3 > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .feature-list.desk-cols-3 > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .feature-list.desk-cols-3 > *:nth-child(3n+1) {
    grid-column: 1/span 7;
  }
  .feature-list.desk-cols-3 > *:nth-child(3n+2) {
    grid-column: 9/span 7;
  }
  .feature-list.desk-cols-3 > *:nth-child(3n) {
    grid-column: 17/span 7;
  }
}
@media (min-width: 992px) {
  .feature-list.desk-cols-3 > *:nth-child(3n+1) {
    grid-column: 1/span 7;
  }
  .feature-list.desk-cols-3 > *:nth-child(3n+2) {
    grid-column: 9/span 7;
  }
  .feature-list.desk-cols-3 > *:nth-child(3n) {
    grid-column: 17/span 7;
  }
}
.feature-item__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  border-top: 1px solid #000;
  padding-top: 32px;
}
@media (min-width: 1200px) {
  .feature-item__box {
    padding-top: 24px;
    gap: 24px;
  }
}
.feature-item__title {
  max-width: 555px;
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .feature-item__title {
    gap: 24px;
  }
}
.feature-item__text {
  max-width: 555px;
  width: 100%;
  margin: 0;
}

.feature-item-w-cta {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .feature-item-w-cta {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.feature-item-w-cta__list {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: start;
  row-gap: 32px;
  min-height: 440px;
  align-items: unset;
}
@media (max-width: 991px) {
  .feature-item-w-cta__list {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .feature-item-w-cta__list {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .feature-item-w-cta__list.desk-cols-2 > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .feature-item-w-cta__list.desk-cols-2 > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .feature-item-w-cta__list.desk-cols-2 > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .feature-item-w-cta__list.desk-cols-2 > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .feature-item-w-cta__list.desk-cols-2 > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .feature-item-w-cta__list.desk-cols-2 > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (max-width: 575px) {
  .feature-item-w-cta__list.desk-cols-3 > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .feature-item-w-cta__list.desk-cols-3 > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .feature-item-w-cta__list.desk-cols-3 > *:nth-child(3n+1) {
    grid-column: 1/span 7;
  }
  .feature-item-w-cta__list.desk-cols-3 > *:nth-child(3n+2) {
    grid-column: 9/span 7;
  }
  .feature-item-w-cta__list.desk-cols-3 > *:nth-child(3n) {
    grid-column: 17/span 7;
  }
}
@media (min-width: 992px) {
  .feature-item-w-cta__list.desk-cols-3 > *:nth-child(3n+1) {
    grid-column: 1/span 7;
  }
  .feature-item-w-cta__list.desk-cols-3 > *:nth-child(3n+2) {
    grid-column: 9/span 7;
  }
  .feature-item-w-cta__list.desk-cols-3 > *:nth-child(3n) {
    grid-column: 17/span 7;
  }
}
@media (max-width: 767px) {
  .feature-item-w-cta__list {
    row-gap: 64px;
  }
}
.feature-item-w-cta__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
  border-top: 2px solid #000;
  align-items: flex-start;
}
@media (max-width: 1199px) {
  .feature-item-w-cta__item {
    gap: 60px;
  }
}
@media (max-width: 575px) {
  .feature-item-w-cta__item {
    gap: 32px;
  }
}
.feature-item-w-cta__box {
  padding-top: 0;
  border: none;
}
.feature-item-w-cta__title {
  width: 100%;
  margin-bottom: 0;
  margin-bottom: 16px;
}
@media (max-width: 1199px) {
  .feature-item-w-cta__title {
    margin-bottom: 24px;
  }
}
@media (max-width: 575px) {
  .feature-item-w-cta__title {
    margin-bottom: 16px;
  }
}
.feature-item-w-cta__text {
  margin-bottom: 0;
}
.feature-item-w-cta .hr-text p {
  margin-bottom: 60px;
  padding-top: 32px;
}
@media (max-width: 767px) {
  .feature-item-w-cta .hr-text p {
    padding-top: 24px;
    margin-bottom: 32px;
  }
}

.black-bottom-section-culture {
  padding-bottom: 40px;
}
.black-bottom-section-culture p {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.black-bottom-section-culture .big-paragraph {
  margin-bottom: 120px;
  padding: 20px 80px 0px 80px;
  font-size: 80px;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.03em;
}
@media (max-width: 1024px) {
  .black-bottom-section-culture {
    padding-bottom: 40px;
  }
  .black-bottom-section-culture .big-paragraph {
    padding-top: 0px;
    margin-bottom: 120px;
    font-size: 64px;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.15625;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 991px) {
  .black-bottom-section-culture .big-paragraph {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 767px) {
  .black-bottom-section-culture {
    padding-top: 60px;
    padding-bottom: 0;
    margin-bottom: -40px;
  }
  .black-bottom-section-culture .big-paragraph {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
    padding: 0px 16px 0px 16px;
    margin-bottom: 40px;
  }
}
.black-bottom-section-culture .smile-badge {
  width: 200px;
  height: 200px;
}
@media (max-width: 767px) {
  .black-bottom-section-culture .smile-badge {
    width: 253px;
    height: 253px;
  }
}

.black-bottom-section {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .black-bottom-section {
    padding-top: 130px;
  }
}
@media (min-width: 768px) {
  .black-bottom-section {
    padding-top: 200px;
  }
}
.black-bottom-section .section {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
@media (min-width: 768px) {
  .black-bottom-section .section {
    padding: 0;
  }
}
.black-bottom-section .text-with-button {
  padding: 0;
  margin-top: 0px;
  margin-bottom: 240px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 840px;
  width: 100%;
}
@media (max-width: 767px) {
  .black-bottom-section .text-with-button {
    margin-bottom: 70px;
  }
}
@media (max-width: 991px) {
  .black-bottom-section .text-with-button {
    max-width: 650px;
  }
}
.black-bottom-section .text-with-button h2 {
  padding-right: unset;
  padding-left: unset;
  margin-top: unset;
}

.h3-text {
  margin-top: 40px;
}
.h3-text h3 {
  margin-bottom: 40px;
}
.h3-text hr {
  border: 0;
  margin: 0;
  border-top: 1px solid #000;
}
@media (max-width: 767px) {
  .h3-text h3 {
    margin-bottom: 16px;
  }
}

.growth_section {
  position: relative;
  overflow: hidden;
  margin-top: 240px;
  margin-bottom: 60px;
}
.growth_section .primer-hr {
  margin: 0;
  border: none;
  border-top: 1px solid;
}
.growth_section .growth_container {
  position: relative;
  inset: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  margin-top: 200px;
  padding-bottom: 230px;
}
@media (max-width: 991px) {
  .growth_section .growth_container {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .growth_section .growth_container {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.growth_section .growth_container h2 {
  grid-column: 1/span 13;
  margin-bottom: 40px;
}
.growth_section .growth_container .growth_text {
  grid-column: 1/span 11;
}
.growth_section .growth_container .growth_text .block-list .block-list-container {
  border-bottom: 1px #fff solid;
}
.growth_section .growth_container .growth_text .block-list .block-list-container hr {
  display: none;
}
@media (max-width: 1024px) {
  .growth_section {
    grid-template-columns: 1fr;
    margin-top: 120px;
    margin-bottom: 32px;
  }
  .growth_section .growth_container h2 {
    grid-column: 1/span 15;
  }
  .growth_section .growth_container .growth_text {
    grid-column: 1/span 15;
  }
}
@media (max-width: 836px) {
  .growth_section .growth_container h2 {
    grid-column: 1/-1;
  }
  .growth_section .growth_container .growth_text {
    grid-column: 1/-1;
  }
}
@media (max-width: 767px) {
  .growth_section {
    padding-left: 0;
    padding-right: 0;
    margin-top: 120px;
    margin-bottom: 18px;
    padding-bottom: 60px;
  }
  .growth_section .growth_container {
    margin-top: 160px;
    padding-bottom: 0px;
  }
  .growth_section .growth_container h2 {
    margin-bottom: 32px;
  }
  .growth_section .growth_text {
    padding-bottom: 0px;
  }
}

.section-list-item-careers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 220px);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  padding-top: 60px;
}
.section-list-item-careers .--richtext, .section-list-item-careers p {
  margin-bottom: 0 !important;
}
.section-list-item-careers .item_1 {
  grid-area: 1/1/2/2;
}
.section-list-item-careers .item_2 {
  grid-area: 2/1/3/2;
}
.section-list-item-careers .item_3 {
  grid-area: 3/1/4/2;
}
.section-list-item-careers .item_4 {
  grid-area: 4/1/5/2;
}
.section-list-item-careers .item_5 {
  grid-area: 5/1/6/2;
}
.section-list-item-careers .item_6 {
  grid-area: 1/2/3/4;
}
.section-list-item-careers .item_7 {
  grid-area: 3/2/4/3;
}
.section-list-item-careers .item_8 {
  grid-area: 4/2/5/3;
}
.section-list-item-careers .item_9 {
  grid-area: 5/2/6/3;
}
.section-list-item-careers .item_10 {
  grid-area: 3/3/5/4;
}
.section-list-item-careers .item_11 {
  grid-area: 5/3/6/4;
}
@media (max-width: 575px) {
  .section-list-item-careers {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(11, max-content);
    padding-top: 44px;
    padding-bottom: 40px;
  }
  .section-list-item-careers .item_1 {
    grid-area: 1/1/2/2;
  }
  .section-list-item-careers .item_2 {
    grid-area: 2/1/3/2;
  }
  .section-list-item-careers .item_3 {
    grid-area: 3/1/4/2;
  }
  .section-list-item-careers .item_4 {
    grid-area: 4/1/5/2;
  }
  .section-list-item-careers .item_5 {
    grid-area: 5/1/6/2;
  }
  .section-list-item-careers .item_6 {
    grid-area: 6/1/7/2;
  }
  .section-list-item-careers .item_7 {
    grid-area: 7/1/8/2;
  }
  .section-list-item-careers .item_8 {
    grid-area: 8/1/9/2;
  }
  .section-list-item-careers .item_9 {
    grid-area: 9/1/10/2;
  }
  .section-list-item-careers .item_10 {
    grid-area: 10/1/11/2;
  }
  .section-list-item-careers .item_11 {
    grid-area: 11/1/12/2;
  }
}

.button-arrow-container_text {
  text-align: center;
  margin-bottom: 65px;
}
.button-arrow-container .btn-arrow__list {
  border-bottom: 1px solid #000;
}
.button-arrow-container .btn-arrow {
  border-bottom: none;
}
@media (max-width: 575px) {
  .button-arrow-container .btn-arrow__title {
    width: max-content;
  }
  .button-arrow-container .btn-arrow__icons {
    display: none;
  }
}

.job_postings_list a {
  color: #000;
  text-decoration: none;
}

.careers-text-slider {
  padding-bottom: 40px;
}
.careers-text-slider .swiper-slide {
  width: auto;
}
.careers-text-slider .swiper-slide__box {
  width: max-content;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.careers-text-slider .swiper-slide__box h2 {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .careers-text-slider .swiper-slide__box {
    height: 150px;
  }
}
@media (max-width: 767px) {
  .careers-text-slider .swiper-slide__box {
    height: 98px;
  }
}
.careers-text-slider .swiper-text, .careers-text-slider .swiper-text--reverse {
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
}
.careers-text-slider .swiper-wrapper {
  transition-timing-function: linear !important;
}
.careers-text-slider .fixed-box, .careers-text-slider .border-bottom {
  height: 180px;
  display: flex;
  align-items: center;
}
.careers-text-slider .fixed-box img, .careers-text-slider .border-bottom img {
  height: 100px;
}
@media (max-width: 1024px) {
  .careers-text-slider .fixed-box, .careers-text-slider .border-bottom {
    height: 150px;
  }
  .careers-text-slider .fixed-box img, .careers-text-slider .border-bottom img {
    height: 80px;
  }
}
@media (max-width: 767px) {
  .careers-text-slider .fixed-box, .careers-text-slider .border-bottom {
    height: 98px;
  }
  .careers-text-slider .fixed-box img, .careers-text-slider .border-bottom img {
    height: 50px;
  }
}
.careers-text-slider .fixed-box {
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
}
.careers-text-slider .swiper-text:first-child {
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
}
.careers-text-slider .fixed-box:last-child {
  border-bottom: 2px solid #000;
  border-top: 1px solid #000;
}
@media (max-width: 767px) {
  .careers-text-slider {
    padding-bottom: 20px;
  }
}

.careers-circles {
  position: relative;
  height: 1280px;
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 240px;
  margin-left: auto;
  margin-right: auto;
  filter: invert(1);
}
.careers-circles .circle-black {
  background-color: #fff;
  height: 1280px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  mix-blend-mode: exclusion;
}
.careers-circles .left {
  position: absolute;
  right: 25vw;
  top: 0;
  overflow: hidden;
  transition: transform 2s ease-out;
}
.careers-circles .right {
  position: absolute;
  top: 0;
  left: 100vw;
  transition: transform 2s ease-in-out;
  overflow: hidden;
}
.careers-circles .circle-text {
  position: absolute;
  top: calc(50% - 205px);
  bottom: calc(50% - 205px);
  right: 0px;
  left: 0px;
  z-index: 3;
  height: max-content;
  transition: all 1.5s ease-out;
}
@media (max-width: 1024px) {
  .careers-circles .circle-text {
    margin: 0 auto;
  }
}
.careers-circles .circle-text h2 {
  margin-bottom: 40px;
  color: #fff;
  transition: margin-bottom 1.5s ease-out;
  transition-delay: 1s;
}
.careers-circles .circle-text p {
  margin-bottom: 0;
  margin-top: 0;
  color: #fff;
  width: 390px;
  transition: none;
}

@media (max-width: 850px) {
  .careers-circles {
    height: 1000px;
  }
  .careers-circles .circle-black {
    height: 1000px;
  }
  .careers-circles .right {
    left: 90vw;
  }
  .careers-circles .left {
    right: 15vw;
  }
  .careers-circles .circle-text {
    top: calc(50% - 164px);
    bottom: calc(50% - 164px);
  }
  .careers-circles .circle-text h2, .careers-circles .circle-text p {
    color: #fff;
  }
}
@media (max-width: 767px) {
  .careers-circles {
    margin-bottom: 120px;
    height: 667px;
    margin-bottom: 120px;
  }
  .careers-circles .circle-black {
    height: 667px;
  }
  .careers-circles p {
    width: 256px !important;
  }
  .careers-circles .right {
    left: 90vw;
  }
  .careers-circles .left {
    right: 15vw;
  }
}
@media (max-width: 575px) {
  .careers-circles .right {
    left: 65vw;
  }
  .careers-circles .left {
    right: -10vw;
  }
}
.position_detail_page_title_description {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 80px;
  margin-top: 240px;
}
@media (max-width: 991px) {
  .position_detail_page_title_description {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .position_detail_page_title_description {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 767px) {
  .position_detail_page_title_description {
    grid-template-columns: 1fr;
    row-gap: 16px;
    margin-top: 120px;
  }
}
.position_detail_page_title_description a {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0em;
  color: #000;
  text-decoration: none;
  grid-column: 1/-1;
  grid-row: 1;
  border-top: 2px solid #000;
  padding-top: 40px;
}
@media (max-width: 767px) {
  .position_detail_page_title_description a {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: 0em;
    padding-top: 16px;
    grid-column: 1/-1;
    margin-bottom: 16px;
  }
}
.position_detail_page_title_description a svg {
  width: 24px;
  transform: rotate(180deg);
  margin-right: 8px;
}
.position_detail_page_title_description h1 {
  font-size: 80px;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.03em;
  grid-column: 1/span 10;
  grid-row: 2;
  margin-bottom: 32px;
  align-self: start;
}
@media (max-width: 1024px) {
  .position_detail_page_title_description h1 {
    font-size: 64px;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.15625;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 767px) {
  .position_detail_page_title_description h1 {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
    grid-row: 2;
    grid-column: 1/-1;
    margin-bottom: 0;
  }
}
.position_detail_page_title_description h2 {
  font-size: 80px;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.03em;
  grid-column: 1/span 10;
  grid-row: 2;
  margin-bottom: 32px;
  align-self: start;
}
@media (max-width: 1024px) {
  .position_detail_page_title_description h2 {
    font-size: 64px;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.15625;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 767px) {
  .position_detail_page_title_description h2 {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
    grid-row: 2;
    grid-column: 1/-1;
    margin-bottom: 0;
  }
}
.position_detail_page_title_description p {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.6923076923;
  letter-spacing: 0em;
  grid-column: 12/-1;
  grid-row: 2;
  margin-bottom: 70px;
  align-self: start;
}
@media (max-width: 1024px) {
  .position_detail_page_title_description p {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: 0em;
  }
}
@media (max-width: 767px) {
  .position_detail_page_title_description p {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0em;
    grid-row: 3;
    grid-column: 1/-1;
    margin-bottom: 52px;
  }
}

.position_detail_page_expectations_requirements .block-list-container:last-child {
  border-bottom: 1px solid #000;
}
.position_detail_page_expectations_requirements hr {
  border: 0;
  border-top: 1px solid #000;
  height: 1px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .position_detail_page_expectations_requirements h1 {
    margin-top: 16px;
    margin-bottom: 0;
  }
  .position_detail_page_expectations_requirements .block-list-container__title {
    padding-bottom: 14px;
  }
  .position_detail_page_expectations_requirements li {
    margin-bottom: 16px !important;
  }
  .position_detail_page_expectations_requirements .block-list-container {
    padding-bottom: 16px;
  }
}

.position_detail_page_benefits {
  margin-top: 240px;
}
.position_detail_page_benefits .block-list-container:last-child {
  border-bottom: 1px solid #000;
}
.position_detail_page_benefits .block-list-container hr {
  display: none;
}
.position_detail_page_benefits hr {
  border: 0;
  border-top: 1px solid #000;
  height: 1px;
  margin-bottom: 0;
}
.position_detail_page_benefits h2 {
  margin-bottom: 32px;
  font-size: 80px;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.03em;
}
@media (max-width: 1024px) {
  .position_detail_page_benefits h2 {
    font-size: 64px;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.15625;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 767px) {
  .position_detail_page_benefits h2 {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .position_detail_page_benefits {
    margin-top: 60px;
  }
  .position_detail_page_benefits .block-list-container__title {
    padding-bottom: 14px;
  }
  .position_detail_page_benefits li {
    margin-bottom: 16px !important;
  }
  .position_detail_page_benefits .block-list-container {
    padding-bottom: 16px;
  }
}

.position_detail_page_apply {
  display: grid;
  place-content: center;
  margin-bottom: 120px;
}
.position_detail_page_apply a {
  color: #000;
  text-decoration: none;
  text-align: center;
}
.position_detail_page_apply .position_detail_page_apply_circle {
  background-color: #FFFF00;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.position_detail_page_apply .position_detail_page_apply_circle p {
  text-decoration: underline;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0em;
  margin-bottom: 0;
}
.position_detail_page_apply .position_detail_page_apply_circle h3 {
  font-size: 56px;
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 0.8571428571;
  letter-spacing: -0.05em;
  font-style: italic;
  margin-bottom: 24px;
}
.position_detail_page_apply .position_detail_page_apply_circle_paragraph {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.6666666667;
  letter-spacing: 0em;
  width: 395px;
  text-align: justify;
  margin-top: 24px;
}
@media (max-width: 767px) {
  .position_detail_page_apply .position_detail_page_apply_circle_paragraph {
    font-size: 11px;
    font-size: 0.6875rem;
    font-weight: 400;
    line-height: 1.6363636364;
    letter-spacing: normal;
    width: 340px;
  }
}
@media (max-width: 767px) {
  .position_detail_page_apply {
    margin-bottom: 18px;
  }
}

.concentric-circles {
  background: url("../images/growth-back.jpg") no-repeat center center;
  background-size: cover;
}
.concentric-circles .circle {
  position: absolute;
  z-index: 0;
  inset: 0;
  border-radius: 50%;
  border: 1px solid #fff;
}
.concentric-circles .circle[data-circle="1"] {
  height: 180px;
  width: 180px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 3px #fff;
  filter: drop-shadow(0px 0px 8px #fff);
}
.concentric-circles .circle[data-circle="2"] {
  opacity: 0.94;
}
.concentric-circles .circle[data-circle="3"] {
  opacity: 0.88;
}
.concentric-circles .circle[data-circle="4"] {
  opacity: 0.82;
}
.concentric-circles .circle[data-circle="5"] {
  opacity: 0.74;
}
.concentric-circles .circle[data-circle="6"] {
  opacity: 0.68;
  filter: blur(0.5px);
}
.concentric-circles .circle[data-circle="7"] {
  opacity: 0.62;
  filter: blur(1px);
}
.concentric-circles .circle[data-circle="8"] {
  opacity: 0.56;
  filter: blur(2px);
}
.concentric-circles .circle[data-circle="9"] {
  opacity: 0.5;
  filter: blur(3px);
}
.concentric-circles .circle[data-circle="10"] {
  opacity: 0.44;
  filter: blur(4px);
}
.concentric-circles .circle[data-circle="11"] {
  opacity: 0.38;
  filter: blur(5px);
}
.concentric-circles .circle[data-circle="12"] {
  opacity: 0.32;
  filter: blur(6px);
}
.concentric-circles .circle[data-circle="13"] {
  opacity: 0.26;
  filter: blur(7px);
}
.concentric-circles .circle[data-circle="14"] {
  opacity: 0.2;
  filter: blur(8px);
}
.concentric-circles .circle[data-circle="15"] {
  opacity: 0.14;
  filter: blur(9px);
}

:root {
  —-app-height: 100%;
}

.onboarding__nav {
  position: absolute;
  left: 1.2rem;
  top: 1.2rem;
  z-index: 300;
}
.onboarding__nav button.circle {
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  border-radius: 50%;
  background: #000;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.onboarding__nav button.circle svg {
  display: none;
}
.onboarding__intro.--hidden {
  opacity: 0;
  pointer-events: none;
}
.onboarding__intro__curtain {
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  bottom: 0;
  z-index: 200;
}
.onboarding__intro__curtain.black {
  background-color: #000;
}
.onboarding__intro__curtain.yellow {
  background-color: #FFFF00;
}
.onboarding__intro__curtain.red {
  background-color: #ff0000;
}
.onboarding__intro__white_ball {
  background-color: #fff;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 600;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.onboarding__screen {
  height: 100vh;
  max-height: var(--app-height);
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  padding: 6.5rem 1.2rem 0;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  pointer-events: none;
}
.onboarding__screen.--visible {
  opacity: 1;
  pointer-events: all;
}
.onboarding__screen.--dark {
  background: #1A1F20;
  color: #fff;
}
.onboarding__screen__content {
  position: relative;
  z-index: 200;
}
.onboarding__screen__content h2 {
  font-size: 2rem;
  line-height: 2.5rem;
}
.onboarding__screen__content h2 strong {
  display: inline-block;
  border-bottom: 3px solid #FFFF00;
}
.onboarding__screen__content p {
  margin: 1rem 0;
  font-size: 1rem;
  line-height: 1.75rem;
}
.onboarding__screen__bottom {
  position: absolute;
  padding: 1.2rem;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 100;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 50%;
}
.onboarding__screen__bottom.--buzo, .onboarding__screen__bottom.--mate, .onboarding__screen__bottom.--perro-laptop, .onboarding__screen__bottom.--adventure-time {
  height: 259px;
}
.onboarding__screen__bottom.--remera {
  height: 280px;
}
.onboarding__screen__bottom.--bosque {
  height: 300px;
}
.onboarding__screen__bottom.--bosque::after {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 14rem;
  background-image: linear-gradient(#1A1F20 10%, rgba(26, 31, 32, 0));
}
.onboarding__screen__bottom.--pines {
  height: 367px;
}
.onboarding__screen__bottom button {
  width: 100%;
}
.onboarding__screen.--final {
  overflow: hidden;
}
.onboarding__screen.--final .dog_slide {
  width: 100%;
  aspect-ratio: 1.69;
  background-size: cover;
  margin: 10rem 0 2.5rem;
}
.onboarding__screen.--final .explanation {
  background-color: #f1f1f1;
  padding: 2rem 1.2rem 0.2rem;
  margin: -1.2rem;
}
.onboarding__screen.--final .explanation h3 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.75rem;
  border-top: 1px solid #000;
  padding-top: 1rem;
}
.onboarding__screen.--final .explanation p.item {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.onboarding__screen.--final .explanation p.item.yellow {
  background-color: #FFFF00;
}

.geometric-separator-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 240px;
  padding-bottom: 120px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .geometric-separator-container {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .geometric-separator-container {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 768px) {
  .geometric-separator-container {
    padding-top: 120px;
    padding-bottom: 60px;
    margin: 0 auto;
  }
}

.geometric-separator-img {
  width: 100%;
  grid-column: 1/-1;
}

.people-are-saying__section {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .people-are-saying__section {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .people-are-saying__section {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1024px) {
  .people-are-saying__section {
    padding-top: 100px;
  }
}
@media (max-width: 768px) {
  .people-are-saying__section {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
.people-are-saying__title {
  grid-row: 1;
  grid-column: 1/-1;
  margin-bottom: 60px;
  justify-self: center;
}
@media (max-width: 768px) {
  .people-are-saying__title {
    margin-left: 16px;
    justify-self: flex-start;
  }
}
.people-are-saying__columns-container {
  grid-row: 2;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  height: 1280px;
  overflow: hidden;
  margin-top: 60px;
}
@media (max-width: 768px) {
  .people-are-saying__columns-container.--desktop {
    display: none;
  }
}
@media (max-width: 1024px) {
  .people-are-saying__columns-container {
    column-gap: 20px;
  }
}
.people-are-saying__column {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}
.people-are-saying__logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #F1F1F1;
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .people-are-saying__logo-container {
    margin-bottom: 20px;
  }
}
.people-are-saying__logo {
  max-width: 220px;
  height: auto;
}
.people-are-saying__item {
  width: 100%;
  padding: 40px;
  margin-bottom: 40px;
  height: auto;
  background-color: #F1F1F1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width: 1024px) {
  .people-are-saying__item {
    padding: 25px;
    margin-bottom: 20px;
  }
}
.people-are-saying__score {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.people-are-saying__score p {
  margin: 0 16px 0 0;
}
.people-are-saying__item-text-container {
  margin-top: 18px;
  font-size: 20px;
  line-height: 34px;
}
@media (max-width: 1024px) {
  .people-are-saying__item-text-container {
    font-size: 18px;
    line-height: 32px;
  }
}
.people-are-saying__item-text-container a {
  color: inherit;
}
.people-are-saying__item-title {
  font-weight: 700;
}
@media (max-width: 1024px) {
  .people-are-saying__item-title {
    font-size: 18px;
    line-height: 26px;
  }
}
.people-are-saying__item-title p {
  margin-bottom: 4px;
}
.people-are-saying__item-description {
  font-weight: 400;
}
.people-are-saying__item-description p {
  margin-top: 4px;
  margin-bottom: 16px;
}
.people-are-saying__item-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-top: 16px;
}
.people-are-saying__mask {
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  grid-column: 1/-1;
  height: 217px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}
.people-are-saying__slider {
  display: none;
}
@media (max-width: 768px) {
  .people-are-saying__slider.--mobile {
    display: flex;
    flex-direction: row;
    grid-column: 1/-1;
    overflow: auto;
  }
}
.people-are-saying__slider.--mobile::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.people-are-saying__slider.--mobile .people-are-saying__logo-container {
  min-width: 275px;
  height: 416px;
  margin: 0 8px 0 16px;
}
.people-are-saying__slider.--mobile .people-are-saying__logo {
  width: 180px;
}
.people-are-saying__slider.--mobile .people-are-saying__item {
  display: flex;
  min-width: 275px;
  height: 416px;
  margin: 0 8px;
}
.people-are-saying__slider.--mobile .people-are-saying__item-text-container {
  margin: 16px 0;
  height: 258px;
}
.people-are-saying__slider.--mobile .people-are-saying__item-title {
  display: block;
  margin-bottom: 4px;
}
.people-are-saying__slider.--mobile .people-are-saying__item-title p {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}
.people-are-saying__slider.--mobile .people-are-saying__item-description {
  display: block;
  margin-top: 4px;
}
.people-are-saying__slider.--mobile .people-are-saying__item-description p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin: 0;
}
.people-are-saying__slider.--mobile .people-are-saying__item-date {
  display: block;
}
.people-are-saying__slider.--mobile .people-are-saying__item-date p {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
}
.people-are-saying__slider.--mobile .people-are-saying__item:last-child {
  margin-right: 16px;
}
.people-are-saying__slider.--mobile .people-are-saying__score {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.people-are-saying__slider.--mobile .people-are-saying__score p {
  margin: 0 16px 0 0;
}

.team-climate__section {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .team-climate__section {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .team-climate__section {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 768px) {
  .team-climate__section {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
.team-climate__title {
  grid-row: 1;
  grid-column: 1/-1;
  justify-self: center;
  max-width: 925px;
  text-align: center;
  margin-bottom: 120px;
}
@media (max-width: 1024px) {
  .team-climate__title {
    max-width: 740px;
    margin-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .team-climate__title {
    margin-bottom: 40px;
    font-weight: 500;
    text-align: start;
  }
}
.team-climate__square-grafic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  height: 620px;
  border-bottom: 2px solid #000;
}
.team-climate__square-grafic.--like-ego {
  grid-row: 2;
  grid-column: 1/12;
  background-image: linear-gradient(to right, #FFFF00 1%, #F1F1F1 0);
}
@media (max-width: 768px) {
  .team-climate__square-grafic.--like-ego {
    grid-column: 1/-1;
  }
}
.team-climate__square-grafic.--recommend-ego {
  grid-row: 2;
  grid-column: 13/-1;
  background-image: linear-gradient(to right, #FFFF00 1%, #F1F1F1 0);
}
@media (max-width: 768px) {
  .team-climate__square-grafic.--recommend-ego {
    grid-column: 1/-1;
    grid-row: 3;
  }
}
@media (max-width: 1024px) {
  .team-climate__square-grafic {
    height: 422px;
    padding: 32px;
  }
}
@media (max-width: 768px) {
  .team-climate__square-grafic {
    height: 340px;
    margin-bottom: 16px;
  }
}
.team-climate__square-grafic__text {
  align-self: flex-start;
  max-width: 400px;
}
@media (max-width: 1024px) {
  .team-climate__square-grafic__text {
    max-width: 340px;
  }
}
.team-climate__square-grafic__text p {
  margin: 0;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.02em;
}
@media (max-width: 1024px) {
  .team-climate__square-grafic__text p {
    font-size: 34px;
    line-height: 40px;
  }
}
@media (max-width: 768px) {
  .team-climate__square-grafic__text p {
    font-size: 30px;
    line-height: 36px;
  }
}
.team-climate__square-grafic__score {
  position: relative;
  height: 128px;
  width: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.team-climate__square-grafic__score p {
  margin: 0;
  margin-right: 10px;
  font-weight: 400;
  font-size: 160px;
  line-height: 80%;
  letter-spacing: -0.03em;
  position: absolute;
  right: 0%;
}
@media (max-width: 1024px) {
  .team-climate__square-grafic__score p {
    font-size: 100px;
  }
}
@media (max-width: 768px) {
  .team-climate__square-grafic__score p {
    font-size: 70px;
  }
}
.team-climate__survey-info {
  grid-row: 3;
  grid-column: 1/-1;
  justify-self: flex-end;
}
@media (max-width: 768px) {
  .team-climate__survey-info {
    grid-row: 4;
    justify-self: flex-start;
  }
}
.team-climate__survey-info p {
  margin: 0;
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  text-align: right;
}
@media (max-width: 1024px) {
  .team-climate__survey-info p {
    font-size: 16px;
    line-height: 16px;
    margin-top: 40px;
  }
}
@media (max-width: 768px) {
  .team-climate__survey-info p {
    text-align: start;
    margin-top: 8px;
    font-size: 12px;
  }
}

.storybook .grid-system .grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: 600px;
  align-items: center;
}
@media (max-width: 991px) {
  .storybook .grid-system .grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .storybook .grid-system .grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.storybook .grid-system .grid span {
  height: 100%;
  border: 1px dashed red;
}
.storybook .grid-system .grid span:nth-child(1) {
  grid-column: 1/span 1;
}
.storybook .grid-system .grid span:nth-child(2) {
  grid-column: 3/span 1;
}
.storybook .grid-system .grid span:nth-child(3) {
  grid-column: 5/span 1;
}
.storybook .grid-system .grid span:nth-child(4) {
  grid-column: 7/span 1;
}
.storybook .grid-system .grid span:nth-child(5) {
  grid-column: 9/span 1;
}
.storybook .grid-system .grid span:nth-child(6) {
  grid-column: 11/span 1;
}
.storybook .grid-system .grid span:nth-child(7) {
  grid-column: 13/span 1;
}
.storybook .grid-system .grid span:nth-child(8) {
  grid-column: 15/span 1;
}
.storybook .grid-system .grid span:nth-child(9) {
  grid-column: 17/span 1;
}
.storybook .grid-system .grid span:nth-child(10) {
  grid-column: 19/span 1;
}
.storybook .grid-system .grid span:nth-child(11) {
  grid-column: 21/span 1;
}
.storybook .grid-system .grid span:nth-child(12) {
  grid-column: 23/span 1;
}
@media (max-width: 767px) {
  .storybook .grid-system .grid {
    display: grid;
    grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
    height: 300px;
    align-items: center;
  }
}
@media (max-width: 767px) and (max-width: 991px) {
  .storybook .grid-system .grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .storybook .grid-system .grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 767px) {
  .storybook .grid-system .grid span:nth-child(n+5) {
    display: none;
  }
}

.test-custom-grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 50px;
}
@media (max-width: 991px) {
  .test-custom-grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .test-custom-grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .test-custom-grid > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .test-custom-grid > *:nth-child(2n+1) {
    grid-column: 1/span 3;
  }
  .test-custom-grid > *:nth-child(2n) {
    grid-column: 5/span 3;
  }
}
@media (min-width: 768px) {
  .test-custom-grid > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .test-custom-grid > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .test-custom-grid > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .test-custom-grid > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
@media (min-width: 992px) {
  .test-custom-grid > *:nth-child(6n+1) {
    grid-column: 1/span 3;
  }
  .test-custom-grid > *:nth-child(6n+2) {
    grid-column: 5/span 3;
  }
  .test-custom-grid > *:nth-child(6n+3) {
    grid-column: 9/span 3;
  }
  .test-custom-grid > *:nth-child(6n+4) {
    grid-column: 13/span 3;
  }
  .test-custom-grid > *:nth-child(6n+5) {
    grid-column: 17/span 3;
  }
  .test-custom-grid > *:nth-child(6n) {
    grid-column: 21/span 3;
  }
}
.test-custom-grid span {
  display: block;
  background: red;
  height: 150px;
}

.manifesto .upper-section {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 991px) {
  .manifesto .upper-section {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .manifesto .upper-section {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.manifesto .upper-section h1 {
  grid-column: 1/span 23;
}
.manifesto .upper-section h3 {
  grid-column: 1/span 23;
  grid-row: 4;
  margin-top: 30px;
}
.manifesto .upper-section p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0em;
  grid-column: 1/span 23;
  grid-row: 3;
  margin-top: 30px;
}
.manifesto .upper-section div {
  grid-column: 1/span 23;
  grid-row: 2;
  border-bottom: 2px solid #000;
  margin-top: 80px;
}
.manifesto .down-section {
  margin-top: 250px;
  margin-bottom: 240px;
}
.manifesto .down-section .txt-div {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 991px) {
  .manifesto .down-section .txt-div {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .manifesto .down-section .txt-div {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.manifesto .down-section .txt-div p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0em;
  grid-column: 13/span 10;
  margin-bottom: 35px;
}
.manifesto .down-section .manifesto-grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.7222222222;
  letter-spacing: normal;
  align-items: flex-start;
  border-top: 1px solid #000;
}
@media (max-width: 991px) {
  .manifesto .down-section .manifesto-grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .manifesto .down-section .manifesto-grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.manifesto .down-section .manifesto-grid .manifesto-grid-left {
  grid-column: 1/span 11;
}
.manifesto .down-section .manifesto-grid .manifesto-grid__nav {
  grid-column: 13/span 11;
}
.manifesto .down-section .manifesto-grid .manifesto-grid__nav .btn__list {
  border-width: 0;
}
@media (max-width: 991px) {
  .manifesto .upper-section p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;
  }
}
@media (max-width: 767px) {
  .manifesto .down-section .txt-div p {
    grid-column: 1/span 5;
  }
  .manifesto .down-section .manifesto-grid {
    border: 0;
  }
  .manifesto .down-section .manifesto-grid .manifesto-grid__nav {
    grid-column: 1/span 7;
  }
  .manifesto .down-section .manifesto-grid .manifesto-grid__nav .btn__list {
    border-width: 1px;
  }
}

.home-hero .section {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  height: 100%;
  padding-top: 120px;
}
@media (max-width: 767px) {
  .home-hero h1 {
    font-size: 21vw;
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .home-hero h1 {
    margin-bottom: 64px;
  }
}
@media (min-width: 1200px) {
  .home-hero h1 {
    margin-bottom: 48px;
  }
}
.home-motto {
  height: 110%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFFF00;
}
.home-motto__content .motto-animation li {
  display: none;
  position: relative;
  opacity: 0;
  top: 20px;
}
.home-motto__content .motto-animation.--animate li {
  display: block;
  animation: reveal 0.3s ease-in-out 1 forwards;
}
.home-motto__content .motto-animation.--animate li:nth-child(1) {
  animation-delay: 0;
}
.home-motto__content .motto-animation.--animate li:nth-child(2) {
  animation-delay: 0.3s;
}
.home-motto__content .motto-animation.--animate li:nth-child(3) {
  animation-delay: 0.6s;
}
.home-motto__content .motto-animation.--animate li:nth-child(4) {
  animation-delay: 1.2s;
}
.home-motto__content .motto-animation.--animate li mark {
  background-color: transparent;
}
.home-motto__content .motto-animation.--animate li mark.--active {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
  text-underline-offset: 0.1em;
  font-style: italic;
  margin-right: 5px;
}
@media (max-width: 1199px) {
  .home-motto__content .motto-animation {
    font-size: 5vw;
  }
}
@media (max-width: 575px) {
  .home-motto__content .motto-animation {
    font-size: 6vw;
  }
  .home-motto__content .motto-animation.--animate li mark.--active {
    text-decoration-thickness: 2px;
  }
}
@keyframes reveal {
  from {
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}
.home-design-journey {
  padding: 110px 0 120px 0;
}
.home-design-journey .section-divider {
  border-width: 2px;
}
@media (min-width: 768px) {
  .home-design-journey {
    padding-top: 240px;
  }
}
@media (min-width: 768px) {
  .home-design-journey {
    padding-top: 230px;
  }
}
.home-design-journey__box {
  color: #fff;
}
.home-design-journey__header {
  padding-bottom: 32px;
}
.home-design-journey__secondary-h {
  padding-bottom: 80px;
}
.home-design-journey__secondary-h p {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .home-design-journey__secondary-h p {
    width: 100%;
    max-width: 620px;
  }
}
.home-design-journey__secondary-h p:last-of-type {
  margin-bottom: 0;
}
.home-design-journey__secondary-h h2 {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .home-design-journey__secondary-h h2 {
    width: 100%;
    margin-bottom: 32px;
    max-width: 840px;
  }
}
@media (max-width: 575px) {
  .home-design-journey__secondary-h {
    padding-bottom: 60px;
  }
}
.home-services {
  padding-top: 123px;
  padding-bottom: 123px;
}
@media (min-width: 768px) {
  .home-services {
    padding-top: 240px;
  }
}
@media (min-width: 992px) {
  .home-services {
    padding-top: 135px;
    padding-bottom: 135px;
  }
}
.home-services__box {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 32px;
}
@media (max-width: 991px) {
  .home-services__box {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .home-services__box {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.home-services__slider {
  position: relative;
  grid-column: 1/-1;
}
@media (min-width: 992px) {
  .home-services__slider {
    grid-column: span 9;
    max-width: 100%;
    height: auto;
  }
}
.home-services__slider .mask-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFF00;
}
.home-services__slider .mask-slider-box {
  overflow: hidden;
  position: relative;
}
.home-services__slider .mask-slider-box img {
  width: 100%;
}
.home-services__slider .mask-slider.--animated-start {
  animation: mask-animation-end 1.1s ease-in-out forwards;
}
.home-services__slider .mask-slider.--animated-end {
  animation: mask-animation-start 1.1s ease-in-out forwards;
}
.home-services__slider .mask-slider.--animated-init {
  animation: mask-animation-init 0.2s ease-in-out forwards;
}
.home-services__slider .swiper {
  z-index: -1;
}
@media (max-width: 991px) {
  .home-services__slider .swiper {
    aspect-ratio: 1;
  }
}
@media (max-width: 991px) {
  .home-services__slider .swiper-wrapper {
    position: absolute;
  }
}
@media (max-width: 991px) {
  .home-services__slider .swiper-slide {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: top;
  }
}
.home-services__badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-47%);
}
@media (min-width: 992px) {
  .home-services__badge {
    top: unset;
    bottom: 0;
    left: 0;
    transform: translate(-10px, 24px);
  }
}
.home-services__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: 1/-1;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .home-services__content {
    gap: 40px;
    grid-column: 11/span 11;
    order: 1;
  }
}
.home-services__title {
  margin-bottom: 0;
}
.home-services__text p:last-of-type {
  margin-bottom: 0;
}
.home-careers {
  padding-top: 123px;
  padding-bottom: 123px;
}
@media (min-width: 1200px) {
  .home-careers {
    padding-top: 135px;
    padding-bottom: 135px;
  }
}
.home-careers__box {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 32px;
}
@media (max-width: 991px) {
  .home-careers__box {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .home-careers__box {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.home-careers__thumb {
  grid-column: -1;
}
@media (min-width: 992px) {
  .home-careers__thumb {
    grid-column: 15/-1;
  }
}
.home-careers__thumb-box {
  overflow: hidden;
  position: relative;
  display: flex;
}
@media (max-width: 991px) {
  .home-careers__thumb-box {
    aspect-ratio: 1;
  }
}
.home-careers__thumb-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFF00;
}
.home-careers__thumb-mask.--animated-init {
  animation: mask-animation-init 0.2s ease-in-out forwards;
}
.home-careers__thumb-img {
  width: 100%;
}
@media (max-width: 991px) {
  .home-careers__thumb-img {
    object-fit: cover;
  }
}
.home-careers__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  grid-column: -1;
}
@media (min-width: 992px) {
  .home-careers__content {
    gap: 40px;
    grid-column: span 12;
  }
}
.home-careers__title {
  margin-bottom: 0;
}
.home-careers__text p:last-of-type {
  margin-bottom: 0;
}
.home-onu {
  padding-bottom: 0;
  padding-top: 0;
}
.home-culture {
  text-align: center;
  margin-top: -40px;
  z-index: -1;
}
@media (min-width: 768px) {
  .home-culture {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .home-culture {
    margin-top: 32px;
  }
}
@media (min-width: 1200px) {
  .home-culture {
    margin-top: -92px;
  }
}
.home-culture__box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  gap: 16px;
  padding-top: 120px;
  padding-bottom: 126px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  .home-culture__box {
    gap: 40px;
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: unset;
    padding-right: unset;
  }
}
@media (min-width: 1200px) {
  .home-culture__box {
    padding-top: 193px;
    padding-bottom: 153px;
  }
}
.home-culture__box > h2 {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .home-culture__box > h2 {
    max-width: 716px;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .home-culture__box > h2 {
    max-width: 840px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .home-culture__box p {
    max-width: 416px;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .home-culture__box p {
    max-width: 619px;
    width: 100%;
  }
}
.home-culture__box p:last-of-type {
  margin-bottom: 0;
}
.home-culture__box .gradient-background {
  position: absolute;
  inset: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #eee742;
  animation: gradient-background 10s infinite ease-in-out;
}
.home-culture__box .gradient-background div {
  position: absolute;
  inset: 0;
  z-index: 0;
}
.home-culture__box .gradient-background div:first-child {
  z-index: 1;
  background: radial-gradient(circle at 0 50%, #dedada 0%, rgba(255, 255, 255, 0) 100%) no-repeat;
  animation: layer1 5s infinite ease-in-out;
}
.home-culture__box .gradient-background div:nth-child(2) {
  background: radial-gradient(ellipse at 100% 80%, #ecdc29 0%, rgba(255, 255, 255, 0) 100%) no-repeat;
}
.home-culture__box .gradient-background div:last-child {
  background: radial-gradient(ellipse at 90% 80%, #b3c817 10%, rgba(255, 255, 255, 0) 50%) no-repeat;
  animation: layer3 5s infinite ease-in-out;
}

@keyframes mask-animation-start {
  0% {
    left: -100%;
    background-color: #FFFF00;
  }
  90% {
    left: -100%;
  }
  100% {
    left: 0;
    background-color: #FFFF00;
  }
}
@keyframes mask-animation-end {
  0% {
    left: 0;
    background-color: #FFFF00;
  }
  90% {
    left: 0;
  }
  100% {
    left: 100%;
    background-color: #FFFF00;
  }
}
@keyframes mask-animation-init {
  0% {
    left: 0;
    background-color: #FFFF00;
  }
  100% {
    left: -100%;
    background-color: #FFFF00;
  }
}
/* Background gradient animation */
@keyframes gradient-background {
  0% {
    background-color: #eee742;
  }
  70% {
    background-color: #eee742;
  }
  85% {
    background-color: #fffa87;
  }
  100% {
    background-color: #eee742;
  }
}
@keyframes layer1 {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes layer3 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.service-hero--copy {
  position: absolute;
  inset: 0 0 auto 0;
  z-index: 1;
  clip-path: inset(0px 0px 0px 0px);
}
.service-hero--copy.section {
  padding-top: 0;
  padding-bottom: 0;
}
.service-services {
  padding-top: 0;
}
.service-services__list {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: start;
  row-gap: 38px;
}
@media (max-width: 991px) {
  .service-services__list {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .service-services__list {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .service-services__list > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .service-services__list > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .service-services__list > *:nth-child(3n+1) {
    grid-column: 1/span 7;
  }
  .service-services__list > *:nth-child(3n+2) {
    grid-column: 9/span 7;
  }
  .service-services__list > *:nth-child(3n) {
    grid-column: 17/span 7;
  }
}
@media (min-width: 992px) {
  .service-services__list > *:nth-child(3n+1) {
    grid-column: 1/span 7;
  }
  .service-services__list > *:nth-child(3n+2) {
    grid-column: 9/span 7;
  }
  .service-services__list > *:nth-child(3n) {
    grid-column: 17/span 7;
  }
}
@media (min-width: 1200px) {
  .service-services__list {
    min-height: 440px;
    align-items: unset;
  }
}
.service__badge {
  margin: 65px auto;
  display: none;
}
@media (max-width: 767px) {
  .service__badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .service-logo-slider .swiper-logo--reverse {
    margin-top: -40px;
  }
}
.service-logo-slider img {
  display: block;
  max-width: 100%;
  height: auto;
}
.service-logo-slider .swiper-wrapper {
  transition-timing-function: linear !important;
  align-items: center;
  align-content: center;
}
@media (max-width: 767px) {
  .service-logo-slider .swiper-wrapper {
    margin-bottom: 25px;
  }
}
.service-culture {
  padding-top: 120px;
}
@media (min-width: 992px) {
  .service-culture {
    padding-top: 240px;
  }
}
.service-culture__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 1024px;
  text-align: center;
}
@media (max-width: 767px) {
  .service-culture__inner {
    gap: 32px;
    text-align: start;
    justify-content: start;
    align-items: start;
  }
}
.service-culture__inner h2 {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .service-culture__inner {
    max-width: 650px;
  }
  .service-culture__inner p {
    max-width: 650px;
  }
}
.service-culture__text {
  width: 100%;
}
@media (min-width: 992px) {
  .service-culture__text {
    max-width: 570px;
  }
}
@media (min-width: 1200px) {
  .service-culture__text {
    max-width: 620px;
  }
}
.service-culture__text p:last-of-type {
  margin-bottom: 0;
}
.service-explanation__container.section {
  padding-top: 0;
  padding-bottom: 0;
}
.service-explanation__inner {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
@media (min-width: 768px) {
  .service-explanation__inner {
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 768px) {
  .service-explanation__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
}
@media (min-width: 1200px) {
  .service-explanation__inner {
    padding-top: 160px;
    padding-bottom: 124px;
  }
}
@media (min-width: 992px) {
  .service-explanation__inner .btn-box {
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .service-explanation__inner .btn-box {
    margin-top: 40px;
  }
}
.service-explanation__title {
  width: 100%;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
}
@media (min-width: 768px) {
  .service-explanation__title {
    max-width: 720px;
    font-size: 64px;
    line-height: 74px;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .service-explanation__title {
    max-width: 840px;
    font-size: 80px;
    line-height: 92px;
  }
}
.service-explanation__text {
  width: 100%;
}
@media (min-width: 768px) {
  .service-explanation__text {
    max-width: 620px;
    text-align: center;
  }
}
.service-explanation__text p:last-of-type {
  margin-bottom: 0;
}
.service-cms__box {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 38px;
}
@media (max-width: 991px) {
  .service-cms__box {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .service-cms__box {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (min-width: 768px) {
  .service-cms__box {
    padding-right: 0;
    padding-left: 0;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
.service-cms__content {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
@media (min-width: 768px) {
  .service-cms__content {
    grid-column: 1/12;
  }
}
@media (min-width: 1200px) {
  .service-cms__content {
    gap: 40px;
  }
}
.service-cms__content-title {
  margin-bottom: 0;
}
.service-cms__content-text p:last-of-type {
  margin-bottom: 0;
}
.service-cms__thumb {
  grid-column: 1/-1;
  order: -1;
}
.service-cms__thumb img {
  max-width: 100%;
  margin: auto;
}
@media (min-width: 768px) {
  .service-cms__thumb {
    grid-column: 15/-1;
    order: unset;
  }
}
.service-cms__thumb-sm {
  display: block;
}
@media (min-width: 768px) {
  .service-cms__thumb-sm {
    display: none;
  }
}
.service-cms__thumb-lg {
  display: block;
}
@media (max-width: 767px) {
  .service-cms__thumb-lg {
    display: none;
  }
}
.service-cms__thumb--all-devices .service-cms__thumb-lg {
  display: block !important;
}
.service-dev-features__box {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 35px;
}
@media (max-width: 991px) {
  .service-dev-features__box {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .service-dev-features__box {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.service-dev-features__content > * {
  margin-bottom: 0;
}
.service-dev-features__thumb {
  max-width: 100%;
}
.service-dev-features--row {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.service-dev-features--row > *:nth-child(odd) .service-dev-features__content {
  grid-column: 1/-1;
  grid-row: 2;
}
@media (min-width: 768px) {
  .service-dev-features--row > *:nth-child(odd) .service-dev-features__content {
    grid-column: 14/-1;
    grid-row: 1;
  }
}
.service-dev-features--row > *:nth-child(odd) .service-dev-features__thumb {
  grid-column: 1/-1;
  grid-row: 1;
}
@media (min-width: 768px) {
  .service-dev-features--row > *:nth-child(odd) .service-dev-features__thumb {
    grid-column: 1/span 12;
    grid-row: 1;
  }
}
@media (min-width: 768px) {
  .service-dev-features--row > *:nth-child(even) .service-dev-features__content {
    grid-column: 1/span 12;
    grid-row: 1;
  }
}
@media (min-width: 768px) {
  .service-dev-features--row > *:nth-child(even) .service-dev-features__thumb {
    grid-column: 14/-1;
    grid-row: 1;
  }
}
.service-dev-features--row .service-dev-features__box {
  align-items: center;
}
.service-dev-features--row .service-dev-features__thumb {
  max-width: 100%;
  grid-column: 1/-1;
}
.service-dev-features--row .service-dev-features__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: 1/-1;
}
@media (min-width: 1200px) {
  .service-dev-features--row .service-dev-features__content {
    gap: 40px;
  }
}
.service-dev-features--col {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: unset;
  margin-top: 50px;
  row-gap: 50px;
}
@media (max-width: 991px) {
  .service-dev-features--col {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .service-dev-features--col {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .service-dev-features--col > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .service-dev-features--col > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .service-dev-features--col > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .service-dev-features--col > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .service-dev-features--col > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .service-dev-features--col > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
.service-dev-features--col .service-dev-features__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  align-items: unset;
  gap: 30px;
  border-top: 3px solid #000;
  padding-top: 40px;
}
@media (min-width: 768px) {
  .service-dev-features--col .service-dev-features__box {
    gap: 80px;
  }
}
.service-dev-features--col .service-dev-features__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
@media (min-width: 768px) {
  .service-dev-features--col .service-dev-features__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}
.service-dev-features--col .service-dev-features__content > * {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .service-dev-features--col .service-dev-features__content > * {
    text-align: center;
  }
}

.inspiring-forest__intro {
  opacity: 0;
  position: relative;
  min-height: 120vw;
  background: #1F292A no-repeat top center;
  background-size: cover;
}
.inspiring-forest__intro__quote {
  position: absolute;
  inset: auto 0 0 0;
  z-index: 0;
  color: #fff;
  background: linear-gradient(0deg, rgb(31, 41, 42) 55%, rgba(31, 41, 42, 0.95) 60%, rgba(31, 41, 42, 0.6) 70%, rgba(31, 41, 42, 0.2) 80%, rgba(31, 41, 42, 0.05) 90%, rgba(31, 41, 42, 0) 100%);
  padding-top: 20vw;
}
.inspiring-forest__intro__quote .main-grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 991px) {
  .inspiring-forest__intro__quote .main-grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .inspiring-forest__intro__quote .main-grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.inspiring-forest__intro__quote .main-grid blockquote {
  display: block;
  grid-column: 5/span 15;
  margin: auto;
}
.inspiring-forest__intro__quote .main-grid blockquote p {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
  color: #fff;
  margin-bottom: 80px;
}
.inspiring-forest__intro__quote .main-grid blockquote address {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;
}
.inspiring-forest__intro__quote .main-grid blockquote address img {
  width: 73px;
  height: 73px;
  border-radius: 50%;
}
.inspiring-forest__intro__quote .main-grid blockquote address .info {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: normal;
}
.inspiring-forest__intro__quote .main-grid blockquote address .info strong {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: normal;
}
@media (max-width: 767px) {
  .inspiring-forest__intro {
    min-height: 130vw;
  }
  .inspiring-forest__intro__quote {
    padding-top: 30vw;
    padding-bottom: 80px;
  }
  .inspiring-forest__intro__quote .main-grid blockquote {
    grid-column: 1/span 7;
  }
  .inspiring-forest__intro__quote .main-grid blockquote p {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
  .inspiring-forest__intro__quote .main-grid blockquote address .info {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    letter-spacing: normal;
  }
  .inspiring-forest__intro__quote .main-grid blockquote address .info strong {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .inspiring-forest__intro {
    min-height: 250vw;
  }
  .inspiring-forest__intro__quote {
    padding-top: 40vw;
    padding-bottom: 60px;
  }
  .inspiring-forest__intro__quote .main-grid blockquote {
    margin: 0;
  }
  .inspiring-forest__intro__quote .main-grid blockquote p {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
    margin-bottom: 32px;
  }
  .inspiring-forest__intro__quote .main-grid blockquote address .info {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: normal;
  }
  .inspiring-forest__intro__quote .main-grid blockquote address .info strong {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.inspiring-forest__gallery {
  background-color: #1F292A;
}
.inspiring-forest__gallery__grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: flex-start;
  position: relative;
}
@media (max-width: 991px) {
  .inspiring-forest__gallery__grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .inspiring-forest__gallery__grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.inspiring-forest__gallery__grid img {
  display: block;
  max-width: 100%;
  height: auto;
  position: relative;
}
.inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img01 {
  grid-column: 1/span 7;
  margin-bottom: 0 !important;
  top: 20%;
}
.inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img02 {
  grid-column: 9/span 7;
  grid-row: 2;
  z-index: 2;
  top: -50%;
}
.inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img03 {
  grid-column: 17/span 7;
  grid-row: 1;
  top: 70%;
}
.inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img04 {
  grid-column: 1/span 23;
  grid-row: 3;
  top: -17%;
  margin-bottom: -13%;
  z-index: 1;
}
.inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img05 {
  grid-column: 1/span 7;
  grid-row: 4;
  top: -10%;
  margin-bottom: -10%;
  z-index: 2;
}
.inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img06 {
  grid-column: 9/span 7;
  grid-row: 5;
  margin-top: 0 !important;
}
.inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img07 {
  grid-column: 17/span 7;
  grid-row: 6;
}
.inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img08 {
  grid-column: 1/span 23;
  grid-row: 7;
  margin-top: 2.5%;
}
@media (max-width: 767px) {
  .inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img01 {
    grid-column: 1/span 3;
    top: 0;
  }
  .inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img02 {
    grid-column: 3/span 3;
    grid-row: 2;
    top: 0;
    margin-top: 5%;
  }
  .inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img03 {
    grid-column: 5/span 3;
    margin-top: 80%;
    top: 0;
    grid-row: 1;
  }
  .inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img04 {
    grid-column: 1/span 7;
    margin-left: -16px;
    width: calc(100% + 32px);
    max-width: none !important;
    margin-top: -4%;
  }
  .inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img05 {
    grid-column: 1/span 3;
    top: 15%;
    margin-bottom: 1%;
  }
  .inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img06 {
    grid-column: 5/span 3;
    top: 15%;
  }
  .inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img07 {
    grid-column: 1/span 3;
    z-index: 1;
    top: 15%;
  }
  .inspiring-forest__gallery__grid .inspiring-forest__gallery__grid-img08 {
    grid-column: 1/span 7;
    margin-left: -16px;
    width: calc(100% + 32px);
    max-width: none !important;
  }
}
.inspiring-forest__dots-forest {
  background-color: #1F292A;
  color: #fff;
}
.inspiring-forest__dots-forest__grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 2.5vw;
  margin-bottom: 240px;
}
@media (max-width: 991px) {
  .inspiring-forest__dots-forest__grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .inspiring-forest__dots-forest__grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 575px) {
  .inspiring-forest__dots-forest__grid > *:nth-child(4n+1) {
    grid-column: 1/span 1;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(4n+2) {
    grid-column: 3/span 1;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(4n+3) {
    grid-column: 5/span 1;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(4n) {
    grid-column: 7/span 1;
  }
}
@media (min-width: 576px) {
  .inspiring-forest__dots-forest__grid > *:nth-child(4n+1) {
    grid-column: 1/span 1;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(4n+2) {
    grid-column: 3/span 1;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(4n+3) {
    grid-column: 5/span 1;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(4n) {
    grid-column: 7/span 1;
  }
}
@media (min-width: 768px) {
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+1) {
    grid-column: 1/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+2) {
    grid-column: 5/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+3) {
    grid-column: 9/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+4) {
    grid-column: 13/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+5) {
    grid-column: 17/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n) {
    grid-column: 21/span 3;
  }
}
@media (min-width: 992px) {
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+1) {
    grid-column: 1/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+2) {
    grid-column: 5/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+3) {
    grid-column: 9/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+4) {
    grid-column: 13/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n+5) {
    grid-column: 17/span 3;
  }
  .inspiring-forest__dots-forest__grid > *:nth-child(6n) {
    grid-column: 21/span 3;
  }
}
.inspiring-forest__dots-forest__grid .dot-tree {
  display: block;
  width: 100%;
}
.inspiring-forest__dots-forest__grid .dot-tree circle {
  fill: #2CDB87;
}
@media (max-width: 991px) {
  .inspiring-forest__dots-forest__grid {
    row-gap: 1.5vw;
  }
}
@media (max-width: 767px) {
  .inspiring-forest__dots-forest__grid {
    row-gap: 3.5vw;
  }
}
.inspiring-forest__dots-forest__counter {
  position: sticky;
  bottom: 72px;
  margin-bottom: 0;
  opacity: 0;
}
@media (max-width: 767px) {
  .inspiring-forest__dots-forest__counter {
    bottom: 16px;
  }
}
@media (max-width: 575px) {
  .inspiring-forest__dots-forest__grid {
    margin-bottom: 120px;
  }
}
.inspiring-forest__zz-boxes {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
}
.inspiring-forest__zz-boxes__box {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  min-height: 600px;
}
@media (max-width: 991px) {
  .inspiring-forest__zz-boxes__box {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .inspiring-forest__zz-boxes__box {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.inspiring-forest__zz-boxes__box .description {
  padding: 20px 0;
  grid-row: 1;
}
.inspiring-forest__zz-boxes__box .description h4 {
  margin-bottom: 40px;
}
.inspiring-forest__zz-boxes__box .description p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
  margin-bottom: 0;
}
.inspiring-forest__zz-boxes__box .description p:not(:last-child) {
  margin-bottom: 1.5em;
}
.inspiring-forest__zz-boxes__box .image {
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
  grid-row: 1;
}
.inspiring-forest__zz-boxes__box .image.--video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: none;
  position: relative;
  overflow: hidden;
}
.inspiring-forest__zz-boxes__box .image.--video .icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
}
.inspiring-forest__zz-boxes__box .image.--video .back {
  position: absolute;
  inset: 0;
  background: no-repeat center center;
  background-size: cover;
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out, filter 0.2s ease-in-out;
}
.inspiring-forest__zz-boxes__box .image.--video:hover .icon {
  width: 110px;
  height: 110px;
}
.inspiring-forest__zz-boxes__box .image.--video:hover .back {
  transform: scale(1);
  filter: brightness(75%);
}
.inspiring-forest__zz-boxes__box:nth-child(odd) .description {
  grid-column: 1/span 11;
}
.inspiring-forest__zz-boxes__box:nth-child(odd) .image {
  grid-column: 15/span 9;
}
.inspiring-forest__zz-boxes__box:nth-child(even) .image {
  grid-column: 1/span 9;
}
.inspiring-forest__zz-boxes__box:nth-child(even) .description {
  grid-column: 13/span 11;
}
@media (max-width: 920px) {
  .inspiring-forest__zz-boxes__box {
    row-gap: 24px;
  }
  .inspiring-forest__zz-boxes__box .image {
    min-height: 80vw;
  }
  .inspiring-forest__zz-boxes__box:nth-child(odd) .description, .inspiring-forest__zz-boxes__box:nth-child(even) .description {
    grid-column: 1/span 23;
    grid-row: 1;
  }
  .inspiring-forest__zz-boxes__box:nth-child(odd) .image, .inspiring-forest__zz-boxes__box:nth-child(even) .image {
    grid-column: 1/span 23;
    grid-row: 2;
  }
}
@media (max-width: 767px) {
  .inspiring-forest__zz-boxes__box .description p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
  .inspiring-forest__zz-boxes__box:nth-child(odd) .description, .inspiring-forest__zz-boxes__box:nth-child(even) .description {
    grid-column: 1/span 7;
  }
  .inspiring-forest__zz-boxes__box:nth-child(odd) .image, .inspiring-forest__zz-boxes__box:nth-child(even) .image {
    grid-column: 1/span 7;
  }
}
@media (max-width: 575px) {
  .inspiring-forest__zz-boxes__box .description p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
  .inspiring-forest__zz-boxes__box .image {
    min-height: 100vw;
  }
}
.inspiring-forest__sliders {
  position: relative;
  background: #1F292A no-repeat top center;
  background-size: cover;
  padding: 0;
  margin-bottom: 240px;
}
.inspiring-forest__sliders--wrapper {
  padding-top: 240px;
  padding-bottom: 240px;
}
.inspiring-forest__sliders__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
.inspiring-forest__sliders__disclaimer {
  position: absolute;
  inset: auto 0 0 0;
  padding: 30px 0;
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: normal;
  color: #fff;
  background: linear-gradient(0deg, rgb(31, 41, 42) 20%, rgba(31, 41, 42, 0.5) 40%, rgba(31, 41, 42, 0.35) 60%, rgba(31, 41, 42, 0.2) 80%, rgba(31, 41, 42, 0.05) 90%, rgba(31, 41, 42, 0) 100%);
}
@media (max-width: 930px) {
  .inspiring-forest__sliders--wrapper {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .inspiring-forest__sliders__grid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 575px) {
  .inspiring-forest__sliders {
    margin-bottom: 120px;
  }
  .inspiring-forest__sliders__disclaimer {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }
}
.inspiring-forest__sliders__slider {
  display: block;
  width: 100%;
  min-height: 300px;
}
.inspiring-forest__sliders__slider .swiper-pagination {
  bottom: 55px;
  text-align: left;
  padding: 0 60px;
}
.inspiring-forest__sliders__slider .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: #6F6F6F;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.inspiring-forest__sliders__slider .swiper-pagination-bullet-active {
  background-color: #000;
}
.inspiring-forest__sliders__slider .swiper-button-next {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.inspiring-forest__sliders__slider .swiper-button-next:after {
  content: "";
  width: 25px;
  height: 25px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  margin-left: -15px;
  transform: rotate(45deg);
}
.inspiring-forest__sliders__slider .swiper-button-prev {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.inspiring-forest__sliders__slider .swiper-button-prev:after {
  content: "";
  width: 25px;
  height: 25px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  margin-right: -15px;
  transform: rotate(-135deg);
}
.inspiring-forest__sliders__slider .swiper-button-disabled {
  opacity: 0 !important;
}
.inspiring-forest__sliders__slider:hover .swiper-button-next {
  opacity: 1;
}
.inspiring-forest__sliders__slider:hover .swiper-button-prev {
  opacity: 1;
}
.inspiring-forest__sliders__slider .swiper-slide {
  padding: 60px 60px 120px;
}
.inspiring-forest__sliders__slider .swiper-slide h4 {
  margin-bottom: 40px;
}
.inspiring-forest__sliders__slider .swiper-slide h4 strong {
  display: block;
  font-weight: inherit;
  color: #2CDB87;
}
.inspiring-forest__sliders__slider .swiper-slide p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
  margin-bottom: 0;
}
.inspiring-forest__sliders__slider .swiper-slide p:not(:last-child) {
  margin-bottom: 1.5em;
}
.inspiring-forest__sliders__slider.--light {
  background-color: #fff;
  color: #000;
}
.inspiring-forest__sliders__slider.--dark {
  background-color: #000;
  color: #fff;
}
.inspiring-forest__sliders__slider.--dark .swiper-pagination-bullet {
  background-color: #fff;
}
.inspiring-forest__sliders__slider.--dark .swiper-pagination-bullet-active {
  background-color: #fff;
}
.inspiring-forest__sliders__slider.--dark .swiper-button-next:after {
  border-top-color: #fff;
  border-right-color: #fff;
}
.inspiring-forest__sliders__slider.--dark .swiper-button-prev:after {
  border-top-color: #fff;
  border-right-color: #fff;
}
@media (max-width: 991px) {
  .inspiring-forest__sliders__slider .swiper-pagination {
    bottom: 35px;
    padding: 0 40px;
  }
  .inspiring-forest__sliders__slider .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  .inspiring-forest__sliders__slider .swiper-button-next {
    right: 5px;
  }
  .inspiring-forest__sliders__slider .swiper-button-next:after {
    width: 20px;
    height: 20px;
  }
  .inspiring-forest__sliders__slider .swiper-button-prev {
    left: 5px;
  }
  .inspiring-forest__sliders__slider .swiper-button-prev:after {
    width: 20px;
    height: 20px;
  }
  .inspiring-forest__sliders__slider .swiper-slide {
    padding: 40px 40px 80px;
  }
  .inspiring-forest__sliders__slider .swiper-slide h4 {
    margin-bottom: 32px;
  }
  .inspiring-forest__sliders__slider .swiper-slide p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .inspiring-forest__sliders__slider .swiper-pagination {
    bottom: 22px;
    padding: 0 32px;
  }
  .inspiring-forest__sliders__slider .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  .inspiring-forest__sliders__slider .swiper-button-next {
    right: 5px;
  }
  .inspiring-forest__sliders__slider .swiper-button-next:after {
    width: 10px;
    height: 10px;
    margin-left: -5px;
  }
  .inspiring-forest__sliders__slider .swiper-button-prev {
    left: 5px;
  }
  .inspiring-forest__sliders__slider .swiper-button-prev:after {
    width: 10px;
    height: 10px;
    margin-right: -5px;
  }
  .inspiring-forest__sliders__slider .swiper-slide {
    padding: 32px 32px 64px;
  }
  .inspiring-forest__sliders__slider .swiper-slide p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}

.error404 {
  padding-bottom: 80px;
}
@media (min-width: 768px) {
  .error404 {
    padding-bottom: 145px;
  }
}
@media (min-width: 1200px) {
  .error404 {
    padding-bottom: 160px;
  }
}

.industries__zz-boxes {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 90px;
}
@media (max-width: 767px) {
  .industries__zz-boxes {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .industries__zz-boxes {
    row-gap: 50px;
  }
}
.industries__zz-boxes__box {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  min-height: 600px;
}
@media (max-width: 991px) {
  .industries__zz-boxes__box {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .industries__zz-boxes__box {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.industries__zz-boxes__box .description {
  padding: 20px 0;
  grid-row: 1;
}
.industries__zz-boxes__box .description h4 {
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .industries__zz-boxes__box .description h4 {
    margin-bottom: 24px;
  }
}
@media (max-width: 575px) {
  .industries__zz-boxes__box .description h4 {
    margin-bottom: 16px;
  }
}
.industries__zz-boxes__box .description .--richtext p:last-of-type {
  margin-bottom: 0;
}
.industries__zz-boxes__box .image {
  height: 100%;
  max-width: 100%;
  width: 100%;
  grid-row: 1;
  object-fit: cover;
}
@media (max-width: 991px) {
  .industries__zz-boxes__box .image {
    aspect-ratio: 1;
  }
}
.industries__zz-boxes__box:nth-child(even) .description {
  grid-column: 1/span 11;
}
.industries__zz-boxes__box:nth-child(even) .image {
  grid-column: 15/span 9;
}
.industries__zz-boxes__box:nth-child(odd) .image {
  grid-column: 1/span 9;
}
.industries__zz-boxes__box:nth-child(odd) .description {
  grid-column: 13/span 11;
}
@media (max-width: 920px) {
  .industries__zz-boxes__box {
    row-gap: 24px;
  }
  .industries__zz-boxes__box:nth-child(odd) .description, .industries__zz-boxes__box:nth-child(even) .description {
    grid-column: 1/span 23;
    grid-row: 2;
  }
  .industries__zz-boxes__box:nth-child(odd) .image, .industries__zz-boxes__box:nth-child(even) .image {
    grid-column: 1/span 23;
    grid-row: 1;
  }
}
@media (max-width: 767px) {
  .industries__zz-boxes__box:nth-child(odd) .description, .industries__zz-boxes__box:nth-child(even) .description {
    grid-column: 1/span 7;
  }
  .industries__zz-boxes__box:nth-child(odd) .image, .industries__zz-boxes__box:nth-child(even) .image {
    grid-column: 1/span 7;
  }
}
.industries__intro-text {
  padding-top: 120;
  padding-bottom: 120px;
}
.industries__intro-text .--richtext p:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .industries__intro-text {
    padding-top: 120px;
    padding-bottom: 30px;
  }
}
.industries__intro-text__content {
  margin-top: 120px;
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 767px) {
  .industries__intro-text__content {
    margin-top: 60px;
  }
}
@media (max-width: 991px) {
  .industries__intro-text__content {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .industries__intro-text__content {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.industries__intro-text h3 {
  grid-column: 1/-1;
  grid-row: 1;
}
@media (min-width: 1200px) {
  .industries__intro-text h3 {
    grid-column: 1/18;
  }
}
.industries__intro-text__text {
  grid-row: 2;
  grid-column: 1/-1;
}
@media (min-width: 992px) {
  .industries__intro-text__text {
    grid-column: 1/18;
  }
}
.industries-companies-slider {
  padding-top: 180px;
  padding-bottom: 180px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .industries-companies-slider {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .industries-companies-slider__box {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.industries-companies-slider__container {
  overflow: hidden;
}
.industries-companies-slider__container.section {
  padding-top: 0;
  padding-bottom: 0;
}
.industries-companies-slider__inner {
  padding-bottom: 60px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .industries-companies-slider__inner {
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .industries-companies-slider__inner {
    padding-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .industries-companies-slider__inner {
    padding-bottom: 120px;
  }
}
.industries-companies-slider__title {
  width: 100%;
  margin-bottom: 24px;
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.02em;
}
@media (min-width: 768px) {
  .industries-companies-slider__title {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .industries-companies-slider__title {
    max-width: 720px;
    font-size: 80px;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
    margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .industries-companies-slider__title {
    max-width: 840px;
    font-size: 100px;
    font-size: 6.25rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.04em;
  }
}
.industries-companies-slider__text {
  width: 100%;
}
@media (min-width: 768px) {
  .industries-companies-slider__text {
    max-width: 620px;
    text-align: center;
  }
}
.industries-companies-slider__text p:last-of-type {
  margin-bottom: 0;
}
.industries-companies-slider__logos img {
  display: block;
  max-width: 100%;
  height: auto;
}
.industries-companies-slider__logos .swiper-wrapper {
  transition-timing-function: linear !important;
}
@media (max-width: 767px) {
  .industries-companies-slider__logos .swiper-wrapper {
    margin-bottom: 25px;
  }
}
.industries-companies-slider__box {
  padding-top: 180px;
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .industries-companies-slider__box {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .industries-companies-slider__box {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.industries-companies-slider.--with-slider .industries-companies-slider__static-logos {
  display: none;
}
.industries-companies-slider.--without-slider .industries-companies-slider__logos {
  display: none;
}
@media (min-width: 425px) {
  .industries-companies-slider.--without-slider .industries-companies-slider__inner {
    padding-bottom: 32px;
  }
}
@media (min-width: 1024px) {
  .industries-companies-slider.--without-slider .industries-companies-slider__inner {
    padding-bottom: 100px;
  }
}
@media (min-width: 1440px) {
  .industries-companies-slider.--without-slider .industries-companies-slider__inner {
    padding-bottom: 120px;
  }
}
.industries-companies-slider.--without-slider .industries-companies-slider__static-logos {
  display: grid;
  grid-template-columns: repeat(2, 172px);
  place-content: center;
}
@media (min-width: 1024px) {
  .industries-companies-slider.--without-slider .industries-companies-slider__static-logos {
    grid-template-columns: repeat(3, 238px);
  }
}
@media (min-width: 1440px) {
  .industries-companies-slider.--without-slider .industries-companies-slider__static-logos {
    grid-template-columns: repeat(3, 290px);
  }
}
.industries-companies-slider.--without-slider .industries-companies-slider__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .industries-companies-slider.--without-slider .industries-companies-slider__image-container {
    width: 172px;
    height: 89px;
  }
}
@media (min-width: 1024px) {
  .industries-companies-slider.--without-slider .industries-companies-slider__image-container {
    width: 238px;
    height: 123px;
  }
}
@media (min-width: 1440px) {
  .industries-companies-slider.--without-slider .industries-companies-slider__image-container {
    width: 290px;
    height: 150px;
  }
}
.industries-companies-slider.--without-slider .industries-companies-slider__image {
  width: 100%;
  height: 100%;
}
.industries__images-txt {
  position: relative;
  padding-top: 60px;
  padding-bottom: 70px;
}
.industries__images-txt.--text-left {
  text-align: left;
}
.industries__images-txt.--text-right {
  text-align: right;
}
.industries__images-txt.--text-center {
  text-align: center;
}
.industries__images-txt .wrapper {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 110px;
}
@media (max-width: 991px) {
  .industries__images-txt .wrapper {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .industries__images-txt .wrapper {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 991px) {
  .industries__images-txt .wrapper {
    row-gap: 16px;
  }
}
@media (max-width: 767px) {
  .industries__images-txt {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.industries__images-txt img {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}
.industries__images-txt__textbox {
  padding: 50px;
}
@media (max-width: 991px) {
  .industries__images-txt__textbox {
    padding: 32px;
  }
}
.industries__images-txt__item {
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.industries__images-txt__item:nth-of-type(1) {
  grid-column: 1/10;
}
@media (min-width: 768px) {
  .industries__images-txt__item:nth-of-type(1) {
    grid-row: 1/3;
  }
}
.industries__images-txt__item:nth-of-type(1) .industries__images-txt__textbox {
  background: #EDFBFF;
}
.industries__images-txt__item:nth-of-type(2) {
  grid-column: 15/-1;
  flex-direction: column-reverse;
  color: #fff;
}
@media (min-width: 768px) {
  .industries__images-txt__item:nth-of-type(2) {
    grid-row: 1/2;
  }
}
.industries__images-txt__item:nth-of-type(2) .industries__images-txt__textbox {
  background: #000000;
}
.industries__images-txt__item:nth-of-type(3) {
  grid-column: 11/20;
}
@media (min-width: 768px) {
  .industries__images-txt__item:nth-of-type(3) {
    grid-row: 3/4;
  }
}
.industries__images-txt__item:nth-of-type(3) .industries__images-txt__textbox {
  background: #FFECCD;
}
.industries__images-txt__item:nth-of-type(4) {
  grid-column: 1/10;
  grid-row: 4/6;
}
@media (min-width: 768px) {
  .industries__images-txt__item:nth-of-type(4) {
    grid-row: 5/7;
  }
}
.industries__images-txt__item:nth-of-type(4) .industries__images-txt__textbox {
  background: #FAF4FF;
}
.industries__images-txt__item:nth-of-type(5) {
  grid-column: 15/-1;
}
@media (min-width: 768px) {
  .industries__images-txt__item:nth-of-type(5) {
    margin-top: -110px;
  }
}
@media (min-width: 768px) {
  .industries__images-txt__item:nth-of-type(5) {
    grid-row: 6/8;
  }
}
.industries__images-txt__item:nth-of-type(5) .industries__images-txt__textbox {
  background: #A5FFE3;
}
.industries__images-txt__item.--img-bottom {
  flex-direction: column-reverse;
}
.industries__images-txt__item.--img-top {
  flex-direction: column;
}
.industries__images-txt__item.--border {
  border: 2px solid #D3D3D3;
}
.industries__images-txt__item.--shadow {
  filter: drop-shadow(0px 20px 80px rgba(0, 0, 0, 0.15));
}
@media (max-width: 767px) {
  .industries__images-txt__item {
    grid-column: 1/-1 !important;
  }
}
.industries__images-txt__item__title {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.3846153846;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}
@media (max-width: 991px) {
  .industries__images-txt__item__title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.4166666667;
    letter-spacing: normal;
  }
}
@media (max-width: 767px) {
  .industries__images-txt__item__title {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.03em;
  }
}
.industries__images-txt__item__text {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: normal;
}
.industries__images-txt__item__text p:last-of-type {
  margin-bottom: 0;
}
.industries__images-txt.--careers-page {
  padding-bottom: 170px;
}
.industries__images-txt.--careers-page .wrapper {
  row-gap: 0;
}
@media (max-width: 1024px) {
  .industries__images-txt.--careers-page {
    padding-bottom: 140px;
  }
}
@media (max-width: 768px) {
  .industries__images-txt.--careers-page {
    padding-bottom: 120px;
  }
  .industries__images-txt.--careers-page .wrapper {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 425px) {
  .industries__images-txt.--careers-page {
    padding-bottom: 80px;
  }
}
.industries__images-txt.--careers-page .industries__images-txt__textbox {
  padding: 40px;
}
@media (max-width: 768px) {
  .industries__images-txt.--careers-page .industries__images-txt__textbox {
    padding: 30px;
  }
}
.industries__images-txt.--careers-page .industries__images-txt__item:nth-child(1) {
  grid-row: 1;
}
.industries__images-txt.--careers-page .industries__images-txt__item:nth-child(2) {
  grid-row: 2;
  margin-top: -280px;
}
.industries__images-txt.--careers-page .industries__images-txt__item:nth-child(3) {
  grid-row: 3;
  margin-top: 80px;
}
.industries__images-txt.--careers-page .industries__images-txt__item:nth-child(4) {
  grid-row: 4;
  margin-top: 80px;
}
.industries__images-txt.--careers-page .industries__images-txt__item:nth-child(5) {
  grid-row: 5;
  margin-top: -85px;
}
@media (max-width: 768px) {
  .industries__images-txt.--careers-page .wrapper .industries__images-txt__item {
    margin-top: 16px;
  }
  .industries__images-txt.--careers-page .wrapper .industries__images-txt__item:first-child {
    margin-top: 29px;
  }
}
.industries__images-txt__linear-gradient {
  position: absolute;
  bottom: 58px;
  width: 100%;
  height: 1068px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .industries__images-txt__linear-gradient {
    bottom: 30px;
  }
}
@media (max-width: 425px) {
  .industries__images-txt__linear-gradient {
    bottom: 18px;
  }
}
.industries__images-txt__gradient-sections {
  height: 178px;
}
@media (max-width: 1024px) {
  .industries__images-txt__gradient-sections {
    height: 150px;
  }
}
@media (max-width: 425px) {
  .industries__images-txt__gradient-sections {
    height: 98px;
  }
}
.industries__images-txt__gradient-sections:nth-child(1) {
  border-top: 2px solid rgba(0, 0, 0, 0.05);
}
.industries__images-txt__gradient-sections:nth-child(2) {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.industries__images-txt__gradient-sections:nth-child(3) {
  border-top: 2px solid rgba(0, 0, 0, 0.25);
}
.industries__images-txt__gradient-sections:nth-child(4) {
  border-top: 2px solid rgba(0, 0, 0, 0.5);
}
.industries__images-txt__gradient-sections:nth-child(5) {
  border-top: 2px solid rgba(0, 0, 0, 0.75);
  border-bottom: 2px solid rgb(0, 0, 0);
}
.industries__float-bar {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  backdrop-filter: blur(16px);
  z-index: 100;
  background: rgba(255, 255, 255, 0.65);
  width: 100%;
  position: fixed;
  bottom: 0;
}
.industries__float-bar.visible {
  opacity: 1;
}
@media (max-width: 575px) {
  .industries__float-bar .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.industries__float-bar__box {
  border-top: 1px solid #000;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}
@media (max-width: 575px) {
  .industries__float-bar__box {
    padding: 12px 16px;
  }
}
@media (max-width: 575px) {
  .industries__float-bar__box {
    justify-content: space-between;
  }
}
.industries__float-bar__title {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
}
@media (max-width: 991px) {
  .industries__float-bar__title {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .industries__float-bar__title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.industries__hero {
  background-color: rgb(0, 0, 0);
  position: relative;
  background-position-y: 40%;
  background-size: 100% 120%;
  /* mostrar los controles de video cuando el mouse no esté en la pantalla */
}
.industries__hero.--finance {
  background-image: url("../images/industry/finance/hero-mask-desk.png");
}
@media (max-width: 767px) {
  .industries__hero.--finance {
    background-image: url("../images/industry/finance/hero-mask-mobile.png");
    background-position: 100% 100%;
  }
}
.industries__hero:before {
  content: "";
  position: absolute;
  z-index: 0;
  inset: 0;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.6);
}
.industries__hero:after {
  content: "";
  position: absolute;
  z-index: 5;
  inset: 0;
  background: url("../images/noise.png") repeat center center;
}
.industries__hero hr {
  border-width: 2px;
}
.industries__hero.--bg-dark {
  color: #fff;
}
.industries__hero.--bg-dark hr {
  border-color: #fff;
}
.industries__hero__grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: start;
}
@media (max-width: 991px) {
  .industries__hero__grid {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .industries__hero__grid {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.industries__hero__header {
  grid-column: 1/-1;
  grid-row: 1/2;
  z-index: 1;
  margin-bottom: 220px;
}
@media (max-width: 1199px) {
  .industries__hero__header {
    margin-bottom: 300px;
  }
}
@media (max-width: 575px) {
  .industries__hero__header {
    max-width: 90%;
    margin-bottom: 0;
  }
}
.industries__hero__header__subtitle {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.6923076923;
  letter-spacing: normal;
  margin-bottom: 16px;
}
@media (max-width: 1199px) {
  .industries__hero__header__subtitle {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .industries__hero__header__subtitle {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: normal;
  }
}
.industries__hero__header__title {
  font-size: 140px;
  font-size: 8.75rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.04em;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .industries__hero__header__title {
    font-size: 100px;
    font-size: 6.25rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 575px) {
  .industries__hero__header__title {
    font-size: 56px;
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
.industries__hero__section {
  grid-column: 1/-1;
  grid-row: 2/3;
  z-index: 1;
}
@media (max-width: 575px) {
  .industries__hero__section {
    grid-column: 1/-1;
    grid-row: 4/5;
  }
}
.industries__hero__text {
  grid-column: 1/12;
  grid-row: 3/6;
  min-height: 500px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
}
@media (max-width: 1199px) {
  .industries__hero__text {
    grid-column: 1/14;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .industries__hero__text {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
    grid-row: 5/6;
    grid-column: 1/-1;
  }
}
.industries__hero__media-1 {
  grid-column: 17/-1;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;
  grid-row: 3/4;
  top: -19.5em;
}
@media (max-width: 1199px) {
  .industries__hero__media-1 {
    top: -25em;
  }
}
@media (max-width: 575px) {
  .industries__hero__media-1 {
    grid-column: 3/-1;
    grid-row: 2/3;
    position: relative;
    top: -1.5em;
  }
}
.industries__hero__media-2 {
  grid-column: 15/20;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;
  grid-row: 3/4;
  top: -3em;
}
@media (max-width: 1199px) {
  .industries__hero__media-2 {
    top: -11em;
  }
}
@media (max-width: 575px) {
  .industries__hero__media-2 {
    grid-column: 1/4;
    grid-row: 3/4;
    position: relative;
    top: -3.5em;
  }
}
.industries__hero__media-3 {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  grid-column: 21/-1;
  position: absolute;
  grid-row: 3/4;
  top: 15em;
}
@media (max-width: 1199px) {
  .industries__hero__media-3 {
    top: 2em;
  }
}
@media (max-width: 575px) {
  .industries__hero__media-3 {
    grid-column: 5/-1;
    grid-row: 7/8;
    position: relative;
    top: 0.5em;
    z-index: 1;
  }
}
.industries__hero__media-4 {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  grid-column: 15/20;
  position: absolute;
  grid-row: 6/7;
  z-index: 1;
}
@media (max-width: 1199px) {
  .industries__hero__media-4 {
    grid-column: 17/-1;
  }
}
@media (max-width: 575px) {
  .industries__hero__media-4 {
    grid-column: 1/6;
    grid-row: 8/9;
    top: -7.5em;
    z-index: 0;
  }
}
.industries__hero video {
  pointer-events: none;
}
.industries__hero video::-webkit-media-controls {
  display: none !important;
}
.industries__hero video::-webkit-media-controls-enclosure {
  display: none !important;
}
.industries__hero video::-webkit-media-controls-overlay-enclosure {
  display: none !important;
}
.industries__hero video::-webkit-media-controls-overlay-play-button {
  display: none !important;
}
.industries__hero video:hover::-webkit-media-controls {
  display: initial !important;
}
.industries__hero.--automotive {
  background-image: url("../images/industry/automotive/hero-bg-desktop.png");
  background-color: rgb(0, 0, 0);
  position: relative;
  background-position-y: 90%;
  background-size: 100% 120%;
}
@media (max-width: 767px) {
  .industries__hero.--automotive {
    background-image: url("../images/industry/automotive/hero-bg-mobile.png");
    background-position: 100% 100%;
  }
}
@media (min-width: 320px) {
  .industries__hero.--automotive .industries__hero__media-4 {
    top: -1.5em;
  }
}
@media (min-width: 375px) {
  .industries__hero.--automotive .industries__hero__header {
    z-index: 2;
  }
  .industries__hero.--automotive .industries__hero__media-1 {
    top: -8.5em;
    z-index: 1;
  }
  .industries__hero.--automotive .industries__hero__media-2 {
    top: -11.5em;
  }
  .industries__hero.--automotive .industries__hero__section {
    grid-row: 3/4;
    margin-top: 60px;
  }
  .industries__hero.--automotive .industries__hero__media-4 {
    top: -1em;
  }
}
@media (min-width: 768px) {
  .industries__hero.--automotive .industries__hero__section {
    grid-row: 2/3;
    margin-top: 0;
  }
  .industries__hero.--automotive .industries__hero__media-1 {
    top: -22em;
  }
  .industries__hero.--automotive .industries__hero__media-3 {
    top: 32em;
    z-index: 2;
  }
}
@media (min-width: 1024px) {
  .industries__hero.--automotive .industries__hero__header {
    margin-bottom: 204px;
  }
  .industries__hero.--automotive .industries__hero__text {
    width: 496px;
  }
  .industries__hero.--automotive .industries__hero__media-1 {
    height: 274px;
    width: 274px;
    z-index: 1;
    top: -16em;
  }
  .industries__hero.--automotive .industries__hero__media-2 {
    top: -2em;
  }
  .industries__hero.--automotive .industries__hero__media-3 {
    top: 10em;
  }
  .industries__hero.--automotive .industries__hero__media-4 {
    top: -5em;
  }
  .industries__hero.--automotive .industries__hero__section {
    grid-row: 2/3;
    margin-top: 0;
  }
}
@media (min-width: 1440px) {
  .industries__hero.--automotive {
    padding-bottom: 200px;
  }
  .industries__hero.--automotive .industries__hero__header {
    z-index: 2;
  }
  .industries__hero.--automotive .industries__hero__media-1 {
    width: 510px;
    height: 510px;
    top: -26.2em;
    z-index: 1;
    grid-column: 15/-1;
  }
  .industries__hero.--automotive .industries__hero__media-2 {
    grid-column: 13/18;
  }
  .industries__hero.--automotive .industries__hero__media-4 {
    width: 400px;
    height: 370px;
    grid-column: 15/21;
    top: 160px;
    grid-row: 5;
  }
}
@media (min-width: 1440px) and (max-width: 1440px) {
  .industries__hero.--automotive .industries__hero__text {
    width: 620px;
  }
}
.industries__portfolio {
  padding-top: 60px;
  padding-bottom: 240px;
}
@media (max-width: 767px) {
  .industries__portfolio {
    padding-top: 30px;
    padding-bottom: 120px;
  }
}
.industries__digital-experience {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 225px;
  padding-bottom: 0px;
}
@media (max-width: 991px) {
  .industries__digital-experience {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .industries__digital-experience {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1440px) {
  .industries__digital-experience {
    padding-top: 219px;
  }
}
@media (max-width: 1024px) {
  .industries__digital-experience {
    padding-top: 442px;
  }
}
@media (max-width: 768px) {
  .industries__digital-experience {
    display: none;
  }
}
.industries__digital-experience__hr-text {
  grid-row: 1;
  grid-column: 1/-1;
  position: sticky;
  top: -12px;
  margin-bottom: 120px;
  transition: all 0.2s ease-in-out;
}
.industries__digital-experience .--hr-text-out {
  opacity: 0;
}
.industries__digital-experience__title-container {
  grid-column: 1/9;
  grid-row: 2;
  display: flex;
  align-self: flex-start;
  align-content: flex-end;
  flex-direction: column;
  position: sticky;
  z-index: 1;
  top: 220px;
  height: 500px;
}
.industries__digital-experience__title-container h3 {
  margin: 0;
  height: 300px;
}
@media (max-width: 1024px) {
  .industries__digital-experience__title-container h3 {
    height: 175px;
  }
}
@media (max-width: 1024px) {
  .industries__digital-experience__title-container {
    grid-column: 1/10;
    height: auto;
  }
}
.industries__digital-experience__circle-container {
  align-self: flex-end;
  transform: rotate(-90deg);
  margin-right: 10px;
  z-index: 1000;
}
@media (max-width: 1024px) {
  .industries__digital-experience__circle-container {
    margin-right: 25px;
  }
}
.industries__digital-experience__circle-progress {
  /* calculate using: (2 * PI * R) */
  stroke-dasharray: 622;
  stroke-dashoffset: 622;
}
.industries__digital-experience__images-container {
  grid-column: 7/14;
  align-self: flex-start;
  width: 400px;
  height: 500px;
  grid-row: 2;
  position: sticky;
  top: 220px;
}
@media (max-width: 1024px) {
  .industries__digital-experience__images-container {
    width: 275px;
    height: 375px;
  }
}
.industries__digital-experience__image {
  width: 400px;
  height: 400px;
  position: absolute;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 1024px) {
  .industries__digital-experience__image {
    width: 275px;
    height: 275px;
  }
}
.industries__digital-experience__boxes-container {
  grid-column: 15/-1;
  display: flex;
  align-self: start;
  flex-direction: column;
  grid-row: 2;
}
.industries__digital-experience__box {
  padding-top: 20px;
  transition: all 0.5s ease-in-out;
  border-top: 1px solid black;
}
.industries__digital-experience__box:last-child {
  border-bottom: 1px solid black;
}
.industries__digital-experience .--box-out {
  opacity: 0;
}
.industries__digital-experience .--img-out {
  opacity: 0;
}
.industries__digital-experience__mobile {
  display: none;
}
@media (max-width: 768px) {
  .industries__digital-experience__mobile {
    display: grid;
    display: grid;
    grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
    height: auto;
    align-items: center;
    padding-top: 180px;
    padding-bottom: 0px;
  }
}
@media (max-width: 768px) and (max-width: 991px) {
  .industries__digital-experience__mobile {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 768px) and (max-width: 767px) {
  .industries__digital-experience__mobile {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 425px) {
  .industries__digital-experience__mobile {
    padding-top: 224px;
  }
}
@media (max-width: 375px) {
  .industries__digital-experience__mobile {
    padding-top: 180px;
  }
}
.industries__digital-experience__mobile__hr-text {
  grid-row: 1;
  grid-column: 1/-1;
  margin-bottom: 28px;
}
.industries__digital-experience__mobile__title-container {
  grid-row: 2;
  grid-column: 1/-1;
  position: relative;
  height: 100px;
}
.industries__digital-experience__mobile__circle-container {
  position: relative;
  transform: rotate(-90deg);
}
@media (min-width: 320px) {
  .industries__digital-experience__mobile__circle-container {
    top: -92px;
    left: -10px;
  }
}
@media (min-width: 375px) {
  .industries__digital-experience__mobile__circle-container {
    top: -70px;
    left: 9px;
  }
}
@media (min-width: 768px) {
  .industries__digital-experience__mobile__circle-container {
    top: -96px;
    left: 20px;
  }
}
.industries__digital-experience__mobile__circle-progress {
  /* calculate using: (2 * PI * R) */
  stroke-dasharray: 264;
  stroke-dashoffset: 264;
}
.industries__digital-experience__mobile__boxes-container {
  grid-column: 1/-1;
  grid-row: 3;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: auto;
  border-bottom: 1px solid black;
}
.industries__digital-experience__mobile__boxes-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.industries__digital-experience__mobile__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 250px;
  margin-bottom: 10px;
  margin-right: 16px;
}
.industries__digital-experience__mobile__image-container {
  width: 250px;
  height: 170px;
  margin-bottom: 24px;
}
.industries__digital-experience__mobile__image {
  object-fit: cover;
  width: 250px;
  height: 170px;
}

.hero-journal.section {
  padding-top: 275px;
  padding-bottom: 60px;
}
@media (min-width: 1024px) {
  .hero-journal.section {
    padding-top: 366px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1440px) {
  .hero-journal.section {
    padding-bottom: 160px;
  }
}
.hero-journal__bg {
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  height: 658px;
  width: 100%;
}
@media (min-width: 400px) {
  .hero-journal__bg {
    height: 604px;
  }
}
@media (min-width: 1024px) {
  .hero-journal__bg {
    height: 869px;
  }
}
@media (min-width: 1440px) {
  .hero-journal__bg {
    height: 1077px;
  }
}
.hero-journal__content {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 991px) {
  .hero-journal__content {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .hero-journal__content {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.hero-journal__title {
  color: #fff;
  margin-bottom: 80px;
  grid-column: 1/-1;
  grid-row: 1;
}
@media (max-width: 576px) {
  .hero-journal__title {
    font-size: 70px;
    font-size: 4.375rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
    margin-bottom: 60px;
  }
}
.hero-journal__post-container {
  align-self: flex-end;
  width: 100%;
  grid-column: 9/-1;
  grid-row: 2;
  color: #000;
}
@media (max-width: 768px) {
  .hero-journal__post-container {
    grid-column: 1/-1;
  }
}

.post {
  width: 100%;
  padding-bottom: 13px;
}
.post__link {
  text-decoration: none;
  color: inherit;
}
.post__image {
  display: flex;
}
.post__image img {
  width: 100%;
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .post__image img {
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .post__image img {
    margin-bottom: 16px;
  }
}
.post__image.--main img {
  min-height: 183px;
}
@media (min-width: 1024px) {
  .post__image.--main img {
    min-height: 303px;
  }
}
@media (min-width: 1440px) {
  .post__image.--main img {
    min-height: 451px;
  }
}
.post__title {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .post__title {
    margin-bottom: 20px;
  }
}
.post__title h4 {
  margin: 0;
}
@media (max-width: 768px) {
  .post__title h4 {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.03em;
  }
}
.post__title h5 {
  margin: 0;
}
@media (max-width: 768px) {
  .post__title h5 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.03em;
  }
}
.post__title h6 {
  margin: 0;
}
@media (max-width: 768px) {
  .post__title h6 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.03em;
  }
}
.post__author {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  gap: 16px;
}
.post__author strong {
  display: block;
  margin-bottom: -10px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.6666666667;
  letter-spacing: normal;
}
@media (min-width: 1200px) {
  .post__author strong {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.post__author span {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.6666666667;
  letter-spacing: normal;
}
@media (min-width: 1200px) {
  .post__author span {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.post__author img {
  width: 46px;
}
@media (max-width: 768px) {
  .post__author img {
    width: 32px;
  }
}
.post.--box {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  border-top: 1px solid #000;
  padding: 24px 0;
}
@media (max-width: 991px) {
  .post.--box {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .post.--box {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.post.--box .post__image {
  grid-row: 1/span 2;
  grid-column: 7/-1;
  align-self: start;
}
@media (min-width: 768px) {
  .post.--box .post__image {
    grid-column: 16/-1;
  }
}
.post.--box .post__title {
  grid-row: 1;
  grid-column: 1/7;
  align-self: start;
  margin: 0;
}
@media (min-width: 768px) {
  .post.--box .post__title {
    grid-column: 1/16;
  }
}
.post.--box .post__author {
  grid-row: 2;
  grid-column: 1/7;
  align-self: start;
  margin-top: 8px;
}
@media (min-width: 768px) {
  .post.--box .post__author {
    grid-column: 1/16;
  }
}
.post.--box .post__author img {
  display: none;
  margin: 0;
}
.post.--box .post__author-text {
  display: flex;
  column-gap: 4px;
}
.post.--box .post__author-text strong {
  margin: 0;
}

.row-posts {
  align-items: flex-end;
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-bottom: 160px;
}
@media (max-width: 991px) {
  .row-posts {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .row-posts {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1024px) {
  .row-posts {
    padding-bottom: 120px;
  }
}
@media (max-width: 768px) {
  .row-posts {
    padding-bottom: 0;
  }
}
.row-posts__post {
  grid-row: 1;
  align-self: end;
}
@media (max-width: 768px) {
  .row-posts__post {
    padding-bottom: 60px;
  }
}
.row-posts :nth-child(1) {
  grid-column: 1/8;
}
@media (max-width: 768px) {
  .row-posts :nth-child(1) {
    grid-row: 1;
    grid-column: 1/-1;
  }
}
.row-posts :nth-child(2) {
  grid-column: 9/-1;
}
@media (max-width: 768px) {
  .row-posts :nth-child(2) {
    grid-row: 2;
    grid-column: 1/-1;
  }
}
.row-posts.--reverse :nth-child(1) {
  grid-column: 17/-1;
}
@media (max-width: 768px) {
  .row-posts.--reverse :nth-child(1) {
    grid-row: 2;
    grid-column: 1/-1;
  }
}
.row-posts.--reverse :nth-child(2) {
  grid-column: 1/16;
}
@media (max-width: 768px) {
  .row-posts.--reverse :nth-child(2) {
    grid-row: 1;
    grid-column: 1/-1;
  }
}

.three-posts {
  padding-bottom: 270px;
}
@media (max-width: 768px) {
  .three-posts {
    padding-bottom: 70px;
  }
}
.three-posts__content {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 991px) {
  .three-posts__content {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .three-posts__content {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.three-posts__content a {
  color: inherit;
  text-decoration: none;
}
@media (max-width: 768px) {
  .three-posts__content {
    display: flex;
    flex-direction: column;
  }
}
.three-posts__post:nth-child(1) {
  grid-row: 1;
  grid-column: 1/8;
}
.three-posts__post:nth-child(2) {
  grid-row: 1;
  grid-column: 9/16;
}
.three-posts__post:nth-child(3) {
  grid-row: 1;
  grid-column: 17/-1;
}
.three-posts .btn-box {
  grid-row: 2;
  grid-column: 9/16;
  margin-top: 120px;
}
@media (max-width: 768px) {
  .three-posts .btn-box {
    width: 100%;
    margin-top: 60px;
  }
}
.three-posts.--more-to-read {
  padding-bottom: 160px;
}
.three-posts.--more-to-read .three-posts__header {
  border-top: 1px solid #000;
  padding: 32px 0;
}
@media (max-width: 768px) {
  .three-posts.--more-to-read .three-posts__header {
    padding: 12px 0;
  }
}
.three-posts.--more-to-read .btn-box {
  display: none;
}
@media (max-width: 768px) {
  .three-posts.--more-to-read {
    padding-bottom: 0px;
  }
}

.post-body {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 190px;
  padding-bottom: 240px;
  row-gap: 160px;
}
@media (max-width: 991px) {
  .post-body {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .post-body {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1024px) {
  .post-body {
    row-gap: 120px;
  }
}
@media (max-width: 768px) {
  .post-body {
    padding-top: 112px;
    padding-bottom: 0;
    row-gap: 80px;
  }
}
.post-body__divider {
  grid-row: 1;
  grid-column: 1/-1;
  border-top: 2px solid #000;
  padding-top: 16px;
}
.post-body__divider a {
  color: inherit;
  text-decoration: none;
  display: flex;
  column-gap: 8px;
  align-items: center;
}
.post-body__title-container {
  grid-column: 9/-1;
  grid-row: 2;
}
@media (max-width: 768px) {
  .post-body__title-container {
    grid-column: 1/-1;
  }
}
.post-body__title {
  font-size: 80px;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.03em;
  margin-bottom: 80px;
}
@media (max-width: 1199px) {
  .post-body__title {
    font-size: 64px;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.15625;
    letter-spacing: -0.03em;
    margin-bottom: 60px;
  }
}
@media (max-width: 575px) {
  .post-body__title {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
    margin-bottom: 24px;
  }
}
.post-body__related-posts {
  grid-row: 3;
  grid-column: 1/6;
  align-self: baseline;
}
@media (max-width: 768px) {
  .post-body__related-posts {
    grid-column: 1/-1;
    grid-row: 4;
  }
}
.post-body__related-posts__title {
  border-top: 2px solid #000;
  padding: 16px 0;
}
@media (max-width: 768px) {
  .post-body__related-posts__title {
    padding: 8px 0;
    border-bottom: 1px solid #000;
  }
}
.post-body__related-posts__item {
  border-bottom: 1px solid #000;
  padding: 16px 0;
}
@media (max-width: 768px) {
  .post-body__related-posts__item {
    padding: 8px 0;
  }
}
.post-body__related-posts__item a {
  color: #000;
}
.post-body__content {
  grid-column: 9/-1;
  grid-row: 3;
}
@media (max-width: 768px) {
  .post-body__content {
    grid-column: 1/-1;
    grid-row: 3;
  }
}
.post-body__content p {
  margin: 0;
  padding-bottom: 24px;
}
.post-body__content img {
  width: 100%;
  padding-bottom: 24px;
}
.post-body__content h2 {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.3846153846;
  letter-spacing: -0.03em;
  padding: 56px 0 24px 0;
}
@media (max-width: 1199px) {
  .post-body__content h2 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.4166666667;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 575px) {
  .post-body__content h2 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.03em;
  }
}
.post-body__content ol {
  list-style-type: none;
  counter-reset: my-counter;
  padding-bottom: 56px;
}
.post-body__content li {
  display: flex;
  padding-bottom: 8px;
}
.post-body__content li strong {
  display: contents;
}
.post-body__content ol li::before {
  counter-increment: my-counter;
  content: counter(my-counter);
  padding-right: 40px;
}
@media (max-width: 768px) {
  .post-body__content ol li::before {
    padding-right: 16px;
    font-size: 16px;
  }
}
.post-body__content ul {
  list-style: none;
  padding-bottom: 56px;
}
.post-body__content ul li::before {
  content: "→";
  padding-right: 40px;
}
@media (max-width: 768px) {
  .post-body__content ul li::before {
    padding-right: 16px;
    font-size: 16px;
  }
}

.cases-header-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.cases-header-section > * {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .cases-header-section {
    max-width: 715px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1200px) {
  .cases-header-section {
    max-width: 840px;
  }
}

.cases-list__container {
  list-style-type: none;
  counter-reset: iter;
}
.cases-list__item {
  display: block;
  padding-bottom: 4px;
  margin-top: 4px;
  padding-left: 40px;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
}
.cases-list__item:before {
  content: counter(iter, upper-alpha) " ";
  counter-increment: iter;
  position: absolute;
  top: 0;
  left: 0;
}
.cases-list__item:last-of-type {
  border-bottom: none;
}

.case-impact {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  border: 1px solid #000;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .case-impact {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .case-impact {
    margin-top: 60px;
  }
}
@media (min-width: 1200px) {
  .case-impact {
    margin-top: 120px;
  }
}
.case-impact__header {
  padding: 30px 0;
  text-align: center;
  margin-bottom: 0;
  border-bottom: 1px solid #000;
  grid-column: 1/-1;
  grid-row: 1/2;
  font-weight: 400;
}
@media (min-width: 768px) {
  .case-impact__header {
    padding: 54px 24px;
  }
}
.case-impact__percent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}
@media (min-width: 768px) {
  .case-impact__percent {
    padding: 34px 10px;
    grid-row: 2/3;
  }
}
@media (max-width: 767px) {
  .case-impact__percent {
    border-bottom: 1px solid #000;
  }
}
.case-impact__percent:nth-of-type(1) {
  grid-column: 1/1;
}
@media (min-width: 768px) {
  .case-impact__percent:nth-of-type(1) {
    grid-column: 1/2;
  }
}
.case-impact__percent:nth-of-type(2) {
  grid-column: 1/1;
}
@media (min-width: 768px) {
  .case-impact__percent:nth-of-type(2) {
    grid-column: 2/3;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }
}
.case-impact__percent:nth-of-type(3) {
  grid-column: 1/1;
}
@media (min-width: 768px) {
  .case-impact__percent:nth-of-type(3) {
    grid-column: 3/4;
  }
}
@media (max-width: 767px) {
  .case-impact__percent:nth-of-type(3) {
    border: none;
  }
}
.case-impact__percent span {
  display: block;
  margin: auto;
  font-size: 80px;
  font-size: 5rem;
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -0.03em;
}
@media (max-width: 1199px) {
  .case-impact__percent span {
    font-size: 64px;
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.15625;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  .case-impact__percent span {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.01em;
  }
}

.--bg-black .case-impact,
.--bg-black .case-impact__percent,
.--bg-black .case-impact__header {
  color: #fff;
  border-color: #fff;
}

@media (min-width: 768px) {
  .case-quote {
    padding-top: 240px;
  }
}
.case-quote__box {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin: unset;
}
@media (min-width: 768px) {
  .case-quote__box {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .case-quote__box {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.case-quote * {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .case-quote * {
    grid-column: 1/20;
  }
}
@media (min-width: 1200px) {
  .case-quote * {
    grid-column: 1/16;
  }
}
.case-quote__author {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 32px;
}
.case-quote__author strong {
  font-weight: 700;
  display: block;
  margin-bottom: -10px;
}
.case-quote__author span {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.6666666667;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .case-quote__author span {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    letter-spacing: normal;
  }
}
@media (min-width: 1200px) {
  .case-quote__author span {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.case-quote__author img {
  display: block;
  max-width: 100%;
  height: auto;
  max-width: 70px;
}
@media (max-width: 1024px) {
  .case-quote__author img {
    max-width: 54px;
  }
}
@media (min-width: 768px) {
  .case-quote__author {
    margin-top: 80px;
  }
}

.quote-right .case-quote__box .big-list-title,
.quote-right .case-quote__box .case-quote__author {
  grid-column: 13/span 11;
}
@media (max-width: 767px) {
  .quote-right .case-quote__box {
    row-gap: 0px;
  }
  .quote-right .case-quote__box .big-list-title {
    grid-column: 1/-1;
  }
  .quote-right .case-quote__box .case-quote__author {
    grid-column: 1/span 5;
  }
  .quote-right .case-quote__box .case-quote__author span {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: normal;
  }
  .quote-right .case-quote__box .case-quote__author span p {
    margin: 0;
  }
  .quote-right .case-quote__box .case-quote__author strong {
    margin-bottom: 0;
  }
}

.case-challenge__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}
@media (max-width: 575px) {
  .case-challenge__img {
    height: 400px;
    max-width: 100%;
    object-fit: cover;
  }
}
.case-challenge__box {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: unset;
  row-gap: 60px;
}
@media (min-width: 768px) {
  .case-challenge__box {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .case-challenge__box {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .case-challenge__box > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .case-challenge__box > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .case-challenge__box > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .case-challenge__box > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .case-challenge__box > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .case-challenge__box > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
.case-challenge__box .section-divider {
  padding-bottom: 16px;
}
@media (min-width: 768px) {
  .case-challenge__box .section-divider {
    padding-bottom: 26px;
  }
}
@media (min-width: 1200px) {
  .case-challenge__box .section-divider {
    padding-bottom: 38px;
  }
}
.case-challenge__box-title h3 {
  margin-bottom: 0;
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .case-challenge__box-title h3 {
    max-width: 350px;
  }
}
@media (min-width: 1200px) {
  .case-challenge__box-title h3 {
    max-width: 510px;
  }
}
.case-challenge__box-items ol {
  list-style-type: none;
  counter-reset: iter;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.case-challenge__box-items li {
  display: block;
  padding-left: 42px;
  position: relative;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .case-challenge__box-items li {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.4166666667;
    letter-spacing: normal;
  }
}
@media (min-width: 1200px) {
  .case-challenge__box-items li {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 400;
    line-height: 1.3846153846;
    letter-spacing: normal;
    letter-spacing: -0.02em;
  }
}
.case-challenge__box-items li b {
  font-weight: 600;
}
.case-challenge__box-items li:before {
  content: counter(iter) " /";
  counter-increment: iter;
  position: absolute;
  top: 0;
  left: 0;
}

.case-hero {
  padding-bottom: 0 !important;
  background-position: 50% 10%;
  background-size: auto 120%;
  box-shadow: 0px -60px 0px 0px #fff inset;
}
@media (min-width: 768px) {
  .case-hero {
    box-shadow: 0px -80px 0px 0px #fff inset;
  }
}
@media (min-width: 1200px) {
  .case-hero {
    box-shadow: 0px -240px 0px 0px #fff inset;
  }
}
.case-hero:before {
  content: "";
  position: absolute;
  z-index: 0;
  inset: 0;
  backdrop-filter: blur(10px);
}
.case-hero:after {
  content: "";
  position: absolute;
  z-index: 1;
  inset: 0;
  background: url("../images/noise.png") repeat center center;
}
.case-hero h1 {
  margin-bottom: 16px;
  font-size: 140px;
  font-size: 8.75rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.04em;
}
@media (max-width: 1199px) {
  .case-hero h1 {
    font-size: 100px;
    font-size: 6.25rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 767px) {
  .case-hero h1 {
    font-size: 56px;
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
.case-hero_text, .case-hero_text_link {
  width: 100%;
  max-width: 100%;
}
.case-hero__main {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
@media (min-width: 768px) {
  .case-hero__main {
    gap: 80px;
  }
}
.case-hero__image {
  display: block;
  max-width: 100%;
  height: auto;
}
@media (max-width: 575px) {
  .case-hero__image {
    height: 340px;
    object-fit: cover;
  }
}
.case-hero__box {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .case-hero__box {
    padding-top: 80px;
  }
}
.case-hero__content {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: start;
  row-gap: 60px;
}
@media (min-width: 768px) {
  .case-hero__content {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .case-hero__content {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.case-hero__description {
  grid-column: 1/-1;
}
.case-hero__description.main-header_text p, .case-hero__description.main-header_text ul, .case-hero__description.main-header_text ol, .case-hero__description.main-header_text a, .case-hero__description.main-header_text_link p, .case-hero__description.main-header_text_link ul, .case-hero__description.main-header_text_link ol, .case-hero__description.main-header_text_link a {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .case-hero__description.main-header_text p, .case-hero__description.main-header_text ul, .case-hero__description.main-header_text ol, .case-hero__description.main-header_text a, .case-hero__description.main-header_text_link p, .case-hero__description.main-header_text_link ul, .case-hero__description.main-header_text_link ol, .case-hero__description.main-header_text_link a {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: normal;
  }
}
@media (min-width: 1200px) {
  .case-hero__description.main-header_text p, .case-hero__description.main-header_text ul, .case-hero__description.main-header_text ol, .case-hero__description.main-header_text a, .case-hero__description.main-header_text_link p, .case-hero__description.main-header_text_link ul, .case-hero__description.main-header_text_link ol, .case-hero__description.main-header_text_link a {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 400;
    line-height: 1.6923076923;
    letter-spacing: normal;
  }
}
@media (min-width: 768px) {
  .case-hero__description {
    grid-column: 1/12;
    width: 100%;
  }
}
.case-hero__description *:last-of-type {
  margin-bottom: 0;
}
.case-hero__description.main-header_text {
  max-width: 100%;
}
.case-hero__highlights {
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .case-hero__highlights {
    grid-column: 15/-1;
    width: 100%;
  }
}
.case-hero__highlights li {
  border-bottom: 1px solid #000;
  padding-bottom: 16px;
}
.case-hero__highlights li a {
  font-weight: 400;
}
.case-hero__highlights *:last-of-type {
  margin-bottom: 0;
}
.case-hero__logo {
  padding-bottom: 120px;
  border-top: 2px solid #000;
  padding-top: 37px;
  padding-bottom: 55px;
}
.case-hero__logo img {
  max-width: 190px;
}
@media (max-width: 575px) {
  .case-hero__logo img {
    max-width: 120px;
  }
}
@media (min-width: 768px) {
  .case-hero__logo {
    padding-top: 65px;
    padding-bottom: 115px;
  }
}
@media (min-width: 1200px) {
  .case-hero__logo {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}

.case-footer {
  padding-bottom: 0;
}
.case-footer .section-divider {
  padding-bottom: 16px;
}
@media (min-width: 768px) {
  .case-footer .section-divider {
    padding-bottom: 32px;
  }
}
.case-footer__box {
  border-top: 1px solid #000;
}

.cases-main-header_text {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: normal;
  margin: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .cases-main-header_text {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: normal;
  }
}
@media (min-width: 1200px) {
  .cases-main-header_text {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 400;
    line-height: 1.6923076923;
    letter-spacing: normal;
  }
}
@media (max-width: 767px) {
  .cases-main-header_text {
    max-width: 340px;
  }
}

.toyota-arg-discovery p {
  margin-bottom: 0;
}
.toyota-arg-discovery__box {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-content: space-between;
}
@media (min-width: 768px) {
  .toyota-arg-discovery__box {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-arg-discovery__box {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.toyota-arg-discovery__thumbs {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.toyota-arg-discovery__thumbs:nth-of-type(1) {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .toyota-arg-discovery__thumbs:nth-of-type(1) {
    grid-column: 1/span 11;
    grid-row: 1/3;
  }
}
.toyota-arg-discovery__thumbs:nth-of-type(2) {
  grid-column: 1/-1;
  grid-row: 1;
}
@media (min-width: 768px) {
  .toyota-arg-discovery__thumbs:nth-of-type(2) {
    grid-column: 13/-1;
  }
}
.toyota-arg-discovery__thumbs:nth-of-type(3) {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .toyota-arg-discovery__thumbs:nth-of-type(3) {
    grid-column: 13/-1;
    grid-row: 2;
  }
}
@media (max-width: 767px) {
  .toyota-arg-discovery .cases-header-section {
    padding-top: 75px;
  }
}
.toyota-arg-journey .swiper-wrapper {
  transition-timing-function: ease-out;
}
.toyota-arg-journey p {
  margin-bottom: 0;
}
.toyota-arg-journey__box {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .toyota-arg-journey__box {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-arg-journey__box {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.toyota-arg-journey__thumbs {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.toyota-arg-journey__thumbs:nth-child(1) {
  grid-column: 1/span 3;
  grid-row: 2/3;
}
@media (min-width: 768px) {
  .toyota-arg-journey__thumbs:nth-child(1) {
    grid-column: 1/span 7;
    grid-row: 1/2;
  }
}
.toyota-arg-journey__thumbs:nth-child(2) {
  grid-column: 1/-1;
  grid-row: 1/2;
}
@media (min-width: 768px) {
  .toyota-arg-journey__thumbs:nth-child(2) {
    grid-column: 9/span 7;
    grid-row: 1/2;
  }
}
.toyota-arg-journey__thumbs:nth-child(3) {
  grid-column: 5/-1;
  grid-row: 2/3;
}
@media (min-width: 768px) {
  .toyota-arg-journey__thumbs:nth-child(3) {
    grid-column: 17/-1;
    grid-row: 1/2;
  }
}
.toyota-arg-journey__thumbs:nth-child(4) {
  grid-column: 1/-1;
  grid-row: 3/4;
}
@media (min-width: 768px) {
  .toyota-arg-journey__thumbs:nth-child(4) {
    grid-column: 1/-1;
    grid-row: 2/3;
  }
}
.toyota-arg-journey__levels {
  grid-column: 1/-1;
}
.toyota-arg-journey__levels .section-divider {
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (min-width: 768px) {
  .toyota-arg-journey__levels .section-divider {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
@media (min-width: 768px) {
  .toyota-arg-journey__levels {
    grid-column: 16/-1;
    grid-row: 3/4;
  }
  .toyota-arg-journey__levels .section-divider {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .toyota-arg-journey .cases-header-section {
    padding-top: 75px;
  }
}
.toyota-arg-site {
  padding-bottom: 120px;
}
.toyota-arg-site__thumb {
  width: 100%;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .toyota-arg-site__thumb {
    margin-bottom: 32px;
  }
}
.toyota-arg-site header {
  padding-top: 76px;
}
@media (min-width: 768px) {
  .toyota-arg-site header {
    padding-top: 146px;
  }
}
@media (min-width: 1200px) {
  .toyota-arg-site header {
    padding-top: 152px;
  }
}
.toyota-arg-site .list-item {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: start;
}
@media (min-width: 768px) {
  .toyota-arg-site .list-item {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-arg-site .list-item {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .toyota-arg-site .list-item > *:nth-child(2n+1) {
    grid-column: 1/span 3;
  }
  .toyota-arg-site .list-item > *:nth-child(2n) {
    grid-column: 5/span 3;
  }
}
@media (min-width: 576px) {
  .toyota-arg-site .list-item > *:nth-child(2n+1) {
    grid-column: 1/span 3;
  }
  .toyota-arg-site .list-item > *:nth-child(2n) {
    grid-column: 5/span 3;
  }
}
@media (min-width: 768px) {
  .toyota-arg-site .list-item > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .toyota-arg-site .list-item > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .toyota-arg-site .list-item > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .toyota-arg-site .list-item > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
@media (min-width: 992px) {
  .toyota-arg-site .list-item > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .toyota-arg-site .list-item > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .toyota-arg-site .list-item > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .toyota-arg-site .list-item > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
@media (max-width: 767px) {
  .toyota-arg-site .list-item-container {
    padding-top: 13px;
  }
}
.toyota-arg-site .list-item-container__title {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.6666666667;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .toyota-arg-site .list-item-container__title {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.7142857143;
    letter-spacing: normal;
  }
}
@media (min-width: 1200px) {
  .toyota-arg-site .list-item-container__title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.toyota-arg-site .list-item-container__description {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.6666666667;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .toyota-arg-site .list-item-container__description {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.7142857143;
    letter-spacing: normal;
  }
}
@media (min-width: 1200px) {
  .toyota-arg-site .list-item-container__description {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.toyota-arg-design.--bg-black {
  background-color: #1f1f1f;
}
.toyota-arg-design__box {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: unset;
}
@media (min-width: 768px) {
  .toyota-arg-design__box {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-arg-design__box {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.toyota-arg-design__thumb {
  max-width: 100%;
  display: block;
}
.toyota-arg-design__thumb:nth-of-type(1) {
  grid-column: 1/-1;
  grid-row: 1/2;
  object-fit: cover;
  min-height: 100%;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(1) {
    grid-column: 1/span 11;
    grid-row: 1/3;
  }
}
.toyota-arg-design__thumb:nth-of-type(2) {
  grid-column: 1/span 3;
  grid-row: 2/3;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(2) {
    grid-column: 13/-1;
    grid-row: 1/2;
  }
}
.toyota-arg-design__thumb:nth-of-type(3) {
  grid-column: 5/-1;
  grid-row: 2/3;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(3) {
    grid-column: 13/-1;
  }
}
.toyota-arg-design__thumb:nth-of-type(4) {
  grid-column: 1/-1;
  grid-row: 3/4;
}
.toyota-arg-design__thumb:nth-of-type(5) {
  grid-column: 1/-1;
  grid-row: 4/5;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(5) {
    grid-column: 13/-1;
  }
}
.toyota-arg-design__thumb:nth-of-type(6) {
  grid-column: 1/-1;
  grid-row: 6/7;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(6) {
    grid-row: 5/6;
  }
}
.toyota-arg-design__thumb:nth-of-type(7) {
  grid-column: 1/-1;
  grid-row: 7/8;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(7) {
    grid-column: 13/-1;
    grid-row: 6/7;
  }
}
.toyota-arg-design__thumb:nth-of-type(8) {
  grid-column: 1/-1;
  grid-row: 8/9;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(8) {
    grid-row: 7/8;
  }
}
.toyota-arg-design__thumb:nth-of-type(9) {
  grid-column: 1/-1;
  grid-row: 9/10;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(9) {
    grid-column: 1/span 11;
    grid-row: 8/9;
  }
}
.toyota-arg-design__thumb:nth-of-type(10) {
  grid-column: 1/-1;
  grid-row: 12/13;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(10) {
    grid-column: 13/-1;
    grid-row: 8/9;
  }
}
.toyota-arg-design__thumb:nth-of-type(11) {
  grid-column: 1/span 3;
  grid-row: 11/12;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(11) {
    grid-column: 1/span 11;
    grid-row: 9/10;
  }
}
.toyota-arg-design__thumb:nth-of-type(12) {
  grid-column: 5/-1;
  grid-row: 11/12;
}
@media (min-width: 768px) {
  .toyota-arg-design__thumb:nth-of-type(12) {
    grid-column: 13/-1;
    grid-row: 9/10;
  }
}
.toyota-arg-design__description {
  color: #fff;
  border-top: 1px solid #fff;
  padding-top: 16px;
}
.toyota-arg-design__description p {
  max-width: 400px;
  width: 100%;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .toyota-arg-design__description {
    padding-top: 24px;
  }
}
.toyota-arg-design__description:nth-of-type(1) {
  grid-column: 1/-1;
  grid-row: 5/6;
}
@media (min-width: 768px) {
  .toyota-arg-design__description:nth-of-type(1) {
    grid-column: 1/span 11;
    grid-row: 4/5;
  }
}
.toyota-arg-design__description:nth-of-type(2) {
  grid-column: 1/-1;
  grid-row: 10/11;
}
@media (min-width: 768px) {
  .toyota-arg-design__description:nth-of-type(2) {
    grid-column: 1/span 11;
    grid-row: 6/7;
  }
}
@media (max-width: 767px) {
  .toyota-arg-design {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 40px;
  }
  .toyota-arg-design .cases-header-section {
    padding-top: 75px;
  }
}

.la-nacion-roadmap .section-divider {
  margin-top: -60px;
}
.la-nacion-roadmap-container {
  background-color: #F1F1F1;
  padding-top: 120px;
  padding-bottom: 165px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}
.la-nacion-roadmap-container .la-nacion-center-text {
  margin-bottom: 120px;
}
.la-nacion-roadmap-container-boxes {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  text-align: left;
}
@media (min-width: 768px) {
  .la-nacion-roadmap-container-boxes {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .la-nacion-roadmap-container-boxes {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.la-nacion-roadmap-container-boxes .boxes-title {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0em;
  align-self: end;
  padding-top: 20px;
}
.la-nacion-roadmap-container-boxes .boxes-tags {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0em;
  width: max-content;
  height: max-content;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #ffffff;
  align-self: start;
}
.la-nacion-roadmap-container-boxes .boxes-description {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0em;
  padding-top: 16px;
}
.la-nacion-roadmap-container-boxes .box-1, .la-nacion-roadmap-container-boxes .box-2, .la-nacion-roadmap-container-boxes .box-3, .la-nacion-roadmap-container-boxes .box-4 {
  border-top: 1px solid #000;
  height: 220px;
  padding-top: 35px;
  display: grid;
  grid-template-rows: repeat(4, max-content);
}
.la-nacion-roadmap-container-boxes .box-1 p, .la-nacion-roadmap-container-boxes .box-2 p, .la-nacion-roadmap-container-boxes .box-3 p, .la-nacion-roadmap-container-boxes .box-4 p {
  margin: 0;
}
.la-nacion-roadmap-container-boxes .box-1 img, .la-nacion-roadmap-container-boxes .box-2 img, .la-nacion-roadmap-container-boxes .box-3 img, .la-nacion-roadmap-container-boxes .box-4 img {
  align-self: center;
}
.la-nacion-roadmap-container-boxes .box-1 {
  grid-column: 5/span 3;
}
.la-nacion-roadmap-container-boxes .box-2 {
  grid-column: 9/span 3;
}
.la-nacion-roadmap-container-boxes .box-3 {
  grid-column: 13/span 3;
}
.la-nacion-roadmap-container-boxes .box-4 {
  grid-column: 17/span 3;
  color: #FF0000;
  border-color: #FF0000;
}
@media (max-width: 1024px) {
  .la-nacion-roadmap-container {
    padding-bottom: 120px;
    margin-top: 26px;
  }
  .la-nacion-roadmap-container-title, .la-nacion-roadmap-container-text {
    padding-left: 75px;
    padding-right: 75px;
  }
  .la-nacion-roadmap-container-boxes .boxes-title {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.4444444444;
    letter-spacing: 0em;
  }
  .la-nacion-roadmap-container-boxes .boxes-description {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.8571428571;
    letter-spacing: 0em;
  }
}
@media (max-width: 768px) {
  .la-nacion-roadmap-container-boxes .box-1 {
    grid-column: 4/span 7;
    grid-row: 1;
  }
  .la-nacion-roadmap-container-boxes .box-2 {
    grid-column: 14/span 7;
    grid-row: 1;
  }
  .la-nacion-roadmap-container-boxes .box-3 {
    grid-column: 4/span 7;
    grid-row: 2;
  }
  .la-nacion-roadmap-container-boxes .box-4 {
    grid-column: 14/span 7;
    grid-row: 2;
  }
}
@media (max-width: 767px) {
  .la-nacion-roadmap {
    padding-bottom: 40px;
  }
  .la-nacion-roadmap-container {
    padding: 60px 16px;
    margin-top: 16px;
  }
  .la-nacion-roadmap-container .la-nacion-center-text {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    margin-bottom: 60px;
  }
  .la-nacion-roadmap-container-boxes .box-1 {
    grid-column: 1/span 3;
    grid-row: 1;
  }
  .la-nacion-roadmap-container-boxes .box-2 {
    grid-column: 5/span 3;
    grid-row: 1;
  }
  .la-nacion-roadmap-container-boxes .box-3 {
    grid-column: 1/span 3;
    grid-row: 2;
  }
  .la-nacion-roadmap-container-boxes .box-4 {
    grid-column: 5/span 3;
    grid-row: 2;
  }
  .la-nacion-roadmap-container-boxes .boxes-title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: 0em;
  }
  .la-nacion-roadmap-container-boxes .boxes-description {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.6666666667;
    letter-spacing: 0em;
  }
}
.la-nacion-designing-rules .la-nacion-center-text {
  margin-top: 150px;
  margin-bottom: 150px;
}
@media (max-width: 767px) {
  .la-nacion-designing-rules .la-nacion-center-text {
    margin-top: 72px;
    margin-bottom: 60px;
    text-align: left;
  }
}
.la-nacion-designing-rules-container {
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}
.la-nacion-designing-rules-down .section-divider {
  border-color: #F1F1F1;
}
.la-nacion-designing-rules-down-container {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  row-gap: 0px;
}
@media (min-width: 768px) {
  .la-nacion-designing-rules-down-container {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .la-nacion-designing-rules-down-container {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.la-nacion-designing-rules-down-container .box-1 {
  grid-column: 1/span 11;
}
.la-nacion-designing-rules-down-container .box-2 {
  grid-column: 13/span 11;
}
.la-nacion-designing-rules-down-container .box-1, .la-nacion-designing-rules-down-container .box-2 {
  margin-top: 32px;
  padding-top: 70px;
  padding-bottom: 60px;
  padding-left: 31px;
  padding-right: 31px;
  background-color: #F1F1F1;
  width: 100%;
  display: grid;
  place-content: center;
  text-align: center;
}
.la-nacion-designing-rules-down-container .box-1 img, .la-nacion-designing-rules-down-container .box-2 img {
  justify-self: center;
  width: 100px;
  height: 100px;
  margin-bottom: 34px;
}
@media (max-width: 1024px) {
  .la-nacion-designing-rules-down-container .box-1 img, .la-nacion-designing-rules-down-container .box-2 img {
    width: 95px;
    height: 95px;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-rules-down-container .box-1 img, .la-nacion-designing-rules-down-container .box-2 img {
    margin: 0;
    width: 50px;
    height: 50px;
  }
}
.la-nacion-designing-rules-down-container .box-1 p, .la-nacion-designing-rules-down-container .box-2 p {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.3846153846;
  letter-spacing: -0.03em;
  max-width: 380px;
}
@media (max-width: 1024px) {
  .la-nacion-designing-rules-down-container .box-1 p, .la-nacion-designing-rules-down-container .box-2 p {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.4166666667;
    letter-spacing: 0em;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-rules-down-container .box-1 p, .la-nacion-designing-rules-down-container .box-2 p {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 16px;
    margin-bottom: 0;
  }
  .la-nacion-designing-rules-down-container .box-1 p strong, .la-nacion-designing-rules-down-container .box-2 p strong {
    font-weight: 400;
  }
}
@media (max-width: 1024px) {
  .la-nacion-designing-rules-down-container .box-1, .la-nacion-designing-rules-down-container .box-2 {
    padding-left: 31px;
    padding-right: 31px;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-rules-down-container {
    grid-template-rows: 1fr 1fr;
  }
  .la-nacion-designing-rules-down-container .box-1 {
    grid-column: 1/span 7;
    grid-row: 1;
  }
  .la-nacion-designing-rules-down-container .box-2 {
    grid-column: 1/span 7;
    grid-row: 2;
  }
  .la-nacion-designing-rules-down-container .box-1, .la-nacion-designing-rules-down-container .box-2 {
    margin-top: 16px;
    padding: 16px;
    display: flex;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-rules {
    padding-bottom: 120px;
  }
}
.la-nacion-designing-stunning {
  background-color: #1D1D1D;
  color: #ffffff;
  padding-bottom: 120px;
}
.la-nacion-designing-stunning-container {
  margin-top: 17px;
  padding-bottom: 120px;
  text-align: center;
  display: grid;
  justify-items: center;
}
.la-nacion-designing-stunning-container .la-nacion-center-text-small {
  margin-top: 25px;
}
.la-nacion-designing-stunning-container img {
  margin-bottom: 25px;
  width: 94px;
  height: 66px;
}
@media (max-width: 767px) {
  .la-nacion-designing-stunning-container {
    margin-top: 0;
  }
  .la-nacion-designing-stunning-container img {
    width: 56px;
    height: 40px;
  }
}
.la-nacion-designing-stunning-photos {
  margin: 0;
}
.la-nacion-designing-stunning-photos .browser-nav {
  margin-bottom: 24px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
@media (max-width: 1024px) {
  .la-nacion-designing-stunning-photos .browser-nav {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-stunning-photos .browser-nav {
    display: none;
  }
}
.la-nacion-designing-stunning-photos img {
  width: 100%;
}
@media (max-width: 767px) {
  .la-nacion-designing-stunning {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  .la-nacion-designing-stunning-container {
    padding-bottom: 60px;
  }
  .la-nacion-designing-stunning-container .la-nacion-center-text-small {
    margin-top: 15px;
  }
}
.la-nacion-designing-components {
  background-color: #F1F1F1;
}
.la-nacion-designing-components img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.la-nacion-designing-components .black-box {
  background-color: #000000;
  color: #ffffff;
  margin-top: -20px;
  margin-bottom: 100px;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .la-nacion-designing-components .black-box {
    margin-bottom: 40px;
    padding-top: 60px;
  }
  .la-nacion-designing-components .black-box p {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.la-nacion-designing-components .nav {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .la-nacion-designing-components .nav {
    margin-bottom: 0px;
    margin-top: 0;
  }
}
.la-nacion-designing-components .browser-nav {
  margin-top: 100px;
  margin-bottom: 32px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
@media (max-width: 767px) {
  .la-nacion-designing-components .browser-nav {
    display: none;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-components .macri {
    margin-top: 60px;
  }
}
.la-nacion-designing-components .red-box {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .la-nacion-designing-components .red-box {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .la-nacion-designing-components .red-box {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.la-nacion-designing-components .red-box .red-img1 {
  grid-column: 1/span 7;
}
.la-nacion-designing-components .red-box .red-img2 {
  grid-column: 9/span 15;
}
@media (max-width: 767px) {
  .la-nacion-designing-components .red-box {
    grid-template-rows: repeat(2, auto);
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .la-nacion-designing-components .red-box .red-img1 {
    grid-column: 1/span 7;
    grid-row: 1;
  }
  .la-nacion-designing-components .red-box .red-img2 {
    grid-column: 1/span 7;
    grid-row: 2;
  }
}
.la-nacion-designing-components .grid-img-mobile {
  display: none;
}
@media (max-width: 767px) {
  .la-nacion-designing-components .grid-img-mobile {
    display: grid;
    height: auto;
    align-items: center;
    grid-template-columns: repeat(3, 1fr 16px) 1fr;
    row-gap: 16px;
  }
}
@media (max-width: 767px) and (min-width: 768px) {
  .la-nacion-designing-components .grid-img-mobile {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (max-width: 767px) and (min-width: 1200px) {
  .la-nacion-designing-components .grid-img-mobile {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-components .grid-img-mobile .img-mobile1 {
    grid-column: 1/span 7;
    grid-row: 1;
  }
  .la-nacion-designing-components .grid-img-mobile .img-mobile2 {
    grid-column: 1/span 7;
    grid-row: 2;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-components .grid-img-dekstop {
    display: none;
  }
}
.la-nacion-designing-components .blue-pink-box {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .la-nacion-designing-components .blue-pink-box {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .la-nacion-designing-components .blue-pink-box {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.la-nacion-designing-components .blue-pink-box .blue-pink-img1 {
  grid-column: 1/span 11;
}
.la-nacion-designing-components .blue-pink-box .blue-pink-img2 {
  grid-column: 13/span 11;
}
@media (max-width: 767px) {
  .la-nacion-designing-components .blue-pink-box {
    margin-top: 16px;
  }
  .la-nacion-designing-components .blue-pink-box .blue-pink-img1 {
    grid-column: 1/span 7;
    grid-row: 1;
  }
  .la-nacion-designing-components .blue-pink-box .blue-pink-img2 {
    grid-column: 1/span 7;
    grid-row: 2;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-components {
    padding-top: 60px;
    padding-bottom: 15px;
  }
}
.la-nacion-designing-quote {
  background-color: #F1F1F1;
  padding-bottom: 240px;
}
@media (max-width: 767px) {
  .la-nacion-designing-quote {
    padding-bottom: 120px;
  }
}
.la-nacion-designing-quote .case-quote__box {
  margin: auto;
}
.la-nacion-designing-fascinating {
  background-color: #133F61;
  color: #ffffff;
  padding-bottom: 0px;
}
.la-nacion-designing-fascinating-container2 {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .la-nacion-designing-fascinating-container2 {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .la-nacion-designing-fascinating-container2 {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-fascinating-container2 {
    row-gap: 0px;
  }
}
.la-nacion-designing-fascinating-container, .la-nacion-designing-fascinating-container2 {
  margin-top: 17px;
  padding-bottom: 120px;
  display: grid;
  justify-items: center;
}
.la-nacion-designing-fascinating-container .la-nacion-center-text-small, .la-nacion-designing-fascinating-container2 .la-nacion-center-text-small {
  row-gap: 0px !important;
}
.la-nacion-designing-fascinating-container .person-icon, .la-nacion-designing-fascinating-container2 .person-icon {
  width: 128px;
  height: 93px;
  margin-bottom: 39px;
}
@media (max-width: 767px) {
  .la-nacion-designing-fascinating-container .person-icon, .la-nacion-designing-fascinating-container2 .person-icon {
    width: 56px;
    height: 40px;
    margin-left: 0px;
  }
}
.la-nacion-designing-fascinating-container-text, .la-nacion-designing-fascinating-container2-text {
  grid-column: 5/span 15;
  text-align: center;
  margin-bottom: 0;
}
.la-nacion-designing-fascinating-container .boxes-icon, .la-nacion-designing-fascinating-container2 .boxes-icon {
  grid-column: 5/span 15;
  width: 100px;
  height: 100px;
  margin-bottom: 37px;
}
@media (max-width: 1024px) {
  .la-nacion-designing-fascinating-container-text, .la-nacion-designing-fascinating-container2-text {
    grid-column: 3/span 19;
  }
  .la-nacion-designing-fascinating-container .boxes-icon, .la-nacion-designing-fascinating-container2 .boxes-icon {
    grid-column: 3/span 19;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-fascinating-container, .la-nacion-designing-fascinating-container2 {
    margin-top: 0;
    padding-bottom: 60px;
  }
  .la-nacion-designing-fascinating-container-text, .la-nacion-designing-fascinating-container2-text {
    grid-column: 1/-1;
    text-align: left;
  }
  .la-nacion-designing-fascinating-container .boxes-icon, .la-nacion-designing-fascinating-container2 .boxes-icon {
    grid-column: 1;
    width: 54px;
    height: 54px;
  }
}
.la-nacion-designing-fascinating-photos {
  margin: 0;
}
.la-nacion-designing-fascinating-photos .browser-nav {
  margin-bottom: 24px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
@media (max-width: 1024px) {
  .la-nacion-designing-fascinating-photos .browser-nav {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-fascinating-photos .browser-nav {
    display: none;
  }
}
.la-nacion-designing-fascinating-photos img {
  margin-top: -10px;
  width: 100%;
}
@media (max-width: 767px) {
  .la-nacion-designing-fascinating-photos img {
    margin-top: 0;
  }
}
.la-nacion-designing-fascinating-down-photos {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  grid-row: repeat(4, 1fr);
  row-gap: 40px;
  align-items: start;
  justify-items: center;
  margin-bottom: 130px;
  margin-top: 35px;
}
@media (min-width: 768px) {
  .la-nacion-designing-fascinating-down-photos {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .la-nacion-designing-fascinating-down-photos {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.la-nacion-designing-fascinating-down-photos picture, .la-nacion-designing-fascinating-down-photos img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.la-nacion-designing-fascinating-down-photos .img1 {
  grid-row: 1;
  grid-column: 1/span 5;
}
.la-nacion-designing-fascinating-down-photos .img2 {
  grid-row: 2;
  grid-column: 1/span 5;
}
.la-nacion-designing-fascinating-down-photos .img3 {
  grid-row: 3/span 2;
  grid-column: 1/span 5;
}
.la-nacion-designing-fascinating-down-photos .img4 {
  grid-row: 1/span 2;
  grid-column: 7/span 5;
}
.la-nacion-designing-fascinating-down-photos .img5 {
  grid-row: 3/span 2;
  grid-column: 7/span 5;
}
.la-nacion-designing-fascinating-down-photos .img6 {
  grid-row: 1/span 4;
  grid-column: 13/span 11;
}
@media (max-width: 767px) {
  .la-nacion-designing-fascinating-down-photos {
    row-gap: 16px;
  }
}
.la-nacion-designing-fascinating-last-photos {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  row-gap: 40px;
  justify-items: center;
  align-items: center;
}
@media (min-width: 768px) {
  .la-nacion-designing-fascinating-last-photos {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .la-nacion-designing-fascinating-last-photos {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.la-nacion-designing-fascinating-last-photos picture, .la-nacion-designing-fascinating-last-photos img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.la-nacion-designing-fascinating-last-photos .img1 {
  grid-row: 1;
  grid-column: 1/-1;
  width: 100%;
}
.la-nacion-designing-fascinating-last-photos .img2 {
  grid-row: 2;
  grid-column: 1/span 11;
  width: 100%;
}
.la-nacion-designing-fascinating-last-photos .img3 {
  grid-row: 2;
  grid-column: 13/span 11;
  width: 100%;
}
.la-nacion-designing-fascinating-last-photos .img4 {
  grid-row: 3;
  grid-column: 1/-1;
  max-height: 760px;
}
@media (max-width: 767px) {
  .la-nacion-designing-fascinating-last-photos {
    row-gap: 16px;
  }
  .la-nacion-designing-fascinating-last-photos .img4 {
    height: 214px;
    width: 343px;
  }
}
@media (max-width: 767px) {
  .la-nacion-designing-fascinating {
    margin-top: 0px;
    padding-top: 60px;
  }
  .la-nacion-designing-fascinating-container {
    justify-items: start;
    padding-bottom: 60px;
  }
  .la-nacion-designing-fascinating-container .la-nacion-center-text-small {
    text-align: left;
  }
  .la-nacion-designing-fascinating-container-text {
    margin-bottom: 0;
  }
  .la-nacion-designing-fascinating-container .boxes-icon {
    width: 50px;
    height: 50px;
  }
  .la-nacion-designing-fascinating-down-photos {
    margin-top: 16px;
    margin-bottom: 60px;
  }
  .la-nacion-designing-fascinating-down-photos .img1 {
    grid-row: 1;
    grid-column: 1/span 3;
  }
  .la-nacion-designing-fascinating-down-photos .img2 {
    grid-row: 2;
    grid-column: 1/span 3;
  }
  .la-nacion-designing-fascinating-down-photos .img3 {
    grid-row: 1/span 2;
    grid-column: 5/span 3;
  }
  .la-nacion-designing-fascinating-down-photos .img4 {
    grid-row: 3;
    grid-column: 1/span 3;
  }
  .la-nacion-designing-fascinating-down-photos .img5 {
    grid-row: 3;
    grid-column: 5/span 3;
  }
  .la-nacion-designing-fascinating-down-photos .img6 {
    grid-row: 4;
    grid-column: 1/-1;
  }
}
.la-nacion-teamwork .la-nacion-roadmap-container {
  text-align: left;
}
.la-nacion-teamwork .la-nacion-roadmap-container .box-4 {
  color: #000000;
  border-color: #000000;
}
.la-nacion-teamwork .la-nacion-roadmap-container .boxes-title {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0em;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 20px;
}
.la-nacion-teamwork .la-nacion-roadmap-container .boxes-tags {
  margin-top: 8px;
}
.la-nacion-teamwork .la-nacion-roadmap-container .boxes-img {
  width: 50px;
  height: 50px;
}
.la-nacion-teamwork .la-nacion-roadmap-container .box-1, .la-nacion-teamwork .la-nacion-roadmap-container .box-2, .la-nacion-teamwork .la-nacion-roadmap-container .box-3, .la-nacion-teamwork .la-nacion-roadmap-container .box-4 {
  grid-template-rows: repeat(2, min-content);
}
@media (max-width: 1024px) {
  .la-nacion-teamwork {
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .la-nacion-teamwork {
    padding-top: 120px;
    margin-bottom: 40px;
  }
  .la-nacion-teamwork .la-nacion-roadmap-container .la-nacion-roadmap-container-boxes {
    row-gap: 0px;
  }
  .la-nacion-teamwork .la-nacion-roadmap-container .boxes-title {
    margin-top: 0px;
  }
  .la-nacion-teamwork .la-nacion-roadmap-container .boxes-tags {
    margin-top: 4px;
  }
}
.la-nacion-width {
  max-width: 490px;
}
@media (max-width: 767px) {
  .la-nacion-width {
    letter-spacing: -0.02rem;
  }
}
.la-nacion-center-text, .la-nacion-center-text-small {
  text-align: center;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  row-gap: 0px;
}
@media (min-width: 768px) {
  .la-nacion-center-text, .la-nacion-center-text-small {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .la-nacion-center-text, .la-nacion-center-text-small {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.la-nacion-center-text h3, .la-nacion-center-text-small h3 {
  margin-bottom: 32px;
  grid-column: 5/span 15;
}
@media (max-width: 1024px) {
  .la-nacion-center-text h3, .la-nacion-center-text-small h3 {
    grid-column: 3/span 19;
  }
}
@media (max-width: 768px) {
  .la-nacion-center-text h3, .la-nacion-center-text-small h3 {
    grid-column: 1/-1;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.la-nacion-center-text p, .la-nacion-center-text-small p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  margin: 0;
  grid-column: 5/span 15;
}
@media (max-width: 1024px) {
  .la-nacion-center-text p, .la-nacion-center-text-small p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
    grid-column: 3/span 19;
  }
}
@media (max-width: 768px) {
  .la-nacion-center-text p, .la-nacion-center-text-small p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
    grid-column: 1/-1;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 767px) {
  .la-nacion-center-text p, .la-nacion-center-text-small p {
    padding: 0;
  }
}
.la-nacion-center-text-small h3 {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
}
@media (max-width: 1024px) {
  .la-nacion-center-text-small h3 {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .la-nacion-center-text-small h3 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .la-nacion-mobile-without-padding h3, .la-nacion-mobile-without-padding p {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.text-align-left {
  text-align: left;
}

.toyota-cl__container {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: unset;
  margin-top: 32px;
  padding-bottom: 60px;
  background-color: #f1f1f1;
}
@media (min-width: 768px) {
  .toyota-cl__container {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl__container {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (min-width: 768px) {
  .toyota-cl__container {
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .toyota-cl__container {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
  }
}
.toyota-cl__inner {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .toyota-cl__inner {
    grid-column: 5/20;
  }
}
.toyota-cl-needs p {
  margin-bottom: 0;
}
.toyota-cl-needs__features {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: unset;
  row-gap: 32px;
}
@media (min-width: 768px) {
  .toyota-cl-needs__features {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl-needs__features {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .toyota-cl-needs__features > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .toyota-cl-needs__features > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .toyota-cl-needs__features > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .toyota-cl-needs__features > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .toyota-cl-needs__features > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .toyota-cl-needs__features > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
@media (min-width: 992px) {
  .toyota-cl-needs__features > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .toyota-cl-needs__features > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .toyota-cl-needs__features > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .toyota-cl-needs__features > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
.toyota-cl-needs__features-items {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;
  border-top: 1px solid #000;
}
.toyota-cl-needs__features-items__content strong {
  font-weight: 700;
  margin-bottom: 16px;
  display: block;
}
@media (max-width: 767px) {
  .toyota-cl-needs__features-items {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 20px;
  }
  .toyota-cl-needs__features-items svg {
    flex-shrink: 0;
    max-width: 60px;
  }
}
@media (max-width: 767px) {
  .toyota-cl-needs .cases-header-section {
    padding-top: 75px;
  }
}
.toyota-cl-stack__tech {
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}
@media (max-width: 535px) {
  .toyota-cl-stack__tech {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
.toyota-cl-stack__tech img {
  max-width: 100%;
  height: auto;
  display: block;
  min-width: 0;
}
@media (max-width: 535px) {
  .toyota-cl-stack__tech img {
    max-width: 147px;
  }
}
@media (min-width: 768px) {
  .toyota-cl-stack__tech {
    grid-column: 3/22;
  }
}
.toyota-cl-stack__description {
  margin-bottom: 44px;
  margin-top: 44px;
}
.toyota-cl-stack__description b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .toyota-cl-stack__description {
    margin-bottom: 80px;
    margin-top: 80px;
  }
}
@media (min-width: 992px) {
  .toyota-cl-stack__description {
    margin-bottom: 120px;
    margin-top: 120px;
  }
}
.toyota-cl-stack__description p:last-of-type {
  margin-bottom: 0;
}
.toyota-cl-stack__companies {
  border-top: 1px solid #000;
}
.toyota-cl-stack__companies p {
  margin-bottom: 60px;
  padding-top: 16px;
}
@media (max-width: 767px) {
  .toyota-cl-stack__companies p {
    margin-bottom: 32px;
  }
}
.toyota-cl-stack__companies-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  gap: 25px;
}
.toyota-cl-stack__companies-logos svg {
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 767px) {
  .toyota-cl-stack .cases-header-section {
    padding-bottom: 44px;
  }
}
@media (max-width: 767px) {
  .toyota-cl-experience .section-divider {
    padding-top: 21px;
  }
}
.toyota-cl-experience img {
  max-width: 100%;
}
.toyota-cl-experience .toyota-cl__container {
  background-color: #ffc500;
  padding-bottom: 0;
}
@media (max-width: 767px) {
  .toyota-cl-experience .toyota-cl__container {
    margin-top: 21px;
  }
}
@media (max-width: 767px) {
  .toyota-cl-experience .cases-header-section {
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .toyota-cl-experience .toyota-cl-experience-img-dekstop {
    display: none;
  }
}
.toyota-cl-experience .toyota-cl-experience-img-mobile {
  display: none;
  margin-top: -2px;
}
@media (max-width: 767px) {
  .toyota-cl-experience .toyota-cl-experience-img-mobile {
    display: block;
  }
}
@media (max-width: 767px) {
  .toyota-cl-experience {
    padding-bottom: 120px;
  }
}
.toyota-cl-platform.--bg-black {
  background-color: #1d1d1d;
}
.toyota-cl-platform img {
  display: block;
  max-width: 100%;
  height: auto;
}
.toyota-cl-platform__custom {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: unset;
}
@media (min-width: 768px) {
  .toyota-cl-platform__custom {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl-platform__custom {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .toyota-cl-platform__custom > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .toyota-cl-platform__custom > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .toyota-cl-platform__custom > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .toyota-cl-platform__custom > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .toyota-cl-platform__custom > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .toyota-cl-platform__custom > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (max-width: 575px) {
  .toyota-cl-platform__custom img:nth-of-type(1) {
    grid-column: 1/4;
  }
  .toyota-cl-platform__custom img:nth-of-type(2) {
    grid-column: 5/-1;
  }
}
.toyota-cl-platform__payment {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: unset;
}
@media (min-width: 768px) {
  .toyota-cl-platform__payment {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl-platform__payment {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.toyota-cl-platform__payment picture, .toyota-cl-platform__payment img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.toyota-cl-platform__payment__1 {
  grid-column: 1/-1;
  grid-row: 1/2;
}
@media (min-width: 768px) {
  .toyota-cl-platform__payment__1 {
    grid-column: 1/span 11;
    grid-row: 1/3;
  }
}
.toyota-cl-platform__payment__2 {
  grid-column: 1/-1;
  grid-row: 2/3;
}
@media (min-width: 768px) {
  .toyota-cl-platform__payment__2 {
    grid-column: 13/-1;
    grid-row: 1/2;
  }
}
.toyota-cl-platform__payment__3 {
  grid-column: 1/-1;
  grid-row: 3/4;
}
@media (min-width: 768px) {
  .toyota-cl-platform__payment__3 {
    grid-column: 13/-1;
    grid-row: 2/3;
  }
}
.toyota-cl-platform__payment__4 {
  grid-column: 1/-1;
  grid-row: 4/5;
}
@media (min-width: 768px) {
  .toyota-cl-platform__payment__4 {
    grid-column: 1/span 11;
    grid-row: 3/4;
  }
}
.toyota-cl-platform__payment__5 {
  grid-column: 1/-1;
  grid-row: 5/6;
}
@media (min-width: 768px) {
  .toyota-cl-platform__payment__5 {
    grid-column: 13/-1;
    grid-row: 3/5;
  }
}
.toyota-cl-platform__after-sales header {
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .toyota-cl-platform__after-sales header {
    padding-bottom: 80px;
  }
}
.toyota-cl-platform__after-sales-features {
  padding-bottom: 16px;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .toyota-cl-platform__after-sales-features {
    padding-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl-platform__after-sales-features {
    padding-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .toyota-cl-platform__after-sales-features {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl-platform__after-sales-features {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .toyota-cl-platform__after-sales-features > *:nth-child(2n+1) {
    grid-column: 1/span 3;
  }
  .toyota-cl-platform__after-sales-features > *:nth-child(2n) {
    grid-column: 5/span 3;
  }
}
@media (min-width: 576px) {
  .toyota-cl-platform__after-sales-features > *:nth-child(2n+1) {
    grid-column: 1/span 3;
  }
  .toyota-cl-platform__after-sales-features > *:nth-child(2n) {
    grid-column: 5/span 3;
  }
}
@media (min-width: 768px) {
  .toyota-cl-platform__after-sales-features > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .toyota-cl-platform__after-sales-features > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .toyota-cl-platform__after-sales-features > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .toyota-cl-platform__after-sales-features > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
@media (min-width: 992px) {
  .toyota-cl-platform__after-sales-features > *:nth-child(4n+1) {
    grid-column: 1/span 5;
  }
  .toyota-cl-platform__after-sales-features > *:nth-child(4n+2) {
    grid-column: 7/span 5;
  }
  .toyota-cl-platform__after-sales-features > *:nth-child(4n+3) {
    grid-column: 13/span 5;
  }
  .toyota-cl-platform__after-sales-features > *:nth-child(4n) {
    grid-column: 19/span 5;
  }
}
.toyota-cl-platform__after-sales-features i {
  max-width: 100%;
  height: auto;
  display: block;
}
.toyota-cl-platform__after-sales-features i svg {
  height: 100%;
  width: 100%;
}
.toyota-cl-platform__after-sales-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  align-items: unset;
}
@media (min-width: 768px) {
  .toyota-cl-platform__after-sales-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl-platform__after-sales-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .toyota-cl-platform__after-sales-grid > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .toyota-cl-platform__after-sales-grid > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .toyota-cl-platform__after-sales-grid > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .toyota-cl-platform__after-sales-grid > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .toyota-cl-platform__after-sales-grid > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .toyota-cl-platform__after-sales-grid > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (max-width: 575px) {
  .toyota-cl-platform__after-sales-grid img:nth-of-type(2) {
    grid-column: 1/4;
  }
  .toyota-cl-platform__after-sales-grid img:nth-of-type(3) {
    grid-column: 5/-1;
  }
}
@media (max-width: 767px) {
  .toyota-cl-platform {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .toyota-cl-platform .section-divider {
    margin-bottom: 15px;
  }
  .toyota-cl-platform__after-sales {
    padding-top: 60px;
  }
}
.toyota-cl__expanding-platform {
  padding-top: 0;
  padding-bottom: 0;
}
.toyota-cl__expanding-platform img {
  display: block;
  max-width: 100%;
  height: auto;
}
.toyota-cl__expanding-platform .toyota-cl__container {
  background-color: #06121f;
  color: #fff;
  padding-bottom: 0;
}
.toyota-cl__expanding-platform__box {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .toyota-cl__expanding-platform__box {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl__expanding-platform__box {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .toyota-cl__expanding-platform__box > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .toyota-cl__expanding-platform__box > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .toyota-cl__expanding-platform__box > *:nth-child(1n) {
    grid-column: 1/span 23;
  }
}
@media (min-width: 992px) {
  .toyota-cl__expanding-platform__box > *:nth-child(1n) {
    grid-column: 1/span 23;
  }
}
@media (max-width: 767px) {
  .toyota-cl__expanding-platform__box .case-impact {
    margin-top: 104px;
    margin-bottom: 40px;
  }
}
.toyota-cl__expanding-platform__reserve, .toyota-cl__expanding-platform__racing {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .toyota-cl__expanding-platform__reserve, .toyota-cl__expanding-platform__racing {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl__expanding-platform__reserve, .toyota-cl__expanding-platform__racing {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .toyota-cl__expanding-platform__reserve > *:nth-child(1n), .toyota-cl__expanding-platform__racing > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .toyota-cl__expanding-platform__reserve > *:nth-child(1n), .toyota-cl__expanding-platform__racing > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .toyota-cl__expanding-platform__reserve > *:nth-child(2n+1), .toyota-cl__expanding-platform__racing > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .toyota-cl__expanding-platform__reserve > *:nth-child(2n), .toyota-cl__expanding-platform__racing > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .toyota-cl__expanding-platform__reserve > *:nth-child(2n+1), .toyota-cl__expanding-platform__racing > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .toyota-cl__expanding-platform__reserve > *:nth-child(2n), .toyota-cl__expanding-platform__racing > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
.toyota-cl__expanding-platform__reserve .cases-header-section, .toyota-cl__expanding-platform__racing .cases-header-section {
  padding: 0;
  padding-bottom: 16px;
}
@media (max-width: 767px) {
  .toyota-cl__expanding-platform__reserve > div, .toyota-cl__expanding-platform__racing > div {
    order: -1;
    padding-top: 44px !important;
  }
}
.toyota-cl__expanding-platform__maintenance {
  color: #fff;
  background-color: #285065;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .toyota-cl__expanding-platform__maintenance {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl__expanding-platform__maintenance {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.toyota-cl__expanding-platform__maintenance > * {
  grid-column: 1/-1;
}
.toyota-cl__expanding-platform__maintenance div {
  padding: 60px 16px 16px 16px;
}
@media (min-width: 768px) {
  .toyota-cl__expanding-platform__maintenance div {
    padding: 120px 16px 120px 16px;
  }
}
@media (min-width: 768px) {
  .toyota-cl__expanding-platform__maintenance img, .toyota-cl__expanding-platform__maintenance picture {
    grid-column: 13/-1;
  }
  .toyota-cl__expanding-platform__maintenance div {
    grid-column: 3/11;
  }
}
.toyota-cl__expanding-platform__kinto {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  color: #fff;
  background-color: #00708d;
}
@media (min-width: 768px) {
  .toyota-cl__expanding-platform__kinto {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .toyota-cl__expanding-platform__kinto {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.toyota-cl__expanding-platform__kinto-thumbs {
  display: grid;
  grid-column: 1/-1;
  max-width: 100%;
  display: block;
}
@media (max-width: 767px) {
  .toyota-cl__expanding-platform__kinto .cases-header-section {
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.supervielle-teambuilding-purple .purple-section {
  background-color: #480526;
  color: #ffffff;
  padding-top: 120px;
  padding-bottom: 120px;
  margin-top: 32px;
}
@media (max-width: 767px) {
  .supervielle-teambuilding-purple .purple-section {
    margin-top: 16px;
    padding-bottom: 24px;
    padding-top: 60px;
  }
}
.supervielle-teambuilding-purple .started {
  background-color: #2F0218;
  padding: 100px 110px 130px 110px;
  margin: 120px auto 40px auto;
  border-radius: 16px;
  max-width: 1060px;
}
.supervielle-teambuilding-purple .started .avatar-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  justify-items: center;
}
.supervielle-teambuilding-purple .started .avatar-container .started-1 {
  grid-area: 1/1/2/2;
}
.supervielle-teambuilding-purple .started .avatar-container .started-2 {
  grid-area: 1/2/2/3;
}
.supervielle-teambuilding-purple .started .avatar-container .started-3 {
  grid-area: 1/3/2/4;
}
.supervielle-teambuilding-purple .started .avatar-container .started-4 {
  grid-area: 1/4/2/5;
}
.supervielle-teambuilding-purple .started .avatar-container .started-5 {
  grid-area: 1/5/2/6;
}
.supervielle-teambuilding-purple .started .avatar-container .started-6 {
  grid-area: 1/6/2/7;
}
.supervielle-teambuilding-purple .started .avatar-container .avatar-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 767px) {
  .supervielle-teambuilding-purple .started .avatar-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
  }
  .supervielle-teambuilding-purple .started .avatar-container img {
    max-width: 100px;
    max-height: 100px;
  }
  .supervielle-teambuilding-purple .started .avatar-container .started-1 {
    grid-area: 1/1/2/2;
  }
  .supervielle-teambuilding-purple .started .avatar-container .started-2 {
    grid-area: 1/2/2/3;
  }
  .supervielle-teambuilding-purple .started .avatar-container .started-3 {
    grid-area: 1/3/2/4;
  }
  .supervielle-teambuilding-purple .started .avatar-container .started-4 {
    grid-area: 2/1/3/2;
  }
  .supervielle-teambuilding-purple .started .avatar-container .started-5 {
    grid-area: 2/2/3/3;
  }
  .supervielle-teambuilding-purple .started .avatar-container .started-6 {
    grid-area: 2/3/3/4;
  }
}
@media (max-width: 1024px) {
  .supervielle-teambuilding-purple .started {
    padding: 24px 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 60px;
    margin: 60px 16px 16px 16px;
  }
}
@media (max-width: 767px) {
  .supervielle-teambuilding-purple .started {
    padding-left: 0;
    padding-right: 0;
  }
}
.supervielle-teambuilding-purple .ended {
  background-color: #69173F;
  border-radius: 16px;
  max-width: 1060px;
  padding: 100px 110px 130px 110px;
  margin: 40px auto 0px auto;
}
@media (max-width: 1024px) {
  .supervielle-teambuilding-purple .ended {
    padding: 24px 0px;
    margin: 0px 16px 0px 16px;
  }
}
.supervielle-teambuilding-purple .ended .big-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 80px;
  place-content: center;
  text-align: center;
}
.supervielle-teambuilding-purple .ended .big-container .part-one {
  grid-area: 1/1/2/4;
}
.supervielle-teambuilding-purple .ended .big-container .part-two {
  grid-area: 1/4/2/7;
}
.supervielle-teambuilding-purple .ended .big-container .part-three {
  grid-area: 2/1/3/4;
}
.supervielle-teambuilding-purple .ended .big-container .part-four {
  grid-area: 2/4/3/5;
}
.supervielle-teambuilding-purple .ended .big-container .part-five {
  grid-area: 2/5/3/7;
}
.supervielle-teambuilding-purple .ended .big-container .part-six {
  grid-area: 3/1/4/7;
}
.supervielle-teambuilding-purple .ended .big-container .part-seven {
  grid-area: 4/1/5/7;
}
@media (max-width: 767px) {
  .supervielle-teambuilding-purple .ended .big-container {
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, max-content);
    place-content: center;
  }
  .supervielle-teambuilding-purple .ended .big-container .avatar-container {
    justify-content: space-around;
  }
  .supervielle-teambuilding-purple .ended .big-container .avatar-container img {
    width: max-100px;
    height: max-100px;
  }
  .supervielle-teambuilding-purple .ended .big-container .avatar-container .avatar-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-one {
    grid-area: 1/1/2/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-two {
    grid-area: 2/1/3/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-three {
    grid-area: 4/1/5/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-four {
    grid-area: 3/1/4/2;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-five {
    grid-area: 3/2/4/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-six {
    grid-area: 5/1/7/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-six .avatar-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .supervielle-teambuilding-purple .ended .big-container .part-six .avatar-container .ended-1 {
    grid-area: 1/1/2/2;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-six .avatar-container .ended-2 {
    grid-area: 1/2/2/3;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-six .avatar-container .ended-3 {
    grid-area: 1/3/2/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-six .avatar-container .ended-4 {
    grid-area: 2/1/3/2;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-six .avatar-container .ended-5 {
    grid-area: 2/2/3/3;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-six .avatar-container .ended-6 {
    grid-area: 2/3/3/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-seven {
    grid-area: 7/1/9/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-seven .avatar-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .supervielle-teambuilding-purple .ended .big-container .part-seven .avatar-container .ended-7 {
    grid-area: 1/1/2/2;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-seven .avatar-container .ended-8 {
    grid-area: 1/2/2/3;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-seven .avatar-container .ended-9 {
    grid-area: 1/3/2/4;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-seven .avatar-container .ended-10 {
    grid-area: 2/1/3/2;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-seven .avatar-container .ended-11 {
    grid-area: 2/2/3/3;
  }
  .supervielle-teambuilding-purple .ended .big-container .part-seven .avatar-container .ended-12 {
    grid-area: 2/3/3/4;
  }
}
.supervielle-teambuilding-purple .title {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
}
@media (max-width: 1024px) {
  .supervielle-teambuilding-purple .title {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.02em;
    margin-left: 16px;
    margin-bottom: 16px;
  }
}
.supervielle-teambuilding-purple .subtitle {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-bottom: 16px;
  text-align: left;
}
@media (max-width: 1024px) {
  .supervielle-teambuilding-purple .subtitle {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0.02em;
    margin-left: 16px;
  }
}
.supervielle-teambuilding-purple hr {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0;
  margin-bottom: 30px;
}
.supervielle-teambuilding-purple .avatar-container {
  display: flex;
}
.supervielle-teambuilding-purple .avatar-container img {
  max-width: 110px;
  max-height: 110px;
}
@media (max-width: 1024px) {
  .supervielle-teambuilding-purple .avatar-container img {
    width: 70px;
    height: 70px;
  }
}
.supervielle-teambuilding-purple .avatar-container .avatar-tag {
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 8px;
  font-size: 0.5rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.02em;
  width: max-content;
  height: min-content;
  padding: 4px 8px;
  border-radius: 10px;
  display: inline-block;
  margin-top: 0;
}
@media (max-width: 767px) {
  .supervielle-teambuilding-purple .avatar-container .avatar-tag {
    padding: 4px 6px;
  }
}
@media (max-width: 768px) {
  .supervielle-teambuilding-purple {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  .supervielle-teambuilding-purple {
    padding-bottom: 40px;
  }
}
.supervielle-teambuilding-white {
  text-align: center;
  margin-top: -120px;
}
.supervielle-teambuilding-white img {
  width: auto;
  height: 178px;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .supervielle-teambuilding-white {
    margin-top: 0;
    padding-bottom: 120px;
  }
  .supervielle-teambuilding-white img {
    height: 142.4px;
    margin-bottom: 24px;
  }
}
.supervielle-evolving-yellow .yellow-section {
  background-color: #FDB239;
  margin-top: 32px;
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .supervielle-evolving-yellow .yellow-section {
    margin-top: 16px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.supervielle-evolving-yellow .yellow-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  gap: 40px;
}
.supervielle-evolving-yellow .yellow-grid div {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  grid-template-rows: 1fr 0.4fr;
  grid-area: 1/1/2/3;
  background-color: #FDB239;
  position: relative;
  padding-bottom: 90px;
}
@media (min-width: 768px) {
  .supervielle-evolving-yellow .yellow-grid div {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .supervielle-evolving-yellow .yellow-grid div {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.supervielle-evolving-yellow .yellow-grid div img, .supervielle-evolving-yellow .yellow-grid div picture {
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/span 2;
}
@media (max-width: 767px) {
  .supervielle-evolving-yellow .yellow-grid div img, .supervielle-evolving-yellow .yellow-grid div picture {
    display: none;
  }
}
.supervielle-evolving-yellow .yellow-grid div p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
  grid-column: 10/span 12;
  grid-row: 2;
  align-self: center;
  margin: 0;
}
@media (max-width: 1124px) {
  .supervielle-evolving-yellow .yellow-grid div p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 900px) {
  .supervielle-evolving-yellow .yellow-grid div p {
    position: absolute;
    bottom: 0px;
  }
}
@media (max-width: 767px) {
  .supervielle-evolving-yellow .yellow-grid div p {
    grid-column: 1/-1;
    align-self: center;
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    background-color: #FF9F04;
    position: inherit;
  }
}
.supervielle-evolving-yellow .yellow-grid .img1 {
  grid-area: 2/1/3/2;
  width: 100%;
  object-fit: cover;
}
.supervielle-evolving-yellow .yellow-grid .img2 {
  grid-area: 2/2/3/3;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 1024px) {
  .supervielle-evolving-yellow .yellow-grid {
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .supervielle-evolving-yellow .yellow-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }
  .supervielle-evolving-yellow .yellow-grid .img1 {
    grid-area: 2/1/3/2;
    height: 100%;
  }
  .supervielle-evolving-yellow .yellow-grid .img2 {
    grid-area: 1/1/2/2;
    height: 100%;
  }
  .supervielle-evolving-yellow .yellow-grid div {
    grid-area: 3/1/4/2;
    grid-template-rows: repeat(3, auto);
    background-color: #FF9F04;
    margin-top: -20px;
    height: max-content;
    position: inherit;
    padding-top: 44px;
    padding-bottom: 44px;
  }
}
@media (max-width: 1024px) {
  .supervielle-evolving-yellow {
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .supervielle-evolving-yellow {
    padding-bottom: 40px;
  }
}
.supervielle-evolving-gray {
  margin-top: -120px;
  padding-bottom: 0px;
}
.supervielle-evolving-gray .gray-box {
  background-color: #E7EEED;
}
.supervielle-evolving-gray .gray-box .gray-text {
  padding: 60px 60px 0px 60px;
}
.supervielle-evolving-gray .gray-box .gray-text .gray-text-title {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.3846153846;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}
@media (max-width: 1024px) {
  .supervielle-evolving-gray .gray-box .gray-text .gray-text-title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.4166666667;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  .supervielle-evolving-gray .gray-box .gray-text .gray-text-title {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.02em;
  }
}
.supervielle-evolving-gray .gray-box .gray-text .gray-text-description {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  margin: 0;
}
@media (max-width: 1024px) {
  .supervielle-evolving-gray .gray-box .gray-text .gray-text-description {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .supervielle-evolving-gray .gray-box .gray-text .gray-text-description {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}
.supervielle-evolving-gray-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 40px;
}
.supervielle-evolving-gray-grid .gray-box1 {
  grid-area: 1/1/3/2;
}
.supervielle-evolving-gray-grid .gray-box2 {
  grid-area: 1/2/2/3;
  background-color: transparent;
}
.supervielle-evolving-gray-grid .gray-box2 img {
  width: 100%;
  height: 100%;
}
.supervielle-evolving-gray-grid .gray-box3 {
  grid-area: 2/2/4/3;
}
.supervielle-evolving-gray-grid .gray-box4 {
  grid-area: 3/1/5/2;
}
.supervielle-evolving-gray-grid .gray-box5 {
  grid-area: 4/2/5/3;
}
.supervielle-evolving-gray-grid .gray-box5 img {
  width: 100%;
  height: 100%;
}
.supervielle-evolving-gray-grid .gray-box img {
  object-fit: cover;
  width: 100%;
}
@media (max-width: 1024px) {
  .supervielle-evolving-gray-grid {
    grid-gap: 20px;
  }
}
@media (max-width: 767px) {
  .supervielle-evolving-gray-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, max-content);
  }
  .supervielle-evolving-gray-grid .gray-box1 {
    grid-area: 1/1/2/2;
  }
  .supervielle-evolving-gray-grid .gray-box2 {
    grid-area: 2/1/3/2;
  }
  .supervielle-evolving-gray-grid .gray-box3 {
    grid-area: 3/1/4/2;
  }
  .supervielle-evolving-gray-grid .gray-box4 {
    grid-area: 4/1/5/2;
  }
  .supervielle-evolving-gray-grid .gray-box5 {
    grid-area: 5/1/6/2;
  }
  .supervielle-evolving-gray-grid .gray-box .gray-text {
    padding: 32px 32px 0px 32px;
  }
}
.supervielle-evolving-gray-emojis {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  column-gap: 40px;
  margin-top: 40px;
}
.supervielle-evolving-gray-emojis .gray-box6 {
  grid-area: 1/1/2/2;
}
.supervielle-evolving-gray-emojis .gray-box7 {
  grid-area: 1/2/2/3;
}
.supervielle-evolving-gray-emojis .gray-box8 {
  grid-area: 1/3/2/4;
}
.supervielle-evolving-gray-emojis .gray-box8 .gray-text {
  padding-right: 57px;
}
.supervielle-evolving-gray-emojis .gray-box {
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.supervielle-evolving-gray-emojis .gray-box img {
  width: 120px;
  height: 120px;
  margin-top: 60px;
}
@media (max-width: 1024px) {
  .supervielle-evolving-gray-emojis {
    column-gap: 20px;
    margin-top: 20px;
  }
  .supervielle-evolving-gray-emojis .gray-box .gray-text {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 767px) {
  .supervielle-evolving-gray-emojis {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 16px;
  }
  .supervielle-evolving-gray-emojis .gray-box6 {
    grid-area: 1/1/2/2;
  }
  .supervielle-evolving-gray-emojis .gray-box7 {
    grid-area: 2/1/3/2;
  }
  .supervielle-evolving-gray-emojis .gray-box8 {
    grid-area: 3/1/4/2;
  }
  .supervielle-evolving-gray-emojis .gray-box {
    flex-direction: row;
    padding-bottom: 32px;
    padding-right: 32px;
  }
  .supervielle-evolving-gray-emojis .gray-box img {
    width: 60px;
    height: 60px;
  }
  .supervielle-evolving-gray-emojis .gray-box .gray-text {
    padding-top: 32px;
  }
}
@media (max-width: 767px) {
  .supervielle-evolving-gray {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
}
.supervielle-evolving-quote {
  padding-bottom: 360px;
}
.supervielle-evolving-quote .case-quote__box .big-list-title, .supervielle-evolving-quote .case-quote__box .case-quote__author {
  grid-column: 1/19;
}
@media (max-width: 767px) {
  .supervielle-evolving-quote {
    padding-bottom: 120px;
  }
}
.supervielle-simple {
  background-color: #1D1D1D;
  color: #ffffff;
}
.supervielle-simple .section-divider {
  border-color: #ffffff;
}
.supervielle-simple .simple-content {
  margin-top: 150px;
}
.supervielle-simple .simple-content .simple-black-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  row-gap: 40px;
}
@media (min-width: 768px) {
  .supervielle-simple .simple-content .simple-black-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .supervielle-simple .simple-content .simple-black-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.supervielle-simple .simple-content .simple-black-grid picture, .supervielle-simple .simple-content .simple-black-grid img {
  width: 100%;
}
.supervielle-simple .simple-content .simple-black-grid .box {
  grid-column: 1/-1;
  grid-row: 1;
  margin-top: 120px;
}
.supervielle-simple .simple-content .simple-black-grid .simple-paragraph {
  grid-column: 5/span 15;
  grid-row: 2;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  margin: 0;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
}
@media (max-width: 1024px) {
  .supervielle-simple .simple-content .simple-black-grid .simple-paragraph {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.8888888889;
    letter-spacing: 0em;
  }
}
@media (max-width: 767px) {
  .supervielle-simple .simple-content .simple-black-grid .simple-paragraph {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}
.supervielle-simple .simple-content .simple-black-grid .box1 {
  grid-column: 1/span 11;
  grid-row: 3/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-simple .simple-content .simple-black-grid .box2 {
  grid-column: 13/span 11;
  grid-row: 3;
  height: 100%;
  object-fit: cover;
}
.supervielle-simple .simple-content .simple-black-grid .box3 {
  grid-column: 13/span 11;
  grid-row: 4;
  height: 100%;
  object-fit: cover;
}
.supervielle-simple .simple-content .simple-black-grid .box4 {
  grid-column: 1/-1;
  grid-row: 5;
  height: 100%;
  object-fit: cover;
}
.supervielle-simple .simple-content .simple-black-grid .box5 {
  grid-column: 1/-1;
  grid-row: 6;
  height: 100%;
  object-fit: cover;
}
.supervielle-simple .simple-content .simple-black-grid .box6 {
  grid-column: 1/span 11;
  grid-row: 7;
  height: 100%;
  object-fit: cover;
}
.supervielle-simple .simple-content .simple-black-grid .box7 {
  grid-column: 13/span 11;
  grid-row: 7;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1024px) {
  .supervielle-simple .simple-content .simple-black-grid {
    row-gap: 20px;
  }
}
@media (max-width: 767px) {
  .supervielle-simple .simple-content .simple-black-grid {
    row-gap: 17px;
  }
  .supervielle-simple .simple-content .simple-black-grid .box {
    grid-column: 1/-1;
    grid-row: 1;
    margin-top: 60px;
  }
  .supervielle-simple .simple-content .simple-black-grid .simple-paragraph {
    grid-column: 1/-1;
    grid-row: 2;
    padding-top: 44px;
    padding-bottom: 44px;
  }
  .supervielle-simple .simple-content .simple-black-grid .box1 {
    grid-column: 1/-1;
    grid-row: 3;
  }
  .supervielle-simple .simple-content .simple-black-grid .box2 {
    grid-column: 1/-1;
    grid-row: 4;
  }
  .supervielle-simple .simple-content .simple-black-grid .box3 {
    grid-column: 1/-1;
    grid-row: 5;
  }
  .supervielle-simple .simple-content .simple-black-grid .box4 {
    grid-column: 1/-1;
    grid-row: 6;
  }
  .supervielle-simple .simple-content .simple-black-grid .box5 {
    grid-column: 1/-1;
    grid-row: 7;
  }
  .supervielle-simple .simple-content .simple-black-grid .box6 {
    grid-column: 1/span 3;
    grid-row: 8;
  }
  .supervielle-simple .simple-content .simple-black-grid .box7 {
    grid-column: 5/span 3;
    grid-row: 8;
  }
}
@media (max-width: 767px) {
  .supervielle-simple {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .supervielle-simple .simple-content {
    margin-top: 60px;
  }
}
.supervielle-mobile-app {
  padding-bottom: 0px;
}
.supervielle-mobile-app .mobile-app-content {
  margin-top: 150px;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 120px;
  row-gap: 40px;
}
@media (min-width: 768px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid img {
  width: 100%;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box1 {
  grid-column: 1/span 11;
  grid-row: 1/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box2 {
  grid-column: 13/span 11;
  grid-row: 1;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box3 {
  grid-column: 13/span 11;
  grid-row: 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box4 {
  grid-column: 1/span 11;
  grid-row: 3/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box5 {
  grid-column: 13/span 11;
  grid-row: 3/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box6 {
  grid-column: 1/span 11;
  grid-row: 5;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box7 {
  grid-column: 1/span 11;
  grid-row: 6;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box8 {
  grid-column: 13/span 11;
  grid-row: 5/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box-text {
  grid-column: 3/span 19;
  grid-row: 7;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box-text p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
}
@media (max-width: 1024px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box-text p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 575px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box-text p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box-text img {
  width: 184px;
  height: 178px;
}
@media (max-width: 575px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box-text img {
    width: 147px;
    height: 142px;
  }
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box9 {
  grid-column: 1/span 11;
  grid-row: 8/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box9b {
  grid-column: 13/span 11;
  grid-row: 8/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box10 {
  grid-column: 1/-1;
  grid-row: 10/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box11 {
  grid-column: 1/span 11;
  grid-row: 12/span 2;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box12 {
  grid-column: 13/span 11;
  grid-row: 12;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box13 {
  grid-column: 13/span 11;
  grid-row: 13;
  height: 100%;
  object-fit: cover;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box14 {
  grid-column: 13/span 11;
  grid-row: 14/span 2;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-grid .box15 {
  grid-column: 1/span 11;
  grid-row: 14/span 2;
}
@media (max-width: 1024px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid {
    row-gap: 20px;
  }
}
@media (max-width: 767px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid {
    row-gap: 17px;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box1 {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box2 {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box3 {
    grid-column: 1/-1;
    grid-row: 3;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box4 {
    grid-column: 1/-1;
    grid-row: 4;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box5 {
    grid-column: 1/-1;
    grid-row: 5;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box8 {
    grid-column: 1/-1;
    grid-row: 6;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box6 {
    grid-column: 1/-1;
    grid-row: 7;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box7 {
    grid-column: 1/-1;
    grid-row: 8;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box-text {
    grid-column: 1/-1;
    grid-row: 9;
    padding-top: 103px;
    padding-bottom: 103px;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box-text p {
    margin-bottom: 0;
    margin-top: 24px;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box9 {
    grid-column: 1/-1;
    grid-row: 10;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box9b {
    grid-column: 1/-1;
    grid-row: 11;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box10 {
    grid-column: 1/-1;
    grid-row: 12;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box11 {
    grid-column: 1/-1;
    grid-row: 13;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box12 {
    grid-column: 1/-1;
    grid-row: 14;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box13 {
    grid-column: 1/-1;
    grid-row: 15;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box14 {
    grid-column: 1/-1;
    grid-row: 16;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid .box15 {
    grid-column: 1/-1;
    grid-row: 17;
  }
}
.supervielle-mobile-app .mobile-app-content .mobile-app-quote {
  margin-top: 240px;
  padding-bottom: 0px;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  row-gap: 80px;
}
@media (min-width: 768px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-quote {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-quote {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.supervielle-mobile-app .mobile-app-content .mobile-app-quote h4 {
  grid-column: 1/span 19;
}
.supervielle-mobile-app .mobile-app-content .mobile-app-quote img {
  grid-column: 1/span 5;
  grid-row: 2;
  width: 299px;
}
@media (max-width: 767px) {
  .supervielle-mobile-app .mobile-app-content .mobile-app-quote {
    row-gap: 32px;
    margin-top: 120px;
    padding-bottom: 40px;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-quote img {
    width: 198px;
  }
}
@media (max-width: 767px) {
  .supervielle-mobile-app {
    padding-top: 120px;
    padding-bottom: 40px;
  }
  .supervielle-mobile-app .mobile-app-content {
    margin-top: 75px;
  }
  .supervielle-mobile-app .mobile-app-content .mobile-app-grid {
    margin-top: 60px;
  }
}
.supervielle-mobile-app-quote {
  padding-bottom: 120px;
}
.supervielle-mobile-app-quote .case-quote__box .big-list-title, .supervielle-mobile-app-quote .case-quote__box .case-quote__author {
  grid-column: 1/19;
}
.supervielle-transition .transition-content {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  background-color: #F1F1F1;
  margin-top: 32px;
  padding-top: 120px;
  padding-bottom: 146px;
}
@media (min-width: 768px) {
  .supervielle-transition .transition-content {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .supervielle-transition .transition-content {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.supervielle-transition .transition-content .transition-box {
  grid-column: 5/span 15;
}
.supervielle-transition .transition-content .transition-box img {
  width: 222px;
  height: 178px;
}
.supervielle-transition .transition-content .transition-box h3 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.supervielle-transition .transition-content .transition-box .transition-paragraph, .supervielle-transition .transition-content .transition-box .transition-values {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  margin: 0;
}
@media (max-width: 1024px) {
  .supervielle-transition .transition-content .transition-box .transition-paragraph, .supervielle-transition .transition-content .transition-box .transition-values {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 767px) {
  .supervielle-transition .transition-content .transition-box .transition-paragraph, .supervielle-transition .transition-content .transition-box .transition-values {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}
.supervielle-transition .transition-content .transition-box .transition-values {
  margin-top: 120px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .supervielle-transition .transition-content .transition-box .transition-values {
    margin-top: 60px;
    margin-bottom: 32px;
  }
}
.supervielle-transition .transition-content .transition-box .transition-list {
  margin-bottom: 20px;
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: -0.02em;
}
@media (max-width: 1024px) {
  .supervielle-transition .transition-content .transition-box .transition-list {
    font-size: 34px;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.4705882353;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 575px) {
  .supervielle-transition .transition-content .transition-box .transition-list {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.02em;
    margin-bottom: 0;
  }
}
@media (max-width: 1024px) {
  .supervielle-transition .transition-content {
    padding-bottom: 120px;
  }
  .supervielle-transition .transition-content .transition-box img {
    width: 137px;
    height: 110px;
  }
}
@media (max-width: 768px) {
  .supervielle-transition .transition-content {
    margin-top: 16px;
    padding: 60px 40px 60px 40px;
  }
  .supervielle-transition .transition-content .transition-box {
    grid-column: 1/-1;
  }
}
@media (max-width: 767px) {
  .supervielle-transition {
    padding-top: 0;
  }
}
.supervielle-center-text, .supervielle-center-text-small {
  text-align: center;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  row-gap: 0px !important;
}
@media (min-width: 768px) {
  .supervielle-center-text, .supervielle-center-text-small {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .supervielle-center-text, .supervielle-center-text-small {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.supervielle-center-text h3, .supervielle-center-text-small h3 {
  margin-bottom: 32px;
  grid-column: 5/span 15;
}
@media (max-width: 1024px) {
  .supervielle-center-text h3, .supervielle-center-text-small h3 {
    grid-column: 3/span 19;
  }
}
@media (max-width: 768px) {
  .supervielle-center-text h3, .supervielle-center-text-small h3 {
    grid-column: 1/-1;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 767px) {
  .supervielle-center-text h3, .supervielle-center-text-small h3 {
    padding: 0;
  }
}
.supervielle-center-text p, .supervielle-center-text-small p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  margin: 0;
  grid-column: 5/span 15;
}
@media (max-width: 1024px) {
  .supervielle-center-text p, .supervielle-center-text-small p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
    grid-column: 3/span 19;
  }
}
@media (max-width: 768px) {
  .supervielle-center-text p, .supervielle-center-text-small p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
    grid-column: 1/-1;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 767px) {
  .supervielle-center-text p, .supervielle-center-text-small p {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.supervielle-center-text-small h3 {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
}
@media (max-width: 1024px) {
  .supervielle-center-text-small h3 {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .supervielle-center-text-small h3 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .supervielle-text-align {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .supervielle-img img {
    height: auto;
  }
}
.supervielle-img .case-challenge__img {
  height: auto;
}
.supervielle-width {
  max-width: 490px;
}
@media (max-width: 1200px) {
  .supervielle-width {
    width: 290px;
  }
}
@media (max-width: 767px) {
  .supervielle-width {
    letter-spacing: -0.02rem;
    width: 100%;
  }
}
.supervielle-padding-bottom {
  padding-bottom: 0px;
}
@media (max-width: 767px) {
  .supervielle-mobile-without-padding h3, .supervielle-mobile-without-padding p {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 575px) {
  .telecom-img {
    padding-left: 0;
    padding-right: 0;
  }
  .telecom-img img {
    object-fit: contain;
    height: auto;
  }
  .telecom-img .case-challenge__img {
    height: auto;
  }
}
.telecom-roadmap-white-content {
  margin-top: 150px;
}
@media (max-width: 767px) {
  .telecom-roadmap-white-content {
    margin-top: 60px;
  }
}
.telecom-roadmap-white-img {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 120px;
}
@media (min-width: 768px) {
  .telecom-roadmap-white-img {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-roadmap-white-img {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.telecom-roadmap-white-img img {
  grid-column: 5/span 15;
  width: 100%;
}
@media (max-width: 767px) {
  .telecom-roadmap-white-img {
    margin-top: 60px;
  }
  .telecom-roadmap-white-img img {
    grid-column: 1/-1;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-white {
    padding-bottom: 120px;
  }
}
.telecom-roadmap-black {
  background-color: #000000;
  color: #FFFFFF;
}
.telecom-roadmap-black-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.telecom-roadmap-black-content .telecom-roadmap-black-icon {
  width: 108px;
  height: 98px;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .telecom-roadmap-black-content .telecom-roadmap-black-icon {
    width: 66px;
    height: 60px;
    margin-bottom: 16px;
  }
}
.telecom-roadmap-black-content .telecom-roadmap-black-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  row-gap: 40px;
  margin-top: 120px;
}
@media (min-width: 768px) {
  .telecom-roadmap-black-content .telecom-roadmap-black-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-roadmap-black-content .telecom-roadmap-black-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.telecom-roadmap-black-content .telecom-roadmap-black-grid img {
  width: 100%;
}
.telecom-roadmap-black-content .telecom-roadmap-black-grid .img1 {
  grid-column: 1/span 11;
  grid-row: 1;
}
.telecom-roadmap-black-content .telecom-roadmap-black-grid .img2 {
  grid-column: 13/span 11;
  grid-row: 1;
}
.telecom-roadmap-black-content .telecom-roadmap-black-grid .img3 {
  grid-row: 2;
  grid-column: 1/span 5;
}
.telecom-roadmap-black-content .telecom-roadmap-black-grid .img4 {
  grid-row: 2;
  grid-column: 7/span 5;
}
.telecom-roadmap-black-content .telecom-roadmap-black-grid .img5 {
  grid-row: 2;
  grid-column: 13/span 5;
}
.telecom-roadmap-black-content .telecom-roadmap-black-grid .img6 {
  grid-row: 2;
  grid-column: 19/span 5;
}
@media (max-width: 767px) {
  .telecom-roadmap-black-content .telecom-roadmap-black-grid {
    row-gap: 20px;
    margin-top: 60px;
  }
  .telecom-roadmap-black-content .telecom-roadmap-black-grid .img1 {
    grid-column: 1/-1;
    grid-row: 1;
    margin-bottom: -10px;
  }
  .telecom-roadmap-black-content .telecom-roadmap-black-grid .img2 {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .telecom-roadmap-black-content .telecom-roadmap-black-grid .img3 {
    grid-row: 3;
    grid-column: 1/span 3;
  }
  .telecom-roadmap-black-content .telecom-roadmap-black-grid .img4 {
    grid-row: 3;
    grid-column: 5/span 3;
  }
  .telecom-roadmap-black-content .telecom-roadmap-black-grid .img5 {
    grid-row: 4;
    grid-column: 1/span 3;
  }
  .telecom-roadmap-black-content .telecom-roadmap-black-grid .img6 {
    grid-row: 4;
    grid-column: 5/span 3;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-black {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.telecom-roadmap-green-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.telecom-roadmap-green-content .telecom-roadmap-green-icon {
  width: 108px;
  height: 98px;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .telecom-roadmap-green-content .telecom-roadmap-green-icon {
    width: 66px;
    height: 60px;
    margin-bottom: 16px;
  }
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 120px;
}
@media (min-width: 768px) {
  .telecom-roadmap-green-content .telecom-roadmap-green-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-roadmap-green-content .telecom-roadmap-green-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid picture, .telecom-roadmap-green-content .telecom-roadmap-green-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid .img1 {
  grid-column: 1/span 11;
  grid-row: 1/span 2;
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid .img2 {
  grid-column: 13/span 11;
  grid-row: 1;
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid .img3 {
  grid-row: 2;
  grid-column: 13/span 5;
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid .img4 {
  grid-row: 2;
  grid-column: 19/span 5;
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid .img5 {
  grid-row: 3/span 2;
  grid-column: 1/span 11;
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid .img6 {
  grid-row: 3;
  grid-column: 13/span 11;
}
.telecom-roadmap-green-content .telecom-roadmap-green-grid .img7 {
  grid-row: 4;
  grid-column: 13/span 11;
}
@media (max-width: 767px) {
  .telecom-roadmap-green-content .telecom-roadmap-green-grid {
    margin-top: 60px;
  }
  .telecom-roadmap-green-content .telecom-roadmap-green-grid .img1 {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .telecom-roadmap-green-content .telecom-roadmap-green-grid .img2 {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .telecom-roadmap-green-content .telecom-roadmap-green-grid .img3 {
    grid-row: 3;
    grid-column: 1/span 3;
  }
  .telecom-roadmap-green-content .telecom-roadmap-green-grid .img4 {
    grid-row: 3;
    grid-column: 5/span 3;
  }
  .telecom-roadmap-green-content .telecom-roadmap-green-grid .img5 {
    grid-row: 6;
    grid-column: 1/-1;
  }
  .telecom-roadmap-green-content .telecom-roadmap-green-grid .img6 {
    grid-row: 4;
    grid-column: 1/-1;
  }
  .telecom-roadmap-green-content .telecom-roadmap-green-grid .img7 {
    grid-row: 5;
    grid-column: 1/-1;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-green {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.telecom-roadmap-emojis img {
  width: 60px;
  height: 60px;
}
.telecom-roadmap-emojis p {
  margin: 0;
}
.telecom-roadmap-emojis .container1, .telecom-roadmap-emojis .container2, .telecom-roadmap-emojis .container3, .telecom-roadmap-emojis .container4 {
  border-left: 4px solid #000000;
  border-right: 4px solid #000000;
  padding-left: 40px;
  padding-right: 40px;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .telecom-roadmap-emojis .container1, .telecom-roadmap-emojis .container2, .telecom-roadmap-emojis .container3, .telecom-roadmap-emojis .container4 {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-roadmap-emojis .container1, .telecom-roadmap-emojis .container2, .telecom-roadmap-emojis .container3, .telecom-roadmap-emojis .container4 {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container1, .telecom-roadmap-emojis .container2, .telecom-roadmap-emojis .container3, .telecom-roadmap-emojis .container4 {
    padding-left: 16px;
    padding-right: 16px;
    border-left: 2px solid #000000;
    border-right: 2px solid #000000;
    row-gap: 0px;
  }
}
.telecom-roadmap-emojis .container1 {
  border-top: 4px solid #000000;
  border-bottom: 4px solid #000000;
  padding-top: 65.5px;
  padding-bottom: 69px;
}
.telecom-roadmap-emojis .container1 .big-text {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  grid-column: 13/-1;
}
@media (max-width: 1024px) {
  .telecom-roadmap-emojis .container1 .big-text {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container1 .big-text {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
    grid-column: 1/-1;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container1 {
    padding-top: 32px;
    padding-bottom: 36px;
    border-bottom: 2px solid #000000;
    border-top: 2px solid #000000;
    border-left: 2px solid #000000;
    border-right: 2px solid #000000;
  }
  .telecom-roadmap-emojis .container1 .big-title {
    margin-bottom: 16px;
  }
}
.telecom-roadmap-emojis .container2 {
  margin-top: -45px;
  padding-bottom: 40px;
  border-bottom: 2px solid #000000;
}
.telecom-roadmap-emojis .container2 .img1 {
  grid-column: 1/-1;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}
.telecom-roadmap-emojis .container2 .img2 {
  grid-column: 1/-1;
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container2 {
    overflow: scroll;
    margin-top: 0;
    padding-bottom: 32px;
    padding-top: 32px;
  }
  .telecom-roadmap-emojis .container2 .img1 {
    height: 290px;
    width: auto;
  }
  .telecom-roadmap-emojis .container2 .img2 {
    height: 110px;
    width: auto;
  }
}
.telecom-roadmap-emojis .container3 {
  border-top: 2px solid #000000;
  padding-top: 60px;
  align-items: start;
}
.telecom-roadmap-emojis .container3 .emoji-box {
  border-radius: 32px;
  margin-bottom: 40px;
  padding: 40px 40px 8px 40px;
}
.telecom-roadmap-emojis .container3 .emoji-title-img {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .telecom-roadmap-emojis .container3 .emoji-title-img {
    flex-direction: column-reverse;
    margin-bottom: 16px;
  }
  .telecom-roadmap-emojis .container3 .emoji-title-img p {
    margin-bottom: 0;
    margin-top: 8px;
  }
}
.telecom-roadmap-emojis .container3 .column1 {
  grid-column: 1/span 7;
}
.telecom-roadmap-emojis .container3 .column1 .emoji-box1 {
  border: 4px solid #45F803;
  box-shadow: 0px 16px 0px #45F803;
}
.telecom-roadmap-emojis .container3 .column1 .emoji-box2 {
  border: 4px solid #F4D201;
  box-shadow: 0px 16px 0px #F4D201;
}
.telecom-roadmap-emojis .container3 .column1 .emoji-box3 {
  border: 4px solid #95F600;
  box-shadow: 0px 16px 0px #95F600;
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container3 .column1 {
    grid-column: 1/-1;
  }
}
.telecom-roadmap-emojis .container3 .column2 {
  grid-column: 9/span 7;
  padding-top: 80px;
}
.telecom-roadmap-emojis .container3 .column2 .emoji-box4 {
  border: 4px solid #DBFE02;
  box-shadow: 0px 16px 0px #DBFE02;
}
.telecom-roadmap-emojis .container3 .column2 .emoji-box5 {
  border: 4px solid #FF0402;
  box-shadow: 0px 16px 0px #FF0402;
}
.telecom-roadmap-emojis .container3 .column2 .emoji-box6 {
  border: 4px solid #63FB02;
  box-shadow: 0px 16px 0px #63FB02;
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container3 .column2 {
    grid-column: 1/-1;
    padding-top: 0px;
  }
}
.telecom-roadmap-emojis .container3 .column3 {
  grid-column: 17/span 7;
  padding-top: 160px;
}
.telecom-roadmap-emojis .container3 .column3 .emoji-box7 {
  border: 4px solid #F5E901;
  box-shadow: 0px 16px 0px #F5E901;
}
.telecom-roadmap-emojis .container3 .column3 .emoji-box8 {
  border: 4px solid #F6A801;
  box-shadow: 0px 16px 0px #F6A801;
}
.telecom-roadmap-emojis .container3 .column3 .emoji-box9 {
  border: 4px solid #FF0402;
  box-shadow: 0px 16px 0px #FF0402;
  background-color: #FFFFFF;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container3 .column3 {
    grid-column: 1/-1;
    padding-top: 0px;
  }
  .telecom-roadmap-emojis .container3 .column3 .emoji-box9 {
    margin-bottom: 45px;
  }
}
@media (max-width: 1024px) {
  .telecom-roadmap-emojis .container3 .emoji-box {
    padding: 32px 24px;
  }
}
@media (max-width: 768px) {
  .telecom-roadmap-emojis .container3 .emoji-box {
    padding: 20px 10px 9px 10px;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container3 {
    border-top: none;
    padding-top: 40px;
  }
  .telecom-roadmap-emojis .container3 .emoji-box {
    padding: 24px;
    padding-bottom: 8px;
    margin-bottom: 32px;
  }
  .telecom-roadmap-emojis .container3 .big-title {
    margin-bottom: 32px;
  }
}
.telecom-roadmap-emojis .container4 {
  border-top: 4px solid #000000;
  border-bottom: 4px solid #000000;
  margin-top: -50px;
  padding: 60px 40px;
  align-items: start;
  background-color: #F1F1F1;
}
.telecom-roadmap-emojis .container4 .emoji-box {
  border: 4px solid #000000;
  box-shadow: 0px 16px 0px #000000;
  border-radius: 32px;
  grid-row: 2;
  padding: 40px 39px 8px 39px;
  background-color: #ffffff;
}
.telecom-roadmap-emojis .container4 .emoji-box .emoji-tiny-title {
  margin-bottom: 16px;
  margin-top: 16px;
}
.telecom-roadmap-emojis .container4 .emoji-box1 {
  grid-column: 1/span 7;
}
.telecom-roadmap-emojis .container4 .emoji-box2 {
  grid-column: 9/span 7;
}
.telecom-roadmap-emojis .container4 .emoji-box3 {
  grid-column: 17/span 7;
}
@media (max-width: 768px) {
  .telecom-roadmap-emojis .container4 .emoji-box1, .telecom-roadmap-emojis .container4 .emoji-box2, .telecom-roadmap-emojis .container4 .emoji-box3 {
    padding: 20px 10px 9px 10px;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .container4 {
    margin-top: 0px;
    padding: 32px 16px 45px 16px;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
  }
  .telecom-roadmap-emojis .container4 .emoji-box1, .telecom-roadmap-emojis .container4 .emoji-box2, .telecom-roadmap-emojis .container4 .emoji-box3 {
    grid-column: 1/-1;
    grid-row: auto;
    padding: 24px;
    margin-bottom: 32px;
  }
  .telecom-roadmap-emojis .container4 .big-title {
    margin-bottom: 24px;
  }
}
.telecom-roadmap-emojis .big-title {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
  grid-column: 1/span 11;
  grid-row: 1;
  align-self: start;
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .telecom-roadmap-emojis .big-title {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .big-title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
.telecom-roadmap-emojis .emoji-title {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 2.3076923077;
  letter-spacing: 0em;
  font-style: italic;
}
@media (max-width: 1024px) {
  .telecom-roadmap-emojis .emoji-title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0em;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .emoji-title {
    line-height: 32px;
  }
}
.telecom-roadmap-emojis .emoji-tiny-title {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.03em;
  margin-bottom: 8px;
}
.telecom-roadmap-emojis .emoji-tiny-paragraph {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5714285714;
  letter-spacing: 0em;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis .emoji-tiny-paragraph {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0em;
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-emojis {
    padding-top: 40px;
  }
}
.telecom-roadmap-testing {
  margin-top: -120px;
}
.telecom-roadmap-testing img {
  width: 100%;
  margin-top: 120px;
}
@media (max-width: 767px) {
  .telecom-roadmap-testing img {
    width: 100vw;
    margin-left: -16px;
    margin-right: -16px;
  }
}
.telecom-roadmap-testing .black-boxes {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 40px;
  align-items: start;
}
@media (min-width: 768px) {
  .telecom-roadmap-testing .black-boxes {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-roadmap-testing .black-boxes {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.telecom-roadmap-testing .black-boxes div {
  background-color: #000000;
  color: #ffffff;
  padding: 40px;
  height: 100%;
}
.telecom-roadmap-testing .black-boxes div .number {
  font-size: 80px;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: -0.03em;
  margin-bottom: 8px;
}
.telecom-roadmap-testing .black-boxes div .text {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0em;
}
.telecom-roadmap-testing .black-boxes div .number, .telecom-roadmap-testing .black-boxes div .text {
  margin: 0;
  text-align: center;
}
.telecom-roadmap-testing .black-boxes .box1 {
  grid-column: 1/span 5;
}
.telecom-roadmap-testing .black-boxes .box2 {
  grid-column: 7/span 5;
}
.telecom-roadmap-testing .black-boxes .box3 {
  grid-column: 13/span 5;
}
.telecom-roadmap-testing .black-boxes .box4 {
  grid-column: 19/span 5;
}
@media (max-width: 1024px) {
  .telecom-roadmap-testing .black-boxes {
    margin-top: 20px;
  }
  .telecom-roadmap-testing .black-boxes div {
    padding: 24px;
  }
  .telecom-roadmap-testing .black-boxes div .number {
    font-size: 64px;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.15625;
    letter-spacing: -0.03em;
  }
  .telecom-roadmap-testing .black-boxes div .text {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.2777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 768px) {
  .telecom-roadmap-testing .black-boxes div {
    padding: 14px;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-testing .black-boxes {
    margin-top: 8px;
  }
  .telecom-roadmap-testing .black-boxes div {
    padding: 19px;
  }
  .telecom-roadmap-testing .black-boxes div .number {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: -0.03em;
  }
  .telecom-roadmap-testing .black-boxes div .text {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2857142857;
    letter-spacing: 0em;
  }
  .telecom-roadmap-testing .black-boxes .box1 {
    grid-column: 1/span 3;
    grid-row: 1;
  }
  .telecom-roadmap-testing .black-boxes .box2 {
    grid-column: 5/span 3;
    grid-row: 1;
  }
  .telecom-roadmap-testing .black-boxes .box3 {
    grid-column: 1/span 3;
    grid-row: 2;
  }
  .telecom-roadmap-testing .black-boxes .box4 {
    grid-column: 5/span 3;
    grid-row: 2;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-testing {
    margin-top: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .telecom-roadmap-testing img {
    margin-top: 60px;
  }
}
.telecom-roadmap-results .results-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 120px;
}
@media (min-width: 768px) {
  .telecom-roadmap-results .results-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-roadmap-results .results-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.telecom-roadmap-results .results-grid p {
  margin: 0;
}
.telecom-roadmap-results .results-grid .circle, .telecom-roadmap-results .results-grid .circle1, .telecom-roadmap-results .results-grid .circle2, .telecom-roadmap-results .results-grid .circle3, .telecom-roadmap-results .results-grid .circle4 {
  border: 20px solid #9CFFA0;
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 41px;
  padding-bottom: 65px;
  justify-content: center;
}
.telecom-roadmap-results .results-grid .circle .circle-letter, .telecom-roadmap-results .results-grid .circle1 .circle-letter, .telecom-roadmap-results .results-grid .circle2 .circle-letter, .telecom-roadmap-results .results-grid .circle3 .circle-letter, .telecom-roadmap-results .results-grid .circle4 .circle-letter {
  font-size: 100px;
  font-size: 6.25rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.04em;
}
.telecom-roadmap-results .results-grid .circle .circle-text, .telecom-roadmap-results .results-grid .circle1 .circle-text, .telecom-roadmap-results .results-grid .circle2 .circle-text, .telecom-roadmap-results .results-grid .circle3 .circle-text, .telecom-roadmap-results .results-grid .circle4 .circle-text {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.2222222222;
  letter-spacing: -0.02em;
}
.telecom-roadmap-results .results-grid .circle img, .telecom-roadmap-results .results-grid .circle1 img, .telecom-roadmap-results .results-grid .circle2 img, .telecom-roadmap-results .results-grid .circle3 img, .telecom-roadmap-results .results-grid .circle4 img {
  width: 106px;
  height: 106px;
}
.telecom-roadmap-results .results-grid .circle {
  grid-column: 1/span 11;
  grid-row: 1/span 2;
  position: sticky;
  top: 21px;
  background-color: #9CFFA0;
  width: 620px;
  height: 620px;
  padding: 0;
  margin: auto;
}
.telecom-roadmap-results .results-grid .circle img {
  width: 520px;
  height: 520px;
}
.telecom-roadmap-results .results-grid .circle .circle-letter {
  position: absolute;
  bottom: 265px;
}
.telecom-roadmap-results .results-grid .circle .circle-text {
  position: absolute;
  bottom: 240px;
}
.telecom-roadmap-results .results-grid .circle1 {
  grid-column: 13/span 5;
  grid-row: 1;
}
.telecom-roadmap-results .results-grid .circle2 {
  grid-column: 19/span 5;
  grid-row: 1;
}
.telecom-roadmap-results .results-grid .circle3 {
  grid-column: 13/span 5;
  grid-row: 2;
}
.telecom-roadmap-results .results-grid .circle4 {
  grid-column: 19/span 5;
  grid-row: 2;
}
.telecom-roadmap-results .results-grid .box1, .telecom-roadmap-results .results-grid .box2, .telecom-roadmap-results .results-grid .box3, .telecom-roadmap-results .results-grid .box4 {
  border: 4px solid #9CFFA0;
  box-shadow: 0px 16px 0px #9CFFA0;
  padding: 40px;
  border-radius: 32px;
  height: 100%;
}
.telecom-roadmap-results .results-grid .box1 .box-title, .telecom-roadmap-results .results-grid .box2 .box-title, .telecom-roadmap-results .results-grid .box3 .box-title, .telecom-roadmap-results .results-grid .box4 .box-title {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.6538461538;
  letter-spacing: -0.02em;
}
.telecom-roadmap-results .results-grid .box1 .box-number, .telecom-roadmap-results .results-grid .box2 .box-number, .telecom-roadmap-results .results-grid .box3 .box-number, .telecom-roadmap-results .results-grid .box4 .box-number {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.075;
  letter-spacing: -0.02em;
  margin-bottom: 32px;
}
.telecom-roadmap-results .results-grid .box1 .box-text, .telecom-roadmap-results .results-grid .box2 .box-text, .telecom-roadmap-results .results-grid .box3 .box-text, .telecom-roadmap-results .results-grid .box4 .box-text {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5714285714;
  letter-spacing: 0em;
}
.telecom-roadmap-results .results-grid .box1 {
  grid-column: 13/span 5;
  grid-row: 3;
}
.telecom-roadmap-results .results-grid .box2 {
  grid-column: 19/span 5;
  grid-row: 3;
}
.telecom-roadmap-results .results-grid .box3 {
  grid-column: 13/span 5;
  grid-row: 4;
}
.telecom-roadmap-results .results-grid .box4 {
  grid-column: 19/span 5;
  grid-row: 4;
}
@media (max-width: 1024px) {
  .telecom-roadmap-results .results-grid .circle1, .telecom-roadmap-results .results-grid .circle2, .telecom-roadmap-results .results-grid .circle3, .telecom-roadmap-results .results-grid .circle4 {
    width: 100%;
    aspect-ratio: 1/1;
    padding-top: 28px;
    padding-bottom: 42px;
  }
  .telecom-roadmap-results .results-grid .circle1 .circle-letter, .telecom-roadmap-results .results-grid .circle2 .circle-letter, .telecom-roadmap-results .results-grid .circle3 .circle-letter, .telecom-roadmap-results .results-grid .circle4 .circle-letter {
    font-size: 80px;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
  .telecom-roadmap-results .results-grid .circle1 .circle-text, .telecom-roadmap-results .results-grid .circle2 .circle-text, .telecom-roadmap-results .results-grid .circle3 .circle-text, .telecom-roadmap-results .results-grid .circle4 .circle-text {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5714285714;
    letter-spacing: -0.02em;
  }
  .telecom-roadmap-results .results-grid .circle1 img, .telecom-roadmap-results .results-grid .circle2 img, .telecom-roadmap-results .results-grid .circle3 img, .telecom-roadmap-results .results-grid .circle4 img {
    width: 73.5px;
    height: 73.5px;
  }
  .telecom-roadmap-results .results-grid .circle {
    width: 422px;
    height: 422px;
  }
  .telecom-roadmap-results .results-grid .circle img {
    width: 354px;
    height: 354px;
  }
  .telecom-roadmap-results .results-grid .circle .circle-letter {
    font-size: 80px;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
    bottom: 165px;
  }
  .telecom-roadmap-results .results-grid .circle .circle-text {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5714285714;
    letter-spacing: -0.02em;
    bottom: 140px;
  }
  .telecom-roadmap-results .results-grid .box1, .telecom-roadmap-results .results-grid .box2, .telecom-roadmap-results .results-grid .box3, .telecom-roadmap-results .results-grid .box4 {
    box-shadow: 0px 8px 0px #9CFFA0;
    padding: 32px 24px;
    height: 100%;
  }
  .telecom-roadmap-results .results-grid .box1 .box-title, .telecom-roadmap-results .results-grid .box2 .box-title, .telecom-roadmap-results .results-grid .box3 .box-title, .telecom-roadmap-results .results-grid .box4 .box-title {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 768px) {
  .telecom-roadmap-results .results-grid .circle {
    grid-column: 1/-1;
    grid-row: 1;
    position: relative;
  }
  .telecom-roadmap-results .results-grid .circle1 {
    grid-column: 1/span 11;
    grid-row: 2;
  }
  .telecom-roadmap-results .results-grid .circle2 {
    grid-column: 13/span 11;
    grid-row: 2;
  }
  .telecom-roadmap-results .results-grid .circle3 {
    grid-column: 1/span 11;
    grid-row: 3;
  }
  .telecom-roadmap-results .results-grid .circle4 {
    grid-column: 13/span 11;
    grid-row: 3;
  }
  .telecom-roadmap-results .results-grid .box1 {
    grid-column: 1/span 11;
    grid-row: 4;
  }
  .telecom-roadmap-results .results-grid .box2 {
    grid-column: 13/span 11;
    grid-row: 4;
  }
  .telecom-roadmap-results .results-grid .box3 {
    grid-column: 1/span 11;
    grid-row: 5;
  }
  .telecom-roadmap-results .results-grid .box4 {
    grid-column: 13/span 11;
    grid-row: 5;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-results .results-grid {
    margin-top: 60px;
  }
  .telecom-roadmap-results .results-grid .circle1, .telecom-roadmap-results .results-grid .circle2, .telecom-roadmap-results .results-grid .circle3, .telecom-roadmap-results .results-grid .circle4 {
    width: 100%;
    aspect-ratio: 1/1;
    padding-top: 28px;
    padding-bottom: 38px;
    border: 17px solid #9CFFA0;
  }
  .telecom-roadmap-results .results-grid .circle1 .circle-letter, .telecom-roadmap-results .results-grid .circle2 .circle-letter, .telecom-roadmap-results .results-grid .circle3 .circle-letter, .telecom-roadmap-results .results-grid .circle4 .circle-letter {
    font-size: 50px;
    font-size: 3.125rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
  }
  .telecom-roadmap-results .results-grid .circle1 .circle-text, .telecom-roadmap-results .results-grid .circle2 .circle-text, .telecom-roadmap-results .results-grid .circle3 .circle-text, .telecom-roadmap-results .results-grid .circle4 .circle-text {
    font-size: 12px;
  }
  .telecom-roadmap-results .results-grid .circle1 img, .telecom-roadmap-results .results-grid .circle2 img, .telecom-roadmap-results .results-grid .circle3 img, .telecom-roadmap-results .results-grid .circle4 img {
    width: 60px;
    height: 60px;
  }
  .telecom-roadmap-results .results-grid .circle {
    width: 343px;
    height: 343px;
    grid-column: 1/-1;
    grid-row: 1;
    position: relative;
    top: 0;
  }
  .telecom-roadmap-results .results-grid .circle img {
    width: 288px;
    height: 288px;
  }
  .telecom-roadmap-results .results-grid .circle .circle-letter {
    font-size: 50px;
    font-size: 3.125rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
    bottom: 125px;
  }
  .telecom-roadmap-results .results-grid .circle .circle-text {
    bottom: 100px;
  }
  .telecom-roadmap-results .results-grid .circle1 {
    grid-column: 1/span 3;
    grid-row: 2;
  }
  .telecom-roadmap-results .results-grid .circle2 {
    grid-column: 5/span 3;
    grid-row: 2;
  }
  .telecom-roadmap-results .results-grid .circle3 {
    grid-column: 1/span 3;
    grid-row: 3;
  }
  .telecom-roadmap-results .results-grid .circle4 {
    grid-column: 5/span 3;
    grid-row: 3;
  }
  .telecom-roadmap-results .results-grid .box1 {
    grid-column: 1/span 3;
    grid-row: 4;
  }
  .telecom-roadmap-results .results-grid .box2 {
    grid-column: 5/span 3;
    grid-row: 4;
  }
  .telecom-roadmap-results .results-grid .box3 {
    grid-column: 1/span 3;
    grid-row: 5;
  }
  .telecom-roadmap-results .results-grid .box4 {
    grid-column: 5/span 3;
    grid-row: 5;
  }
  .telecom-roadmap-results .results-grid .box1, .telecom-roadmap-results .results-grid .box2, .telecom-roadmap-results .results-grid .box3, .telecom-roadmap-results .results-grid .box4 {
    padding: 24px 16px;
    height: 100%;
  }
  .telecom-roadmap-results .results-grid .box1 .box-title, .telecom-roadmap-results .results-grid .box2 .box-title, .telecom-roadmap-results .results-grid .box3 .box-title, .telecom-roadmap-results .results-grid .box4 .box-title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625;
    letter-spacing: -0.02em;
  }
  .telecom-roadmap-results .results-grid .box1 .box-number, .telecom-roadmap-results .results-grid .box2 .box-number, .telecom-roadmap-results .results-grid .box3 .box-number, .telecom-roadmap-results .results-grid .box4 .box-number {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .telecom-roadmap-results {
    padding-bottom: 60px;
  }
}
.telecom-designing-black {
  background-color: #1C1C1C;
  color: #ffffff;
}
.telecom-designing-black .section-divider {
  border-color: #F1F1F1;
  margin-bottom: 150px;
}
.telecom-designing-black img {
  width: 100%;
}
.telecom-designing-black .img {
  margin-top: 120px;
  margin-bottom: 120px;
}
.telecom-designing-black-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 120px;
}
@media (min-width: 768px) {
  .telecom-designing-black-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-designing-black-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.telecom-designing-black-grid picture, .telecom-designing-black-grid img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.telecom-designing-black-grid .img1 {
  grid-column: 1/-1;
  grid-row: 1;
}
.telecom-designing-black-grid .img2 {
  grid-column: 1/span 11;
  grid-row: 2;
}
.telecom-designing-black-grid .img3 {
  grid-column: 13/span 11;
  grid-row: 2;
}
.telecom-designing-black-grid .img4 {
  grid-column: 1/span 11;
  grid-row: 3/span 2;
}
.telecom-designing-black-grid .img5 {
  grid-column: 13/span 11;
  grid-row: 3;
}
.telecom-designing-black-grid .img6 {
  grid-column: 13/span 11;
  grid-row: 4;
}
.telecom-designing-black-grid .img7 {
  grid-column: 1/span 11;
  grid-row: 5/span 2;
}
.telecom-designing-black-grid .img8 {
  grid-column: 13/span 11;
  grid-row: 5;
}
.telecom-designing-black-grid .img9 {
  grid-column: 13/span 11;
  grid-row: 6;
}
@media (max-width: 767px) {
  .telecom-designing-black {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .telecom-designing-black .telecom-center-text {
    text-align: left;
  }
  .telecom-designing-black .telecom-center-text h3 {
    margin-bottom: 32px;
  }
  .telecom-designing-black .telecom-center-text-small {
    text-align: left;
  }
  .telecom-designing-black .section-divider {
    margin-bottom: 80px;
  }
  .telecom-designing-black .img {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .telecom-designing-black-grid {
    margin-top: 60px;
  }
  .telecom-designing-black-grid .img2 {
    grid-column: 1/span 3;
  }
  .telecom-designing-black-grid .img3 {
    grid-column: 5/span 3;
  }
  .telecom-designing-black-grid .img4 {
    grid-column: 1/span 3;
  }
  .telecom-designing-black-grid .img5 {
    grid-column: 5/span 3;
  }
  .telecom-designing-black-grid .img6 {
    grid-column: 5/span 3;
  }
  .telecom-designing-black-grid .img7 {
    grid-column: 1/span 3;
  }
  .telecom-designing-black-grid .img8 {
    grid-column: 5/span 3;
  }
  .telecom-designing-black-grid .img9 {
    grid-column: 5/span 3;
  }
}
.telecom-designing-support, .telecom-designing-client {
  background-color: #F1F1F1;
}
.telecom-designing-support-content, .telecom-designing-client-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.telecom-designing-support img, .telecom-designing-client img {
  width: 100%;
}
.telecom-designing-support .img, .telecom-designing-client .img {
  width: 352.72px;
  height: 350px;
  margin-bottom: 120px;
}
.telecom-designing-support-grid, .telecom-designing-client-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 120px;
}
@media (min-width: 768px) {
  .telecom-designing-support-grid, .telecom-designing-client-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-designing-support-grid, .telecom-designing-client-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.telecom-designing-support-grid img, .telecom-designing-client-grid img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.telecom-designing-support-grid .img1, .telecom-designing-client-grid .img1 {
  grid-column: 1/-1;
  grid-row: 1;
}
.telecom-designing-support-grid .img2, .telecom-designing-client-grid .img2 {
  grid-column: 1/span 11;
  grid-row: 2;
}
.telecom-designing-support-grid .img3, .telecom-designing-client-grid .img3 {
  grid-column: 13/span 11;
  grid-row: 2;
}
.telecom-designing-support-grid .img4, .telecom-designing-client-grid .img4 {
  grid-column: 1/span 11;
  grid-row: 3;
}
.telecom-designing-support-grid .img5, .telecom-designing-client-grid .img5 {
  grid-column: 13/span 11;
  grid-row: 3;
}
.telecom-designing-support-grid .img6, .telecom-designing-client-grid .img6 {
  grid-column: 1/span 11;
  grid-row: 4;
}
.telecom-designing-support-grid .img7, .telecom-designing-client-grid .img7 {
  grid-column: 13/span 11;
  grid-row: 4;
}
@media (max-width: 1024px) {
  .telecom-designing-support .img, .telecom-designing-client .img {
    width: 350px;
    height: 350px;
  }
}
@media (max-width: 767px) {
  .telecom-designing-support, .telecom-designing-client {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .telecom-designing-support .img, .telecom-designing-client .img {
    margin-bottom: 60px;
    margin-top: 0;
    width: 150px;
    height: 150px;
  }
  .telecom-designing-support-grid, .telecom-designing-client-grid {
    margin-top: 60px;
  }
  .telecom-designing-support-grid .img, .telecom-designing-client-grid .img {
    grid-column: 1/-1;
  }
  .telecom-designing-support-grid .img2, .telecom-designing-client-grid .img2 {
    grid-column: 1/span 3;
  }
  .telecom-designing-support-grid .img3, .telecom-designing-client-grid .img3 {
    grid-column: 5/span 3;
  }
  .telecom-designing-support-grid .img4, .telecom-designing-client-grid .img4 {
    grid-column: 1/span 3;
  }
  .telecom-designing-support-grid .img5, .telecom-designing-client-grid .img5 {
    grid-column: 5/span 3;
  }
  .telecom-designing-support-grid .img6, .telecom-designing-client-grid .img6 {
    grid-column: 1/span 3;
  }
  .telecom-designing-support-grid .img7, .telecom-designing-client-grid .img7 {
    grid-column: 5/span 3;
  }
}
.telecom-designing-client {
  background-color: #FFFFFF;
}
@media (max-width: 767px) {
  .telecom-designing-client {
    padding-bottom: 40px;
  }
}
.telecom-center-text, .telecom-center-text-small {
  text-align: center;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  row-gap: 0px !important;
}
@media (min-width: 768px) {
  .telecom-center-text, .telecom-center-text-small {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .telecom-center-text, .telecom-center-text-small {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.telecom-center-text h3, .telecom-center-text-small h3 {
  margin-bottom: 32px;
  grid-column: 5/span 15;
}
@media (max-width: 768px) {
  .telecom-center-text h3, .telecom-center-text-small h3 {
    grid-column: 1/-1;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 767px) {
  .telecom-center-text h3, .telecom-center-text-small h3 {
    padding: 0;
    margin-bottom: 16px;
  }
}
.telecom-center-text p, .telecom-center-text-small p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  margin: 0;
  grid-column: 5/span 15;
}
@media (max-width: 1024px) {
  .telecom-center-text p, .telecom-center-text-small p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 768px) {
  .telecom-center-text p, .telecom-center-text-small p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
    grid-column: 1/-1;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 767px) {
  .telecom-center-text p, .telecom-center-text-small p {
    padding: 0;
  }
}
.telecom-center-text-small h3 {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
}
@media (max-width: 1024px) {
  .telecom-center-text-small h3 {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .telecom-center-text-small h3 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .telecom-text-align {
    text-align: left;
  }
}

.case-challenge__box-items-list span {
  display: block;
  margin-top: 16px;
}

.santander-challenge-slider-container {
  position: relative;
}
.santander-challenge-slider-container .swiper-slide {
  width: auto;
}
.santander-challenge-slider-container .swiper-slide__box {
  width: max-content;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF0000;
}
.santander-challenge-slider-container .swiper-slide__box h2 {
  font-size: 180px;
  font-size: 11.25rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.04em;
}
@media (max-width: 1024px) {
  .santander-challenge-slider-container .swiper-slide__box h2 {
    font-size: 120px;
    font-size: 7.5rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
@media (max-width: 767px) {
  .santander-challenge-slider-container .swiper-slide__box h2 {
    font-size: 70px;
    font-size: 4.375rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.03em;
  }
}
.santander-challenge-slider-container .swiper-wrapper {
  transition-timing-function: linear !important;
}
.santander-challenge-slider-container .challenge-img {
  width: 290px;
  height: 290px;
  z-index: 1;
}
.santander-challenge-slider-container .challenge-img.img1 {
  position: absolute;
  top: 110px;
  left: 110px;
}
.santander-challenge-slider-container .challenge-img.img2 {
  position: absolute;
  top: 400px;
  right: 110px;
}
.santander-challenge-slider-container .challenge-img.img3 {
  position: absolute;
  top: 690px;
  right: 0;
  left: 0;
  margin: auto;
}
@media (max-width: 1024px) {
  .santander-challenge-slider-container .swiper-slide__box {
    height: 120px;
  }
  .santander-challenge-slider-container .challenge-img {
    width: 201px;
    height: 201px;
  }
  .santander-challenge-slider-container .challenge-img.img1 {
    top: 60px;
    left: 60px;
  }
  .santander-challenge-slider-container .challenge-img.img2 {
    top: 260px;
    right: 60px;
  }
  .santander-challenge-slider-container .challenge-img.img3 {
    top: 460px;
  }
}
@media (max-width: 767px) {
  .santander-challenge-slider-container .swiper-slide__box {
    height: 70px;
  }
  .santander-challenge-slider-container .challenge-img {
    width: 164px;
    height: 164px;
  }
  .santander-challenge-slider-container .challenge-img.img1 {
    top: -70px;
    left: 0;
  }
  .santander-challenge-slider-container .challenge-img.img2 {
    top: 80px;
    right: 0;
  }
  .santander-challenge-slider-container .challenge-img.img3 {
    top: 245px;
    left: 0;
    right: auto;
  }
}
.santander-transform {
  padding-bottom: 0;
}
.santander-transform img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.santander-transform .santander-comunication-tool {
  background-color: #FFE9CA;
  padding-top: 120px;
  padding-bottom: 120px;
  margin-top: 32px;
}
@media (max-width: 767px) {
  .santander-transform .santander-comunication-tool {
    margin-top: 16px;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.santander-transform .santander-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .santander-transform .santander-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .santander-transform .santander-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.santander-transform .santander-grid .box1 {
  grid-column: 1/span 11;
  grid-row: 1;
}
.santander-transform .santander-grid .box2 {
  grid-column: 13/span 11;
  grid-row: 1;
}
.santander-transform .santander-grid .box3 {
  grid-column: 1/span 11;
  grid-row: 2;
}
.santander-transform .santander-grid .box4 {
  grid-column: 13/span 11;
  grid-row: 2;
}
.santander-transform .santander-grid .box5-d {
  grid-column: 7/span 5;
  grid-row: 3;
}
.santander-transform .santander-grid .box5-m {
  display: none;
}
.santander-transform .santander-grid .box6 {
  grid-column: 13/span 11;
  grid-row: 3/span 2;
}
.santander-transform .santander-grid .box7 {
  grid-column: 1/-1;
  grid-row: 5;
}
.santander-transform .santander-grid .box8 {
  grid-column: 1/span 11;
  grid-row: 6;
}
.santander-transform .santander-grid .box9 {
  grid-column: 13/span 11;
  grid-row: 6;
}
@media (max-width: 1024px) {
  .santander-transform .santander-grid {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .santander-transform .santander-grid {
    margin-top: 16px;
  }
  .santander-transform .santander-grid .box1 {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .santander-transform .santander-grid .box2 {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .santander-transform .santander-grid .box3 {
    grid-column: 1/span 3;
    grid-row: 3;
  }
  .santander-transform .santander-grid .box4 {
    grid-column: 5/span 3;
    grid-row: 3;
  }
  .santander-transform .santander-grid .box5-d {
    display: none;
  }
  .santander-transform .santander-grid .box5-m {
    display: block;
    grid-column: 1/-1;
    grid-row: 5;
  }
  .santander-transform .santander-grid .box6 {
    grid-column: 1/-1;
    grid-row: 4;
  }
  .santander-transform .santander-grid .box7 {
    grid-column: 1/-1;
    grid-row: 6;
  }
  .santander-transform .santander-grid .box8 {
    grid-column: 1/span 3;
    grid-row: 7;
  }
  .santander-transform .santander-grid .box9 {
    grid-column: 5/span 3;
    grid-row: 7;
  }
}
@media (max-width: 767px) {
  .santander-transform {
    padding-bottom: 40px;
  }
}
.santander-design {
  background-color: #1F1F1F;
  color: #FFFFFF;
  padding-bottom: 0;
}
.santander-design .section-divider {
  border-color: #FFFFFF;
}
.santander-design .santander-text-left {
  margin-top: 150px;
  margin-bottom: 120px;
}
.santander-design .santander-text-left h3, .santander-design .santander-text-left p {
  grid-column: 1/span 15;
}
@media (max-width: 1024px) {
  .santander-design .santander-text-left h3, .santander-design .santander-text-left p {
    grid-column: 1/span 19;
  }
}
.santander-design picture, .santander-design img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.santander-design-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .santander-design-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .santander-design-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.santander-design-grid .box1 {
  grid-column: 1/span 15;
  grid-row: 1;
}
.santander-design-grid .box2 {
  grid-column: 17/span 7;
  grid-row: 1;
}
.santander-design-grid .box3 {
  grid-column: 1/span 15;
  grid-row: 2;
}
.santander-design-grid .box4 {
  grid-column: 17/span 7;
  grid-row: 2;
  border-top: 1px solid #FFFFFF;
  height: 100%;
  display: flex;
  padding-top: 24px;
}
.santander-design-grid .box4 p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .santander-design-grid .box4 p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 768px) {
  .santander-design-grid .box4 p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
}
.santander-design-grid .box5 {
  grid-column: 1/span 5;
  grid-row: 3;
}
.santander-design-grid .box6 {
  grid-column: 7/span 5;
  grid-row: 3;
}
.santander-design-grid .box7 {
  grid-column: 1/span 5;
  grid-row: 4;
}
.santander-design-grid .box8 {
  grid-column: 7/span 5;
  grid-row: 4;
}
.santander-design-grid .box9 {
  grid-column: 13/span 11;
  grid-row: 3/span 2;
}
@media (max-width: 767px) {
  .santander-design {
    padding-top: 60px;
    margin-top: 40px;
  }
  .santander-design .santander-text-left {
    margin-top: 76px;
    margin-bottom: 60px;
  }
  .santander-design .santander-text-left h3, .santander-design .santander-text-left p {
    grid-column: 1/-1;
  }
  .santander-design-grid {
    display: grid;
    height: auto;
    align-items: center;
    grid-template-columns: repeat(3, 1fr 16px) 1fr;
    row-gap: 16px;
  }
}
@media (max-width: 767px) and (min-width: 768px) {
  .santander-design-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (max-width: 767px) and (min-width: 1200px) {
  .santander-design-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 767px) {
  .santander-design-grid .box1 {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .santander-design-grid .box2 {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .santander-design-grid .box3 {
    grid-column: 1/-1;
    grid-row: 4;
  }
  .santander-design-grid .box4 {
    grid-column: 1/-1;
    grid-row: 3;
    padding-top: 24px;
  }
  .santander-design-grid .box5 {
    grid-column: 1/span 3;
    grid-row: 5;
  }
  .santander-design-grid .box6 {
    grid-column: 5/span 3;
    grid-row: 5;
  }
  .santander-design-grid .box7 {
    grid-column: 1/span 3;
    grid-row: 6;
  }
  .santander-design-grid .box8 {
    grid-column: 5/span 3;
    grid-row: 6;
  }
  .santander-design-grid .box9 {
    grid-column: 1/-1;
    grid-row: 7;
  }
}
.santander-design-quote {
  background-color: #1F1F1F;
  color: #FFFFFF;
  padding-bottom: 240px;
}
.santander-design-quote .case-quote__box {
  margin: auto;
}
.santander-design-quote .case-quote__box .big-list-title, .santander-design-quote .case-quote__box .case-quote__author {
  grid-column: 13/span 11;
}
@media (max-width: 768px) {
  .santander-design-quote {
    padding-bottom: 120px;
    padding-top: 136px;
  }
  .santander-design-quote .case-quote__box .big-list-title, .santander-design-quote .case-quote__box .case-quote__author {
    grid-column: 1/-1;
  }
}
.santander-reimagine {
  padding-bottom: 0;
}
.santander-reimagine-content {
  background-color: #000000;
  color: #FFFFFF;
  padding-top: 120px;
  margin-top: 32px;
}
.santander-reimagine-content picture, .santander-reimagine-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 120px;
}
@media (max-width: 767px) {
  .santander-reimagine-content {
    padding-top: 60px;
    margin-top: 16px;
  }
  .santander-reimagine-content p {
    padding-left: 16px;
    padding-right: 16px;
  }
  .santander-reimagine-content img {
    margin-top: 60px;
  }
}
.santander-reimagine .santander-reimagine-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .santander-reimagine .santander-reimagine-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .santander-reimagine .santander-reimagine-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.santander-reimagine .santander-reimagine-grid picture, .santander-reimagine .santander-reimagine-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.santander-reimagine .santander-reimagine-grid p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
}
.santander-reimagine .santander-reimagine-grid .box2 {
  grid-column: 1/span 11;
  grid-row: 1;
  border-top: 1px solid #000000;
  height: 262px;
  width: 100%;
  display: flex;
  padding-top: 24px;
}
.santander-reimagine .santander-reimagine-grid .box2 p {
  max-width: 510px;
  margin: 0;
}
.santander-reimagine .santander-reimagine-grid .box3 {
  grid-column: 1/span 11;
  grid-row: 2/span 2;
}
.santander-reimagine .santander-reimagine-grid .box4 {
  grid-column: 13/span 11;
  grid-row: 1/span 2;
}
.santander-reimagine .santander-reimagine-grid .box5 {
  grid-column: 13/span 11;
  grid-row: 3;
  border-top: 1px solid #000000;
  height: 262px;
  width: 100%;
  display: flex;
  padding-top: 24px;
}
.santander-reimagine .santander-reimagine-grid .box5 p {
  max-width: 510px;
  margin: 0;
}
.santander-reimagine .santander-reimagine-grid .box6 {
  grid-column: 1/-1;
  grid-row: 4;
}
.santander-reimagine .santander-reimagine-grid .box7 {
  grid-column: 1/span 11;
  grid-row: 5;
}
.santander-reimagine .santander-reimagine-grid .box8 {
  grid-column: 13/span 11;
  grid-row: 5;
}
@media (max-width: 1024px) {
  .santander-reimagine .santander-reimagine-grid {
    margin-top: 20px;
  }
  .santander-reimagine .santander-reimagine-grid .box2 p, .santander-reimagine .santander-reimagine-grid .box5 p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
    max-width: 348px;
  }
}
@media (max-width: 767px) {
  .santander-reimagine .santander-reimagine-grid {
    margin-top: 16px;
  }
  .santander-reimagine .santander-reimagine-grid .box2 {
    grid-column: 1/-1;
    grid-row: 1;
    height: max-content;
    padding-top: 16px;
  }
  .santander-reimagine .santander-reimagine-grid .box2 p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
  .santander-reimagine .santander-reimagine-grid .box3 {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .santander-reimagine .santander-reimagine-grid .box4 {
    grid-column: 1/-1;
    grid-row: 4;
  }
  .santander-reimagine .santander-reimagine-grid .box5 {
    grid-column: 1/-1;
    grid-row: 3;
    height: max-content;
    padding-top: 16px;
  }
  .santander-reimagine .santander-reimagine-grid .box5 p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
  }
  .santander-reimagine .santander-reimagine-grid .box6 {
    grid-column: 1/-1;
    grid-row: 5;
  }
  .santander-reimagine .santander-reimagine-grid .box7 {
    grid-column: 1/span 3;
    grid-row: 6;
  }
  .santander-reimagine .santander-reimagine-grid .box8 {
    grid-column: 5/span 3;
    grid-row: 6;
  }
}
@media (max-width: 767px) {
  .santander-reimagine {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.santander-explore {
  background-color: #1F1F1F;
  color: #FFFFFF;
}
.santander-explore .section-divider {
  border-color: #FFFFFF;
}
.santander-explore .santander-text-left {
  margin-top: 150px;
  margin-bottom: 120px;
}
.santander-explore .santander-text-left h3, .santander-explore .santander-text-left p {
  grid-column: 1/span 15;
}
@media (max-width: 1024px) {
  .santander-explore .santander-text-left h3, .santander-explore .santander-text-left p {
    grid-column: 1/span 19;
  }
}
.santander-explore picture, .santander-explore img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.santander-explore-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .santander-explore-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .santander-explore-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.santander-explore-grid .box1 {
  grid-column: 1/span 11;
  grid-row: 1;
}
.santander-explore-grid .box2 {
  grid-column: 13/span 11;
  grid-row: 1;
}
.santander-explore-grid .box3 {
  grid-column: 1/span 11;
  grid-row: 2;
}
.santander-explore-grid .box4 {
  grid-column: 13/span 11;
  grid-row: 2;
}
.santander-explore-grid .box5 {
  grid-column: 1/span 11;
  grid-row: 3;
}
.santander-explore-grid .box6 {
  grid-column: 13/span 11;
  grid-row: 3;
}
.santander-explore-grid .box7 {
  grid-column: 1/span 11;
  grid-row: 4;
}
.santander-explore-grid .box8 {
  grid-column: 13/span 11;
  grid-row: 4;
}
@media (max-width: 767px) {
  .santander-explore {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 40px;
  }
  .santander-explore .santander-text-left {
    margin-top: 76px;
    margin-bottom: 60px;
  }
  .santander-explore .santander-text-left h3, .santander-explore .santander-text-left p {
    grid-column: 1/-1;
  }
  .santander-explore-grid {
    display: grid;
    height: auto;
    align-items: center;
    grid-template-columns: repeat(3, 1fr 16px) 1fr;
    row-gap: 16px;
  }
}
@media (max-width: 767px) and (min-width: 768px) {
  .santander-explore-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (max-width: 767px) and (min-width: 1200px) {
  .santander-explore-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 767px) {
  .santander-explore-grid .box1 {
    grid-column: 1/span 3;
    grid-row: 1;
  }
  .santander-explore-grid .box2 {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .santander-explore-grid .box3 {
    grid-column: 5/span 3;
    grid-row: 1;
  }
  .santander-explore-grid .box4 {
    grid-column: 1/-1;
    grid-row: 6;
  }
  .santander-explore-grid .box5 {
    grid-column: 1/span 3;
    grid-row: 3;
  }
  .santander-explore-grid .box6 {
    grid-column: 1/-1;
    grid-row: 4;
  }
  .santander-explore-grid .box7 {
    grid-column: 1/-1;
    grid-row: 5;
  }
  .santander-explore-grid .box8 {
    grid-column: 5/span 3;
    grid-row: 3;
  }
}
.santander-experiences {
  padding-bottom: 0;
}
.santander-experiences-content {
  background-color: #8017E4;
  color: #FFFFFF;
  padding-top: 120px;
  margin-top: 32px;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .santander-experiences-content {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 16px;
  }
  .santander-experiences-content p {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.santander-experiences .box1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.santander-experiences .santander-experiences-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .santander-experiences .santander-experiences-grid {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .santander-experiences .santander-experiences-grid {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.santander-experiences .santander-experiences-grid picture, .santander-experiences .santander-experiences-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.santander-experiences .santander-experiences-grid .box2 {
  grid-column: 1/span 11;
  grid-row: 1;
}
.santander-experiences .santander-experiences-grid .box3 {
  grid-column: 13/span 11;
  grid-row: 1;
}
.santander-experiences .santander-experiences-grid .box4 {
  grid-column: 1/-1;
  grid-row: 2;
}
.santander-experiences .santander-experiences-grid .box5 {
  grid-column: 1/span 11;
  grid-row: 3;
}
.santander-experiences .santander-experiences-grid .box6 {
  grid-column: 13/span 11;
  grid-row: 3;
}
@media (max-width: 767px) {
  .santander-experiences .santander-experiences-grid {
    margin-top: 16px;
  }
  .santander-experiences .santander-experiences-grid .box2 {
    grid-column: 1/span 3;
    grid-row: 1;
  }
  .santander-experiences .santander-experiences-grid .box3 {
    grid-column: 5/span 3;
    grid-row: 1;
  }
  .santander-experiences .santander-experiences-grid .box4 {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .santander-experiences .santander-experiences-grid .box5 {
    grid-column: 1/span 3;
    grid-row: 3;
  }
  .santander-experiences .santander-experiences-grid .box6 {
    grid-column: 5/span 3;
    grid-row: 3;
  }
}
@media (max-width: 767px) {
  .santander-experiences {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.santander-center-text, .santander-center-text-small {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 991px) {
  .santander-center-text, .santander-center-text-small {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .santander-center-text, .santander-center-text-small {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.santander-center-text h3, .santander-center-text-small h3 {
  margin-bottom: 32px;
  grid-column: 5/span 15;
}
@media (max-width: 1024px) {
  .santander-center-text h3, .santander-center-text-small h3 {
    grid-column: 4/span 17;
  }
}
@media (max-width: 768px) {
  .santander-center-text h3, .santander-center-text-small h3 {
    grid-column: 1/-1;
  }
}
.santander-center-text p, .santander-center-text-small p {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  margin: 0;
  grid-column: 5/span 15;
}
@media (max-width: 1024px) {
  .santander-center-text p, .santander-center-text-small p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 768px) {
  .santander-center-text p, .santander-center-text-small p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
    grid-column: 1/-1;
  }
}
.santander-center-text-small h3 {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
}
@media (max-width: 1024px) {
  .santander-center-text-small h3 {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .santander-center-text-small h3 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .santander-text-align {
    text-align: left;
  }
}
.santander-text-left {
  text-align: left;
}

.ucema .case-challenge__img-box {
  position: relative;
  width: 100%;
  padding: 80px 0;
}
@media (max-width: 767px) {
  .ucema .case-challenge__img-box {
    padding: 60px 0;
  }
}
.ucema .case-challenge__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(237.45deg, #7C1723 -0.03%, #B03258 100%);
}
.ucema .case-challenge__img {
  display: block;
  max-width: 100%;
  height: auto;
  height: auto;
  width: 100%;
}
.ucema .case-challenge__sub-img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 240px;
}
@media (max-width: 767px) {
  .ucema .case-challenge__sub-img {
    margin-top: 120px;
  }
}
.ucema-roots {
  background-color: #F1F1F1;
}
.ucema-discovering {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .ucema-discovering {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .ucema-discovering {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .ucema-discovering > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .ucema-discovering > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .ucema-discovering > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .ucema-discovering > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .ucema-discovering > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .ucema-discovering > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
.ucema-discovering__box {
  padding: 80px 70px;
  text-align: center;
  background-color: #FFFFFF;
  height: 100%;
}
.ucema-discovering__box img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}
@media (max-width: 991px) {
  .ucema-discovering__box {
    padding: 50px 60px;
  }
}
@media (max-width: 767px) {
  .ucema-discovering__box {
    padding: 8px 16px;
  }
}
.ucema-users {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
.ucema-users-section {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .ucema-users {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .ucema-users {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 575px) {
  .ucema-users > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 576px) {
  .ucema-users > *:nth-child(1n) {
    grid-column: 1/span 7;
  }
}
@media (min-width: 768px) {
  .ucema-users > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .ucema-users > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
@media (min-width: 992px) {
  .ucema-users > *:nth-child(2n+1) {
    grid-column: 1/span 11;
  }
  .ucema-users > *:nth-child(2n) {
    grid-column: 13/span 11;
  }
}
.ucema-users picture, .ucema-users img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .ucema-users picture, .ucema-users img {
    grid-row: auto;
  }
}
.ucema-users__1 {
  grid-row: 1/2;
}
.ucema-users__2 {
  grid-row: 1/3;
}
.ucema-users__3 {
  grid-row: 2/3;
}
.ucema-users__4 {
  grid-row: 3/4;
}
.ucema-users__5 {
  grid-row: 3/4;
}
.ucema-users__6 {
  grid-column: 1/-1 !important;
  grid-row: 4/5;
}
.ucema-roadmap-findings {
  padding-top: 16px;
}
@media (min-width: 768px) {
  .ucema-roadmap-findings {
    padding-top: 20px;
  }
}
@media (min-width: 1200px) {
  .ucema-roadmap-findings {
    padding-top: 40px;
  }
}
.ucema-roadmap-findings p {
  margin: 0;
}
.ucema-roadmap-findings .container1, .ucema-roadmap-findings .container2, .ucema-roadmap-findings .container3 {
  border-left: 4px solid #59BFEF;
  border-right: 4px solid #59BFEF;
  padding-left: 40px;
  padding-right: 40px;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
@media (min-width: 768px) {
  .ucema-roadmap-findings .container1, .ucema-roadmap-findings .container2, .ucema-roadmap-findings .container3 {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .ucema-roadmap-findings .container1, .ucema-roadmap-findings .container2, .ucema-roadmap-findings .container3 {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .container1, .ucema-roadmap-findings .container2, .ucema-roadmap-findings .container3 {
    padding-left: 12px;
    padding-right: 12px;
    border-left: 4px solid #59BFEF;
    border-right: 4px solid #59BFEF;
    row-gap: 0px;
  }
}
.ucema-roadmap-findings .container1 {
  border-top: 4px solid #59BFEF;
  border-bottom: 4px solid #59BFEF;
  border-radius: 70px 0px 0px 0px;
  padding-top: 65.5px;
  padding-bottom: 69px;
}
.ucema-roadmap-findings .container1 .big-text {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0em;
  grid-column: 13/-1;
}
@media (max-width: 991px) {
  .ucema-roadmap-findings .container1 .big-text {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: 0em;
  }
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .container1 .big-text {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
    grid-column: 1/-1;
  }
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .container1 {
    padding-top: 44px;
    padding-bottom: 48px;
    border: 4px solid #59BFEF;
    border-radius: 35px 0px 0px 0px;
  }
  .ucema-roadmap-findings .container1 .big-title {
    margin-bottom: 16px;
  }
}
.ucema-roadmap-findings .container2 {
  padding: 60px 40px;
  border-bottom: 2px solid #59BFEF;
}
.ucema-roadmap-findings .container2 .img1 {
  grid-column: 1/-1;
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .container2 {
    overflow: scroll;
    margin-top: 0;
    padding: 58px 12px;
  }
  .ucema-roadmap-findings .container2 .img1 {
    height: 180px;
    width: auto;
  }
}
.ucema-roadmap-findings .container3 {
  border-top: 2px solid #59BFEF;
  border-bottom: 4px solid #59BFEF;
  padding-top: 60px;
  align-items: start;
  padding-bottom: 30px;
  background-color: #F1F1F1;
}
.ucema-roadmap-findings .container3 .findings-box {
  border-radius: 32px;
  margin-bottom: 40px;
  padding: 40px 40px 8px 40px;
  border: 4px solid #59BFEF;
  box-shadow: 0px 16px 0px #59BFEF;
}
.ucema-roadmap-findings .container3 .column1 {
  grid-column: 1/span 7;
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .container3 .column1 {
    grid-column: 1/-1;
  }
}
.ucema-roadmap-findings .container3 .column2 {
  grid-column: 9/span 7;
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .container3 .column2 {
    grid-column: 1/-1;
    padding-top: 0px;
  }
}
.ucema-roadmap-findings .container3 .column3 {
  grid-column: 17/span 7;
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .container3 .column3 {
    grid-column: 1/-1;
  }
}
@media (max-width: 991px) {
  .ucema-roadmap-findings .container3 .findings-box {
    padding: 32px 24px;
  }
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .container3 {
    border-top: none;
    padding-top: 40px;
  }
  .ucema-roadmap-findings .container3 .findings-box {
    padding: 24px;
    padding-bottom: 8px;
    margin-bottom: 32px;
  }
  .ucema-roadmap-findings .container3 .big-title {
    margin-bottom: 32px;
  }
}
.ucema-roadmap-findings .big-title {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.1666666667;
  letter-spacing: -0.03em;
  grid-column: 1/span 11;
  grid-row: 1;
  align-self: start;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .ucema-roadmap-findings .big-title {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .big-title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}
.ucema-roadmap-findings .findings-title {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.2307692308h;
  letter-spacing: 0em;
  font-style: italic;
  margin-bottom: 32px;
}
@media (max-width: 991px) {
  .ucema-roadmap-findings .findings-title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0em;
  }
}
.ucema-roadmap-findings .findings-tiny-title {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.03em;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.ucema-roadmap-findings .findings-tiny-paragraph {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5714285714;
  letter-spacing: 0em;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .ucema-roadmap-findings .findings-tiny-paragraph {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0em;
  }
}
.ucema-roadmap-findings ul.findings-tiny-paragraph {
  list-style: disc;
  list-style-position: inside;
}
@media (max-width: 767px) {
  .ucema-roadmap-findings {
    padding-top: 40px;
  }
}
.ucema-website {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
.ucema-website-section {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .ucema-website {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .ucema-website {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.ucema-website picture, .ucema-website img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  grid-auto-rows: auto;
}
@media (max-width: 767px) {
  .ucema-website picture, .ucema-website img {
    grid-column: 1/-1;
  }
}
.ucema-website__res__img-box {
  position: relative;
  width: 100%;
  padding: 80px 0;
  margin: 80px 0;
}
@media (max-width: 767px) {
  .ucema-website__res__img-box {
    padding: 60px 0;
    margin: 16px 0;
  }
}
.ucema-website__res__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(89.76deg, #000000 0.15%, #00BBBD 99.74%);
  mix-blend-mode: normal;
}
@media (max-width: 767px) {
  .ucema-website__res__gradient {
    background: linear-gradient(89.76deg, #000000 0.15%, #00BBBD 99.74%);
  }
}
.ucema-website__res-img {
  display: block;
  max-width: 100%;
  height: auto;
  height: auto;
  width: 100%;
}
.ucema-website__1 {
  grid-column: 1/-1;
}
.ucema-website__2, .ucema-website__4, .ucema-website__6 {
  grid-column: 1/12;
}
.ucema-website__3 {
  grid-column: 13/-1;
}
.ucema-website__5 {
  grid-column: 13/-1;
  grid-row: span 2;
}
.ucema-research {
  padding-top: 120px;
}
@media (max-width: 767px) {
  .ucema-research {
    padding-top: 80px;
  }
}
.ucema-research__img-connect {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 450px;
  margin: 0 auto 65px;
}
@media (max-width: 767px) {
  .ucema-research__img-connect {
    max-width: 70%;
  }
}
.ucema-research__header {
  text-align: center;
  max-width: 840px;
  margin: 0 auto 120px;
}
@media (max-width: 991px) {
  .ucema-research__header {
    max-width: 720px;
  }
}
@media (max-width: 767px) {
  .ucema-research__header {
    margin-bottom: 60px;
  }
}
.ucema-research__img-research {
  display: block;
  max-width: 100%;
  height: auto;
}
.ucema-research__middle-p {
  text-align: center;
  max-width: 840px;
  margin: 120px auto;
}
@media (max-width: 991px) {
  .ucema-research__middle-p {
    max-width: 720px;
  }
}
.ucema-storytelling {
  background-color: #F1F1F1;
}
.ucema-storytelling__header {
  text-align: center;
  max-width: 840px;
  margin: 0 auto 120px;
}
@media (max-width: 991px) {
  .ucema-storytelling__header {
    padding: inherit 16px;
    max-width: 720px;
  }
}
.ucema-storytelling__img {
  display: block;
  max-width: 100%;
  height: auto;
}
.ucema-ux {
  padding-top: 80px;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  row-gap: 16px;
}
.ucema-ux-section {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .ucema-ux-section {
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  .ucema-ux {
    padding-top: 240px;
  }
}
@media (min-width: 768px) {
  .ucema-ux {
    grid-template-columns: repeat(11, 1fr 20px) 1fr;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .ucema-ux {
    grid-template-columns: repeat(11, 1fr 40px) 1fr;
    row-gap: 40px;
  }
}
.ucema-ux picture, .ucema-ux img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  grid-auto-rows: auto;
}
@media (max-width: 767px) {
  .ucema-ux picture, .ucema-ux img {
    grid-column: 1/-1;
  }
}
.ucema-ux__1, .ucema-ux__3, .ucema-ux__5 {
  grid-column: 1/12;
}
.ucema-ux__2, .ucema-ux__6 {
  grid-column: 13/-1;
}
.ucema-ux__4, .ucema-ux__7 {
  grid-column: 1/-1;
}
.ucema-ux__2 {
  grid-row: span 2;
}

.three-images__section.--before-challenge {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 220px);
  column-gap: 40px;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 1024px) {
  .three-images__section.--before-challenge {
    grid-template-rows: repeat(6, 150px);
    column-gap: 20px;
  }
}
@media (max-width: 768px) {
  .three-images__section.--before-challenge {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
  }
}
.three-images__section.--before-challenge .case-challenge__img-1 {
  width: 100%;
  grid-row: 1/2;
  grid-column: 1;
}
.three-images__section.--before-challenge .case-challenge__img-2 {
  width: 100%;
  grid-row: 2/5;
  grid-column: 2;
}
.three-images__section.--before-challenge .case-challenge__img-3 {
  width: 100%;
  grid-row: 5/6;
  grid-column: 1;
}
.three-images__section.--after-challenge {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 435px);
  column-gap: 40px;
  padding-top: 180px;
  padding-bottom: 60px;
}
@media (max-width: 1024px) {
  .three-images__section.--after-challenge {
    grid-template-rows: repeat(4, 300px);
    column-gap: 20px;
  }
}
@media (max-width: 768px) {
  .three-images__section.--after-challenge {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
  }
}
.three-images__section.--after-challenge .case-challenge__img-1 {
  width: 100%;
  grid-row: 1/2;
  grid-column: 1;
}
.three-images__section.--after-challenge .case-challenge__img-2 {
  width: 100%;
  grid-row: 2/3;
  grid-column: 2;
}
.three-images__section.--after-challenge .case-challenge__img-3 {
  width: 100%;
  grid-row: 4;
  grid-column: 1;
}
.three-images__section.--footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  padding-top: 180px;
  padding-bottom: 0px;
  row-gap: 40px;
}
@media (max-width: 1024px) {
  .three-images__section.--footer {
    column-gap: 20px;
    row-gap: 20px;
  }
}
@media (max-width: 768px) {
  .three-images__section.--footer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
.three-images__section.--footer .case-challenge__img-1 {
  width: 100%;
  height: 100%;
  display: block;
  grid-row: 1;
  grid-column: 1;
}
.three-images__section.--footer .case-challenge__img-2 {
  width: 100%;
  height: 100%;
  display: block;
  grid-row: 2;
  grid-column: 2;
}
.three-images__section.--footer .case-challenge__img-3 {
  width: 100%;
  height: 100%;
  display: block;
  grid-row: 3;
  grid-column: 1;
}

.design-process__section {
  background-color: #F5F7FF;
  padding-top: 120px;
  position: relative;
}
@media (max-width: 768px) {
  .design-process__section {
    padding-bottom: 332px;
  }
}
.design-process__content {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
}
@media (max-width: 991px) {
  .design-process__content {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .design-process__content {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 768px) {
  .design-process__content {
    display: flex;
    flex-direction: column;
  }
}
.design-process__content__hr {
  width: 100%;
  grid-row: 1;
  grid-column: 1/-1;
  border-top: 1px solid #000;
  margin-bottom: 150px;
}
.design-process__content__hr p {
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  margin: 32px 0 0 0;
}
@media (max-width: 768px) {
  .design-process__content__hr {
    margin-bottom: 60px;
  }
}
.design-process__content__text-container {
  grid-row: 2;
  grid-column: 5/20;
  text-align: center;
  margin-bottom: 120px;
}
@media (max-width: 1024px) {
  .design-process__content__text-container {
    grid-column: 3/22;
  }
}
@media (max-width: 768px) {
  .design-process__content__text-container {
    text-align: start;
    margin-bottom: 60px;
  }
}
.design-process__content__text-container h2 {
  font-weight: 500;
  font-size: 80px;
  line-height: 92px;
  letter-spacing: -0.03em;
  margin-bottom: 32px;
}
@media (max-width: 1024px) {
  .design-process__content__text-container h2 {
    font-size: 64px;
    line-height: 74px;
  }
}
@media (max-width: 768px) {
  .design-process__content__text-container h2 {
    font-size: 32px;
    line-height: 40px;
  }
}
.design-process__content__text-container p {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .design-process__content__text-container p {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .design-process__content__text-container p {
    font-size: 16px;
    line-height: 28px;
  }
}
.design-process__content__image-1 {
  display: flex;
  flex-direction: column;
  grid-row: 3;
  grid-column: 1/12;
  background-color: #fff;
  height: 100%;
}
@media (max-width: 768px) {
  .design-process__content__image-1 {
    margin-bottom: 16px;
  }
}
.design-process__content__image-2 {
  display: flex;
  flex-direction: column;
  grid-row: 3;
  grid-column: 13/-1;
  background-color: #fff;
  height: 100%;
}
.design-process__content__img {
  width: 100%;
}
.design-process__content__image-text-container {
  padding: 40px 80px 80px 80px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .design-process__content__image-text-container {
    padding: 40px;
  }
}
.design-process__content__image-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0;
  margin-bottom: 16px;
}
@media (max-width: 1024px) {
  .design-process__content__image-title {
    font-size: 24px;
    line-height: 34px;
  }
}
.design-process__content__image-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  margin: 0;
}
@media (max-width: 1024px) {
  .design-process__content__image-text {
    font-size: 18px;
    line-height: 32px;
  }
}
.design-process__content__digital-culture {
  grid-row: 4;
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  padding-top: 240px;
}
@media (max-width: 768px) {
  .design-process__content__digital-culture {
    padding-top: 110px;
  }
}
.design-process__content__digital-culture__img {
  width: 100%;
}
.design-process__content__digital-culture__text {
  width: 400px;
  height: 400px;
  display: flex;
  align-self: flex-end;
  align-items: center;
}
@media (max-width: 1024px) {
  .design-process__content__digital-culture__text {
    height: 305px;
    width: 273px;
  }
}
@media (max-width: 768px) {
  .design-process__content__digital-culture__text {
    width: 100%;
    height: auto;
    align-self: flex-start;
    margin-top: 32px;
  }
}
.design-process__content__digital-culture__text p {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .design-process__content__digital-culture__text p {
    font-size: 18px;
    line-height: 32px;
  }
}
.design-process__hand {
  position: absolute;
  width: 257px;
  height: 526px;
  bottom: -47px;
  right: 44%;
}
.design-process__hand__img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .design-process__hand {
    height: 399px;
    width: 195px;
    bottom: -40px;
  }
}
@media (max-width: 768px) {
  .design-process__hand {
    height: 332px;
    width: 162px;
    bottom: -60px;
    right: 50%;
    transform: translate(50%, 0);
  }
}

.chapter__section {
  padding-top: 47px;
  padding-bottom: 60px;
}
@media (max-width: 1024px) {
  .chapter__section {
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
  .chapter__section {
    padding-top: 60px;
  }
}
.chapter__section.--chapter-2 {
  color: #3443CA;
  padding-top: 120px;
  padding-bottom: 0;
}
@media (max-width: 1024px) {
  .chapter__section.--chapter-2 {
    padding-top: 0;
  }
}
.chapter__section.--chapter-2 .chapter__content {
  border-top: 1px solid #3443CA;
}
.chapter__section.--divider-out .chapter__content {
  border-top: none;
}
.chapter__section.--chapter-4 {
  padding-top: 240px;
}
.chapter__section.--accessibility {
  padding-top: 360px;
}
@media (max-width: 768px) {
  .chapter__section.--accessibility {
    padding-top: 120px;
  }
}
.chapter__box-section {
  padding-bottom: 60px;
}
.chapter__content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  border-top: 1px solid #000;
  padding-top: 120px;
}
@media (max-width: 768px) {
  .chapter__content {
    padding-top: 60px;
  }
}
.chapter__text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 32px;
  max-width: 840px;
}
@media (max-width: 1024px) {
  .chapter__text-container {
    max-width: 716px;
  }
}
@media (max-width: 768px) {
  .chapter__text-container {
    max-width: 100%;
  }
}
.chapter__subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}
.chapter__title {
  font-weight: 400;
  font-size: 60px;
  line-height: 70px;
  margin: 0;
}
@media (max-width: 1024px) {
  .chapter__title {
    font-size: 40px;
    line-height: 50px;
    max-width: 500px;
  }
}
@media (max-width: 768px) {
  .chapter__title {
    font-size: 24px;
    line-height: 30px;
    max-width: 100%;
  }
}
.chapter__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .chapter__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .chapter__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.chapter__boxes-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 120px;
  align-items: flex-start;
}
@media (max-width: 991px) {
  .chapter__boxes-container {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .chapter__boxes-container {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 768px) {
  .chapter__boxes-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: auto;
  }
}
.chapter__boxes-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.chapter__box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-top: 4px solid #3443CA;
  padding-top: 40px;
  color: #3443CA;
}
@media (max-width: 1024px) {
  .chapter__box {
    padding-top: 32px;
  }
}
@media (max-width: 768px) {
  .chapter__box {
    min-width: 200px;
    margin-right: 16px;
  }
}
.chapter__box.--box-1 {
  grid-column: 1/6;
}
.chapter__box.--box-2 {
  grid-column: 7/12;
}
.chapter__box.--box-3 {
  grid-column: 13/18;
}
.chapter__box.--box-4 {
  grid-column: 19/24;
}
.chapter__box__number {
  font-weight: 400;
  font-size: 60px;
  line-height: 100%;
  margin: 0;
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .chapter__box__number {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .chapter__box__number {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 16px;
  }
}
.chapter__box__title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  margin: 0;
  margin-bottom: 16px;
}
@media (max-width: 1024px) {
  .chapter__box__title {
    font-size: 24px;
    line-height: 34px;
  }
}
@media (max-width: 768px) {
  .chapter__box__title {
    font-size: 20px;
    line-height: 26px;
  }
}
.chapter__box__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
  color: #000;
}
@media (max-width: 1024px) {
  .chapter__box__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .chapter__box__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.chapter__img {
  width: 100%;
  margin-bottom: 120px;
  margin-top: 120px;
}
@media (max-width: 768px) {
  .chapter__img {
    margin-bottom: 60px;
    margin-top: 60px;
  }
}
.chapter__two-boxes {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  align-items: flex-start;
}
@media (max-width: 991px) {
  .chapter__two-boxes {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .chapter__two-boxes {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
.chapter__two-boxes__box {
  display: flex;
  flex-direction: column;
  border-top: 2px solid #000;
  text-align: left;
  padding-top: 40px;
  padding-right: 70px;
}
@media (max-width: 768px) {
  .chapter__two-boxes__box {
    padding-right: 0;
    padding-top: 32px;
  }
}
.chapter__two-boxes__box-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
}
@media (max-width: 1024px) {
  .chapter__two-boxes__box-title {
    font-size: 24px;
    line-height: 34px;
  }
}
@media (max-width: 768px) {
  .chapter__two-boxes__box-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 16px;
  }
}
.chapter__two-boxes__box-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
}
@media (max-width: 1024px) {
  .chapter__two-boxes__box-description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .chapter__two-boxes__box-description {
    font-size: 16px;
    line-height: 28px;
  }
}
.chapter__two-boxes__box.--box-1 {
  grid-column: 1/12;
}
@media (max-width: 575px) {
  .chapter__two-boxes__box.--box-1 {
    grid-column: 1/4;
  }
}
.chapter__two-boxes__box.--box-2 {
  grid-column: 13/-1;
}
@media (max-width: 575px) {
  .chapter__two-boxes__box.--box-2 {
    grid-column: 5/-1;
  }
}

.six-premises__section {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 40px;
  padding-top: 60px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .six-premises__section {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .six-premises__section {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 768px) {
  .six-premises__section {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding-bottom: 60px;
  }
}
.six-premises__hr {
  width: 100%;
  grid-row: 1;
  grid-column: 1/-1;
  border-top: 1px solid #000;
}
@media (max-width: 768px) {
  .six-premises__hr {
    margin-bottom: 16px;
  }
}
.six-premises__hr p {
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  margin: 32px 0 0 0;
}
.six-premises__box {
  display: flex;
  flex-direction: column;
}
.six-premises__box-text {
  padding: 40px 80px 80px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .six-premises__box-text {
    padding: 40px;
  }
}
.six-premises__box-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0;
  margin-bottom: 16px;
}
@media (max-width: 1024px) {
  .six-premises__box-title {
    font-size: 24px;
    line-height: 34px;
  }
}
.six-premises__box-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  margin: 0;
}
@media (max-width: 1024px) {
  .six-premises__box-description {
    font-size: 18px;
    line-height: 32px;
  }
}
.six-premises__box.--first {
  grid-row: 2;
  grid-column: 1/12;
  background-color: #FDEECC;
}
.six-premises__box.--second {
  grid-row: 2;
  grid-column: 13/-1;
  background-color: #E0E0FF;
}
.six-premises__box.--third {
  grid-row: 3;
  grid-column: 1/12;
  background: #FEDAE5;
}
.six-premises__box.--fourth {
  grid-row: 3;
  grid-column: 13/-1;
  background: #FFD6F7;
}
.six-premises__box.--fifth {
  grid-row: 4;
  grid-column: 1/12;
  background: #FFEEC5;
}
.six-premises__box.--sixth {
  grid-row: 4;
  grid-column: 13/-1;
  background: #D2F7FF;
}
.six-premises__img {
  width: 100%;
}

.stack__section {
  padding-top: 180px;
}
@media (max-width: 768px) {
  .stack__section {
    padding-left: 0;
    padding-right: 0;
    padding-top: 60px;
  }
}
.stack__content {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  background-color: #FFDED1;
  padding-top: 240px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .stack__content {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .stack__content {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 768px) {
  .stack__content {
    padding-top: 120px;
    padding-bottom: 60px;
  }
}
.stack__title-container {
  grid-column: 1/-1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 140px;
}
@media (max-width: 1024px) {
  .stack__title-container {
    margin-bottom: 80px;
  }
}
.stack__subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 32px;
}
.stack__title {
  margin: 0;
}
.stack__title.--spanish {
  text-align: center;
  width: 80%;
}
@media (max-width: 768px) {
  .stack__title.--spanish {
    width: 100%;
  }
}
.stack__images-container {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 0 16px;
}
@media (max-width: 768px) {
  .stack__images-container {
    flex-wrap: wrap;
    gap: 15px;
    padding: 0;
  }
}
.stack__image {
  max-width: 250px;
  width: 100%;
  height: auto;
  display: block;
  min-width: 0;
}
@media (max-width: 1024px) {
  .stack__image {
    max-width: 164px;
  }
}
@media (max-width: 768px) {
  .stack__image {
    max-width: 170px;
  }
}
@media (max-width: 425px) {
  .stack__image {
    max-width: 190px;
  }
}
@media (max-width: 375px) {
  .stack__image {
    max-width: 164px;
  }
}
@media (max-width: 320px) {
  .stack__image {
    max-width: 135px;
  }
}
.stack__description-container {
  grid-row: 3;
  grid-column: 5/20;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}
@media (max-width: 1024px) {
  .stack__description-container {
    margin-top: 100px;
  }
}
@media (max-width: 768px) {
  .stack__description-container {
    grid-column: 1/-1;
    padding: 0 16px;
    margin-top: 60px;
  }
}
.stack__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
}
@media (max-width: 1024px) {
  .stack__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .stack__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.stack__description span {
  font-weight: 700;
}

.digital-experience__section {
  background-color: #F5F7FF;
  margin-top: 120px;
  padding-top: 120px;
  padding-bottom: 145px;
}
@media (max-width: 768px) {
  .digital-experience__section {
    padding-top: 100px;
    padding-bottom: 60px;
  }
}
.digital-experience__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.digital-experience__text-container {
  text-align: center;
  max-width: 840px;
  margin-top: 150px;
  margin-bottom: 240px;
}
@media (max-width: 1024px) {
  .digital-experience__text-container {
    max-width: 716px;
  }
}
@media (max-width: 768px) {
  .digital-experience__text-container {
    margin-top: 100px;
    margin-bottom: 60px;
  }
}
.digital-experience__text-container p span {
  font-weight: 700;
}
.digital-experience__img {
  width: 100%;
}

.design-system__section {
  padding-top: 360px;
}
@media (max-width: 768px) {
  .design-system__section {
    padding-top: 120px;
  }
}
.design-system__header {
  margin: auto;
  max-width: 840px;
  margin-bottom: 120px;
}
.design-system__header h4 {
  margin-bottom: 32px;
}
.design-system__header p {
  margin: 0;
}
.design-system__icon {
  height: 180px;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .design-system__icon {
    height: 118px;
  }
}
.design-system__images-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 40px;
  margin-top: 120px;
}
@media (max-width: 991px) {
  .design-system__images-container {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .design-system__images-container {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1024px) {
  .design-system__images-container {
    row-gap: 20px;
  }
}
@media (max-width: 768px) {
  .design-system__images-container {
    row-gap: 16px;
    margin-top: 60px;
  }
}
.design-system__img {
  width: 100%;
  height: 100%;
}
.design-system__img.--img-1 {
  grid-row: 1;
  grid-column: 1/12;
}
@media (max-width: 768px) {
  .design-system__img.--img-1 {
    grid-row: 1;
    grid-column: 1/-1;
  }
}
.design-system__img.--img-2 {
  grid-row: 1;
  grid-column: 13/-1;
}
@media (max-width: 768px) {
  .design-system__img.--img-2 {
    grid-row: 2;
    grid-column: 1/-1;
  }
}
.design-system__img.--img-3 {
  grid-row: 2;
  grid-column: 1/12;
}
@media (max-width: 768px) {
  .design-system__img.--img-3 {
    grid-row: 4;
    grid-column: 1/-1;
  }
}
.design-system__img.--img-4 {
  grid-row: 2;
  grid-column: 13/-1;
}
@media (max-width: 768px) {
  .design-system__img.--img-4 {
    grid-row: 3;
    grid-column: 1/-1;
  }
}

@media (max-width: 768px) {
  .case-quote.--osde-1 {
    padding-bottom: 120px;
  }
}
@media (max-width: 1440px) {
  .case-quote.--osde-1 .big-list-title {
    grid-column: 1/20;
  }
}
.case-quote.--osde .case-quote__box {
  margin: auto;
}
.case-quote.--osde .case-quote__author {
  line-height: 1.8;
}
@media (max-width: 768px) {
  .case-quote.--osde .case-quote__author {
    line-height: 1.2;
  }
}
@media (max-width: 768px) {
  .case-quote.--julio-quote {
    padding-bottom: 120px;
    padding-top: 120px;
  }
}
@media (max-width: 1440px) {
  .case-quote.--julio-quote .big-list-title {
    grid-column: 1/18;
  }
}
@media (max-width: 1024px) {
  .case-quote.--julio-quote .big-list-title {
    grid-column: 1/20;
  }
}
@media (max-width: 768px) {
  .case-quote.--charo-quote {
    padding-bottom: 120px;
  }
}
@media (max-width: 1440px) {
  .case-quote.--charo-quote .big-list-title {
    grid-column: 1/20;
  }
}
.case-quote.--emiliano-quote {
  padding-top: 240px;
  padding-bottom: 260px;
}
@media (max-width: 768px) {
  .case-quote.--emiliano-quote {
    padding-bottom: 40px;
  }
}
@media (max-width: 1440px) {
  .case-quote.--emiliano-quote .big-list-title {
    grid-column: 1/18;
  }
}
@media (max-width: 1024px) {
  .case-quote.--emiliano-quote .big-list-title {
    grid-column: 1/20;
  }
}

.define-users__section {
  padding-top: 100px;
  padding-bottom: 60px;
}
@media (max-width: 768px) {
  .define-users__section {
    padding-top: 60px;
  }
}
.define-users__divider {
  grid-row: 1;
  width: 100%;
  border-top: 2px solid #000;
  margin-bottom: 72px;
  padding-top: 20px;
}
.define-users__divider h5 {
  margin: 0;
}
@media (max-width: 768px) {
  .define-users__divider {
    margin-bottom: 60px;
  }
}
.define-users__content {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  grid-template-rows: repeat(2, 240px);
  row-gap: 40px;
}
@media (max-width: 991px) {
  .define-users__content {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .define-users__content {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1024px) {
  .define-users__content {
    row-gap: 20px;
    grid-template-rows: repeat(2, 164px);
  }
}
@media (max-width: 768px) {
  .define-users__content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
.define-users__img-1 {
  grid-row: 1;
  grid-column: 1/12;
  align-self: end;
}
@media (max-width: 768px) {
  .define-users__img-1 {
    grid-row: 1;
    grid-column: 1/-1;
  }
}
.define-users__img-2 {
  grid-row: 2;
  grid-column: 1/12;
  align-self: baseline;
}
@media (max-width: 768px) {
  .define-users__img-2 {
    grid-row: 2;
    grid-column: 1/-1;
  }
}
.define-users__img-3 {
  grid-row: 1/span 2;
  grid-column: 13/-1;
}
@media (max-width: 768px) {
  .define-users__img-3 {
    grid-row: 3;
    grid-column: 1/-1;
  }
}
.define-users__img {
  width: 100%;
  height: 100%;
  display: block;
}

.slider-principles__section {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 991px) {
  .slider-principles__section {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .slider-principles__section {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 768px) {
  .slider-principles__section {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
.slider-principles__divider {
  grid-row: 1;
  grid-column: 1/-1;
  width: 100%;
  border-top: 2px solid #000;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .slider-principles__divider {
    width: 92%;
    margin: auto;
    margin-bottom: 20px;
  }
}
.slider-principles__section-title {
  grid-row: 2;
  grid-column: 1/6;
  align-self: baseline;
  margin-bottom: 80px;
}
.slider-principles__section-title h5 {
  margin: 0;
}
@media (max-width: 1024px) {
  .slider-principles__section-title {
    margin-bottom: 90px;
    grid-column: 1/8;
  }
}
@media (max-width: 768px) {
  .slider-principles__section-title {
    margin-bottom: 60px;
    padding-left: 16px;
    padding-right: 16px;
    grid-column: 1/-1;
  }
}
.slider-principles__slider-pagination {
  grid-row: 2;
  grid-column: 9/15;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: baseline;
  margin-bottom: 80px;
}
@media (max-width: 1024px) {
  .slider-principles__slider-pagination {
    margin-bottom: 90px;
  }
}
@media (max-width: 768px) {
  .slider-principles__slider-pagination {
    display: none;
  }
}
.slider-principles__buttons-container {
  display: flex;
  flex-direction: row;
  align-self: end;
  justify-content: space-between;
  width: 165px;
}
@media (max-width: 768px) {
  .slider-principles__buttons-container {
    display: none;
  }
}
.slider-principles__slider-text {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  color: #F1F1F1;
}
.slider-principles__slider-text .swiper-pagination-current {
  color: #000;
}
@media (max-width: 1024px) {
  .slider-principles__slider-text {
    font-size: 34px;
    line-height: 40px;
  }
}
.slider-principles__text-container {
  grid-row: 3;
  grid-column: 1/6;
  align-self: baseline;
}
.slider-principles__text-container p {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  user-select: none;
}
@media (max-width: 1024px) {
  .slider-principles__text-container {
    grid-column: 1/8;
  }
  .slider-principles__text-container p {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .slider-principles__text-container {
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    grid-column: 1/-1;
  }
}
.slider-principles__slider {
  grid-row: 3;
  grid-column: 9/-1;
  margin: 0 !important;
  height: 490px;
}
@media (max-width: 1024px) {
  .slider-principles__slider {
    height: 380px;
  }
}
@media (max-width: 768px) {
  .slider-principles__slider {
    grid-column: 1/-1;
    grid-row: 4;
    padding-left: 16px !important;
  }
}
.slider-principles__card {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFBE3;
}
.slider-principles__card.--messages {
  background-color: #E5FFF2;
}
.slider-principles__card-img {
  width: 100%;
  display: block;
}
.slider-principles__card-text {
  padding: 40px 40px 0 40px;
}
@media (max-width: 1024px) {
  .slider-principles__card-text {
    padding: 30px 16px 0 16px;
  }
}
.slider-principles__card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
}
@media (max-width: 1024px) {
  .slider-principles__card-title {
    font-size: 16px;
  }
}
.slider-principles__card-subtitle {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 130%;
}
@media (max-width: 1024px) {
  .slider-principles__card-subtitle {
    font-size: 16px;
  }
}
.slider-principles__card-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
}
@media (max-width: 1024px) {
  .slider-principles__card-description {
    font-size: 14px;
  }
}

.behavior-patterns__title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 32px;
  max-width: 840px;
  margin: auto;
  color: #FFFFFF;
  margin-bottom: 240px;
}
@media (max-width: 1024px) {
  .behavior-patterns__title-container {
    max-width: 716px;
  }
}
@media (max-width: 768px) {
  .behavior-patterns__title-container {
    max-width: 100%;
    padding: 0 32px;
    margin-bottom: 100px;
    row-gap: 16px;
  }
}
@media (max-width: 575px) {
  .behavior-patterns__title-container {
    padding: 0 16px;
  }
}
.behavior-patterns__subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}
.behavior-patterns__title {
  margin: 0;
}
.behavior-patterns__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .behavior-patterns__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .behavior-patterns__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.behavior-patterns__images-txt {
  position: relative;
  background-color: #1A4E66;
}
@media (max-width: 767px) {
  .behavior-patterns__images-txt {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.behavior-patterns__images-txt .wrapper {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 110px;
}
@media (max-width: 991px) {
  .behavior-patterns__images-txt .wrapper {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .behavior-patterns__images-txt .wrapper {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 991px) {
  .behavior-patterns__images-txt .wrapper {
    row-gap: 16px;
  }
}
.behavior-patterns__images-txt img {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}
.behavior-patterns__images-txt__textbox {
  padding: 50px;
}
@media (max-width: 1024px) {
  .behavior-patterns__images-txt__textbox {
    padding: 40px;
  }
}
@media (max-width: 768px) {
  .behavior-patterns__images-txt__textbox {
    padding: 32px;
  }
}
.behavior-patterns__images-txt__item {
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.behavior-patterns__images-txt__item:nth-of-type(1) {
  grid-column: 1/10;
  background: #FFEAE2;
}
@media (min-width: 768px) {
  .behavior-patterns__images-txt__item:nth-of-type(1) {
    grid-row: 1/3;
  }
}
.behavior-patterns__images-txt__item:nth-of-type(2) {
  grid-column: 15/-1;
  background: #F5F7FF;
}
@media (min-width: 768px) {
  .behavior-patterns__images-txt__item:nth-of-type(2) {
    grid-row: 1/2;
  }
}
.behavior-patterns__images-txt__item:nth-of-type(3) {
  grid-column: 11/20;
  background: #FFFFFF;
}
@media (min-width: 768px) {
  .behavior-patterns__images-txt__item:nth-of-type(3) {
    grid-row: 3/4;
  }
}
.behavior-patterns__images-txt__item:nth-of-type(4) {
  grid-column: 1/10;
  grid-row: 4/6;
  background: #E7E9F7;
}
@media (min-width: 768px) {
  .behavior-patterns__images-txt__item:nth-of-type(4) {
    grid-row: 5/7;
  }
}
.behavior-patterns__images-txt__item:nth-of-type(5) {
  grid-column: 15/-1;
  background: #FFF7CA;
}
@media (min-width: 768px) {
  .behavior-patterns__images-txt__item:nth-of-type(5) {
    margin-top: -110px;
  }
}
@media (min-width: 768px) {
  .behavior-patterns__images-txt__item:nth-of-type(5) {
    grid-row: 6/8;
  }
}
.behavior-patterns__images-txt__item.--img-bottom {
  flex-direction: column-reverse;
}
.behavior-patterns__images-txt__item.--img-top {
  flex-direction: column;
}
.behavior-patterns__images-txt__item.--border {
  border: 2px solid #D3D3D3;
}
.behavior-patterns__images-txt__item.--shadow {
  filter: drop-shadow(0px 20px 80px rgba(0, 0, 0, 0.15));
}
@media (max-width: 767px) {
  .behavior-patterns__images-txt__item {
    grid-column: 1/-1 !important;
  }
}
.behavior-patterns__images-txt__item__title {
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.3846153846;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}
@media (max-width: 991px) {
  .behavior-patterns__images-txt__item__title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.4166666667;
    letter-spacing: normal;
  }
}
@media (max-width: 767px) {
  .behavior-patterns__images-txt__item__title {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.03em;
  }
}
.behavior-patterns__images-txt__item__text {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: normal;
}
.behavior-patterns__images-txt__item__text p:last-of-type {
  margin-bottom: 0;
}
.behavior-patterns__images-txt.--osde {
  padding-top: 240px;
  padding-bottom: 120px;
  color: inherit;
}
.behavior-patterns__images-txt.--osde .wrapper {
  row-gap: 0;
}
@media (max-width: 1024px) {
  .behavior-patterns__images-txt.--osde {
    padding-bottom: 140px;
  }
}
@media (max-width: 768px) {
  .behavior-patterns__images-txt.--osde {
    padding-bottom: 120px;
    padding-top: 120px;
  }
  .behavior-patterns__images-txt.--osde .wrapper {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 575px) {
  .behavior-patterns__images-txt.--osde {
    padding-bottom: 80px;
  }
}
.behavior-patterns__images-txt.--osde .behavior-patterns__images-txt__textbox {
  padding: 40px;
}
@media (max-width: 768px) {
  .behavior-patterns__images-txt.--osde .behavior-patterns__images-txt__textbox {
    padding: 30px;
  }
}
.behavior-patterns__images-txt.--osde .behavior-patterns__images-txt__item:nth-child(1) {
  grid-row: 1;
}
.behavior-patterns__images-txt.--osde .behavior-patterns__images-txt__item:nth-child(2) {
  grid-row: 2;
  margin-top: -280px;
}
.behavior-patterns__images-txt.--osde .behavior-patterns__images-txt__item:nth-child(3) {
  grid-row: 3;
  margin-top: 80px;
}
.behavior-patterns__images-txt.--osde .behavior-patterns__images-txt__item:nth-child(4) {
  grid-row: 4;
  margin-top: 80px;
}
.behavior-patterns__images-txt.--osde .behavior-patterns__images-txt__item:nth-child(5) {
  grid-row: 5;
  margin-top: -85px;
}
@media (max-width: 768px) {
  .behavior-patterns__images-txt.--osde .wrapper .behavior-patterns__images-txt__item {
    margin-top: 16px;
  }
  .behavior-patterns__images-txt.--osde .wrapper .behavior-patterns__images-txt__item:first-child {
    margin-top: 29px;
  }
}

.within-the-design-system__section {
  padding-top: 60px;
}
@media (max-width: 768px) {
  .within-the-design-system__section {
    padding-top: 0;
  }
}
.within-the-design-system__divider {
  display: block;
  width: 100%;
  border-top: 2px solid #000;
  margin-bottom: 30px;
}
.within-the-design-system__section-title {
  margin-bottom: 32px;
}
.within-the-design-system__section-title h5 {
  margin-bottom: 0;
}
.within-the-design-system__card-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 40px;
}
@media (max-width: 991px) {
  .within-the-design-system__card-container {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .within-the-design-system__card-container {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1024px) {
  .within-the-design-system__card-container {
    row-gap: 20px;
  }
}
@media (max-width: 768px) {
  .within-the-design-system__card-container {
    display: flex;
    flex-direction: row;
    overflow: auto;
  }
  .within-the-design-system__card-container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
.within-the-design-system__card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 300px;
  width: 100%;
  background-color: #FFF3EE;
}
@media (max-width: 1024px) {
  .within-the-design-system__card {
    height: 250px;
  }
}
@media (max-width: 768px) {
  .within-the-design-system__card {
    flex-direction: column;
    height: 375px;
    min-width: 253px;
    margin-right: 16px;
    align-items: flex-end;
  }
}
.within-the-design-system__card-text {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 70px 0 40px;
}
@media (max-width: 1024px) {
  .within-the-design-system__card-text {
    padding: 0 24px 0 32px;
  }
}
@media (max-width: 768px) {
  .within-the-design-system__card-text {
    padding: 24px 24px 0 24px;
    width: 100%;
  }
}
.within-the-design-system__card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 8px;
}
@media (max-width: 1024px) {
  .within-the-design-system__card-title {
    font-size: 18px;
    line-height: 26px;
  }
}
.within-the-design-system__card-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .within-the-design-system__card-description {
    font-size: 18px;
    line-height: 32px;
  }
}
.within-the-design-system__card-image {
  max-width: 35%;
}
@media (max-width: 768px) {
  .within-the-design-system__card-image {
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.within-the-design-system__card-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
  .within-the-design-system__card-image img {
    width: auto;
    height: 100%;
  }
}
.within-the-design-system__card.--card-1 {
  grid-row: 1;
  grid-column: 1/12;
}
.within-the-design-system__card.--card-2 {
  grid-row: 1;
  grid-column: 13/-1;
}
.within-the-design-system__card.--card-3 {
  grid-row: 2;
  grid-column: 1/12;
}
.within-the-design-system__card.--card-4 {
  grid-row: 2;
  grid-column: 13/-1;
  margin-right: 0;
}

.chapter-library__section {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 310px;
  row-gap: 70px;
}
@media (max-width: 991px) {
  .chapter-library__section {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .chapter-library__section {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1024px) {
  .chapter-library__section {
    row-gap: 120px;
  }
}
@media (max-width: 768px) {
  .chapter-library__section {
    row-gap: 16px;
    padding-top: 90px;
  }
}
.chapter-library__title-container {
  grid-row: 1;
  grid-column: 1/12;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 50px;
}
@media (max-width: 1024px) {
  .chapter-library__title-container {
    padding-bottom: 0;
  }
}
@media (max-width: 768px) {
  .chapter-library__title-container {
    text-align: center;
    grid-row: 2;
    grid-column: 1/-1;
    height: auto;
    padding-top: 44px;
    padding-bottom: 44px;
  }
}
.chapter-library__subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 32px;
}
.chapter-library__title {
  margin-bottom: 32px;
}
.chapter-library__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .chapter-library__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .chapter-library__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.chapter-library__header-img {
  grid-row: 1;
  grid-column: 13/-1;
  width: 300px;
  height: 100%;
  justify-self: center;
}
@media (max-width: 1024px) {
  .chapter-library__header-img {
    width: 196px;
  }
}
@media (max-width: 768px) {
  .chapter-library__header-img {
    grid-column: 1/-1;
  }
}
@media (max-width: 575px) {
  .chapter-library__header-img {
    width: 160px;
  }
}
.chapter-library__box {
  display: flex;
  flex-direction: column;
  border-top: 2px solid #000;
  text-align: left;
  padding-top: 40px;
  padding-right: 70px;
  align-self: baseline;
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .chapter-library__box {
    margin-bottom: 0;
  }
}
@media (max-width: 768px) {
  .chapter-library__box {
    padding-right: 0;
    padding-top: 32px;
    margin-bottom: 44px;
  }
}
.chapter-library__box-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
}
@media (max-width: 1024px) {
  .chapter-library__box-title {
    font-size: 24px;
    line-height: 34px;
  }
}
@media (max-width: 768px) {
  .chapter-library__box-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 16px;
  }
}
.chapter-library__box-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
}
@media (max-width: 1024px) {
  .chapter-library__box-description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .chapter-library__box-description {
    font-size: 16px;
    line-height: 28px;
  }
}
.chapter-library__box.--box-1 {
  grid-row: 2;
  grid-column: 1/12;
}
@media (max-width: 768px) {
  .chapter-library__box.--box-1 {
    grid-row: 3;
  }
}
@media (max-width: 575px) {
  .chapter-library__box.--box-1 {
    grid-column: 1/4;
  }
}
.chapter-library__box.--box-2 {
  grid-row: 2;
  grid-column: 13/-1;
}
@media (max-width: 768px) {
  .chapter-library__box.--box-2 {
    grid-row: 3;
  }
}
@media (max-width: 575px) {
  .chapter-library__box.--box-2 {
    grid-column: 5/-1;
  }
}
.chapter-library__box.--box-3 {
  grid-row: 3;
  grid-column: 1/12;
}
@media (max-width: 768px) {
  .chapter-library__box.--box-3 {
    grid-row: 4;
  }
}
@media (max-width: 575px) {
  .chapter-library__box.--box-3 {
    grid-column: 1/4;
  }
}
.chapter-library__box.--box-4 {
  grid-row: 3;
  grid-column: 13/-1;
}
@media (max-width: 768px) {
  .chapter-library__box.--box-4 {
    grid-row: 4;
  }
}
@media (max-width: 575px) {
  .chapter-library__box.--box-4 {
    grid-column: 5/-1;
  }
}
.chapter-library__images {
  width: 100%;
  height: 100%;
  grid-row: 4;
}
.chapter-library__images.--image-1 {
  grid-row: 4;
  grid-column: 1/12;
}
@media (max-width: 768px) {
  .chapter-library__images.--image-1 {
    grid-row: 5;
    grid-column: 1/-1;
  }
}
.chapter-library__images.--image-2 {
  grid-row: 4;
  grid-column: 13/-1;
}
@media (max-width: 768px) {
  .chapter-library__images.--image-2 {
    grid-row: 6;
    grid-column: 1/-1;
  }
}

.icon-grid__section {
  padding-top: 120px;
  padding-bottom: 40px;
}
@media (max-width: 575px) {
  .icon-grid__section {
    padding-bottom: 80px;
  }
}
.icon-grid__divider {
  width: 100%;
  border-top: 2px solid #000;
  margin-bottom: 32px;
}
@media (max-width: 1024px) {
  .icon-grid__divider {
    margin-bottom: 28px;
  }
}
.icon-grid__header h5 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
}
@media (max-width: 1024px) {
  .icon-grid__header h5 {
    font-size: 24px;
    line-height: 34px;
  }
}
@media (max-width: 768px) {
  .icon-grid__header h5 {
    font-size: 20px;
    line-height: 26px;
  }
}
.icon-grid__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding-top: 70px;
}
@media (max-width: 1024px) {
  .icon-grid__content {
    gap: 20px;
    padding-top: 48px;
  }
}
@media (max-width: 768px) {
  .icon-grid__content {
    display: flex;
    flex-direction: row;
    overflow: auto;
  }
}
.icon-grid__content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.icon-grid__column {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
@media (max-width: 1024px) {
  .icon-grid__column {
    row-gap: 20px;
  }
}
@media (max-width: 768px) {
  .icon-grid__column {
    min-width: 253px;
  }
}
.icon-grid__column.--column-1 {
  grid-column: 1;
}
.icon-grid__column.--column-2 {
  grid-column: 2;
}
.icon-grid__column.--column-3 {
  grid-column: 3;
}
.icon-grid__item {
  display: flex;
  width: 100%;
  height: 75px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  border-radius: 40px;
  border: 2px solid #C2DCFF;
}
@media (max-width: 1024px) {
  .icon-grid__item {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .icon-grid__item {
    font-size: 16px;
    line-height: 28px;
  }
}
.icon-grid__item.--colored {
  background-color: #F5F7FF;
  border: none;
}

.solutions-for-people__section {
  padding-top: 120px;
  padding-bottom: 120px;
}
.solutions-for-people__divider {
  width: 100%;
  border-top: 2px solid #000;
  margin-bottom: 32px;
}
@media (max-width: 1024px) {
  .solutions-for-people__divider {
    margin-bottom: 28px;
  }
}
.solutions-for-people__header h5 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
}
@media (max-width: 1024px) {
  .solutions-for-people__header h5 {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .solutions-for-people__header h5 {
    font-size: 16px;
    line-height: 28px;
  }
}
.solutions-for-people__title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 32px;
  max-width: 840px;
  margin: auto;
  padding-top: 150px;
  padding-bottom: 120px;
}
.solutions-for-people__title-container.--spanish {
  max-width: 875px;
}
@media (max-width: 1024px) {
  .solutions-for-people__title-container {
    max-width: 716px;
  }
}
@media (max-width: 768px) {
  .solutions-for-people__title-container {
    max-width: 100%;
    row-gap: 16px;
    padding: 100px 16px 60px 16px;
  }
}
.solutions-for-people__title {
  width: 90%;
  margin: 0;
}
.solutions-for-people__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .solutions-for-people__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .solutions-for-people__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.solutions-for-people__content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
}
@media (max-width: 768px) {
  .solutions-for-people__content-container {
    padding-top: 60px;
    padding-left: 0;
    padding-right: 0;
  }
}
.solutions-for-people__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2B378A;
}
.solutions-for-people__content__title {
  margin-bottom: 32px;
}
.solutions-for-people__content__text {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
}
@media (max-width: 1024px) {
  .solutions-for-people__content__text {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .solutions-for-people__content__text {
    font-size: 16px;
    line-height: 28px;
  }
}
.solutions-for-people__img {
  width: 100%;
}
.solutions-for-people__text-container {
  text-align: center;
  color: #fff;
  padding: 120px 0;
  max-width: 840px;
}
@media (max-width: 1024px) {
  .solutions-for-people__text-container {
    max-width: 716px;
  }
}
@media (max-width: 768px) {
  .solutions-for-people__text-container {
    max-width: 100%;
    padding: 60px 16px;
  }
}

.online-consultation__section {
  padding-top: 120px;
}
.online-consultation__images-divider {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 768px) {
  .online-consultation__images-divider {
    flex-direction: column;
    column-gap: 16px;
  }
}
.online-consultation__content {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  padding-top: 80px;
  row-gap: 80px;
}
@media (max-width: 991px) {
  .online-consultation__content {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .online-consultation__content {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 768px) {
  .online-consultation__content {
    row-gap: 40px;
    padding-top: 32px;
  }
}
.online-consultation__text {
  grid-column: 13/-1;
  grid-row: 1;
}
@media (max-width: 768px) {
  .online-consultation__text {
    grid-column: 1/-1;
  }
}
.online-consultation__title {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .online-consultation__title {
    margin-bottom: 16px;
  }
}
.online-consultation__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .online-consultation__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .online-consultation__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.online-consultation__images-container {
  grid-column: 1/12;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 40px;
}
@media (max-width: 768px) {
  .online-consultation__images-container {
    grid-column: 1/-1;
    row-gap: 60px;
  }
}
.online-consultation__img {
  width: 50%;
}
@media (max-width: 768px) {
  .online-consultation__img {
    width: 100%;
  }
}
.online-consultation__img.--score {
  align-self: flex-end;
  width: 47%;
}
.online-consultation__img.--disponible {
  width: 47%;
}

.emergency-assistance__section {
  padding-top: 40px;
  padding-bottom: 120px;
}
@media (max-width: 768px) {
  .emergency-assistance__section {
    padding: 60px 0 30px 0;
    column-gap: 16px;
  }
}
.emergency-assistance__content {
  display: flex;
  flex-direction: column;
  background-color: #DADEFF;
}
.emergency-assistance__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 32px;
  max-width: 840px;
  margin: auto;
  padding: 120px 0 60px 0;
}
@media (max-width: 1024px) {
  .emergency-assistance__text {
    max-width: 716px;
  }
}
@media (max-width: 768px) {
  .emergency-assistance__text {
    padding: 60px 16px 30px 16px;
    max-width: 100%;
  }
}
.emergency-assistance__title {
  margin: 0;
}
.emergency-assistance__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .emergency-assistance__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .emergency-assistance__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.emergency-assistance__two-boxes {
  display: flex;
  flex-direction: row;
  padding: 60px 100px 120px 100px;
  column-gap: 40px;
}
@media (max-width: 1024px) {
  .emergency-assistance__two-boxes {
    padding: 60px 74px 120px 74px;
    column-gap: 20px;
  }
}
@media (max-width: 768px) {
  .emergency-assistance__two-boxes {
    padding: 30px 16px 60px 16px;
    column-gap: 16px;
  }
}
@media (max-width: 575px) {
  .emergency-assistance__two-boxes {
    flex-direction: column;
    row-gap: 20px;
  }
}
.emergency-assistance__box {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.emergency-assistance__box__text {
  padding: 50px;
}
@media (max-width: 1024px) {
  .emergency-assistance__box__text {
    padding: 40px;
    padding-bottom: 8px;
  }
}
.emergency-assistance__box__title {
  margin-top: 40px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
}
@media (max-width: 1024px) {
  .emergency-assistance__box__title {
    font-size: 24px;
    line-height: 34px;
  }
}
@media (max-width: 768px) {
  .emergency-assistance__box__title {
    font-size: 20px;
    line-height: 26px;
  }
}
.emergency-assistance__box__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .emergency-assistance__box__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .emergency-assistance__box__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.emergency-assistance__box__img {
  width: 100%;
  height: 100%;
}
.emergency-assistance__footer {
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .emergency-assistance__footer {
    flex-direction: column-reverse;
  }
}
.emergency-assistance__footer__left {
  display: flex;
  flex-direction: column;
}
.emergency-assistance__footer__left img {
  width: 100%;
  height: 100%;
}
.emergency-assistance__footer__right img {
  width: 100%;
  height: 100%;
}

.extranet__section {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 60px;
}
@media (max-width: 768px) {
  .extranet__section {
    padding-bottom: 0;
  }
}
.extranet__header {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 20px;
  margin-bottom: 120px;
}
@media (max-width: 1024px) {
  .extranet__header {
    padding-right: 15px;
  }
}
@media (max-width: 768px) {
  .extranet__header {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
}
.extranet__header-img {
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .extranet__header-img {
    margin-bottom: 0;
  }
}
.extranet__header.--right {
  align-self: flex-end;
  padding-left: 20px;
}
@media (max-width: 1024px) {
  .extranet__header.--right {
    padding-left: 15px;
  }
}
@media (max-width: 768px) {
  .extranet__header.--right {
    width: 100%;
    padding-left: 0;
  }
}
.extranet__header.--right img {
  margin-bottom: 0;
  margin-top: 120px;
}
@media (max-width: 768px) {
  .extranet__header.--right img {
    margin-top: 32px;
  }
}
.extranet__title {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .extranet__title {
    margin-bottom: 16px;
  }
}
.extranet__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin: 0;
}
@media (max-width: 1024px) {
  .extranet__description {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .extranet__description {
    font-size: 16px;
    line-height: 28px;
  }
}
.extranet__images-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr 0.53fr) 1fr;
  height: auto;
  align-items: center;
  row-gap: 40px;
  margin-top: 120px;
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  .extranet__images-container {
    grid-template-columns: repeat(11, 1fr 0.33fr) 1fr;
  }
}
@media (max-width: 767px) {
  .extranet__images-container {
    grid-template-columns: repeat(3, 1fr 0.23fr) 1fr;
  }
}
@media (max-width: 1024px) {
  .extranet__images-container {
    row-gap: 20px;
  }
}
@media (max-width: 768px) {
  .extranet__images-container {
    row-gap: 16px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
.extranet__img {
  width: 100%;
  height: 100%;
}
.extranet__img.--img-1 {
  grid-row: 1;
  grid-column: 1/12;
}
@media (max-width: 768px) {
  .extranet__img.--img-1 {
    grid-row: 1;
    grid-column: 1/-1;
  }
}
.extranet__img.--img-2 {
  grid-row: 1;
  grid-column: 13/-1;
}
@media (max-width: 768px) {
  .extranet__img.--img-2 {
    grid-row: 2;
    grid-column: 1/-1;
  }
}
.extranet__img.--img-3 {
  grid-row: 2;
  grid-column: 1/12;
}
@media (max-width: 768px) {
  .extranet__img.--img-3 {
    grid-row: 3;
    grid-column: 1/-1;
  }
}
.extranet__img.--img-4 {
  grid-row: 2;
  grid-column: 13/-1;
}
@media (max-width: 768px) {
  .extranet__img.--img-4 {
    grid-row: 4;
    grid-column: 1/-1;
  }
}
.extranet__footer {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-self: flex-end;
  padding-left: 20px;
}
@media (max-width: 1024px) {
  .extranet__footer {
    padding-left: 15px;
  }
}
@media (max-width: 768px) {
  .extranet__footer {
    width: 100%;
    padding-left: 0;
  }
}

.--lock-scroll {
  overflow: hidden;
}

.--block {
  pointer-events: none;
}

.--bg-black {
  background-color: #000;
  color: #fff;
}
.--bg-black hr {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.--bg-yellow {
  background-color: #FFFF00;
  color: #000;
}

.--bg-red {
  background-color: #ff0000;
  color: #fff;
}
.--bg-red hr {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.--bg-dark {
  color: #fff;
}
.--bg-dark hr {
  color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.--full-screen {
  height: 100%;
  min-height: 600px;
}

.--richtext {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: normal;
}
.--richtext b, .--richtext strong {
  font-weight: 700;
}
.--richtext a {
  color: inherit;
  text-decoration: underline;
  font-weight: 700;
}
.--richtext a:hover {
  text-decoration: none;
}
.--richtext p {
  margin-bottom: 2rem;
}
.--richtext img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 2rem 0;
}
.--richtext hr {
  display: block;
  margin: 1.5rem 0;
  border-top: 1px solid black;
  border-bottom: 0;
}
.--richtext hr ~ p {
  margin-top: 2rem;
}
.--richtext ul {
  padding-left: 25px;
  list-style: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7214 4.61415L11.5263 0.138499C11.2872 -0.0672882 10.9279 -0.0406405 10.723 0.197719C10.5167 0.436078 10.5434 0.794345 10.7809 1.00014L14.7643 4.43043L0.570138 4.42895C0.255376 4.42895 0 4.68359 0 4.99745C0 5.31131 0.255376 5.56596 0.570138 5.56596L14.9068 5.56596L12.6367 7.58681L10.8639 8.98586C10.6174 9.18128 10.5743 9.53807 10.7703 9.78385C10.8832 9.92598 11.0495 10 11.2187 10C11.342 10 11.4667 9.96003 11.5721 9.87712L13.3716 8.45733L16.7315 5.46973C16.8533 5.36165 16.9231 5.20472 16.9231 5.04039C16.9171 4.87606 16.8459 4.72209 16.7212 4.61401L16.7214 4.61415Z' fill='black'/%3E%3C/svg%3E%0A");
}
.--richtext ul li {
  margin-bottom: 24px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .--richtext ul li {
    padding-left: 20px;
  }
}
@media (min-width: 992px) {
  .--richtext ul li {
    padding-left: 40px;
  }
}
@media (max-width: 1199px) {
  .--richtext {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}
@media (max-width: 575px) {
  .--richtext {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: normal;
  }
}

@media (min-width: 576px) {
  .--custom-scrollbars::-webkit-scrollbar {
    width: 0.5em;
    padding-left: 10px;
  }
  .--custom-scrollbars.--horizontal-scroll::-webkit-scrollbar {
    height: 0.5em;
  }
  .--custom-scrollbars::-webkit-scrollbar-track {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .--custom-scrollbars::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    outline: 0;
    border-radius: 5px;
  }
}

.--center {
  margin: auto;
  text-align: center;
  align-items: center;
}
@media (max-width: 575px) {
  .--center-sm {
    margin: auto;
    text-align: center;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .--center-lg {
    margin: auto;
    text-align: center;
    align-items: center;
  }
}

.pt-0 {
  padding-top: 0rem !important;
}
@media (min-width: 768px) {
  .pt-0 {
    padding-top: 0rem !important;
  }
}
@media (min-width: 992px) {
  .pt-0 {
    padding-top: 0rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-0 {
    padding-top: 0rem !important;
  }
}

.pt-25 {
  padding-top: 0.9615384615rem !important;
}
@media (min-width: 768px) {
  .pt-25 {
    padding-top: 1.25rem !important;
  }
}
@media (min-width: 992px) {
  .pt-25 {
    padding-top: 1.9230769231rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-25 {
    padding-top: 2.5rem !important;
  }
}

.pt-50 {
  padding-top: 1.9230769231rem !important;
}
@media (min-width: 768px) {
  .pt-50 {
    padding-top: 2.5rem !important;
  }
}
@media (min-width: 992px) {
  .pt-50 {
    padding-top: 3.8461538462rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-50 {
    padding-top: 5rem !important;
  }
}

.pt-75 {
  padding-top: 2.8846153846rem !important;
}
@media (min-width: 768px) {
  .pt-75 {
    padding-top: 3.75rem !important;
  }
}
@media (min-width: 992px) {
  .pt-75 {
    padding-top: 5.7692307692rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-75 {
    padding-top: 7.5rem !important;
  }
}

.pt-100 {
  padding-top: 3.8461538462rem !important;
}
@media (min-width: 768px) {
  .pt-100 {
    padding-top: 5rem !important;
  }
}
@media (min-width: 992px) {
  .pt-100 {
    padding-top: 7.6923076923rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-100 {
    padding-top: 10rem !important;
  }
}

.pb-0 {
  padding-bottom: 0rem !important;
}
@media (min-width: 768px) {
  .pb-0 {
    padding-bottom: 0rem !important;
  }
}
@media (min-width: 992px) {
  .pb-0 {
    padding-bottom: 0rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-0 {
    padding-bottom: 0rem !important;
  }
}

.pb-25 {
  padding-bottom: 0.9615384615rem !important;
}
@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 1.25rem !important;
  }
}
@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 1.9230769231rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-25 {
    padding-bottom: 2.5rem !important;
  }
}

.pb-50 {
  padding-bottom: 1.9230769231rem !important;
}
@media (min-width: 768px) {
  .pb-50 {
    padding-bottom: 2.5rem !important;
  }
}
@media (min-width: 992px) {
  .pb-50 {
    padding-bottom: 3.8461538462rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-50 {
    padding-bottom: 5rem !important;
  }
}

.pb-75 {
  padding-bottom: 2.8846153846rem !important;
}
@media (min-width: 768px) {
  .pb-75 {
    padding-bottom: 3.75rem !important;
  }
}
@media (min-width: 992px) {
  .pb-75 {
    padding-bottom: 5.7692307692rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-75 {
    padding-bottom: 7.5rem !important;
  }
}

.pb-100 {
  padding-bottom: 3.8461538462rem !important;
}
@media (min-width: 768px) {
  .pb-100 {
    padding-bottom: 5rem !important;
  }
}
@media (min-width: 992px) {
  .pb-100 {
    padding-bottom: 7.6923076923rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-100 {
    padding-bottom: 10rem !important;
  }
}