@import "../utils/countries-iso-codes";

.tag {
    &-boxed {
        display: inline-block;
        @include font-size(16, 28);
        background-color: $gray-lighter;
        padding: 4px 8px;

        @include device-md("min") {
            @include font-size(18, 32);
            padding: 8px 12px;
        }

        @include device-lg("min") {
            @include font-size(20, 34);
            padding: 8px 16px;
        }
    }

    &-rounded {
        display: inline-block;
        vertical-align: 0.5em;
        margin: 0 5px;
        @include font-size(10, 10, 600);
        text-transform: uppercase;
        border-radius: 12px;
        padding: 5px 10px;
        background-color: rgba($gray-light, .2);
        color: $gray;
        transition: background-color $timming ease-in-out;
    
        &.--with-flag {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-right: 0px;
    
            &::after {
                content: '';
                display: inline-block;
                width: 1.5em;
                height: 1.5em;
                background: transparent url('../images/icons/flags/ar.svg') center center no-repeat;
                background-size: cover;
            }
    
            @each $code in $iso-codes {
                &[data-flag="#{$code}"] {
                    &::after {
                        background-image: url('../images/icons/flags/#{$code}.svg');
                    }
                }
            }
        }
    }
}

.tags-list {
    &.--horizontal {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;

        @include device-md(){
            gap: 8px;
        }
    }
}