.geometric-separator-container{
    @include main-grid;
    padding-top: 240px;
    padding-bottom: 120px;
    margin: 0 auto;
    @include rwd(768){
        padding-top: 120px;
        padding-bottom: 60px;
        margin: 0 auto;
    }
}
.geometric-separator-img{
    width: 100%;
    grid-column: 1/-1;
}