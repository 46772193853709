.careers-circles {
    position: relative;
    height: 1280px;
    overflow: hidden;
    margin-top: 40px;
    margin-bottom: 240px;
    margin-left: auto;
    margin-right: auto;
    filter: invert(1);

    .circle-black {
        background-color: $white;
        height: 1280px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        mix-blend-mode: exclusion;
    }

    .left {
        position: absolute;
        right: 25vw;
        top: 0;
        overflow: hidden;
        transition: transform 2s ease-out;
    }

    .right {
        position: absolute;
        top: 0;
        left: 100vw;
        transition: transform 2s ease-in-out;
        overflow: hidden;
    }

    .circle-text {
        position: absolute;
        top: calc(50% - 205px);
        bottom: calc(50% - 205px);
        right: 0px;
        left: 0px;
        z-index: 3;
        height: max-content;
        transition: all 1.5s ease-out;

        @include rwd(1024) {
            margin: 0 auto;
        }

        h2 {
            margin-bottom: 40px;
            color: $white;
            transition: margin-bottom 1.5s ease-out;
            transition-delay: 1s;
        }

        p {
            margin-bottom: 0;
            margin-top: 0;
            color: $white;
            width: 390px;
            transition: none;
        }
    }
}

@include rwd(850) {
    .careers-circles {
        height: 1000px;
        
        .circle-black {
            height: 1000px;
        }

        .right {
            left: 90vw;
        }

        .left {
            right: 15vw;
        }
        
        .circle-text {
            top: calc(50% - 164px);
            bottom: calc(50% - 164px);
            h2, p {
                color: $white;
            }
        }
    }
}


@include device-md(){
    
    .careers-circles {
        margin-bottom: 120px;
        height: 667px;
        margin-bottom: 120px;
        
        .circle-black {
            height: 667px;
        }
        
        p {
            width: 256px !important;
        }

        .right {
            left: 90vw;
        }

        .left {
            right: 15vw;
        }
    }
}
@include device-sm() {
    .careers-circles {
        .right {
            left: 65vw;
        }
        
        .left {
            right: -10vw;
        }
    }
}