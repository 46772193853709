.text-with-tag-container {
    padding-top: 44px;
    padding-bottom: 240px;

    @include rwd(1024) {
        padding-top: 0;
        padding-bottom: 60px;
    }
}

.text-with-tag {
    display: flex;
    align-items: center;

    h4 {
        letter-spacing: -0.02em;
        @include device-lg {
            width: 100%;
        }
    }

    .tag-rounded {
        padding-right: 8px;
        padding-left: 8px;
    }

    @include device-sm() {
        align-items: flex-start;
        margin-bottom: 16px;
        .tag-rounded {
            margin-top: 5px;
            i {
                width: max-content;
            }
        }
    }
}
