.home {
    // ------------------------------------------------------
    // Hero Section
    &-hero {
        .section {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            height: 100%;
            padding-top: 120px;
        }
        h1 {
            @include device-md {
                font-size: 21vw;
                margin-bottom: 32px;
            }
            @include device-md("min") {
                margin-bottom: 64px;
            }
            @include device-xl("min") {
                margin-bottom: 48px;
            }
        }
    }
    // ------------------------------------------------------
    // Description Section
    &-motto {
        height: 110%;
        @include flex-center;
        background-color: $yellow;
        // clip-path: circle(5% at center);

        &__content {
            .motto-animation {
                li {
                    display: none;
                    position: relative;
                    opacity: 0;
                    top: 20px;
                }

                &.--animate {
                    li {
                        display: block;
                        animation: reveal 0.3s ease-in-out 1 forwards;

                        &:nth-child(1) {
                            animation-delay: 0;
                        }
                        &:nth-child(2) {
                            animation-delay: 0.3s;
                        }
                        &:nth-child(3) {
                            animation-delay: 0.6s;
                        }
                        &:nth-child(4) {
                            animation-delay: 1.2s;
                        }

                        mark {
                            background-color: transparent;

                            &.--active {
                                text-decoration: underline;
                                text-decoration-thickness: 3px;
                                text-decoration-skip-ink: none;
                                text-underline-offset: 0.1em;
                                font-style: italic;
                                margin-right: 5px;
                            }
                        }
                    }
                }

                @include device-xl {
                    font-size: 5vw;
                }

                @include device-sm {
                    font-size: 6vw;

                    &.--animate {
                        li {
                            mark {
                                &.--active {
                                    text-decoration-thickness: 2px;
                                }
                            }
                        }
                    }
                }

                @keyframes reveal {
                    from {
                        opacity: 0;
                        top: 20px;
                    }
                    to {
                        opacity: 1;
                        top: 0px;
                    }
                }
            }
        }
    }

    // ------------------------------------------------------
    // Portfolio / Design Journey Section
    &-design-journey {
        .section-divider {
            border-width: 2px;
        }
        padding: 110px 0 120px 0;
        @include device-md("min") {
            padding-top: 240px;
        }
        @include device-md("min") {
            padding-top: 230px;
        }
        &__box {
            color: $white;
        }
        &__header {
            padding-bottom: 32px;
        }
        &__secondary-h {
            p {
                padding-bottom: 0;
                @include device-md("min") {
                    width: 100%;
                    max-width: 620px;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            h2 {
                margin-bottom: 16px;

                @include device-md("min") {
                    width: 100%;
                    margin-bottom: 32px;
                    max-width: 840px;
                }
            }
            padding-bottom: 80px;
            @include device-sm {
                padding-bottom: 60px;
            }
        }
    }
    // -----------------------------------------------------
    // Services section
    &-services {
        // margin-top: 123px;
        // margin-bottom: 123px;
        padding-top: 123px;
        padding-bottom: 123px;

        @include device-md("min"){
            padding-top: 240px;
        }

        @include device-lg("min") {
            padding-top: 135px;
            padding-bottom: 135px;
        }

        &__box {
            @include main-grid;
            row-gap: 32px;
        }
        &__slider {
            position: relative;
            grid-column: 1 / -1;
            @include device-lg("min") {
                grid-column: span 9;
                max-width: 100%;
                height: auto;
            }
            // slider animation
            .mask-slider {
                &-box {
                    overflow: hidden;
                    position: relative;
                    img {
                        width: 100%;
                    }
                }
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $yellow;
                &.--animated-start {
                    animation: mask-animation-end 1.1s ease-in-out forwards;
                }
                &.--animated-end {
                    animation: mask-animation-start 1.1s ease-in-out forwards;
                }
                &.--animated-init {
                    animation: mask-animation-init 0.2s ease-in-out forwards;
                }
            }

            .swiper {
                @include device-lg {
                    aspect-ratio: 1;
                }
                z-index: -1;
            }
            .swiper-wrapper {
                @include device-lg {
                    position: absolute;
                }
            }
            .swiper-slide {
                @include device-lg {
                    aspect-ratio: 1;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }
        &__badge {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(-47%);
            @include device-lg("min") {
                top: unset;
                bottom: 0;
                left: 0;
                transform: translate(-10px, 24px);
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            grid-column: 1 / -1;
            align-items: flex-start;
            @include device-lg("min") {
                gap: 40px;
                grid-column: 11 / span 11;
                order: 1;
            }
        }
        &__title {
            margin-bottom: 0;
        }
        &__text p:last-of-type {
            margin-bottom: 0;
        }
    }
    // -----------------------------------------------------
    // Careers section
    &-careers {
        // margin-top: 123px;
        // margin-bottom: 123px;
        padding-top: 123px;
        padding-bottom: 123px;

        @include device-xl("min") {
            // margin-top: 135px;
            // margin-bottom: 135px;
            padding-top: 135px;
            padding-bottom: 135px;
        }
        &__box {
            @include main-grid;
            row-gap: 32px;
        }
        &__thumb {
            grid-column: -1;

            @include device-lg("min") {
                grid-column: 15 / -1;
            }
            &-box {
                overflow: hidden;
                position: relative;
                display: flex;
                @include device-lg {
                    aspect-ratio: 1;
                }
            }
            &-mask {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $yellow;
                &.--animated-init {
                    animation: mask-animation-init 0.2s ease-in-out forwards;
                }
            }
            &-img {
                width: 100%;
                @include device-lg {
                    object-fit: cover;
                }
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            grid-column: -1;
            @include device-lg("min") {
                gap: 40px;
                grid-column: span 12;
            }
        }
        &__title {
            margin-bottom: 0;
        }
        &__text p:last-of-type {
            margin-bottom: 0;
        }
    }
    // -----------------------------------------------------
    // ONU Programme section
    &-onu {
        padding-bottom: 0;
        padding-top: 0;
    }
    // -----------------------------------------------------
    // Culture section
    &-culture {
        text-align: center;
        margin-top: -40px;
        z-index: -1;
        @include device-md("min") {
            margin-top: 0;
        }
        @include device-lg("min") {
            margin-top: 32px;
        }
        @include device-xl("min") {
            margin-top: -92px;
        }
        &__box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            gap: 16px;
            padding-top: 120px;
            padding-bottom: 126px;
            padding-left: 16px;
            padding-right: 16px;
            @include device-md("min") {
                gap: 40px;
                padding-top: 120px;
                padding-bottom: 120px;
                padding-left: unset;
                padding-right: unset;
            }
            @include device-xl("min") {
                padding-top: 193px;
                padding-bottom: 153px;
            }
            & > h2 {
                margin-bottom: 0;
                @include device-md("min") {
                    max-width: 716px;
                    width: 100%;
                }
                @include device-xl("min") {
                    max-width: 840px;
                    width: 100%;
                }
            }
            p {
                @include device-md("min") {
                    max-width: 416px;
                    width: 100%;
                }
                @include device-xl("min") {
                    max-width: 619px;
                    width: 100%;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .gradient-background {
                position: absolute;
                inset: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: #eee742;
                animation: gradient-background 10s infinite ease-in-out;

                div {
                    position: absolute;
                    inset: 0;
                    z-index: 0;

                    &:first-child {
                        z-index: 1;
                        background: radial-gradient(
                                circle at 0 50%,
                                #dedada 0%,
                                rgba(255, 255, 255, 0) 100%
                            )
                            no-repeat;
                        animation: layer1 5s infinite ease-in-out;
                    }

                    &:nth-child(2) {
                        background: radial-gradient(
                                ellipse at 100% 80%,
                                #ecdc29 0%,
                                rgba(255, 255, 255, 0) 100%
                            )
                            no-repeat;
                    }

                    &:last-child {
                        background: radial-gradient(
                                ellipse at 90% 80%,
                                #b3c817 10%,
                                rgba(255, 255, 255, 0) 50%
                            )
                            no-repeat;
                        animation: layer3 5s infinite ease-in-out;
                    }
                }
            }
        }
    }
}

@keyframes mask-animation-start {
    0% {
        left: -100%;
        background-color: $yellow;
    }
    90% {
        left: -100%;
    }
    100% {
        left: 0;
        background-color: $yellow;
    }
}
@keyframes mask-animation-end {
    0% {
        left: 0;
        background-color: $yellow;
    }
    90% {
        left: 0;
    }
    100% {
        left: 100%;
        background-color: $yellow;
    }
}
@keyframes mask-animation-init {
    0% {
        left: 0;
        background-color: $yellow;
    }

    100% {
        left: -100%;
        background-color: $yellow;
    }
}

/* Background gradient animation */
@keyframes gradient-background {
    0% {
        background-color: #eee742;
    }
    70% {
        background-color: #eee742;
    }
    85% {
        background-color: #fffa87;
    }
    100% {
        background-color: #eee742;
    }
}

@keyframes layer1 {
    0% {
        opacity: 0.7;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.7;
    }
}

@keyframes layer3 {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
