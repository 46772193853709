@use "sass:math";

// asegurarse de que existan los mismos intervalos en el BaseBlock
$intervals: 0, 25, 50, 75, 100;
// $max: 8rem;
$max: 10rem;

@each $step in $intervals {
    .pt-#{$step} {
        padding-top: $max * math.div($step, 260) !important;

        @include device-md("min") {
            padding-top: $max * math.div($step, 200) !important;
        }

        @include device-lg("min") {
            padding-top: $max * math.div($step, 130) !important;
        }

        @include device-xl("min") {
            padding-top: $max * math.div($step, 100) !important;
        }
    }
}

@each $step in $intervals {
    .pb-#{$step} {
        padding-bottom: $max * math.div($step, 260) !important;

        @include device-md("min") {
            padding-bottom: $max * math.div($step, 200) !important;
        }

        @include device-lg("min") {
            padding-bottom: $max * math.div($step, 130) !important;
        }

        @include device-xl("min") {
            padding-bottom: $max * math.div($step, 100) !important;
        }
    }
}
