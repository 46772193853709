.main-header {
    $main-header: &;
    overflow: hidden;
    padding-bottom: 0;
    &__h1-seo-friendly{
        margin-bottom: 32px;
        @include font-size(180, 180, 500, -4);
        margin-bottom: 0.3em;
    
        @include device-xl {
            @include font-size(120, 120, 500, -3);
        }
        
        @include device-md {
            @include font-size(70, 70, 500, -3);
        }
        @include device-sm {
            // @include font-size(70, 70, 500, -2);
            @include font-size(56, 56, 500, -3);
        }
        @include device-md() {
            margin-bottom: 16px;
            // width: 317px;
        }

        img {
            height: 0.8em;
            vertical-align: baseline;
        }
    }
    h1 {
        margin-bottom: 32px;
        @include device-md() {
            margin-bottom: 16px;
            // width: 317px;
        }

        img {
            height: 0.8em;
            vertical-align: baseline;
        }
    }

    a {
        color: $black;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    &_text,
    &_text_link {
        p,
        ul,
        ol,
        a {
            @include font-size(20, 32, 400);

            @include device-sm("min") {
                @include font-size(24, 40, 400);
            }

            @include device-xl("min") {
                @include font-size(26, 44, 400);
            }
        }

        margin: 0;
        width: 100%;
        @include device-md("min") {
            max-width: 616px;
        }
    }

    &_link {
        color: $black;
    }

    &_separator {
        margin-top: 80px;
        position: relative;
        margin-bottom: 0;
        color: $black;
        @include device-md() {
            margin-top: 60px;
            position: unset;
        }
        hr {
            border: none;
            border-top: 2px solid $black;
            height: 2px;
        }
        .tnd-badge {
            display: none;
            @include device-lg("min") {
                display: unset;
                position: absolute;
                bottom: -100px;
                right: 0;
                transform: translateX(44%);
            }
            @include device-xl("min") {
                transform: translateX(-15%);
            }
        }
    }

    &_extra {
        font-weight: 700;
        margin-top: 32px;
        margin-bottom: 32px;
        @include device-md() {
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }
}
