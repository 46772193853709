.inspiring-forest {
    &__intro {
        opacity: 0;
        position: relative;
        min-height: 120vw;
        background: $gray-darker no-repeat top center;
        background-size: cover;

        &__quote {
            position: absolute;
            inset: auto 0 0 0;
            z-index: 0;
            color: $white;
            background: linear-gradient(0deg, 
                rgb(31, 41, 42) 55%, 
                rgba(31, 41, 42, 0.95) 60%, 
                rgba(31, 41, 42, 0.6) 70%, 
                rgba(31, 41, 42, 0.2) 80%, 
                rgba(31, 41, 42, 0.05) 90%, 
                rgba(31, 41, 42, 0) 100%
            );
            padding-top: 20vw;

            .main-grid {
                @include main-grid;

                blockquote {
                    display: block;
                    grid-column: 5 /span 15;
                    margin: auto;
    
                    p {
                        @include font-size(60, 70, 400, -3);
                        color: $white;
                        margin-bottom: 80px;
                    }
    
                    address {
                        display: flex;
                        flex-flow: row nowrap;
                        gap: 16px;
                        align-items: center;
    
                        img {
                            width: 73px;
                            height: 73px;
                            border-radius: 50%;
                        }
    
                        .info {
                            @include font-size(16, 28, 400);
    
                            strong {
                                @include font-size(20, 34, 700);
                            }
                        }
                    }
                }
            }

        }

        @include device-md {
            min-height: 130vw;

            &__quote {
                padding-top: 30vw;
                padding-bottom: 80px;

                .main-grid {
                    blockquote {
                        grid-column: 1 /span 7;
                        
                        p {
                            @include font-size(40, 50, 400, -2);
                        }

                        address {
                            .info {
                                @include font-size(14, 20, 400);
        
                                strong {
                                    @include font-size(18, 32, 700);
                                }
                            }
                        }
                    }
                }
            }
        }

        @include device-sm {
            min-height: 250vw;

            &__quote {
                padding-top: 40vw;
                padding-bottom: 60px;
                
                .main-grid {
                    blockquote {
                        margin: 0;

                        p {
                            @include font-size(24, 30, 400, -2);
                            margin-bottom: 32px;
                        }

                        address {
                            .info {
                                @include font-size(12, 20, 400);
        
                                strong {
                                    @include font-size(16, 28, 700);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__gallery {
        background-color: $gray-darker;
        
        &__grid {
            @include main-grid;
            align-items: flex-start;
            position: relative;

            img {
                @include image-responsive;
                position: relative;
            }

            .inspiring-forest__gallery__grid-img01 {
                grid-column: 1 /span 7;
                margin-bottom: 0 !important;
                top: 20%;
            }
    
            .inspiring-forest__gallery__grid-img02 {
                grid-column: 9 /span 7;
                grid-row: 2;
                z-index: 2;
                top: -50%;
            }
    
            .inspiring-forest__gallery__grid-img03 {
                grid-column: 17 /span 7;
                grid-row: 1;
                top: 70%;
            }

            .inspiring-forest__gallery__grid-img04 {
                grid-column: 1 /span 23;
                grid-row: 3;
                top: -17%;
                margin-bottom: -13%;
                z-index: 1;
            }

            .inspiring-forest__gallery__grid-img05 {
                grid-column: 1 /span 7;
                grid-row: 4;
                top: -10%;
                margin-bottom: -10%;
                z-index: 2;
            }
    
            .inspiring-forest__gallery__grid-img06 {
                grid-column: 9 /span 7;
                grid-row: 5;
                margin-top: 0 !important;
            }
    
            .inspiring-forest__gallery__grid-img07 {
                grid-column: 17 /span 7;
                grid-row: 6;
            }

            .inspiring-forest__gallery__grid-img08 {
                grid-column: 1 /span 23;
                grid-row: 7;
                margin-top: 2.5%;
            }
        }


        @include device-md {
            &__grid {
                .inspiring-forest__gallery__grid-img01 {
                    grid-column: 1 /span 3;
                    top: 0;
                }

                .inspiring-forest__gallery__grid-img02 {
                    grid-column: 3 /span 3;
                    grid-row: 2;
                    top: 0;
                    margin-top: 5%;
                }

                .inspiring-forest__gallery__grid-img03 {
                    grid-column: 5 /span 3;
                    margin-top: 80%;
                    top: 0;
                    grid-row: 1;
                }

                .inspiring-forest__gallery__grid-img04 {
                    grid-column: 1 /span 7;
                    margin-left: -16px;
                    width: calc(100% + 32px);
                    max-width: none !important;
                    margin-top: -4%;
                }

                .inspiring-forest__gallery__grid-img05 {
                    grid-column: 1 /span 3;
                    top: 15%;
                    margin-bottom: 1%;
                }

                .inspiring-forest__gallery__grid-img06 {
                    grid-column: 5 /span 3;
                    top: 15%;
                }

                .inspiring-forest__gallery__grid-img07 {
                    grid-column: 1 /span 3;
                    z-index: 1;
                    top: 15%;
                }

                .inspiring-forest__gallery__grid-img08 {
                    grid-column: 1 /span 7;
                    margin-left: -16px;
                    width: calc(100% + 32px);
                    max-width: none !important;
                }
            }
        }
    }


    &__dots-forest {
        background-color: $gray-darker;
        color: $white; 

        &__grid {
            @include main-grid;
            @include main-grid-columns(("xl": 6, "lg": 6, "md": 4, "sm": 4));
            row-gap: 2.5vw;
            margin-bottom: 240px;

            .dot-tree {
                display: block;
                width: 100%;
                
                circle {
                    fill: $green;
                }
            }

            @include device-lg {
                row-gap: 1.5vw;
            }

            @include device-md {
                row-gap: 3.5vw;
            }
        }

        &__counter {
            position: sticky;
            bottom: 72px;
            margin-bottom: 0;
            opacity: 0;
        }

        @include device-md {
            &__counter {
                bottom: 16px;
            }
        }

        @include device-sm {
            &__grid {
                margin-bottom: 120px;
            }
        }
    }

    &__zz-boxes {
        display: flex;
        flex-direction: column;
        row-gap: 100px;

        &__box {
            @include main-grid;
            min-height: 600px;

            .description {
                padding: 20px 0;
                grid-row: 1;

                h4 {
                    margin-bottom: 40px;
                }

                p {
                    @include font-size(20, 34);
                    margin-bottom: 0;

                    &:not(:last-child) {
                        margin-bottom: 1.5em;
                    }
                }
            }
            
            .image {
                height: 100%;
                background: no-repeat center center;
                background-size: cover;
                grid-row: 1;

                &.--video {
                    @include flex-center;
                    background: none;
                    position: relative;
                    overflow: hidden;

                    .icon {
                        @include flex-center;
                        position: relative;
                        z-index: 2;
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        background-color: $white;
                        transition: width $timming ease-in-out, height $timming ease-in-out;
                    }

                    .back {
                        position: absolute;
                        inset: 0;
                        background: no-repeat center center;
                        background-size: cover;
                        transform: scale(1.05);
                        transition: transform $timming ease-in-out, filter $timming ease-in-out;
                    }

                    &:hover {
                        .icon {
                            width: 110px;
                            height: 110px;
                        }

                        .back {
                            transform: scale(1);
                            filter: brightness(75%);
                        }
                    }
                }
            }

            &:nth-child(odd) {
                .description {
                    grid-column: 1 /span 11;
                }
                .image {
                    grid-column: 15 /span 9;
                }
            }

            &:nth-child(even) {
                .image {
                    grid-column: 1 /span 9;
                }
                .description {
                    grid-column: 13 /span 11;
                }
            }
        }

        @include rwd(920) {
            &__box {
                row-gap: 24px;

                .image {
                    min-height: 80vw;
                }

                &:nth-child(odd),
                &:nth-child(even) {
                    .description {
                        grid-column: 1 /span 23;
                        grid-row: 1;
                    }
                    .image {
                        grid-column: 1 /span 23;
                        grid-row: 2;
                    }
                }
            }
        }

        @include device-md {
            &__box {
                .description {
                    p {
                        @include font-size(18, 32);
                    }
                }

                &:nth-child(odd),
                &:nth-child(even) {
                    .description {
                        grid-column: 1 /span 7;
                    }
                    .image {
                        grid-column: 1 /span 7;
                    }
                }
            }
        }

        @include device-sm {
            &__box {
                .description {
                    p {
                        @include font-size(16, 28);
                    }
                }
                .image {
                    min-height: 100vw;
                }
            }
        }
    }


    &__sliders {
        position: relative;
        background: $gray-darker no-repeat top center;
        background-size: cover;
        padding: 0;
        margin-bottom: 240px;

        &--wrapper {
            padding-top: 240px;
            padding-bottom: 240px;
        }

        &__grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;

        }

        &__disclaimer {
            position: absolute;
            inset: auto 0 0 0;
            padding: 30px 0;
            text-align: center;
            @include font-size(14, 18);
            color: $white;
            background: linear-gradient(0deg, 
                rgb(31, 41, 42) 20%, 
                rgba(31, 41, 42, 0.5) 40%, 
                rgba(31, 41, 42, 0.35) 60%, 
                rgba(31, 41, 42, 0.2) 80%, 
                rgba(31, 41, 42, 0.05) 90%, 
                rgba(31, 41, 42, 0) 100%
            );
        }
        
        @include rwd(930) {
            &--wrapper {
                padding-top: 120px;
                padding-bottom: 120px;
            }

            &__grid {
                grid-template-columns: 1fr;
            }
        }

        @include device-sm {
            margin-bottom: 120px;
            &__disclaimer {
                @include font-size(12, 18);
                text-align: left;
            }
        }

        &__slider {
            display: block;
            width: 100%;
            min-height: 300px;

            .swiper-pagination {
                bottom: 55px;
                text-align: left;
                padding: 0 60px;

                &-bullet {
                    width: 14px;
                    height: 14px;
                    background-color: $gray;
                    cursor: pointer;
                    transition: background-color $timming ease-in-out, opacity $timming ease-in-out;

                    &-active {
                        background-color: $black;
                    }
                }
            }

            .swiper-button {
                &-next {
                    opacity: 0;
                    transition: opacity $timming ease-in-out;

                    &:after {
                        content: '';
                        width: 25px;
                        height: 25px;
                        border-top: 2px solid $black;
                        border-right: 2px solid $black;
                        margin-left: -15px;
                        transform: rotate(45deg);
                    }
                }

                &-prev {
                    opacity: 0;
                    transition: opacity $timming ease-in-out;

                    &:after {
                        content: '';
                        width: 25px;
                        height: 25px;
                        border-top: 2px solid $black;
                        border-right: 2px solid $black;
                        margin-right: -15px;
                        transform: rotate(-135deg);
                    }
                }

                &-disabled {
                    opacity: 0 !important;
                }
            }

            &:hover {
                .swiper-button {
                    &-next {
                        opacity: 1;
                    }
                    &-prev {
                        opacity: 1;
                    }
                }
            }

            .swiper-slide {
                padding: 60px 60px 120px;

                h4 {
                    margin-bottom: 40px;

                    strong {
                        display: block;
                        font-weight: inherit;
                        color: $green;
                    }
                }

                p {
                    @include font-size(20, 34);
                    margin-bottom: 0;

                    &:not(:last-child) {
                        margin-bottom: 1.5em;
                    }
                }
            }

            &.--light {
                background-color: $white;
                color: $black;
            }

            &.--dark {
                background-color: $black;
                color: $white;

                .swiper-pagination {
                    &-bullet {
                        background-color: $white;
                        &-active {
                            background-color: $white;
                        }
                    }
                }

                .swiper-button {
                    &-next {
                        &:after {
                            border-top-color: $white;
                            border-right-color: $white;
                        }
                    }
    
                    &-prev {
                        &:after {
                            border-top-color: $white;
                            border-right-color: $white;
                        }
                    }
                }
            }

            @include device-lg {
                .swiper-pagination {
                    bottom: 35px;
                    padding: 0 40px;

                    &-bullet {
                        width: 12px;
                        height: 12px;
                    }
                }

                .swiper-button {
                    &-next {
                        right: 5px;
                        &:after {
                            width: 20px;
                            height: 20px;
                        }
                    }
    
                    &-prev {
                        left: 5px;
                        &:after {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                .swiper-slide {
                    padding: 40px 40px 80px;

                    h4 {
                        margin-bottom: 32px;
                    }
    
                    p {
                        @include font-size(18, 32);
                    }
                }
            }

            @include device-sm {
                .swiper-pagination {
                    bottom: 22px;
                    padding: 0 32px;

                    &-bullet {
                        width: 10px;
                        height: 10px;
                    }
                }

                .swiper-button {
                    &-next {
                        right: 5px;

                        &:after {
                            width: 10px;
                            height: 10px;
                            margin-left: -5px;
                        }
                    }
    
                    &-prev {
                        left: 5px;
                        &:after {
                            width: 10px;
                            height: 10px;
                            margin-right: -5px;
                        }
                    }
                }

                .swiper-slide {
                    padding: 32px 32px 64px;

                    p {
                        @include font-size(16, 28);
                    }
                }
            }
        }
    }
}