.portfolio-list {
    @include main-grid;
    @include main-grid-columns(
        (
            "xl": 2,
            "lg": 2,
            "md": 2,
            "sm": 1,
        )
    );
    row-gap: 40px;
    align-items: unset;

    @include device-xl("min") {
        row-gap: 80px;
    }
}
.portfolio-box {
    // Clear anchor
    color: $black;
    text-decoration: none;
    display: block;
    &__container {
        @include device-md("min") {
            gap: 60px;
            padding-top: 23px;
        }
        @include device-xl("min") {
            gap: 91px;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 32px;
        transition: all $timming ease-in-out;
        border-top: 1px solid $black;
        padding-top: 16px;

        header {
            padding-right: 32px;
            max-width: 100%;
            @include device-xl("min") {
                padding-right: 48px;
            }
        }  

        &:hover, &:focus-within  {
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 16px;
            background-color: $black;
            color: $white;

            @include device-xl("min") {
                padding-left: 24px;
                padding-right: 24px;
                padding-bottom: 24px;
            }

            h3 {
                color: $yellow;
            }

            .portfolio-box__thumb {
                height: 244px;
                @include device-lg("min") {
                    height: 424px;
                }
                @include device-xl("min") {
                    height: 596px;
                }
            }
            .portfolio-box__client {
                opacity: 0.5;
            }

            header {
                padding-right: 0;
            }
        }
    }
    &__title {
        margin-bottom: 0;
    }
    &__client {
        font-size: 16px;
        line-height: 28px;
        @include device-md("min") {
            font-size: 18px;
            line-height: 32px;
        }
        @include device-xl("min") {
            font-size: 20px;
            line-height: 34px;
        }
    }
    &__thumb {
        position: relative;
        height: 260px;
        width: 100%;
        @include device-lg("min") {
            height: 440px;
        }
        @include device-xl("min") {
            height: 620px;
        }
        transition: all $timming ease-in-out;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
.--bg-black {
    .portfolio-box {
        &__container {
            color: $white;
            border-color: $white;

            &:hover, &:focus-within {
                background-color: $yellow;
                border-color: $yellow;
                color: $black;
                h3 {
                    color: $black;
                }
                
                .portfolio-box__client {
                    opacity: 1;
                }
            }
        }
        &__client {
            opacity: 0.5;
        }
    }
}

.--coming-soon{
    pointer-events: none;
    
}
