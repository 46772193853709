.tnd-badge, .apply-badge {
    max-width: 320px;
    max-height: 320px;

    .shadow {
        fill: $black;
        transform-origin: center;
        transition: transform $timming ease-in-out;
    }

    .badge {
        transition: transform $timming ease-in-out;
        transform-origin: center;

        .title {
            transition: transform $timming ease-in-out;
            transform-origin: center;
        }

        .text-circle {
            animation: rotation 15s infinite linear;
            transform-origin: center;
        }
    }

    &:hover {
        .badge {
            transform: translate(-0.5em, -0.5em);

            .title {
                transform: rotate(-10deg);
            }
        }
        .shadow {
            transform: scale(1.05);
        }
    }
}

.smile-badge {
    width: 137px;
    height: 137px;
    
    @include device-lg("min") {
        width: 180px;
        height: 180px;
    }
    .text-circle {
        animation: rotation 15s infinite linear;
        transform-origin: center;
    }
}

.apply-badge {
    overflow: visible;
    width: 400px;
    height: 400px;
    margin-top: 120px;
    @include device-sm() {
        width: 340px;
        height: 340px;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.--bg-black,
.--bg-red {
    .tnd-badge {
        .shadow {
            fill: $white;
        }
    }
    .smile-badge {
        &__back {
            fill: $white;
        }
    }
}
