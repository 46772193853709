.manifesto {
    .upper-section {
        @include main-grid;
            
        h1 {
            grid-column: 1 /span 23;
        }

        h3 {
            grid-column: 1 /span 23;
            grid-row: 4;
            margin-top: 30px;
        }

        p {
            @include font-size(20, 34, 700, 0);
            grid-column: 1 /span 23;
            grid-row: 3;
            margin-top: 30px;
        }

        div {
            grid-column: 1 /span 23;
            grid-row: 2;
            border-bottom: 2px solid $black;
            margin-top: 80px;
        }
    }

    .down-section {
        margin-top: 250px;
        margin-bottom: 240px;

        .txt-div {
            @include main-grid;
    
            p {
                @include font-size(20, 34, 700, 0);
                grid-column: 13 / span 10;
                margin-bottom: 35px;
            }
        }

        .manifesto-grid {
            @include main-grid;
            @include font-size(18, 31);
            align-items: flex-start;
            border-top: 1px solid $black;

            .manifesto-grid-left {
                grid-column: 1 /span 11;
            }

            .manifesto-grid__nav {
                grid-column: 13 /span 11;

                .btn__list {
                    border-width: 0;
                }
            }
        }
    }

    @include device-lg(){
        .upper-section {
            p {
                @include font-size(16, 24);
            }
        }

    }
    
    @include device-md(){
        .down-section {
            .txt-div {
                p {
                    grid-column: 1 / span 5;
                }
            }
            .manifesto-grid {
                border: 0;
                .manifesto-grid__nav {
                    grid-column: 1 /span 7;

                    .btn__list {
                        border-width: 1px;
                    }
                }
            }
        }
    }
}