.three-images{
    &__section{
        &.--before-challenge{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(6, 220px);
            column-gap: 40px;
            padding-top: 60px;
            padding-bottom: 60px;
            @include rwd(1024){
                grid-template-rows: repeat(6, 150px);
                column-gap: 20px;
            }
            @include rwd(768){
                display: flex;
                flex-direction: column;
                column-gap: 16px;
            }
            .case-challenge{
                &__img-1{
                    width: 100%;
                    grid-row: 1/2;
                    grid-column: 1;
                }
                &__img-2{
                    width: 100%;
                    grid-row: 2/5;
                    grid-column: 2;
                }
                &__img-3{
                    width: 100%;
                    grid-row: 5/6;
                    grid-column: 1;
                }
            }
        }
        &.--after-challenge{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(4, 435px);
            column-gap: 40px;
            padding-top: 180px;
            padding-bottom: 60px;
            @include rwd(1024){
                grid-template-rows: repeat(4, 300px);
                column-gap: 20px;
            }
            @include rwd(768){
                display: flex;
                flex-direction: column;
                column-gap: 16px;
            }
            .case-challenge{
                &__img-1{
                    width: 100%;
                    grid-row: 1/2;
                    grid-column: 1;
                }
                &__img-2{
                    width: 100%;
                    grid-row: 2/3;
                    grid-column: 2;
                }
                &__img-3{
                    width: 100%;
                    grid-row: 4;
                    grid-column: 1;
                }
            }
        }
        &.--footer{
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 40px;
            padding-top: 180px;
            padding-bottom: 0px;
            row-gap: 40px;
            @include rwd(1024){
                column-gap: 20px;
                row-gap: 20px;
            }
            @include rwd(768){
                display: flex;
                flex-direction: column;
                row-gap: 16px;
            }
            .case-challenge{
                &__img-1{
                    width: 100%;
                    height: 100%;
                    display: block;
                    grid-row: 1;
                    grid-column: 1;
                }
                &__img-2{
                    width: 100%;
                    height: 100%;
                    display: block;
                    grid-row: 2;
                    grid-column: 2;
                }
                &__img-3{
                    width: 100%;
                    height: 100%;
                    display: block;
                    grid-row: 3;
                    grid-column: 1;
                }
            }
        }
    }
}

.design-process{
    &__section{
        background-color: #F5F7FF;
        padding-top: 120px;
        position: relative;
        @include rwd (768){
            padding-bottom: 332px;               
        }
    }
    &__content{
        @include main-grid;
        @include rwd (768){
            display: flex;
            flex-direction: column;
        }
        &__hr{
            width: 100%;
            grid-row: 1;
            grid-column: 1/-1;
            border-top: 1px solid #000;
            margin-bottom: 150px;
            p{
                font-weight: 700;
                font-size: 20px;
                line-height: 34px;
                margin: 32px 0 0 0;
            }
            @include rwd(768){
                margin-bottom: 60px;
            }
        }
        &__text-container{
            grid-row: 2;
            grid-column: 5/20;
            text-align: center;
            margin-bottom: 120px;
            @include rwd (1024){
                grid-column: 3/22;
            }
            @include rwd (768){
                text-align: start;
                margin-bottom: 60px;
            }
            h2{
                font-weight: 500;
                font-size: 80px;
                line-height: 92px;
                letter-spacing: -0.03em;
                margin-bottom: 32px;
                @include rwd (1024){
                    font-size: 64px;
                    line-height: 74px;
                }
                @include rwd (768){
                    font-size: 32px;
                    line-height: 40px;
                }
            }
            p{
                font-weight: 400;
                font-size: 20px;
                line-height: 34px;
                margin: 0;
                @include rwd (1024){
                    font-size: 18px;
                    line-height: 32px;
                }
                @include rwd (768){
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
        &__image-1{
            display: flex;
            flex-direction: column;
            grid-row: 3;
            grid-column: 1/12;
            background-color: #fff;
            height: 100%;
            @include rwd (768){
                margin-bottom: 16px;
            }
        }
        &__image-2{
            display: flex;
            flex-direction: column;
            grid-row: 3;
            grid-column: 13/-1;
            background-color: #fff;
            height: 100%;
        }
        &__img{
            width: 100%;
        }
        &__image-text-container{
            padding: 40px 80px 80px 80px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include rwd (1024){
                padding: 40px;
            }
        }
        &__image-title{
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            text-align: center;
            letter-spacing: -0.02em;
            margin: 0;
            margin-bottom: 16px;
            @include rwd(1024){
                font-size: 24px;
                line-height: 34px;
            }
        }
        &__image-text{
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            text-align: center;
            margin: 0;
            @include rwd(1024){
                font-size: 18px;
                line-height: 32px;
            }
        }
        &__digital-culture{
            grid-row: 4;
            grid-column: 1/-1;
            display: flex;
            flex-direction: column;
            padding-top: 240px;
            @include rwd (768){
                padding-top: 110px;
            }
            &__img{
                width: 100%;
            }
            &__text{
                width: 400px;
                height: 400px;
                display: flex;
                align-self: flex-end;
                align-items: center;
                @include rwd (1024){
                    height: 305px;
                    width: 273px;
                }
                @include rwd (768){
                    width: 100%;
                    height: auto;
                    align-self: flex-start;  
                    margin-top: 32px; 
                }
                p{
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 34px;
                    margin: 0;
                    @include rwd(1024){
                        font-size: 18px;
                        line-height: 32px;  
                    }
                }
                
            }
        }
    }
    &__hand{
        position: absolute;
        width: 257px;
        height: 526px;
        bottom: -47px;
        right: 44%;
        &__img{
            width: 100%;
            height: 100%;
        }
        @include rwd (1024){
            height: 399px;
            width: 195px;
            bottom: -40px;
        }
        @include rwd (768){
            height: 332px;
            width: 162px;
            bottom: -60px;    
            right: 50%;
            transform: translate(50%, 0);     
        }
    }
}

.chapter{
    &__section{
        padding-top: 47px;
        padding-bottom: 60px;
        @include rwd (1024){
            padding-top: 40px;
        }
        @include rwd (768){
            padding-top: 60px;
        } 
        &.--chapter-2{
            color: #3443CA;
            padding-top: 120px;
            padding-bottom: 0;
            @include rwd (1024){
                padding-top: 0;
            }
            .chapter__content{
                border-top: 1px solid #3443CA;
            }
        }
        &.--divider-out{
            .chapter__content{
                border-top: none;
            }
        }
        &.--chapter-4{
            padding-top: 240px;
        }
        &.--accessibility{
            padding-top: 360px;
            @include rwd(768){
                padding-top: 120px;
            }
        }
    }
    &__box-section{
        padding-bottom: 60px;
    }
    &__content{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        border-top: 1px solid #000;
        padding-top: 120px;
        @include rwd (768){
            padding-top: 60px;
        }
    }
    &__text-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        row-gap: 32px;
        max-width: 840px;
        @include rwd (1024){
            max-width: 716px;
        }
        @include rwd (768){
            max-width: 100%;
        }
    }
    &__subtitle{
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        margin: 0;
    }
    &__title{
        font-weight: 400;
        font-size: 60px;
        line-height: 70px;
        margin: 0;
        @include rwd (1024){
            font-size: 40px;
            line-height: 50px;
            max-width: 500px;
        }
        @include rwd (768){
            font-size: 24px;
            line-height: 30px;
            max-width: 100%;
        }
    }
    &__description{
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        margin: 0;
        @include rwd (1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd (768){
            font-size: 16px;
            line-height: 28px;
        }
    }
    &__boxes-container{
        @include main-grid;
        padding-top: 120px;
        align-items: flex-start;
        @include rwd(768){
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            overflow: auto;
        }
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
    }
    &__box{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-top: 4px solid #3443CA;
        padding-top: 40px;
        color: #3443CA;
        @include rwd(1024){
            padding-top: 32px;
        }
        @include rwd(768){
            min-width: 200px;
            margin-right: 16px;
        }
        &.--box-1{
            grid-column: 1/6;
        }
        &.--box-2{
            grid-column: 7/12;
        }
        &.--box-3{
            grid-column: 13/18;
        }
        &.--box-4{
            grid-column: 19/24;
        }
        &__number{
            font-weight: 400;
            font-size: 60px;
            line-height: 100%;
            margin: 0;
            margin-bottom: 40px;
            @include rwd(1024){
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 24px;
            }
            @include rwd(768){
                font-size: 30px;
                line-height: 30px;
                margin-bottom: 16px;
            }
        }
        &__title{
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            margin: 0;
            margin-bottom: 16px;
            @include rwd(1024){
                font-size: 24px;
                line-height: 34px;
            }
            @include rwd(768){
                font-size: 20px;
                line-height: 26px;
            }
        }
        &__description{
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            margin: 0;
            color: #000;
            @include rwd(1024){
                font-size: 18px;
                line-height: 32px;
            }
            @include rwd(768){
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
    &__img{
        width: 100%;
        margin-bottom: 120px;
        margin-top: 120px;
        @include rwd(768){
            margin-bottom: 60px;
            margin-top: 60px;
        }
    }
    &__two-boxes{
        @include main-grid;
        align-items: flex-start;
        &__box{
            display: flex;
            flex-direction: column;
            border-top: 2px solid #000;
            text-align: left;
            padding-top: 40px;
            padding-right: 70px;
            @include rwd(768){
                padding-right: 0;
                padding-top: 32px;
            }
            &-title{
                font-weight: 600;
                font-size: 26px;
                line-height: 36px;
                letter-spacing: -0.02em;
                margin-bottom: 24px;
                @include rwd (1024){
                    font-size: 24px;
                    line-height: 34px;
                }
                @include rwd(768){
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 16px;
                }
            }
            &-description{
                font-weight: 400;
                font-size: 20px;
                line-height: 34px;
                @include rwd (1024){
                    font-size: 18px;
                    line-height: 32px;
                }
                @include rwd(768){
                    font-size: 16px;
                    line-height: 28px;
                }
            }
            &.--box-1{
                grid-column: 1/12;
                @include device-sm{
                    grid-column: 1/4;
                }
            }
            &.--box-2{
                grid-column: 13/-1;
                @include device-sm{
                    grid-column: 5/-1;
                }
            }
        }
    }

}

.six-premises{
    &__section{
        @include main-grid;
        row-gap: 40px;
        padding-top: 60px;
        padding-bottom: 120px;
        @include rwd (768){
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            padding-bottom: 60px;
        }
    }
    &__hr{
        width: 100%;
        grid-row: 1;
        grid-column: 1/-1;
        border-top: 1px solid #000;
        @include rwd (768){
            margin-bottom: 16px;
        }
        p{
            font-weight: 700;
            font-size: 20px;
            line-height: 34px;
            margin: 32px 0 0 0;
        }
    }
    &__box{
        display: flex;
        flex-direction: column;
        &-text{
            padding: 40px 80px 80px 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include rwd (1024){
                padding: 40px;
            }
        }
        &-title{
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            text-align: center;
            letter-spacing: -0.02em;
            margin: 0;
            margin-bottom: 16px;
            @include rwd(1024){
                font-size: 24px;
                line-height: 34px;
            }
        }
        &-description{
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            text-align: center;
            margin: 0;
            @include rwd(1024){
                font-size: 18px;
                line-height: 32px;
            }
        }
        &.--first {
            grid-row: 2;
            grid-column: 1/12;
            background-color: #FDEECC;
        }
        &.--second {
            grid-row: 2;
            grid-column: 13/-1;
            background-color: #E0E0FF;
        }
        &.--third {
            grid-row: 3;
            grid-column: 1/12;
            background: #FEDAE5;
        }
        &.--fourth {
            grid-row: 3;
            grid-column: 13/-1;
            background: #FFD6F7;
        }
        &.--fifth {
            grid-row: 4;
            grid-column: 1/12;
            background: #FFEEC5;
        }
        &.--sixth {
            grid-row: 4;
            grid-column: 13/-1;
            background: #D2F7FF;
        }
    }
    &__img{
        width: 100%;
    }
}

.stack{
    &__section{
        padding-top: 180px;
        @include rwd(768){
            padding-left: 0;
            padding-right: 0;
            padding-top: 60px;
        }
    }
    &__content{
        @include main-grid;
        background-color: #FFDED1;
        padding-top: 240px;
        padding-bottom: 120px;
        @include rwd(768){
            padding-top: 120px;
            padding-bottom: 60px;
        }
    }
    &__title-container{
        grid-column: 1/-1;
        grid-row: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 140px;
        @include rwd(1024){
            margin-bottom: 80px;
        }
    }
    &__subtitle{
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 32px;
    }
    &__title{
        margin: 0;
        &.--spanish{
            text-align: center;
            width: 80%;
            @include rwd(768){
                width: 100%;
            }
        }
    }
    &__images-container{
        grid-column: 1 / -1;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            padding: 0 16px;
            @include rwd(768) {
                flex-wrap: wrap;
                gap: 15px;
                padding: 0;
            }
    }
    &__image{
            max-width: 250px;
            width: 100%;
            height: auto;
            display: block;
            min-width: 0;
            @include rwd(1024) {
                max-width: 164px;
            }
            @include rwd(768) {
                max-width: 170px;
            }
            @include rwd(425) {
                max-width: 190px;
            } 
            @include rwd(375) {
                max-width: 164px;
            }
            @include rwd(320) {
                max-width: 135px;
            }          
    }
    &__description-container{
        grid-row: 3;
        grid-column: 5/20;
        display: flex;
        flex-direction: column;
        margin-top: 120px;
        @include rwd(1024){
            margin-top: 100px;
        }
        @include rwd(768){
            grid-column: 1/-1;
            padding: 0 16px;
            margin-top: 60px;
        }
    }
    &__description{
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        @include rwd(1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd(768){
            font-size: 16px;
            line-height: 28px;
        }
        span{
            font-weight: 700;
        }
    }
}

.digital-experience{
    &__section {
        background-color: #F5F7FF;
        margin-top: 120px;
        padding-top: 120px;
        padding-bottom: 145px;
        @include rwd(768){
            padding-top: 100px;
            padding-bottom: 60px;
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__text-container{
        text-align: center;
        max-width: 840px;
        margin-top: 150px;
        margin-bottom: 240px;
        @include rwd(1024){
            max-width: 716px;
        }
        @include rwd(768){
            margin-top: 100px;
            margin-bottom: 60px;
        }
        p{
            span{
                font-weight: 700;
            }
        }
    }
    &__img{
        width: 100%;
    }
}

.design-system{
    &__section{
        padding-top: 360px;
        @include rwd(768){
            padding-top: 120px;
        }
    }
    &__header{
        margin: auto;
        max-width: 840px;
        margin-bottom: 120px;
        h4{
            margin-bottom: 32px;
        }
        p{
            margin: 0;
        }
    }
    &__icon{
        height: 180px;
        margin-bottom: 60px;
        @include rwd(768){
            height: 118px;
        }
    }
    &__images-container{
        @include main-grid;
        row-gap: 40px;
        margin-top: 120px;
        @include rwd(1024){
            row-gap: 20px;
        }
        @include rwd(768){
            row-gap: 16px;
            margin-top: 60px;
        }
    }
    &__img{
        width: 100%;
        height: 100%;
        &.--img-1{
            grid-row: 1;
            grid-column: 1/12;
            @include rwd(768){
                grid-row: 1;
                grid-column: 1/-1;
            }
        }
        &.--img-2{
            grid-row: 1;
            grid-column: 13/-1;
            @include rwd(768){
                grid-row: 2;
                grid-column: 1/-1;
            }
        }
        &.--img-3{
            grid-row: 2;
            grid-column: 1/12;
            @include rwd(768){
                grid-row: 4;
                grid-column: 1/-1;
            }
        }
        &.--img-4{
            grid-row: 2;
            grid-column: 13/-1;
            @include rwd(768){
                grid-row: 3;
                grid-column: 1/-1;
            }
        }
    }
}

.case-quote {
    &.--osde-1{
        @include rwd(768){
            padding-bottom: 120px;
        }
        .big-list-title{
            @include rwd(1440){
                grid-column: 1/20;
            }
        }
    }
    &.--osde{
        .case-quote__box{
            margin: auto;
        }
        .case-quote__author{
            line-height: 1.8;
            @include rwd(768){
                line-height: 1.2;
            }
        }
    }
    &.--julio-quote{
        @include rwd(768){
            padding-bottom: 120px;
            padding-top: 120px;
        }
        .big-list-title{
            @include rwd(1440){
                grid-column: 1/18;
            }
            @include rwd(1024){
                grid-column: 1/20;
            }
        }
    }
    &.--charo-quote{
        @include rwd(768){
            padding-bottom: 120px;
        }
        .big-list-title{
            @include rwd(1440){
                grid-column: 1/20;
            }
        }
    }
    &.--emiliano-quote{
        padding-top: 240px;
        padding-bottom: 260px;
        @include rwd(768){
            padding-bottom: 40px;
        }
        .big-list-title{
            @include rwd(1440){
                grid-column: 1/18;
            }
            @include rwd(1024){
                grid-column: 1/20;
            }
        }
    }
}

.define-users{
    &__section{
        padding-top: 100px;
        padding-bottom: 60px;
        @include rwd(768){
            padding-top: 60px;
        }
    }
    &__divider{
        grid-row: 1;
        width: 100%;
        border-top: 2px solid #000;
        margin-bottom: 72px;
        padding-top: 20px;
        h5{
            margin: 0;
        }
        @include rwd(768){
            margin-bottom: 60px;
        }
    }
    &__content{
        @include main-grid;
        grid-template-rows: repeat(2, 240px);
        row-gap: 40px;
        @include rwd(1024){
            row-gap: 20px;
            grid-template-rows: repeat(2, 164px);
        }
        @include rwd(768){
            display: flex;
            flex-direction: column;
            row-gap: 16px;
        }
    }
    &__img-1{
        grid-row: 1;
        grid-column: 1/12;
        align-self: end;
        @include rwd(768){
            grid-row: 1;
            grid-column: 1/-1;
        }
    }
    &__img-2{
        grid-row: 2;
        grid-column: 1/12;
        align-self: baseline;
        @include rwd(768){
            grid-row: 2;
            grid-column: 1/-1;
        }
    }
    &__img-3{
        grid-row: 1/span 2;
        grid-column: 13/-1;
        @include rwd(768){
            grid-row: 3;
            grid-column: 1/-1;
        }
    }
    &__img{
        width: 100%;
        height: 100%;
        display: block;
    }
}

.slider-principles{
    &__section{
        @include main-grid;
        padding-top: 60px;
        padding-bottom: 60px;
        @include rwd(768){
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    }
    &__divider{
        grid-row: 1;
        grid-column: 1/-1;
        width: 100%;
        border-top: 2px solid #000;
        margin-bottom: 20px;
        @include rwd(768){
            width: 92%;
            margin: auto;
            margin-bottom: 20px;
        }
    }
    &__section-title{
        grid-row: 2;
        grid-column: 1/6;
        align-self: baseline;
        margin-bottom: 80px;
        h5{
            margin: 0;
        }
        @include rwd(1024){
            margin-bottom: 90px;
            grid-column: 1/8;
        }
        @include rwd(768){
            margin-bottom: 60px;
            padding-left: 16px;
            padding-right: 16px;
            grid-column: 1/-1;
        }
    }
    &__slider-pagination{
        grid-row: 2;
        grid-column: 9/15;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-self: baseline;
        margin-bottom: 80px;
        @include rwd(1024){
            margin-bottom: 90px;
        }
        @include rwd(768){
            display: none;
        }
    }
    &__buttons-container{
        display: flex;
        flex-direction: row;
        align-self: end;
        justify-content: space-between;
        width: 165px;
        @include rwd(768){
            display: none;
        }
    }
    &__slider-text{
        display: flex;
        flex-direction: row;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
        color: #F1F1F1;
        .swiper-pagination-current{
            color: #000;
        }
        @include rwd(1024){
            font-size: 34px;
            line-height: 40px;
        }
    }
    &__text-container{
        grid-row:3;
        grid-column: 1/6;
        align-self: baseline;
        p{
            margin: 0;
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            user-select: none;
        }
        @include rwd(1024){
            grid-column: 1/8;
            p{
                font-size: 18px;
                line-height: 32px;
            }
        }
        @include rwd(768){
            margin-bottom: 24px;
            padding-left: 16px;
            padding-right: 16px;
                grid-column: 1/-1;
        }
    }
    &__slider{
        grid-row: 3;
        grid-column: 9/-1;
        margin: 0 !important;
        height: 490px;
        @include rwd(1024){
            height: 380px;
        }
        @include rwd(768){
            grid-column: 1/-1;
            grid-row: 4;
            padding-left: 16px !important;
        }
    }
    &__card{
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #FFFBE3;
        &.--messages{
            background-color: #E5FFF2;
        }
        &-img{
            width: 100%;
            display: block;
        }
        &-text{
            padding: 40px 40px 0 40px;
            @include rwd(1024){
                padding: 30px 16px 0 16px;
            }
        }
        &-title{
            font-weight: 700;
            font-size: 20px;
            line-height: 130%;
            @include rwd(1024){
                font-size: 16px;
            }
        }
        &-subtitle{
            margin-bottom: 16px;
            font-size: 20px;
            line-height: 130%;
            @include rwd(1024){
                font-size: 16px;
            }
        }
        &-description{
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin: 0;
            @include rwd(1024){
                font-size: 14px;
            }
        }
    }
}

.behavior-patterns{
    &__title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        row-gap: 32px;
        max-width: 840px;
        margin: auto;
        color: #FFFFFF;
        margin-bottom: 240px;
        @include rwd (1024){
            max-width: 716px;
        }
        @include rwd (768){
            max-width: 100%;
            padding: 0 32px;
            margin-bottom: 100px;
            row-gap: 16px;
        }
        @include device-sm{
            padding: 0 16px;
        }
    }
    &__subtitle{
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        margin: 0;
    }
    &__title{
        margin: 0;
    }
    &__description{
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        margin: 0;
        @include rwd (1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd (768){
            font-size: 16px;
            line-height: 28px;
        }
    }
    &__images-txt {
        position: relative;
        background-color: #1A4E66;
        @include device-md {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .wrapper{
            @include main-grid();
            row-gap: 110px;
            @include device-lg {
                row-gap: 16px;
            }
        }
        
        img {
            @include image-responsive;
            width: 100%;
        }

        &__textbox {
            padding: 50px;
            @include rwd(1024){
                padding: 40px;
            }
            @include rwd(768){
                padding: 32px;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            z-index: 1;
            &:nth-of-type(1){
                grid-column: 1 / 10;
                background: #FFEAE2;
                @include device-md("min") {
                    grid-row: 1 / 3;
                }
            }
            &:nth-of-type(2){
                grid-column: 15 / -1;
                background: #F5F7FF;
                @include device-md("min") {
                    grid-row: 1 / 2;
                }
            }
            &:nth-of-type(3){
                grid-column: 11 / 20;
                background: #FFFFFF;
                @include device-md("min") {
                    grid-row: 3 / 4;
                }
            }
            &:nth-of-type(4){
                grid-column: 1 / 10;
                grid-row: 4 / 6;
                background: #E7E9F7;
                @include device-md("min") {
                    grid-row: 5 / 7;
                }
            }
            &:nth-of-type(5){
                grid-column: 15 / -1;
                background: #FFF7CA;
                @include device-md("min") {
                    margin-top: -110px;
                }
                @include device-md("min") {
                    grid-row: 6 / 8;
                }
            }
            &.--img-bottom{
                flex-direction: column-reverse;
            }
            &.--img-top{
                flex-direction: column;
            }

            &.--border{
                border: 2px solid #D3D3D3;
            }
            &.--shadow{
                filter: drop-shadow(0px 20px 80px rgba(0, 0, 0, 0.15));
            }

            @include device-md {
                grid-column: 1 / -1 !important;
            }

            &__title {
                @include font-size(26, 36, 600, -2);
                margin-bottom: 16px;
                
                @include device-lg {
                    @include font-size(24, 34, 600);
                }
                @include device-md {
                    @include font-size(20, 26, 600, -3);

                }
            }
            &__text {
                @include font-size(20, 32);
                p:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        &.--osde{
            padding-top: 240px;
            padding-bottom: 120px;
            color: inherit;
            .wrapper{
                row-gap: 0;
            }
            @include rwd(1024){
                padding-bottom: 140px;
            }
            @include rwd(768){
                padding-bottom: 120px;
                padding-top: 120px;
                .wrapper{
                    display: flex;
                    flex-direction: column;
                }
            }
            @include device-sm{
                padding-bottom: 80px;
            }
            
            .behavior-patterns__images-txt__textbox{
                padding: 40px;
                @include rwd(768){
                    padding: 30px;
                }
            }
            .behavior-patterns__images-txt__item:nth-child(1) {
                grid-row: 1;
            }
            .behavior-patterns__images-txt__item:nth-child(2) {
                grid-row: 2;
                margin-top: -280px;
            }
            .behavior-patterns__images-txt__item:nth-child(3) {
                grid-row: 3;
                margin-top: 80px;
            }
            .behavior-patterns__images-txt__item:nth-child(4) {
                grid-row: 4;
                margin-top: 80px;
            }
            .behavior-patterns__images-txt__item:nth-child(5) {
                grid-row: 5;
                margin-top: -85px;
            }
            @include rwd(768){
                .wrapper{
                    .behavior-patterns__images-txt__item{
                        margin-top: 16px;
                    }
                    .behavior-patterns__images-txt__item:first-child{
                        margin-top: 29px;
                    }
                }
            }   
        }
    }
}

.within-the-design-system{
    &__section{
        padding-top: 60px;
        @include rwd(768){
            padding-top: 0;
        }
    }
    &__divider{
        display: block;
        width: 100%;
        border-top: 2px solid #000;
        margin-bottom: 30px
    }
    &__section-title{
        margin-bottom: 32px;
        h5{
            margin-bottom: 0
        }
    }
    &__card-container{
        @include main-grid();
        row-gap: 40px;
        @include rwd(1024){
            row-gap: 20px;
        }
        @include rwd(768){
            display: flex;
            flex-direction: row;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
        }
    }
    &__card{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 300px;
        width: 100%;
        background-color: #FFF3EE;
        @include rwd(1024){
            height: 250px;
        }
        @include rwd(768){
            flex-direction: column;
            height: 375px;
            min-width: 253px;
            margin-right: 16px;
            align-items: flex-end;
        }
        &-text{
            width: 65%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 70px 0 40px;
            @include rwd(1024){
                padding: 0 24px 0 32px;
            }
            @include rwd(768){
                padding: 24px 24px 0 24px;
                width: 100%;
            }
        }
        &-title{
            font-weight: 700;
            font-size: 20px;
            line-height: 34px;
            margin-bottom: 8px;
            @include rwd(1024){
                font-size: 18px;
                line-height: 26px;
            }
        }
        &-description{
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            margin: 0;
            @include rwd(1024){
                font-size: 18px;
                line-height: 32px;
            }
        }
        &-image{
            max-width: 35%;
            @include rwd(768){
                height: 40%;
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                @include rwd(768){
                    width: auto;
                    height: 100%;
                }
            }
        }
        &.--card-1{
            grid-row: 1;
            grid-column: 1 / 12;
        }
        &.--card-2{
            grid-row: 1;
            grid-column: 13 / -1;
        }
        &.--card-3{
            grid-row: 2;
            grid-column: 1 / 12;
        }
        &.--card-4{
            grid-row: 2;
            grid-column: 13 / -1;
            margin-right: 0;
        }
    }
}

.chapter-library{
    &__section{
        @include main-grid();
        padding-top: 310px;
        row-gap: 70px;
        @include rwd (1024){
            row-gap: 120px;
        }
        @include rwd(768){
            row-gap: 16px;
            padding-top: 90px;
        }
    }
    &__title-container{
        grid-row: 1;
        grid-column: 1/12;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        padding-bottom: 50px;
        @include rwd (1024){
            padding-bottom: 0;
        }
        @include rwd(768){
            text-align: center;
            grid-row: 2;
            grid-column: 1/-1;
            height: auto;
            padding-top: 44px;
            padding-bottom: 44px;
        }
    }
    &__subtitle{
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 32px;
    }
    &__title{
        margin-bottom: 32px;
    }
    &__description{
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        margin-bottom: 0;
        @include rwd (1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd(768){
            font-size: 16px;
            line-height: 28px;
        }
    }
    &__header-img{
        grid-row: 1;
        grid-column: 13/-1;
        width: 300px;
        height: 100%;
        justify-self: center;
        @include rwd (1024){
            width: 196px;
        }
        @include rwd(768){
            grid-column: 1/-1;
        }
        @include device-sm{
            width: 160px;
        }
    }
    &__box{
        display: flex;
        flex-direction: column;
        border-top: 2px solid #000;
        text-align: left;
        padding-top: 40px;
        padding-right: 70px;
        align-self: baseline;
        margin-bottom: 50px;
        @include rwd (1024){
            margin-bottom: 0;
        }
        @include rwd(768){
            padding-right: 0;
            padding-top: 32px;
            margin-bottom: 44px;
        }
        &-title{
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            letter-spacing: -0.02em;
            margin-bottom: 24px;
            @include rwd (1024){
                font-size: 24px;
                line-height: 34px;
            }
            @include rwd(768){
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 16px;
            }
        }
        &-description{
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            @include rwd (1024){
                font-size: 18px;
                line-height: 32px;
            }
            @include rwd(768){
                font-size: 16px;
                line-height: 28px;
            }
        }
        &.--box-1{
            grid-row: 2;
            grid-column: 1/12;
            @include rwd(768){
                grid-row: 3;
            }
            @include device-sm(){
                grid-column: 1/4;
            }
        }
        &.--box-2{
            grid-row: 2;
            grid-column: 13/-1;
            @include rwd(768){
                grid-row: 3;
            }
            @include device-sm(){
                grid-column: 5/-1;
            }
        }
        &.--box-3{
            grid-row: 3;
            grid-column: 1/12;
            @include rwd(768){
                grid-row: 4;
            }
            @include device-sm(){
                grid-column: 1/4;
            }
        }
        &.--box-4{
            grid-row: 3;
            grid-column: 13/-1;
            @include rwd(768){
                grid-row: 4;
            }
            @include device-sm(){
                grid-column: 5/-1;
            }
        }
    }
    &__images{
        width: 100%;
        height: 100%;
        grid-row: 4;
        &.--image-1{
            grid-row: 4;
            grid-column: 1/12;
            @include rwd(768){
                grid-row: 5;
                grid-column: 1/-1;
            }
        }
        &.--image-2{
            grid-row: 4;
            grid-column: 13/-1;
            @include rwd(768){
                grid-row: 6;
                grid-column: 1/-1;
            }
        }
    }
}

.icon-grid{
    &__section{
        padding-top: 120px;
        padding-bottom: 40px;
        @include device-sm{
            padding-bottom: 80px;
        }
    }
    &__divider{
        width: 100%;
        border-top: 2px solid #000;
        margin-bottom: 32px;
        @include rwd(1024){
            margin-bottom: 28px;
        }
    }
    &__header{
        h5{
            margin-bottom: 0;
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            @include rwd(1024){
                font-size: 24px;
                line-height: 34px;
            }
            @include rwd(768){
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
    &__content{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        padding-top: 70px;
        @include rwd(1024){
            gap: 20px;
            padding-top: 48px;
        }
        @include rwd(768){
            display: flex;
            flex-direction: row;
            overflow: auto;
        }
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
    }
    &__column{
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        @include rwd(1024){
            row-gap: 20px;
        }
        @include rwd(768){
            min-width: 253px;
        }

        &.--column-1{
            grid-column: 1;
        }
        &.--column-2{
            grid-column: 2;
        }
        &.--column-3{
            grid-column: 3;
        }
        
    }
    &__item{
        display: flex;
        width: 100%;
        height: 75px;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        border-radius: 40px;
        border: 2px solid #C2DCFF;
        @include rwd(1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd(768){
            font-size: 16px;
            line-height: 28px;
        }
        &.--colored{
            background-color: #F5F7FF;
            border: none;
        }
    }
}

.solutions-for-people{
    &__section{
        padding-top: 120px;
        padding-bottom: 120px;
    }
    &__divider{
        width: 100%;
        border-top: 2px solid #000;
        margin-bottom: 32px;
        @include rwd(1024){
            margin-bottom: 28px;
        }
    }
    &__header{
        h5{
            margin-bottom: 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 34px;
            @include rwd(1024){
                font-size: 18px;
                line-height: 26px;
            }
            @include rwd(768){
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
    &__title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        row-gap: 32px;
        max-width: 840px;
        margin: auto;
        padding-top: 150px;
        padding-bottom: 120px;
        &.--spanish{
            max-width: 875px;
        }
        @include rwd (1024){
            max-width: 716px;
        }
        @include rwd (768){
            max-width: 100%;
            row-gap: 16px;
            padding: 100px 16px 60px 16px;
        }
    }
    &__title{
        width: 90%;
        margin: 0;
    }
    &__description{
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        margin: 0;
        @include rwd (1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd (768){
            font-size: 16px;
            line-height: 28px;
        }
    }
    &__content-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 120px;
        @include rwd(768){
            padding-top: 60px;
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #2B378A;
        &__title{
            margin-bottom: 32px;
        }
        &__text{
            margin: 0;
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            @include rwd (1024){
                font-size: 18px;
                line-height: 32px;
            }
            @include rwd (768){
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
    &__img{
        width: 100%;
    }
    &__text-container{
        text-align: center;
        color: #fff;
        padding: 120px 0;
        max-width: 840px;
        @include rwd (1024){
            max-width: 716px;
        }
        @include rwd (768){
            max-width: 100%;
            padding: 60px 16px;
        }
    }

}

.online-consultation{
    &__section{
        padding-top: 120px;
    }
    &__images-divider{
        display: flex;
        flex-direction: row;
        width: 100%;
        @include rwd(768){
            flex-direction: column;
            column-gap: 16px
        }
    }
    &__content{
        @include main-grid();
        padding-top: 80px;
        row-gap: 80px;
        @include rwd(768){
            row-gap: 40px;
            padding-top: 32px;
        }
    }
    &__text{
        grid-column: 13/-1;
        grid-row: 1;
        @include rwd(768){
            grid-column: 1/-1;   
        }
    }
    &__title{
        margin-bottom: 40px;
        @include rwd(768){
            margin-bottom: 16px;
        }
    }
    &__description{
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        margin: 0;
        @include rwd (1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd (768){
            font-size: 16px;
            line-height: 28px;
        }
    }
    &__images-container{
        grid-column: 1/12;
        grid-row: 2;
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 40px;
        @include rwd(768){
            grid-column: 1/-1;
            row-gap: 60px;
        }
    }
    &__img{
        width: 50%;
        @include rwd(768){
            width: 100%;
        }
        &.--score{
            align-self: flex-end;
            width: 47%;
        }
        &.--disponible{
            width: 47%;
        }
    }
}

.emergency-assistance{
    &__section{
        padding-top: 40px;
        padding-bottom: 120px;
        @include rwd(768){
            padding: 60px 0 30px 0;
            column-gap: 16px;
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        background-color: #DADEFF;
    }
    &__text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        row-gap: 32px;
        max-width: 840px;
        margin: auto;
        padding: 120px 0 60px 0;
        @include rwd (1024){
            max-width: 716px;
        }
        @include rwd (768){
            padding: 60px 16px 30px 16px;
            max-width: 100%;
        }
    }
    &__title{
        margin: 0;
    }
    &__description{
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        margin: 0;
        @include rwd (1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd (768){
            font-size: 16px;
            line-height: 28px;
        }
    }
    &__two-boxes{
        display: flex;
        flex-direction: row;
        padding: 60px 100px 120px 100px;
        column-gap: 40px;
        @include rwd(1024){
            padding: 60px 74px 120px 74px;
            column-gap: 20px;
        }
        @include rwd(768){
            padding: 30px 16px 60px 16px;
            column-gap: 16px;
        }
        @include device-sm(){
            flex-direction: column;
            row-gap: 20px;
        }
    }
    &__box{
        display: flex;
        flex-direction: column;
        background-color: #fff;
        &__text{
            padding: 50px;
            @include rwd(1024){
                padding: 40px;
                padding-bottom: 8px;
            }
        }
        &__title{
            margin-top: 40px;
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 26px;
            line-height: 36px;
            @include rwd(1024){
                font-size: 24px;
                line-height: 34px;
            }
            @include rwd(768){
                font-size: 20px;
                line-height: 26px;
            }
        }
        &__description{
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            margin: 0;
            @include rwd (1024){
                font-size: 18px;
                line-height: 32px;
            }
            @include rwd (768){
                font-size: 16px;
                line-height: 28px;
            }
        }
        &__img{
            width: 100%;
            height: 100%;
        }
    }
    &__footer{
        display: flex;
        flex-direction: row;
        @include rwd(768){
            flex-direction: column-reverse;
        }
        &__left{
            display: flex;
            flex-direction: column;
            img{
                width: 100%;
                height: 100%;
            }
        }
        &__right{
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}

.extranet{
    &__section{
        display: flex;
        flex-direction: column;
        padding-top: 120px;
        padding-bottom: 60px;
        @include rwd(768){
            padding-bottom: 0;
        }
    }
    &__header{
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-right: 20px;
        margin-bottom: 120px;
        @include rwd(1024){
            padding-right: 15px;
        }
        @include rwd(768){
            width: 100%;
            padding-right: 0;
            margin-bottom: 30px;
        }
        &-img{
            width: 100%;
            height: 100%;
            margin-bottom: 80px;
            @include rwd(768){
                margin-bottom: 0;
            }
        }
        &.--right{
            align-self: flex-end;
            padding-left: 20px;
            @include rwd(1024){
                padding-left: 15px;
            }
            @include rwd(768){
                width: 100%;
                padding-left: 0;
            }
            img{
                margin-bottom: 0;
                margin-top: 120px;
                @include rwd(768){
                    margin-top: 32px;
                }
            }
        }
    }
    &__title{
        margin-bottom: 40px;
        @include rwd(768){
            margin-bottom: 16px;
        }
    }
    &__description{
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        margin: 0;
        @include rwd (1024){
            font-size: 18px;
            line-height: 32px;
        }
        @include rwd (768){
            font-size: 16px;
            line-height: 28px;
        }
    }

    &__images-container{
        @include main-grid;
        row-gap: 40px;
        margin-top: 120px;
        margin-bottom: 80px;
        @include rwd(1024){
            row-gap: 20px;
        }
        @include rwd(768){
            row-gap: 16px;
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }
    &__img{
        width: 100%;
        height: 100%;
        &.--img-1{
            grid-row: 1;
            grid-column: 1/12;
            @include rwd(768){
                grid-row: 1;
                grid-column: 1/-1;
            }
        }
        &.--img-2{
            grid-row: 1;
            grid-column: 13/-1;
            @include rwd(768){
                grid-row: 2;
                grid-column: 1/-1;
            }
        }
        &.--img-3{
            grid-row: 2;
            grid-column: 1/12;
            @include rwd(768){
                grid-row: 3;
                grid-column: 1/-1;
            }
        }
        &.--img-4{
            grid-row: 2;
            grid-column: 13/-1;
            @include rwd(768){
                grid-row: 4;
                grid-column: 1/-1;
            }
        }
    }

    &__footer{
        display: flex;
        flex-direction: column;
        width: 50%;
        align-self: flex-end;
        padding-left: 20px;
        @include rwd(1024){
            padding-left: 15px;
        }
        @include rwd(768){
            width: 100%;
            padding-left: 0;
        }
    }
}