.editable-modal {
    display: block;
    width: auto;
    max-width: 620px;
    margin: 0 auto;
    padding-right: 1rem;
    text-align: left;

    &__description {
        @include font-size(26, 44);
    }

    &__title {
        margin-bottom: 1em;

        code {
            position: relative;
            display: inline-block;
            
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 120%;
                height: 140%;
                z-index: -1;
                transform: translate(-50%, -50%);
                background: url('/static/website/images/word-mark.svg') no-repeat center center;
                background-size: contain;
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    &__content {
        s {
            text-decoration: none;
            border-bottom: 1px solid $yellow;
        }
    }

    @include device-md {
        &__description {
            @include font-size(24, 40);
        }
    }

    @include device-sm {
        padding-right: 0;
        
        &__description {
            @include font-size(18, 32);
        }
    }
}

.youtube-video-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 !important;

    #modalVideoEmbed {
        aspect-ratio: 16 / 9;
        width: 100%;
    }
}