.button-arrow-container {
    &_text {
        text-align: center;
        margin-bottom: 65px;
    }
    .btn-arrow__list {
        border-bottom: 1px solid $black;
    }
    .btn-arrow {
        border-bottom: none;
    }
    
    @include device-sm() {
        .btn-arrow__title {
            width: max-content;
        }
        .btn-arrow__icons {
            display: none;
        }
    }
}

.job_postings_list {
    a {
        color: $black;
        text-decoration: none;
    }
}