.growth_section {
    position: relative;
    overflow: hidden;
    margin-top: 240px;
    margin-bottom: 60px;

    .primer-hr {
        margin: 0;
        border: none;
        border-top: 1px solid
    }

    .growth_container {
        position: relative;
        inset: 0;
        z-index: 1;
        @include main-grid();
        margin-top: 200px;
        padding-bottom: 230px;

        h2 {
            grid-column: 1 / span 13;
            margin-bottom: 40px;
        }
        .growth_text {
            grid-column: 1 / span 11;
            
            .block-list {
                .block-list-container {
                    hr {
                        display: none;
                    }
    
                    border-bottom: 1px $white solid;
                }
            }
        }
    }

    @include rwd(1024){
        grid-template-columns: 1fr;
        margin-top: 120px;
        margin-bottom: 32px;

        .growth_container {
            h2 {
                grid-column: 1 / span 15;
            }
            .growth_text {
                grid-column: 1 / span 15;
            }
        }
    }

    @include rwd(836) {
        .growth_container {
            h2 {
                grid-column: 1 / -1;
            }
            .growth_text {
                grid-column: 1 / -1;
            }
        }
    }
    
    @include device-md(){
        padding-left: 0;
        padding-right: 0;
        margin-top: 120px;
        margin-bottom: 18px;
        padding-bottom: 60px;
        
        .growth_container {
            margin-top: 160px;
            padding-bottom: 0px;

            h2 {
                margin-bottom: 32px;
            }
        }
        .growth_text {
            padding-bottom: 0px;
        }
    }
}