.position_detail_page_title_description {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(2, auto);
    // column-gap: 40px;
    @include main-grid();
    row-gap: 80px;
    margin-top: 240px;

    @include device-md() {
        grid-template-columns: 1fr;
        row-gap: 16px;
        margin-top: 120px;
    }
    
    a { 
        @include font-size(20, 34, 700, 0);
        color: $black;
        text-decoration: none;
        // grid-area: 1 / 1 / 2 / 3; 
        grid-column: 1 / -1;
        grid-row: 1;
        border-top: 2px solid $black;
        padding-top: 40px;
        @include device-md() {
            @include font-size(16, 28, 700, 0);
            padding-top: 16px;
            grid-column: 1 / -1;
            margin-bottom: 16px;
        }
        svg {
            width: 24px;
            transform: rotate(180deg);
            margin-right: 8px;
        }
    }

    h1 { 
        @include font-size(80, 92, 500, -3);
        // grid-area: 2 / 1 / 3 / 2; 
        grid-column: 1 / span 10;
        grid-row: 2;
        margin-bottom: 32px;
        align-self: start;

        @include rwd(1024) {
            @include font-size(64, 74, 500, -3);
        }

        @include device-md() {
            @include font-size(32, 40, 500, -3);
            // grid-area: 2 / 1 / 3 / 2;
            grid-row: 2;
            grid-column: 1 / -1;
            margin-bottom: 0;
        }
    }
    h2 { 
        @include font-size(80, 92, 500, -3);
        // grid-area: 2 / 1 / 3 / 2; 
        grid-column: 1 / span 10;
        grid-row: 2;
        margin-bottom: 32px;
        align-self: start;

        @include rwd(1024) {
            @include font-size(64, 74, 500, -3);
        }

        @include device-md() {
            @include font-size(32, 40, 500, -3);
            // grid-area: 2 / 1 / 3 / 2;
            grid-row: 2;
            grid-column: 1 / -1;
            margin-bottom: 0;
        }
    }
    p { 
        @include font-size(26, 44, 400, 0);
        // grid-area: 2 / 2 / 3 / 3; 
        grid-column: 12 / -1;
        grid-row: 2;
        margin-bottom: 70px;
        align-self: start;

        @include rwd(1024) {
            @include font-size(24, 40, 400, 0);
        }

        @include device-md() {
            @include font-size(20, 32, 400, 0);
            // grid-area: 3 / 1 / 4 / 2;
            grid-row: 3;
            grid-column: 1 / -1;
            margin-bottom: 52px;
        }
    }
}

.position_detail_page_expectations_requirements {
    
    .block-list-container:last-child {
        border-bottom: 1px solid $black;
    }
    
    hr {
        border: 0;
        border-top: 1px solid $black;
        height: 1px;
        margin-bottom: 0;
    }
    
    @include device-md() {
        h1 { 
            margin-top: 16px;
            margin-bottom: 0;
        }
        .block-list-container__title {
            padding-bottom: 14px;
        }
        li {
            margin-bottom: 16px !important;
        }
        .block-list-container {
            padding-bottom: 16px;
        }
    }
}

.position_detail_page_benefits {
    margin-top: 240px;

    .block-list-container:last-child {
        border-bottom: 1px solid $black;
    }
    .block-list-container {
        hr {
            display: none;
        }
    }

    hr {
        border: 0;
        border-top: 1px solid $black;
        height: 1px;
        margin-bottom: 0;
    }
    
    h2 { 
        margin-bottom: 32px;
        @include font-size(80, 92, 500, -3);

        @include rwd(1024) {
            @include font-size(64, 74, 500, -3);
        }

        @include device-md() {
            @include font-size(32, 40, 500, -3);
            margin-bottom: 16px;
        }
    }

    @include device-md() {
        margin-top: 60px;

        .block-list-container__title {
            padding-bottom: 14px;
        }
        li {
            margin-bottom: 16px !important;
        }
        .block-list-container {
            padding-bottom: 16px;
        }
    }
}

.position_detail_page_apply {
    display: grid;
    place-content: center;
    margin-bottom: 120px;    

    a {
        color: $black;
        text-decoration: none;
        text-align: center;
    }

    .position_detail_page_apply_circle {
        background-color: $yellow;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            text-decoration: underline;
            @include font-size(20, 36, 400, 0);
            margin-bottom: 0;
        }

        h3 {
            @include font-size(56, 48, 400, -5);
            font-style: italic;
            margin-bottom: 24px;
        }
    }

    .position_detail_page_apply_circle_paragraph {
        @include font-size(12, 20, 400, 0);
        width: 395px;
        text-align: justify;
        margin-top: 24px;
        @include device-md() {
            @include font-size(11, 18);
            width: 340px;
        }
    }

    @include device-md() {
        margin-bottom: 18px;
    }
}
