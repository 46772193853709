.santander {

    // ------  Archivo: challenge / Clase: santander-challenge-slider ------
    &-challenge-slider {
        &-container {
            position: relative;
            
            .swiper-slide {
                width: auto;
            }

            .swiper-slide__box {
                width: max-content;
                height: 180px; 
                display: flex; 
                align-items: center;
                justify-content: center;
                color: #FF0000;
                h2 {
                    @include font-size(180, 180, 500, -4);
                    
                    @include rwd(1024) {
                        @include font-size(120, 120, 400, -3)
                    }

                    @include device-md() {
                        @include font-size(70, 70, 500, -3)
                    }
                }
            }

            .swiper-wrapper {
                transition-timing-function: linear !important;
            }

            .challenge-img {
                width: 290px;
                height: 290px;
                z-index: 1;
            }

            .challenge-img.img1 {
                position: absolute;
                top: 110px;
                left: 110px;
            }
            .challenge-img.img2 {
                position: absolute;
                top: 400px;
                right: 110px;
            }
            .challenge-img.img3 {
                position: absolute;
                top: 690px;
                right: 0;
                left: 0;
                margin: auto;
            }

            @include rwd(1024) {
                .swiper-slide__box {
                    height: 120px;
                }
                .challenge-img {
                    width: 201px;
                    height: 201px;
                }
                .challenge-img.img1 {
                    top: 60px;
                    left: 60px;
                }
                .challenge-img.img2 {
                    top: 260px;
                    right: 60px;
                }
                .challenge-img.img3 {
                    top: 460px;
                }
            }

            @include device-md() {
                .swiper-slide__box {
                    height: 70px;
                }
                .challenge-img {
                    width: 164px;
                    height: 164px;
                }
                .challenge-img.img1 {
                    top: -70px;
                    left: 0;
                }
                .challenge-img.img2 {
                    top: 80px;
                    right: 0;
                }
                .challenge-img.img3 {
                    top: 245px;
                    left: 0;
                    right: auto;
                }
            }
        }
    }

    // ------  Archivo: transform / Clase: santander-transform ------
    &-transform {
        padding-bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .santander-comunication-tool {
            background-color: #FFE9CA;
            padding-top: 120px;
            padding-bottom: 120px;
            margin-top: 32px;

            @include device-md() {
                margin-top: 16px;
                padding-top: 60px;
                padding-bottom: 60px;
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .santander-grid {
            @include main-grid-cases();
            margin-top: 40px;

            .box1 {
                grid-column: 1 / span 11;
                grid-row: 1;
            }
            .box2 {
                grid-column: 13 / span 11;
                grid-row: 1;
            }
            .box3 {
                grid-column: 1 / span 11;
                grid-row: 2;
            }
            .box4 {
                grid-column: 13 / span 11;
                grid-row: 2;
            }
            .box5-d {
                grid-column: 7 / span 5;
                grid-row: 3;
            }
            .box5-m {
                display: none;
            }
            .box6 {
                grid-column: 13 / span 11;
                grid-row: 3 / span 2;
            }
            .box7 {
                grid-column: 1 / -1;
                grid-row: 5;
            }
            .box8 {
                grid-column: 1 / span 11;
                grid-row: 6;
            }
            .box9 {
                grid-column: 13 / span 11;
                grid-row: 6;
            }

            @include rwd(1024) {
                margin-top: 20px;
            }

            @include device-md() {
                margin-top: 16px;

                .box1 {
                    grid-column: 1 / -1;
                    grid-row: 1;
                }
                .box2 {
                    grid-column: 1 / -1;
                    grid-row: 2;
                }
                .box3 {
                    grid-column: 1 / span 3;
                    grid-row: 3;
                }
                .box4 {
                    grid-column: 5 / span 3;
                    grid-row: 3;
                }
                .box5-d {
                    display: none;
                }
                .box5-m {
                    display: block;
                    grid-column: 1 / -1;
                    grid-row: 5;
                }
                .box6 {
                    grid-column: 1 / -1;
                    grid-row: 4;
                }
                .box7 {
                    grid-column: 1 / -1;
                    grid-row: 6;
                }
                .box8 {
                    grid-column: 1 / span 3;
                    grid-row: 7;
                }
                .box9 {
                    grid-column: 5 / span 3;
                    grid-row: 7;
                }
            }
        }

        @include device-md() {
            padding-bottom: 40px;
        }
    }

    // ------  Archivo: design / Clase: santander-design ------
    &-design {
        background-color: #1F1F1F;
        color: #FFFFFF;
        padding-bottom: 0;

        .section-divider {
            border-color: #FFFFFF;
        }
        .santander-text-left {
            margin-top: 150px;
            margin-bottom: 120px;
            h3, p {
                grid-column: 1 / span 15;

                @include rwd(1024) {
                    grid-column: 1 / span 19;
                }
            }
        }

        picture, img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-grid {
            @include main-grid-cases();

            .box1 {
                grid-column: 1 / span 15;
                grid-row: 1;
            }
            .box2 {
                grid-column: 17 / span 7;
                grid-row: 1;
            }
            .box3 {
                grid-column: 1 / span 15;
                grid-row: 2;
            }
            .box4 {
                grid-column: 17 / span 7;
                grid-row: 2;
                border-top: 1px solid #FFFFFF;
                height: 100%;
                display: flex;
                padding-top: 24px;
                p {
                    @include font-size(20, 34, 400, 0);
                    margin-bottom: 0;

                    @include rwd(1024) {
                        @include font-size(18, 32, 400, 0);
                    }

                    @include rwd(768) {
                        @include font-size(16, 28, 400, 0);
                    }
                }
            }
            .box5 {
                grid-column: 1 / span 5;
                grid-row: 3;
            }
            .box6 {
                grid-column: 7 / span 5;
                grid-row: 3;
            }
            .box7 {
                grid-column: 1 / span 5;
                grid-row: 4;
            }
            .box8 {
                grid-column: 7 / span 5;
                grid-row: 4;
            }
            .box9 {
                grid-column: 13 / span 11;
                grid-row: 3 / span 2;
            }
        }

        @include device-md() {
            padding-top: 60px;
            margin-top: 40px;

            .santander-text-left {
                margin-top: 76px;
                margin-bottom: 60px;
                h3, p {
                    grid-column: 1 / -1;
                }
            }

            &-grid {
                @include main-grid-cases();
    
                .box1 {
                    grid-column: 1 / -1;
                    grid-row: 1;
                }
                .box2 {
                    grid-column:  1 / -1;
                    grid-row: 2;
                }
                .box3 {
                    grid-column: 1 / -1;
                    grid-row: 4;
                }
                .box4 {
                    grid-column: 1 / -1;
                    grid-row: 3;
                    padding-top: 24px;
                }
                .box5 {
                    grid-column: 1 / span 3;
                    grid-row: 5;
                }
                .box6 {
                    grid-column: 5 / span 3;
                    grid-row: 5;
                }
                .box7 {
                    grid-column: 1 / span 3;
                    grid-row: 6;
                }
                .box8 {
                    grid-column: 5 / span 3;
                    grid-row: 6;
                }
                .box9 {
                    grid-column: 1 / -1;
                    grid-row: 7;
                }
            }
        }

        // ------  Archivo: design-quote / Clase: santander-design-quote ------
        &-quote {
            background-color: #1F1F1F;
            color: #FFFFFF;
            padding-bottom: 240px;
            .case-quote__box {
                margin: auto;
                .big-list-title, .case-quote__author {
                    grid-column: 13 / span 11;
                }
            }
            @include rwd(768) {
                padding-bottom: 120px;
                padding-top: 136px;
                .case-quote__box {
                    .big-list-title, .case-quote__author {
                        grid-column: 1 / -1;
                    }
                }
            }
        }
    }

    // ------  Archivo: reimagine / Clase: santander-reimagine ------
    &-reimagine {
        padding-bottom: 0;

        &-content {
            background-color: #000000;
            color:#FFFFFF;
            padding-top: 120px;
            margin-top: 32px;
            
            picture, img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-top: 120px;
            }

            @include device-md() {
                padding-top: 60px;
                margin-top: 16px;
                p {
                    padding-left: 16px;
                    padding-right: 16px;
                }
                img {
                    margin-top: 60px;
                }
            }
        }

        .santander-reimagine-grid {
            @include main-grid-cases();
            margin-top: 40px;
            
            picture, img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            p {
                @include font-size(20, 34, 400, 0);
            }

            .box2{
                grid-column: 1 / span 11;
                grid-row: 1;
                border-top: 1px solid #000000;
                height: 262px;
                width: 100%;
                display: flex;
                padding-top: 24px;
                p {
                    max-width: 510px;
                    margin: 0;
                }
            }
            .box3{
                grid-column: 1 / span 11;
                grid-row: 2 / span 2;
            }
            .box4{
                grid-column: 13 / span 11;
                grid-row: 1 / span 2;
            }
            .box5{
                grid-column: 13 / span 11;
                grid-row: 3;
                border-top: 1px solid #000000;
                height: 262px;
                width: 100%;
                display: flex;
                padding-top: 24px;
                p {
                    max-width: 510px;
                    margin: 0;
                }
            }
            .box6{
                grid-column: 1 / -1;
                grid-row: 4;
            }
            .box7{
                grid-column: 1 / span 11;
                grid-row: 5;
            }
            .box8{
                grid-column: 13 / span 11;
                grid-row: 5;
            }

            @include rwd(1024) {
                margin-top: 20px;

                .box2, .box5 {
                    p {
                        @include font-size(18, 32, 400, 0);
                        max-width: 348px;
                    }
                }
            }

            @include device-md() {
                margin-top: 16px;
                
                .box2 {
                    grid-column: 1 / -1;
                    grid-row: 1;
                    height: max-content;
                    padding-top: 16px;
                    p {
                        @include font-size(16, 28, 400, 0);
                    }
                }
                .box3 {
                    grid-column: 1 / -1;
                    grid-row: 2;
                }
                .box4 {
                    grid-column: 1 / -1;
                    grid-row: 4;
                }
                .box5 {
                    grid-column: 1 / -1;
                    grid-row: 3;
                    height: max-content;
                    padding-top: 16px;
                    p {
                        @include font-size(16, 28, 400, 0);
                    }
                }
                .box6 {
                    grid-column: 1 / -1;
                    grid-row: 5;
                }
                .box7 {
                    grid-column: 1 / span 3;
                    grid-row: 6;
                }
                .box8 {
                    grid-column: 5 / span 3;
                    grid-row: 6;
                }

            }
        }

        @include device-md() {
            padding-top: 60px;
            padding-bottom: 40px;
        }
    }

    // ------  Archivo: explore / Clase: santander-explore ------
    &-explore {
        background-color: #1F1F1F;
        color: #FFFFFF;

        .section-divider {
            border-color: #FFFFFF;
        }
        .santander-text-left {
            margin-top: 150px;
            margin-bottom: 120px;
            h3, p {
                grid-column: 1 / span 15;

                @include rwd(1024) {
                    grid-column: 1 / span 19;
                }
            }
        }

        picture, img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-grid {
            @include main-grid-cases();

            .box1 {
                grid-column: 1 / span 11;
                grid-row: 1;
            }
            .box2 {
                grid-column: 13 / span 11;
                grid-row: 1;
            }
            .box3 {
                grid-column: 1 / span 11;
                grid-row: 2;
            }
            .box4 {
                grid-column: 13 / span 11;
                grid-row: 2;
            }
            .box5 {
                grid-column: 1 / span 11;
                grid-row: 3;
            }
            .box6 {
                grid-column: 13 / span 11;
                grid-row: 3;
            }
            .box7 {
                grid-column: 1 / span 11;
                grid-row: 4;
            }
            .box8 {
                grid-column: 13 / span 11;
                grid-row: 4;
            }
        }

        @include device-md() {
            padding-top: 60px;
            padding-bottom: 60px;
            margin-top: 40px;

            .santander-text-left {
                margin-top: 76px;
                margin-bottom: 60px;
                h3, p {
                    grid-column: 1 / -1;
                }
            }

            &-grid {
                @include main-grid-cases();
    
                .box1 {
                    grid-column: 1 / span 3;
                    grid-row: 1;
                }
                .box2 {
                    grid-column:  1 / -1;
                    grid-row: 2;
                }
                .box3 {
                    grid-column: 5 / span 3;
                    grid-row: 1;
                }
                .box4 {
                    grid-column: 1 / -1;
                    grid-row: 6;
                }
                .box5 {
                    grid-column: 1 / span 3;
                    grid-row: 3;
                }
                .box6 {
                    grid-column: 1 / -1;
                    grid-row: 4;
                }
                .box7 {
                    grid-column: 1 / -1;
                    grid-row: 5;
                }
                .box8 {
                    grid-column: 5 / span 3;
                    grid-row: 3;
                }
            }
        }


    }

    // ------  Archivo: experiences / Clase: santander-experiences ------
    &-experiences {
        padding-bottom: 0;

        &-content {
            background-color: #8017E4;
            color:#FFFFFF;
            padding-top: 120px;
            margin-top: 32px;
            padding-bottom: 120px;
            
            
            @include device-md() {
                padding-top: 60px;
                padding-bottom: 60px;
                margin-top: 16px;
                p {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }
        }

        .box1 {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        .santander-experiences-grid {
            @include main-grid-cases();
            margin-top: 40px;
            
            picture, img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .box2{
                grid-column: 1 / span 11;
                grid-row: 1;
            }
            .box3{
                grid-column: 13 / span 11;
                grid-row: 1;
            }
            .box4{
                grid-column: 1 / -1;
                grid-row: 2;
            }
            .box5{
                grid-column: 1 / span 11;
                grid-row: 3;
            }
            .box6{
                grid-column: 13 / span 11;
                grid-row: 3;
            }

            @include device-md() {
                margin-top: 16px;
                
                .box2 {
                    grid-column: 1 / span 3;
                    grid-row: 1;
                }
                .box3 {
                    grid-column: 5 / span 3;
                    grid-row: 1;
                }
                .box4 {
                    grid-column: 1 / -1;
                    grid-row: 2;
                }
                .box5 {
                    grid-column: 1 / span 3;
                    grid-row: 3;
                }
                .box6 {
                    grid-column: 5 / span 3;
                    grid-row: 3;
                }
            }
        }

        @include device-md() {
            padding-top: 60px;
            padding-bottom: 40px;
        }
    }

    // ------  Archivo: varios / Clase: santander-center-text ------
    &-center-text, &-center-text-small  {
        text-align: center;
        @include main-grid();
        
        h3 {
            margin-bottom: 32px;
            grid-column: 5 / span 15;
            @include rwd(1024) {
                grid-column: 4 / span 17;
            }
            @include rwd(768) {
                grid-column: 1 / -1;
            }
        }
        
        p {
            @include font-size(20, 34, 400, 0);
            margin: 0;
            grid-column: 5 / span 15;
            
            
            @include rwd(1024) {
                @include font-size(18, 32, 400, 0);
            }

            @include rwd(768) {
                @include font-size(16, 28, 400, 0);
                grid-column: 1 / -1;
            }
        }
    }
    &-center-text-small {
        h3 {
            @include font-size(60, 70, 400, -3);

            @include rwd(1024) {
                @include font-size(40, 50, 400, -2);
            }

            @include device-md() {
                @include font-size(24, 30, 400, -2);
            }
        }
    }

    // ------ Clase: santander-text-align ------
    &-text-align {
        @include device-md() {
            text-align: left;
        }
    }

    // ------ Clase: santander-text-left ------
    &-text-left {
        text-align: left;
    }
}