.center-list_section {
    @include main-grid;
    .center-list_text {
        grid-column: 9 / span 7;
        @include font-size(20, 34, 700, 0);
        text-align: center;
        margin-bottom: 22px;
        margin-top: 0px;
        @include rwd(1024) {
            grid-column: 7 / span 11;
        }
        @include device-md() {
            grid-column: 1 / -1;
            margin-top: 64px;
            @include font-size(16, 28, 700, 0);
        }
    }
    .btn__list {
        grid-column: 5 / span 15;
        margin-bottom: 130px;
        @include device-md() {
            grid-column: 1 / -1;
            margin-bottom: 0;
        }
    }
}