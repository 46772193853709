.h3-text {
    margin-top: 40px;
    h3 {
        margin-bottom: 40px;
    }
    hr {
        border: 0;
        margin: 0;
        border-top: 1px solid $black;
    }

    @include device-md() {
        h3 {
            margin-bottom: 16px;
        }
    }
}