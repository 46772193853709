.img-box {
    border-top: 1px solid $black;
    max-width: 100%;
    height: 438px;
    position: relative;
    overflow: hidden;
    margin-bottom: 80px;

    &_title {
        @include font-size(20, 34, 700, 0);
        margin-top: 16px;
        margin-bottom: 0;
    }

    &_anchor-img {
        margin-top: 16px;
    }
    
    &_subtitle {
        @include font-size(16, 19, 400, 0);
        margin-top: 0;
        margin-bottom: 50px;
    }
    
    &_img-container {
        max-width: 400px;
        height: 320px;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        transition: all $timming ease-in-out;

        .img-box_img {
            height: 380px;
            width: auto;
            margin-left: -20px;
            margin-right: -20px;
            margin-top: -30px;
            margin-bottom: -30px;
            transition: all $timming ease-in-out;
        }

        .img-box_plus {
            display: none;
        }
    }

    &_text-container {
        border-bottom: 6px solid $yellow;
        @include font-size(26, 40, 400, 0);
        position: absolute;
        z-index: -2;
        bottom: 0;
        width: 100%;
        transition: transform $timming ease-in-out;
        .--richtext {
            @include font-size(26, 40, 400, 0);
        }
    }
    
    &_text {
        margin-bottom: 27px;
    }

}
.quote_visible {
    .img-box_img-container {
        height: 0px;
    }
    .img-box_text-container {
        cursor: pointer;
        transform: translateY(0);
    }
}
.img_visible {
    .img-box_img-container {
        height: 320px;

        @include device-md() {
            height: 200px;
        }
    }
    .img-box_text-container {
        transform: translateY(100px);
    }
}

.img-box:hover, .img-box:focus-within {
    .img-box_img-container{
        .img-box_img {
            cursor: pointer;
            height: 340px;
            width: auto;
            margin: 0;
        }
    }
}

@include rwd(1140){
    .img-box_img-container {
        height: 100%;
        width: 100%;
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        .img-box_img {
            height: 100%;
            width: 106%;
            margin-left: -4%;
            margin-right: -4%;
            object-fit: cover;
            margin-top: 0;
        }
    }

    .img-box:hover, .img-box:focus-within {
        .img-box_img-container{
            .img-box_img {
            height: 100%;
            width: 106%;
            margin-left: -4%;
            margin-right: -4%;
            object-fit: cover;
            margin-top: 0;
            }
        }
    }
}

@include rwd(1024){
    .img-box_img-container {
        .img-box_plus {
            display: block;
            position: absolute;
            bottom: 16px;
            left: 16px;
            width: 40px;
            height: 40px;
        }
    }

    .img-box_text-container {
        @include font-size(18, 32, 400, 0);
        .--richtext {
            @include font-size(18, 32, 400, 0);
        }
    }
}
@include rwd(850) {
    .img-box_title {
        line-height: 1.2;
    }
}
@include device-md(){
    .img-box {
        height: 320px;

        .img-box_anchor-img {
            margin-top: 16px;
        }
    }
    .img-box_title {
        @include font-size(16, 20, 700, 0);
    }
    .img-box_subtitle {
        @include font-size(14, 20, 400, 0);
        margin-bottom: 0;
    }
    .img-box_text-container {
        @include font-size(12, 18, 400, 0);
        border-bottom: 4px solid $yellow;
        .--richtext {
            @include font-size(12, 18, 400, 0);
        }
    }
    .img-box_img-container {
        .img-box_plus {
            width: 20px;
            height: 20px;
            bottom: 8px;
            left: 8px;
        }
    }
}