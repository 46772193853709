.black-bottom-section-culture {
    padding-bottom: 40px;

    p {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .big-paragraph {
        margin-bottom: 120px;
        padding: 20px 80px 0px 80px;
        @include font-size(80, 92, 500, -3);
    }

    @include rwd(1024) {
        padding-bottom: 40px;

        .big-paragraph {
            padding-top: 0px;
            margin-bottom: 120px;
            @include font-size(64, 74, 500, -3);
        }
    }

    @include device-lg {
        .big-paragraph {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    @include device-md() {
        padding-top: 60px;
        padding-bottom: 0;
        margin-bottom: -40px;
        .big-paragraph {
            @include font-size(32, 40, 500, -3);
            padding: 0px 16px 0px 16px;
            margin-bottom: 40px;
        }
    }

    .smile-badge {
        width: 200px;
        height: 200px;

        @include device-md() {
            width: 253px;
            height: 253px;
        }
    }
}

.black-bottom-section {
    padding-top: 60px;

    @include device-md("min") {
        padding-top: 130px;
    }
    @include device-md("min") {
        padding-top: 200px;
    }
    .section {
        // padding: 0;
        padding-left: 0;
        padding-right: 0;
        @include device-md("min") {
            padding: 0;
        }
        margin: 0;
    }

    .text-with-button {
        padding: 0;
        margin-top: 0px;
        margin-bottom: 240px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 840px;
        width: 100%;

        @include device-md() {
            margin-bottom: 70px;
        }
        @include device-lg() {
            max-width: 650px;
        }

        h2 {
            // TODO: Check _text-with-button file
            // margin-bottom: unset;
            padding-right: unset;
            padding-left: unset;
            margin-top: unset;
        }
    }
}
