.cases-header-section {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    // Initial paddings (reference), modify them in another class
    padding-top: 60px;
    padding-bottom: 60px;

    & > * {
        margin-bottom: 0;
    }

    @include device-md("min") {
        max-width: 715px;
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @include device-xl("min") {
        max-width: 840px;
    }
}

.cases-list {
    &__container {
        list-style-type: none;
        counter-reset: iter;
    }
    &__item {
        display: block;
        padding-bottom: 4px;
        margin-top: 4px;
        padding-left: 40px;
        position: relative;
        border-bottom: 1px solid #f1f1f1;

        &:before {
            // Remove dot
            content: counter(iter, upper-alpha) " ";
            counter-increment: iter;

            position: absolute;
            top: 0;
            left: 0;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}

.case-impact {
    display: grid;
    @include device-md("min") {
        grid-template-columns: 1fr 1fr 1fr;
    }
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    border: 1px solid $black;
    margin-top: 16px;

    @include device-md("min") {
        margin-top: 60px;
    }
    @include device-xl("min") {
        margin-top: 120px;
    }

    &__header {
        padding: 30px 0;
        text-align: center;
        margin-bottom: 0;
        border-bottom: 1px solid $black;
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        font-weight: 400;

        @include device-md("min") {
            padding: 54px 24px;
        }
    }
    &__percent {
        @include flex-center(column);
        padding: 15px 0;
        @include device-md("min") {
            padding: 34px 10px;
            grid-row: 2 / 3;
        }
        @include device-md {
            border-bottom: 1px solid $black;
        }

        &:nth-of-type(1) {
            grid-column: 1 / 1;
            @include device-md("min") {
                grid-column: 1 / 2;
            }
        }
        &:nth-of-type(2) {
            grid-column: 1 / 1;

            @include device-md("min") {
                grid-column: 2 / 3;
                border-left: 1px solid $black;
                border-right: 1px solid $black;
            }
        }
        &:nth-of-type(3) {
            grid-column: 1 / 1;

            @include device-md("min") {
                grid-column: 3 / 4;
            }

            @include device-md {
                border: none;
            }
        }

        span {
            display: block;
            margin: auto;
            @include font-size(80, 92, 400, -3);

            @include device-xl {
                @include font-size(64, 74, 400, -2);
            }

            @include device-sm {
                @include font-size(32, 40, 400, -1);
            }
        }
    }
}

.--bg-black {
    .case-impact,
    .case-impact__percent,
    .case-impact__header {
        color: $white;
        border-color: $white;
    }
}

.case-quote {
    @include device-md("min") {
        padding-top: 240px;
    }
    &__box {
        @include main-grid-cases;
        margin: unset;
    }
    * {
        grid-column: 1 / -1;
        @include device-md("min") {
            grid-column: 1 / 20;
        }
        @include device-xl("min") {
            grid-column: 1 / 16;
        }
    }
    &__author {
        @include flex-center;
        justify-content: flex-start;
        gap: 16px;

        strong {
            font-weight: 700;
            display: block;
            margin-bottom: -10px;
        }

        span {
            @include font-size(12, 20);
            @include device-md("min") {
                @include font-size(14, 20);
            }
            @include device-xl("min") {
                @include font-size(16, 28);
            }
        }
        img {
            @include image-responsive;
            max-width: 70px;
            @include rwd(1024) {
                max-width: 54px;
            }
        }
        margin-top: 32px;

        @include device-md("min") {
            margin-top: 80px;
        }
    }
}

.quote-right {
    .case-quote__box {
        .big-list-title,
        .case-quote__author {
            grid-column: 13 / span 11;
        }
        @include device-md() {
            row-gap: 0px;
            .big-list-title {
                grid-column: 1 / -1;
            }
            .case-quote__author {
                grid-column: 1 / span 5;
                span {
                    @include font-size(12, 20);
                    p {
                        margin: 0;
                    }
                }
                strong {
                    margin-bottom: 0;
                }
                
            }
        }
    }
}

.case-challenge {
    &__img {
        @include image-responsive;
        margin: auto;
        @include device-sm {
            height: 400px;
            max-width: 100%;
            object-fit: cover;
        }
    }
    &__box {
        @include main-grid-cases;
        @include main-grid-columns(
            (
                "xl": 2,
                "lg": 2,
                "md": 1,
                "sm": 1,
            )
        );
        align-items: unset;
        row-gap: 60px;
        .section-divider {
            padding-bottom: 16px;

            @include device-md("min") {
                padding-bottom: 26px;
            }
            @include device-xl("min") {
                padding-bottom: 38px;
            }
        }
        &-title {
            h3 {
                margin-bottom: 0;
                width: 100%;
                max-width: 100%;

                @include device-md("min"){
                    max-width: 350px;
                }
                @include device-xl("min"){
                    max-width: 510px;
                }
            }
        }
        &-items {
            ol {
                list-style-type: none;
                counter-reset: iter;
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
            li {
                display: block;
                // padding-bottom: 4px;
                // margin-top: 4px;
                padding-left: 42px;
                position: relative;

                @include font-size(20, 26, 400);
                @include device-md("min") {
                    @include font-size(24, 34, 400);
                }
                @include device-xl("min") {
                    @include font-size(26, 36, 400);
                    letter-spacing: -0.02em;
                }

                b {
                    font-weight: 600;
                }

                &:before {
                    // Remove dot
                    content: counter(iter) " /";
                    counter-increment: iter;

                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

.case-hero {
    padding-bottom: 0 !important;
    background-position: 50% 10%;
    background-size: auto 120%;
    box-shadow: 0px -60px 0px 0px #fff inset;
    @include device-md("min") {
        box-shadow: 0px -80px 0px 0px #fff inset;
    }
    @include device-xl("min") {
        box-shadow: 0px -240px 0px 0px #fff inset;
    }

    &:before {
        content: '';
        position: absolute;
        z-index: 0;
        inset: 0;
        backdrop-filter: blur(10px);
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        inset: 0;
        background: url('../images/noise.png') repeat center center;
    }

    h1 {
        margin-bottom: 16px;
        @include font-size(140, 140, 500, -4);
    
        @include device-xl {
            @include font-size(100, 100, 500, -3);
        }
        
        @include device-md {
            @include font-size(56, 56, 500, -3);
        }
    }

    &_text,
    &_text_link {
        width: 100%;
        max-width: 100%;
    }

    &__main {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 60px;
        @include device-md("min") {
            gap: 80px;
        }
    }
    &__image {
        @include image-responsive;
        @include device-sm {
            height: 340px;
            object-fit: cover;
        }
    }
    &__box {
        padding-top: 60px;
        padding-bottom: 60px;
        @include device-md("min") {
            padding-top: 80px;
        }
    }
    &__content {
        @include main-grid-cases;
        align-items: start;
        row-gap: 60px;
    }
    &__description {
        grid-column: 1 / -1;

        &.main-header_text,
        &.main-header_text_link {
            p, ul, ol, a {
                @include font-size(20, 32, 400);
        
                @include device-md("min"){
                    @include font-size(24, 40, 400);
                }
        
                @include device-xl("min"){
                    @include font-size(26, 44, 400);
                }
            }
        }

        @include device-md("min") {
            grid-column: 1 / 12;
            width: 100%;
        }
        *:last-of-type {
            margin-bottom: 0;
        }
        &.main-header_text{
            max-width: 100%;
        }
    }
    &__highlights {
        display: flex;
        flex-direction: column;
        gap: 16px;
        grid-column: 1 / -1;
        @include device-md("min") {
            grid-column: 15 / -1;
            width: 100%;
        }
        li {
            border-bottom: 1px solid $black;
            padding-bottom: 16px;
            a{
                font-weight: 400;
            }
        }
        *:last-of-type {
            margin-bottom: 0;
        }
    }
    &__logo {
        padding-bottom: 120px;
        border-top: 2px solid $black;
        img{
            max-width: 190px;
            @include device-sm {
                max-width: 120px;
            }
        }

        padding-top: 37px;
        padding-bottom: 55px;

        @include device-md("min") {
            padding-top: 65px;
            padding-bottom: 115px;
        }
        @include device-xl("min") {
            padding-top: 60px;
            padding-bottom: 120px;
        }
    }
}

.case-footer {
    padding-bottom: 0;
    .section-divider {
        padding-bottom: 16px;
        @include device-md("min") {
            padding-bottom: 32px;
        }
    }
    &__box {
        border-top: 1px solid $black;
    }
}

.cases-main-header_text {
    @include font-size(20, 32, 400);
    @include device-md("min"){
        @include font-size(24, 40, 400);
    }
    @include device-xl("min"){
        @include font-size(26, 44, 400);
    }
    margin: 0;
    width: 100%;
    @include device-md() {
        max-width: 340px;
    }
}