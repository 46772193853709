.big-paragraph {
    @include font-size(80, 92, 500, -3);
    padding: 80px;
    p {
        margin-bottom: 64px;
    }

    @include rwd(1024) {
        @include font-size(64, 74, 500, -3);
        p {
        margin-bottom: 58px;
        }
    }

    @include device-lg {
        padding: 32px
    }

    @include device-sm {
        @include font-size(32, 40, 500, -3);
        padding: 16px;
        margin-top: 16px;
        p {
        margin-bottom: 32px;
        }
    }
}