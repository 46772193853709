.hero-journal{
    &.section{
        padding-top: 275px;
        padding-bottom: 60px;
        @include rwd-min(1024){
            padding-top: 366px;
            padding-bottom: 120px;
        }
        @include rwd-min(1440){
            padding-bottom: 160px;
        }
    }
    &__bg{
        background-color: $black;
        position: absolute;
        top: 0;
        left: 0;
        height: 658px;
        width: 100%;
        @include rwd-min(400){
            height: 604px;
        }
        @include rwd-min(1024){
            height: 869px;
        }
        @include rwd-min(1440){
            height: 1077px;
        }
    }
    &__content{
        @include main-grid
    }
    &__title{
        color: $white;
        margin-bottom: 80px;
        grid-column: 1/-1;
        grid-row: 1;
        @include rwd(576) {
            @include font-size(70, 70, 500, -3);
            margin-bottom: 60px;
        }
        // @include rwd(375) {
        //     margin-bottom: 159px;
        // }
    }
    &__post-container{
        align-self: flex-end;
        width: 100%;
        grid-column: 9/-1 ;
        grid-row: 2;
        color: $black;
        @include rwd(768){
            grid-column: 1/-1;
        }
    }
}

.post{
    width: 100%;
    padding-bottom: 13px;
    &__link{
        text-decoration: none;
        color: inherit;
    }
    &__image{
        display: flex;
        img{
            width: 100%;
            margin-bottom: 40px;
            @include rwd(1024){
                margin-bottom: 24px;
            }
            @include rwd(768){
                margin-bottom: 16px;
            }
        }
        &.--main{
            img{
                min-height: 183px;
                @include rwd-min(1024){
                    min-height: 303px;
                }
                @include rwd-min(1440){
                    min-height: 451px;
                }
            }
        }
    }
    &__title{
        margin-bottom: 40px;
        @include rwd(768){
            margin-bottom: 20px;
        }
        h4{
            margin: 0;
            @include rwd(768){
                @include font-size(30, 36, 400, -3);
            }
        }
        h5{
            margin: 0;
            @include rwd(768){
                @include font-size(20, 26, 400, -3);
            }
        }
        h6{
            margin: 0;
            @include rwd(768){
                @include font-size(20, 26, 400, -3);
            }
        }
    }
    &__author {
        @include flex-center;
        justify-content: flex-start;
        gap: 16px;

        strong {
            display: block;
            margin-bottom: -10px;
            @include font-size(12, 20, 700);
            @include device-xl("min") {
                @include font-size(16, 28, 700);
            }
        }

        span {
            @include font-size(12, 20);
            @include device-xl("min") {
                @include font-size(16, 28);
            }
        }
        img {
            width: 46px;
            @include rwd(768){
                width: 32px;
            }
        }
    }
    &.--box{
        @include main-grid;
        border-top: 1px solid $black;
        padding: 24px 0;
        .post__image{
            grid-row: 1/span 2;
            grid-column: 7/-1;
            align-self: start;
            @include rwd-min(768){
                grid-column: 16/-1;
            }
        }
        .post__title{
            grid-row: 1;
            grid-column: 1/7;
            align-self: start;
            margin: 0;
            @include rwd-min(768){
                grid-column: 1/16;
            }
        }
        .post__author{
            grid-row: 2;
            grid-column: 1/7;
            align-self: start;
            margin-top: 8px;
            @include rwd-min(768){
                grid-column: 1/16;
            }
            img{
                display: none;
                margin: 0;
            }
            &-text{
                display: flex;
                column-gap: 4px;
                strong{
                    margin: 0;
                }
            }
        }
    }
}

.row-posts{
    align-items: flex-end;
    @include main-grid;
    padding-bottom: 160px;
    @include rwd(1024){
        padding-bottom: 120px;
    }
    @include rwd(768){
        padding-bottom: 0;
    }
    &__post{
        grid-row: 1;
        align-self: end;
        @include rwd(768){
            padding-bottom: 60px;
        }
    }
    :nth-child(1){
        grid-column: 1/8;
        @include rwd(768){
            grid-row: 1;
            grid-column: 1/-1;
        }
    }
    :nth-child(2){
        grid-column: 9/-1;
        @include rwd(768){
            grid-row: 2;
            grid-column: 1/-1;
        }
    }
    &.--reverse{
        :nth-child(1){
            grid-column: 17/-1;
            @include rwd(768){
                grid-row: 2;
                grid-column: 1/-1;
            }
        }
        :nth-child(2){
            grid-column: 1/16;
            @include rwd(768){
                grid-row: 1;
                grid-column: 1/-1;
            }
        }
    }
}

.three-posts{
    padding-bottom: 270px;
    @include rwd(768){
        padding-bottom: 70px;
    }
    &__content{
        @include main-grid;
        a{
            color: inherit;
            text-decoration: none;
        }
        @include rwd(768){
        display: flex;
        flex-direction: column;
        }
    }
    &__post:nth-child(1){
        grid-row: 1;
        grid-column: 1/8;
    }
    &__post:nth-child(2){
        grid-row: 1;
        grid-column: 9/16;
    }
    &__post:nth-child(3){
        grid-row: 1;
        grid-column: 17/-1;
    }
    
    .btn-box{
        grid-row: 2;
        grid-column: 9/16;
        margin-top: 120px;
        @include rwd(768){
            width: 100%;
            margin-top: 60px;
        }
    }
    &.--more-to-read{
        padding-bottom: 160px;
        .three-posts__header{
            border-top: 1px solid $black;
            padding: 32px 0;
            @include rwd(768){
                padding: 12px 0;
            }
        }
        .btn-box{
            display:none;
        }
        @include rwd(768){
            padding-bottom: 0px;
        }
    }
}

.post-body{
    @include main-grid;
    padding-top: 190px;
    padding-bottom: 240px;
    row-gap: 160px;
    @include rwd(1024){
        row-gap: 120px;
    }
    @include rwd(768){
        padding-top: 112px;
        padding-bottom: 0;
        row-gap: 80px;
    }
    &__divider{
        grid-row: 1;
        grid-column: 1/-1;
        border-top: 2px solid $black;
        padding-top: 16px;
        a{
            color: inherit;
            text-decoration: none;
            display: flex;
            column-gap: 8px;
            align-items: center;
        }
    }
    &__title-container{
        grid-column: 9/-1;
        grid-row: 2;
        @include rwd(768){
            grid-column: 1/-1;
        }
    }
    &__title{
        @include font-size(80, 92, 500, -3);
        margin-bottom: 80px;
    
        @include device-xl {
            @include font-size(64, 74, 500, -3);
            margin-bottom: 60px
        }
    
        @include device-sm {
            @include font-size(32, 40, 500, -3);
            margin-bottom: 24px;
        }
    }
    &__related-posts{
        grid-row: 3;
        grid-column: 1/6;
        align-self: baseline;
        @include rwd(768){
            grid-column: 1/-1;
            grid-row: 4;
        }
        &__title{
            border-top: 2px solid $black;
            padding: 16px 0;
            @include rwd(768){
                padding: 8px 0;
                border-bottom: 1px solid $black;
            }
        }
        &__item{
            border-bottom: 1px solid $black;
            padding: 16px 0;
            @include rwd(768){
                padding: 8px 0;
            }
            a{
                color: $black;
            }
        }
    }
    &__content{
        grid-column: 9/-1;
        grid-row: 3;
        @include rwd(768){
            grid-column: 1/-1;
            grid-row: 3;
        }
        p{
            margin: 0;
            padding-bottom: 24px;
        }
        img{
            width: 100%;
            padding-bottom: 24px;
        }
        h2{
            @include font-size(26, 36, 600, -3);
            padding: 56px 0 24px 0;

            @include device-xl {
                @include font-size(24, 34, 600, -3);
            }
        
            @include device-sm {
                @include font-size(20, 26, 600, -3);
            }
        }
        ol {
            list-style-type: none;
            counter-reset: my-counter;
            padding-bottom: 56px;
        }
        li {
            display: flex;
            padding-bottom: 8px;
            strong{
                display: contents;
            }
        }
        ol li::before {
            counter-increment: my-counter;
            content: counter(my-counter);
            padding-right: 40px;
            @include rwd(768){
                padding-right: 16px;
                font-size: 16px;
            }
        }
        ul {
            list-style:none;
            padding-bottom: 56px;
        }
        ul li::before {
            content: "→";
            padding-right: 40px;
            @include rwd(768){
                padding-right: 16px;
                font-size: 16px;
            }
        }
    }
}