.feature {
    &-list {
        @include main-grid;

        &.desk-cols-2 {
            @include main-grid-columns(
                (
                    "xl": 2,
                    "lg": 2,
                    "md": 1,
                    "sm": 1,
                )
            );
        }
        &.desk-cols-3 {
            @include main-grid-columns(
                (
                    "xl": 3,
                    "lg": 3,
                    "md": 1,
                    "sm": 1,
                )
            );
        }

        align-items: start;
        row-gap: 32px;
    }
    &-item {
        &__box {
            @include flex-start;
            gap: 32px;
            border-top: 1px solid $black;
            padding-top: 32px;
            @include device-xl("min") {
                padding-top: 24px;
                gap: 24px;
            }
        }
        &__title {
            max-width: 555px;
            width: 100%;
            margin-bottom: 0;
            @include device-xl("min") {
                gap: 24px;
            }
        }
        &__text {
            max-width: 555px;
            width: 100%;
            margin: 0;
        }
    }
}

.feature-item-w-cta {
    padding-top: 120px;
    padding-bottom: 120px;
    @include device-md {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &__list {
        @include main-grid;

        &.desk-cols-2 {
            @include main-grid-columns(
                (
                    "xl": 2,
                    "lg": 2,
                    "md": 1,
                    "sm": 1,
                )
            );
        }
        &.desk-cols-3 {
            @include main-grid-columns(
                (
                    "xl": 3,
                    "lg": 3,
                    "md": 1,
                    "sm": 1,
                )
            );
        }

        align-items: start;
        row-gap: 32px;

        @include device-md{
            row-gap: 64px;
        }

        min-height: 440px;
        align-items: unset;
    }
    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 100px;
        border-top: 2px solid $black;
        align-items: flex-start;
        @include device-xl {
            gap: 60px;
        }
        @include device-sm {
            gap: 32px
        }
    }
    &__box {
        padding-top: 0;
        border: none;
    }
    &__title {
        width: 100%;
        margin-bottom: 0;
        margin-bottom: 16px;
        @include device-xl {
            margin-bottom: 24px;
        }
        @include device-sm{
            margin-bottom: 16px;
        }
    }
    &__text {
        margin-bottom: 0;
    }
    .hr-text {
        p {
            margin-bottom: 60px;
            padding-top: 32px ;
            @include device-md {
                padding-top: 24px ;
                margin-bottom: 32px;
            }
        }
    }
}