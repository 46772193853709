.industries {
    &__zz-boxes {
        display: flex;
        flex-direction: column;
        row-gap: 80px;
        justify-content: center;
        padding-top: 120px;
        padding-bottom: 90px;
        @include device-md {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        @include device-sm {
            row-gap: 50px;
        }

        &__box {
            @include main-grid;
            min-height: 600px;

            .description {
                padding: 20px 0;
                grid-row: 1;

                h4 {
                    margin-bottom: 40px;
                    @include device-lg {
                        margin-bottom: 24px;
                    }
                    @include device-sm {
                        margin-bottom: 16px;
                    }
                }

                .--richtext{
                    p:last-of-type{
                        margin-bottom: 0;
                    }
                }
            }
            
            .image {
                height: 100%;
                max-width: 100%;
                width: 100%;
                grid-row: 1;
                object-fit: cover;
                @include device-lg {
                    aspect-ratio: 1;
                }
            }

            &:nth-child(even) {
                .description {
                    grid-column: 1 /span 11;
                }
                .image {
                    grid-column: 15 /span 9;
                }
            }

            &:nth-child(odd) {
                .image {
                    grid-column: 1 /span 9;
                }
                .description {
                    grid-column: 13 /span 11;
                }
            }
        }

        @include rwd(920) {
            &__box {
                row-gap: 24px;
                &:nth-child(odd),
                &:nth-child(even) {
                    .description {
                        grid-column: 1 /span 23;
                        grid-row: 2;
                    }
                    .image {
                        grid-column: 1 /span 23;
                        grid-row: 1;
                    }
                }
            }
        }

        @include device-md {
            &__box {

                &:nth-child(odd),
                &:nth-child(even) {
                    .description {
                        grid-column: 1 /span 7;
                    }
                    .image {
                        grid-column: 1 /span 7;
                    }
                }
            }
        }
    }
    &__intro-text {
        .--richtext{
            p:last-of-type{
                margin-bottom: 0;
            }
        }
        padding-top: 120;
        padding-bottom: 120px;
        @include device-md {
            padding-top: 120px;
            padding-bottom: 30px;
        }
        &__content {
            margin-top: 120px;
            @include device-md {
                margin-top: 60px;
            }
            @include main-grid;
        }
        h3 {
            grid-column: 1 / -1;
            grid-row: 1;
            @include device-xl("min"){
                grid-column: 1 / 18;
            }
        }
        &__text {
            grid-row: 2;
            grid-column: 1 / -1;
            @include device-lg("min"){
                grid-column: 1 / 18;
            }
        }
    }
    &-companies-slider {
        padding-top: 180px;
        padding-bottom: 180px;
        @include device-md {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        overflow: hidden;
        &__box {
            @include device-md {
                margin-left: -16px;
                margin-right: -16px;
            }
        }
        &__container {
            overflow: hidden;
            &.section {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        &__inner {
            // padding-top: 32px;
            padding-bottom: 60px;
            padding-left: 16px;
            padding-right: 16px;

            @include device-md("min") {
                padding-left: 32px;
                padding-right: 32px;
                @include flex-center(column);
            }
            @include device-lg("min") {
                padding-bottom: 80px;
            }
            @include device-xl("min") {
                padding-bottom: 120px;
            }
            @include flex-start(column);
        }
        &__title {
            width: 100%;
            margin-bottom: 24px;
            @include device-md("min") {
                text-align: center;
            }
            @include font-size(40, 50, 500, -2);
            @include device-lg("min") {
                max-width: 720px;
                @include font-size(80, 80,500, -3);
                margin-bottom: 40px;
            }
            @include device-xl("min") {
                max-width: 840px;
                @include font-size(100, 100, 500, -4);
            }
        }
        &__text {
            width: 100%;
            @include device-md("min") {
                max-width: 620px;
                text-align: center;
            }
            p:last-of-type {
                margin-bottom: 0;
            }
        }
        &__logos {
            img {
                @include image-responsive;
            }
            .swiper-wrapper {
                transition-timing-function: linear !important;
                // align-items: center;
                // align-content: center;
    
                @include device-md() {
                    margin-bottom: 25px;
                }
            }
        }
        &__box {
            padding-top: 180px;
            padding-bottom: 100px;

            @include device-lg {
                padding-top: 120px;
                padding-bottom: 100px;
            }
            @include device-md {
                padding-top: 60px;
                padding-bottom: 40px;
            }
        }
        &.--with-slider{
            .industries-companies-slider__static-logos{
                display: none;
            }
        }
        &.--without-slider{
            .industries-companies-slider__logos{
                display: none;
            }
            .industries-companies-slider__inner{
                @include rwd-min(425){
                    padding-bottom: 32px;
                }
                @include rwd-min(1024){
                    padding-bottom: 100px;
                }
                @include rwd-min(1440){
                    padding-bottom: 120px;
                }
            }
            .industries-companies-slider__static-logos{
                display: grid;
                grid-template-columns: repeat(2, 172px);
                place-content: center;
                @include rwd-min(1024){
                    grid-template-columns: repeat(3, 238px);
                }
                @include rwd-min(1440){
                    grid-template-columns: repeat(3, 290px);
                }
            }

            .industries-companies-slider__image-container{
                display: flex;
                align-items: center;
                justify-content: center;
                @include rwd-min(768){
                    width: 172px;
                    height: 89px;
                }   
                @include rwd-min(1024){
                    width: 238px;
                    height: 123px;
                }             
                @include rwd-min(1440){
                    width: 290px;
                    height: 150px;
                }
            }
            .industries-companies-slider__image{
                width: 100%;
                height: 100%;
            }
        }
    }
    &__images-txt {
        position: relative;

        &.--text-left{
            text-align: left;
        }
        &.--text-right{
            text-align: right;
        }
        &.--text-center{
            text-align: center;
        }

        .wrapper{
            @include main-grid();
            row-gap: 110px;
            @include device-lg {
                row-gap: 16px;
            }
        }
        padding-top: 60px;
        padding-bottom: 70px;
        @include device-md {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        img {
            @include image-responsive;
            width: 100%;
        }
        $images-txt: &;

        &__textbox {
            padding: 50px;
            @include device-lg {
                padding: 32px;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            z-index: 1;
            &:nth-of-type(1){
                grid-column: 1 / 10;
                @include device-md("min") {
                    grid-row: 1 / 3;
                }
                #{$images-txt}__textbox{
                    background: #EDFBFF;
                }
            }
            &:nth-of-type(2){
                grid-column: 15 / -1;
                flex-direction: column-reverse;
                color: $white;
                @include device-md("min") {
                    grid-row: 1 / 2;
                }
                #{$images-txt}__textbox{
                    background: #000000;
                }
            }
            &:nth-of-type(3){
                grid-column: 11 / 20;
                @include device-md("min") {
                    grid-row: 3 / 4;
                }
                #{$images-txt}__textbox{
                    background: #FFECCD;
                }
            }
            &:nth-of-type(4){
                grid-column: 1 / 10;
                grid-row: 4 / 6;
                @include device-md("min") {
                    grid-row: 5 / 7;
                }
                #{$images-txt}__textbox{
                    background: #FAF4FF;
                }
            }
            &:nth-of-type(5){
                grid-column: 15 / -1;
                @include device-md("min") {
                    margin-top: -110px;
                }
                @include device-md("min") {
                    grid-row: 6 / 8;
                }
                #{$images-txt}__textbox{
                    background: #A5FFE3;
                }
            }
            &.--img-bottom{
                flex-direction: column-reverse;
            }
            &.--img-top{
                flex-direction: column;
            }

            &.--border{
                border: 2px solid #D3D3D3;
            }
            &.--shadow{
                filter: drop-shadow(0px 20px 80px rgba(0, 0, 0, 0.15));
            }

            @include device-md {
                grid-column: 1 / -1 !important;
            }

            &__title {
                @include font-size(26, 36, 600, -2);
                margin-bottom: 16px;
                
                @include device-lg {
                    @include font-size(24, 34, 600);
                }
                @include device-md {
                    @include font-size(20, 26, 600, -3);

                }
            }
            &__text {
                @include font-size(20, 32);
                p:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        &.--careers-page{
            padding-bottom: 170px;
            .wrapper{
                row-gap: 0;
            }
            @include rwd(1024){
                padding-bottom: 140px;
            }
            @include rwd(768){
                padding-bottom: 120px;
                .wrapper{
                    display: flex;
                    flex-direction: column;
                }
            }
            @include rwd(425){
                padding-bottom: 80px;
            }
            
            .industries__images-txt__textbox{
                padding: 40px;
                @include rwd(768){
                    padding: 30px;
                }
            }
            .industries__images-txt__item:nth-child(1) {
                grid-row: 1;
            }
            .industries__images-txt__item:nth-child(2) {
                grid-row: 2;
                margin-top: -280px;
            }
            .industries__images-txt__item:nth-child(3) {
                grid-row: 3;
                margin-top: 80px;
            }
            .industries__images-txt__item:nth-child(4) {
                grid-row: 4;
                margin-top: 80px;
            }
            .industries__images-txt__item:nth-child(5) {
                grid-row: 5;
                margin-top: -85px;
            }
            @include rwd(768){
                .wrapper{
                    .industries__images-txt__item{
                        margin-top: 16px;
                    }
                    .industries__images-txt__item:first-child{
                        margin-top: 29px;
                    }
                }
            }   
        }

        &__linear-gradient {
            position: absolute;
            bottom: 58px;
            width: 100%;
            height: 1068px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @include rwd(1024){
                bottom: 30px;
            }
            @include rwd(425){
                bottom: 18px;
            }
        }
        &__gradient-sections {
            height: 178px;
            @include rwd(1024){
                height: 150px;
            }
            @include rwd(425){
                height: 98px;
            }
        }
        &__gradient-sections:nth-child(1){
            border-top:2px solid rgba(0, 0, 0, 0.05);
        }
        &__gradient-sections:nth-child(2){
            border-top:2px solid rgba(0, 0, 0, 0.10);
        }
        &__gradient-sections:nth-child(3){
            border-top:2px solid rgba(0, 0, 0, 0.25);
        }
        &__gradient-sections:nth-child(4){
            border-top:2px solid rgba(0, 0, 0, 0.50);
        }
        &__gradient-sections:nth-child(5){
            border-top:2px solid rgba(0, 0, 0, 0.75);
            border-bottom:2px solid rgba(0, 0, 0, 1);
        }
    }
    &__float-bar {
        &.visible {
            opacity: 1;
        }
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        backdrop-filter: blur(16px);
        z-index: 100;
        background: rgba(255, 255, 255, 0.65);
        width: 100%;
        position: fixed;
        bottom: 0;
        @include device-sm {
            .wrapper{
                padding-left: 0;
                padding-right: 0;
            }
        }
        &__box {
            border-top: 1px solid $black;
            padding-top: 8px;
            padding-bottom: 8px;
            @include device-sm {
                // padding-top: 12px;
                // padding-bottom: 12px;
                padding: 12px 16px;
            }
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 24px;
            @include device-sm {
                justify-content: space-between;
            }
        }
        &__title {
            @include font-size(20, 34, 400);

            @include device-lg {
                @include font-size(18, 32, 400)
            }
            @include device-sm {
                @include font-size(16, 28, 400)
            }
        }
    }
    &__hero {
        &.--finance {
            background-image: url('../images/industry/finance/hero-mask-desk.png'); 
            @include device-md {
                background-image: url('../images/industry/finance/hero-mask-mobile.png'); 
                background-position: 100% 100%;
            }
        }
        background-color: rgba(0, 0, 0, 1);
        position: relative;
        background-position-y: 40%;
        background-size: 100% 120%;
        &:before {
            content: '';
            position: absolute;
            z-index: 0;
            inset: 0;
            backdrop-filter: blur(10px);
            background: rgba(0, 0, 0, 0.6);
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 5;
            inset: 0;
            background: url('../images/noise.png') repeat center center;                
        }
        hr {
            border-width: 2px;
        }
        &.--bg-dark {
            color: $white;
            hr{
                border-color: $white;
            }
        }
        &__grid {
            @include main-grid;
            align-items: start;
        }

        &__header {
            grid-column: 1/-1;
            grid-row: 1/2;
            z-index: 1;
            margin-bottom: 220px;
            @include device-xl {
                margin-bottom: 300px;
            }
            @include device-sm{
                max-width: 90%;
                margin-bottom: 0
            }


            &__subtitle {
                @include font-size(26, 44);
                @include device-xl {
                    @include font-size(24, 40);
                }
                @include device-sm {
                    @include font-size(20, 32);
                }
                margin-bottom: 16px;
            }
            &__title {
                @include font-size(140, 140, 500, -4);
                @include device-xl {
                    @include font-size(100, 100, 500, -3);
                }
                @include device-sm {
                    @include font-size(56, 56, 500, -3);
                }
                margin-bottom: 0;
            }
        }
        &__section {
            grid-column: 1/-1;
            grid-row: 2/3;
            z-index: 1;
            @include device-sm {
                grid-column: 1/-1;
                grid-row: 4/5;
            }
        }
        &__text {
            grid-column: 1/12;
            grid-row: 3/6;
            min-height: 500px;
            @include font-size(20, 34);
            @include device-xl {
                grid-column: 1/14;
                @include font-size(18, 32)
            }
            @include device-sm{
                @include font-size(16, 28);
                grid-row: 5/6;
                grid-column: 1/-1;
            }
        }
        &__media-1{
            grid-column: 17/-1;
            // height: 100%;
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            position: absolute;
            grid-row: 3/4;
            top: -19.5em;
            @include device-xl {
                top: -25em;
            }
            @include device-sm {
                grid-column: 3/-1;
                grid-row: 2/3;
                position: relative;
                top: -1.5em;
            }
        }
        &__media-2{
            grid-column: 15/20;
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            position: absolute;
            grid-row: 3/4;
            top: -3em;
            @include device-xl {
                top: -11em; 
            }
            @include device-sm {
                grid-column: 1/4;
                grid-row: 3/4;
                position: relative;
                top: -3.5em;
            }
        }
        &__media-3{
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            grid-column: 21/-1;
            position: absolute;
            grid-row: 3/4;
            top: 15em;
            @include device-xl {
                top: 2em; 
            }
            @include device-sm {
                grid-column: 5/-1;
                grid-row: 7/8;
                position: relative;
                top: 0.5em;
                z-index: 1;
            }
        }
        &__media-4{
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            grid-column: 15/20;
            position: absolute;
            grid-row: 6/7;
            z-index: 1;
            @include device-xl {
                grid-column: 17/-1;
            }
            @include device-sm {
                grid-column: 1/6;
                grid-row: 8/9;
                top: -7.5em;
                z-index: 0;
                // position: relative;
            }
        }

        video {
            pointer-events: none;
        }

        video::-webkit-media-controls {
            display: none !important;
        }
        video::-webkit-media-controls-enclosure {
            display: none !important;
        }
        video::-webkit-media-controls-overlay-enclosure {
            display: none !important;
        }
        video::-webkit-media-controls-overlay-play-button {
            display: none !important;
        }
        
        /* mostrar los controles de video cuando el mouse no esté en la pantalla */
        video:hover::-webkit-media-controls {
        display: initial !important;
        }

        &.--automotive {
                background-image: url('../images/industry/automotive/hero-bg-desktop.png'); 
                @include device-md {
                    background-image: url('../images/industry/automotive/hero-bg-mobile.png'); 
                    background-position: 100% 100%;
                }
                background-color: rgba(0, 0, 0, 1);
            position: relative;
            background-position-y: 90%;
            background-size: 100% 120%;
            @include rwd-min(320){
                .industries__hero__media-4{
                    top: -1.5em;
                }
            }
            @include rwd-min(375){
                .industries__hero__header{
                    z-index: 2;
                }
                .industries__hero__media-1{
                    top: -8.5em;
                    z-index: 1;
                }
                .industries__hero__media-2{
                    top: -11.5em;
                }
                .industries__hero__section{
                    grid-row: 3/4;
                    margin-top: 60px;
                }
                .industries__hero__media-4{
                    top: -1em
                }
            }
            @include rwd-min(768){
                .industries__hero__section{
                    grid-row: 2/3;
                    margin-top: 0;
                }
                .industries__hero__media-1{
                    top:-22em;
                }
                .industries__hero__media-3{
                    top: 32em;
                    z-index: 2;
                }
            }
            @include rwd-min(1024){
                .industries__hero__header{
                    margin-bottom: 204px;
                }
                .industries__hero__text{
                    width: 496px;
                }
                .industries__hero__media-1{
                    height: 274px;
                    width: 274px;
                    z-index: 1;
                    top: -16em;
                }
                .industries__hero__media-2{
                    top: -2em;
                }
                .industries__hero__media-3{
                    top: 10em;
                }
                .industries__hero__media-4{
                    top: -5em;
                }
                .industries__hero__section{
                    grid-row: 2/3;
                    margin-top: 0;
                }
            }
            @include rwd-min(1440){
                padding-bottom: 200px;
                .industries__hero__header{
                    z-index: 2;
                }
                .industries__hero__media-1{
                    width: 510px;
                    height: 510px;
                    top: -26.2em;
                    z-index: 1;
                    grid-column: 15/-1;
                }
                .industries__hero__media-2{
                    grid-column: 13/18;
                }
                .industries__hero__media-4{
                    width: 400px;
                    height: 370px;
                    grid-column: 15/21;
                    top: 160px;
                    grid-row: 5;
                }
                .industries__hero__text{
                    @include rwd(1440){
                        width: 620px;
                    }
                }

            }
            
        }
    }
    &__portfolio {
        padding-top: 60px;
        padding-bottom: 240px;
        @include device-md {
            padding-top: 30px;
            padding-bottom: 120px;
        } 
    }
    &__digital-experience {
        @include main-grid;
        padding-top: 225px;
        padding-bottom: 0px;
        @include rwd(1440){
            padding-top: 219px;
        }
        @include rwd(1024){
            padding-top: 442px;
        }
        @include rwd(768){
            display: none
        }

        &__hr-text{
            grid-row: 1;
            grid-column: 1/-1;
            position: sticky;
            top: -12px;
            margin-bottom: 120px;
            transition: all 0.2s ease-in-out;
        }

        .--hr-text-out{
            opacity: 0;
        }

        &__title-container{
            grid-column: 1/9;
            grid-row: 2;
            display: flex;
            align-self: flex-start;
            align-content: flex-end;
            flex-direction: column;
            position: sticky;
            z-index: 1;
            top: 220px;
            height: 500px;
            h3{
                margin: 0;
                height: 300px;
                @include rwd(1024){
                    height: 175px;
                }
            }
            @include rwd(1024){
                grid-column: 1/10;
                height: auto;
            }
        }

        &__circle-container{
            align-self: flex-end;
            transform: rotate(-90deg);
            margin-right: 10px;
            z-index: 1000;
            @include rwd(1024){
                margin-right: 25px;
            }
        }
        &__circle-progress {
            /* calculate using: (2 * PI * R) */
            stroke-dasharray: 622;
            stroke-dashoffset: 622;
        }

        &__images-container{
            grid-column: 7/14;
            align-self: flex-start;
            width: 400px;
            height: 500px;
            grid-row: 2;
            position: sticky;
            top: 220px;
            @include rwd(1024){
                width: 275px;
                height: 375px;
            }
        }

        &__image{
            width: 400px;
            height: 400px;
            position: absolute;
            transition: all 0.5s ease-in-out;
            @include rwd(1024){
                width: 275px;
                height: 275px;
            }
        }

        &__boxes-container{
            grid-column: 15/-1;
            display:flex;
            align-self: start;
            flex-direction: column;
            grid-row: 2;
        }

        &__box{
            padding-top: 20px;
            transition: all 0.5s ease-in-out;
            border-top: 1px solid black;
        }

        &__box:last-child{
            border-bottom: 1px solid black;
        }

        .--box-out{
            opacity: 0;
        }

        .--img-out{
            opacity: 0;
        }

        &__mobile{
            display: none;
            
            @include rwd(768){
                display: grid;
                @include main-grid;
                padding-top: 180px;
                padding-bottom: 0px;
            }
            @include rwd(425){
                padding-top: 224px;
            }
            @include rwd(375){
                padding-top: 180px;
            }

            &__hr-text{
                grid-row: 1;
                grid-column: 1/-1;
                margin-bottom: 28px;
            }

            &__title-container{
                grid-row: 2;
                grid-column: 1/-1;
                position: relative;
                height: 100px;
            }

            &__circle-container{
                position: relative;
                transform: rotate(-90deg);
                @include rwd-min(320){
                    top: -92px;
                    left: -10px;
                }
                @include rwd-min(375){
                    top: -70px;
                    left: 9px;
                }
                @include rwd-min(768){
                    top: -96px;
                    left: 20px;
                }
                
            }
            &__circle-progress {
                /* calculate using: (2 * PI * R) */
                stroke-dasharray: 264;
                stroke-dashoffset: 264;
            }
            
            &__boxes-container{
                grid-column: 1/-1;
                grid-row: 3;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                overflow: auto;
                border-bottom: 1px solid black;
            }

            &__boxes-container::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }

            &__box{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 250px;
                margin-bottom: 10px;
                margin-right: 16px;
            }

            &__image-container{
                width: 250px;
                height: 170px;
                margin-bottom: 24px;
            }

            &__image{
                object-fit: cover;
                width: 250px;
                height: 170px;
            }
        }
    }
}
