.telecom{
    // ------  Archivo: challenge / Clase: telecom-img ------
    &-img {
        @include device-sm(){
            padding-left: 0;
            padding-right: 0;
            img {
                object-fit: contain;
                height: auto;
            }
            .case-challenge__img{
                height: auto;
            }
        }
    }

    // ------  Archivo: roadmap-white / Clase: telecom-roadmap-white ------
    &-roadmap-white {
        &-content {
            margin-top: 150px;
            @include device-md() {
                margin-top: 60px;
            }

        }

        &-img {
            @include main-grid-cases();
            margin-top: 120px;

            img {
                grid-column: 5 / span 15;
                width: 100%;
            }
            @include device-md() {
                margin-top: 60px;
                img {
                    grid-column: 1 / -1;
                }
            }
        }

        @include device-md() {
            padding-bottom: 120px;
        }
    }
    
    // ------  Archivo: roadmap-black / Clase: telecom-roadmap-black ------
    &-roadmap-black {
        background-color: #000000;
        color: #FFFFFF;
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .telecom-roadmap-black-icon {
                width: 108px;
                height: 98px;
                margin-bottom: 32px;

                @include device-md() {
                    width: 66px;
                    height: 60px;
                    margin-bottom: 16px;
                }
            }

            .telecom-roadmap-black-grid {
                @include main-grid-cases();
                row-gap: 40px;
                margin-top: 120px;

                img {
                    width: 100%;
                }

                .img1 {
                    grid-column: 1 / span 11;
                    grid-row: 1;
                }
                .img2 {
                    grid-column: 13 / span 11;
                    grid-row: 1;
                }
                .img3 {
                    grid-row: 2;
                    grid-column: 1 / span 5;
                }
                .img4 {
                    grid-row: 2;
                    grid-column: 7 / span 5;
                }
                .img5 {
                    grid-row: 2;
                    grid-column: 13 / span 5;
                }
                .img6 {
                    grid-row: 2;
                    grid-column: 19 / span 5;
                }

                @include device-md() {
                    row-gap: 20px;
                    margin-top: 60px;

                    .img1 {
                        grid-column: 1 / -1;
                        grid-row: 1;
                        margin-bottom: -10px;
                    }
                    .img2 {
                        grid-column: 1 / -1;
                        grid-row: 2;
                    }
                    .img3 {
                        grid-row: 3;
                        grid-column: 1 / span 3;
                    }
                    .img4 {
                        grid-row: 3;
                        grid-column: 5 / span 3;
                    }
                    .img5 {
                        grid-row: 4;
                        grid-column: 1 / span 3;
                    }
                    .img6 {
                        grid-row: 4;
                        grid-column: 5 / span 3;
                    }
                }
            }
        }

        @include device-md() {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    // ------  Archivo: roadmap-green / Clase: telecom-roadmap-green ------
    &-roadmap-green {
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .telecom-roadmap-green-icon {
                width: 108px;
                height: 98px;
                margin-bottom: 32px;

                @include device-md() {
                    width: 66px;
                    height: 60px;
                    margin-bottom: 16px;
                }
            }

            .telecom-roadmap-green-grid {
                @include main-grid-cases();
                // row-gap: 40px;
                margin-top: 120px;

                picture, img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .img1 {
                    grid-column: 1 / span 11;
                    grid-row: 1 / span 2;
                }
                .img2 {
                    grid-column: 13 / span 11;
                    grid-row: 1;
                }
                .img3 {
                    grid-row: 2;
                    grid-column: 13 / span 5;
                }
                .img4 {
                    grid-row: 2;
                    grid-column: 19 / span 5;
                }
                .img5 {
                    grid-row: 3 / span 2;
                    grid-column: 1 / span 11;
                }
                .img6 {
                    grid-row: 3;
                    grid-column: 13 / span 11;
                }
                .img7 {
                    grid-row: 4;
                    grid-column: 13 / span 11;
                }

                @include device-md() {
                    // row-gap: 20px;
                    margin-top: 60px;

                    .img1 {
                        grid-column: 1 / -1;
                        grid-row: 1;
                    }
                    .img2 {
                        grid-column: 1 / -1;
                        grid-row: 2;
                    }
                    .img3 {
                        grid-row: 3;
                        grid-column: 1 / span 3;
                    }
                    .img4 {
                        grid-row: 3;
                        grid-column: 5 / span 3;
                    }
                    .img5 {
                        grid-row: 6;
                        grid-column: 1 / -1;
                    }
                    .img6 {
                        grid-row: 4;
                        grid-column: 1 / -1;
                    }
                    .img7 {
                        grid-row: 5;
                        grid-column: 1 / -1;
                    }
                }
            }
        }

        @include device-md(){
            padding-top: 60px;
            padding-bottom: 40px;
        }
    }

    // ------  Archivo: roadmap-emojis / Clase: telecom-roadmap-emojis ------
    &-roadmap-emojis {
        img {
            width: 60px;
            height: 60px;
        }

        p {
            margin: 0;
        }

        .container1, .container2, .container3, .container4 {
            border-left: 4px solid #000000;
            border-right: 4px solid #000000;
            padding-left: 40px;
            padding-right: 40px;
            @include main-grid-cases();

            @include device-md {
                padding-left: 16px;
                padding-right: 16px;
                border-left: 2px solid #000000;
                border-right: 2px solid #000000;
                row-gap: 0px;
            }
        }
        .container1 {
            border-top: 4px solid #000000;
            border-bottom: 4px solid #000000;
            padding-top: 65.5px;
            padding-bottom: 69px;

            .big-text {
                @include font-size(20, 34, 400, 0);
                grid-column: 13 / -1;
                @include rwd(1024) {
                    @include font-size(18, 32, 400, 0);
                }
                @include device-md {
                    @include font-size(16, 28, 400, 0);
                    grid-column: 1 / -1;
                }

            }

            @include device-md {
                padding-top: 32px;
                padding-bottom: 36px;
                border-bottom: 2px solid #000000;
                border-top: 2px solid #000000;
                border-left: 2px solid #000000;
                border-right: 2px solid #000000;
                .big-title {
                    margin-bottom: 16px;
                }
            }
        }
        .container2 {
            margin-top: -45px;
            padding-bottom: 40px;
            border-bottom: 2px solid #000000;

            .img1 {
                grid-column: 1 / -1;
                width: 100%;
                height: auto;
                margin-bottom: 40px;
            }
            .img2 {
                grid-column: 1 / -1;
                width: 100%;
                height: 100%;
            }

            @include device-md() {
                overflow: scroll;
                margin-top: 0;
                padding-bottom: 32px;
                padding-top: 32px;
                .img1 {
                    height: 290px;
                    width: auto;
                }
                .img2 {
                    height: 110px;
                    width: auto;
                }
            }
        }
        .container3{
            border-top: 2px solid #000000;
            padding-top: 60px;
            align-items: start;
            .emoji-box {
                border-radius: 32px;
                margin-bottom: 40px;
                padding: 40px 40px 8px 40px;
            }
            .emoji-title-img {
                display: flex;
                justify-content: space-between;
                margin-bottom: 32px;
                flex-wrap: wrap;
                @include rwd(1024) {
                    flex-direction: column-reverse;
                    margin-bottom: 16px;
                    p {
                        margin-bottom: 0;
                        margin-top: 8px;
                    }
                }
            }
            .column1 {
                grid-column: 1 / span 7;

                .emoji-box1 {
                    border: 4px solid #45F803;
                    box-shadow: 0px 16px 0px #45F803;
                }
                .emoji-box2 {
                    border: 4px solid #F4D201;
                    box-shadow: 0px 16px 0px #F4D201;
                }
                .emoji-box3 {
                    border: 4px solid #95F600;
                    box-shadow: 0px 16px 0px #95F600;
                }

                @include device-md() {
                    grid-column: 1 / -1;
                }
            }

            .column2 {
                grid-column: 9 / span 7;
                padding-top: 80px;
                .emoji-box4 {
                    border: 4px solid #DBFE02;
                    box-shadow: 0px 16px 0px #DBFE02;
                }
                .emoji-box5 {
                    border: 4px solid #FF0402;
                    box-shadow: 0px 16px 0px #FF0402;
                }
                .emoji-box6 {
                    border: 4px solid #63FB02;
                    box-shadow: 0px 16px 0px #63FB02;
                }

                @include device-md() {
                    grid-column: 1 / -1;
                    padding-top: 0px;
                }
            }

            .column3 {
                grid-column: 17 / span 7;
                padding-top: 160px;
                .emoji-box7{
                    border: 4px solid #F5E901;
                    box-shadow: 0px 16px 0px #F5E901;
                }
                .emoji-box8{
                    border: 4px solid #F6A801;
                    box-shadow: 0px 16px 0px #F6A801;
                }
                .emoji-box9{
                    border: 4px solid #FF0402;
                    box-shadow: 0px 16px 0px #FF0402;
                    background-color: #FFFFFF;
                    margin-bottom: 0;
                }

                @include device-md() {
                    grid-column: 1 / -1;
                    padding-top: 0px;
                    .emoji-box9 {
                        margin-bottom: 45px;
                    }
                }
            }
            @include rwd(1024) {
                .emoji-box {
                    padding: 32px 24px;
                }
            }
            @include rwd(768) {
                .emoji-box {
                    padding: 20px 10px 9px 10px;
                }
            }
            @include device-md() {
                border-top: none;
                padding-top: 40px;
                .emoji-box {
                    padding: 24px;
                    padding-bottom: 8px;
                    margin-bottom: 32px;
                }
                .big-title {
                    margin-bottom: 32px;
                }
            }
        }
        .container4 {
            border-top: 4px solid #000000;
            border-bottom: 4px solid #000000;
            margin-top: -50px;
            padding: 60px 40px;
            align-items: start;
            background-color: #F1F1F1;

            .emoji-box {
                border: 4px solid #000000;
                box-shadow: 0px 16px 0px #000000;
                border-radius: 32px;
                grid-row: 2;
                padding: 40px 39px 8px 39px;
                background-color: #ffffff;


                .emoji-tiny-title {
                    margin-bottom: 16px;
                    margin-top: 16px;
                }
            }

            .emoji-box1 {
                grid-column: 1 / span 7;
            }
            .emoji-box2 {
                grid-column: 9 / span 7;
            }
            .emoji-box3 {
                grid-column: 17 / span 7;
            }
            @include rwd(768) {
                .emoji-box1, .emoji-box2, .emoji-box3 {
                    padding: 20px 10px 9px 10px;
                }
            }
            @include device-md() {
                margin-top: 0px;
                padding: 32px 16px 45px 16px;
                border-top: 2px solid #000000;
                border-bottom: 2px solid #000000;

                .emoji-box1, .emoji-box2, .emoji-box3 {
                    grid-column: 1 / -1;
                    grid-row: auto;
                    padding: 24px;
                    margin-bottom: 32px;
                }

                .big-title {
                    margin-bottom: 24px;
                }
            }
        }
        .big-title {
            @include font-size(60, 70, 400, -3);
            grid-column: 1 / span 11;
            grid-row: 1;
            align-self: start;
            margin-bottom: 40px;
            @include rwd(1024){
                @include font-size(40, 50, 400, -2);
            }
            @include device-md(){
                @include font-size(24, 30, 400, -2);
            }
        }
        .emoji-title {
            @include font-size(26, 60, 400, 0);
            font-style: italic;
            @include rwd(1024){
                @include font-size(24, 36, 400, 0);
            }
            @include device-md(){
                line-height: 32px;  
            }
        }
        .emoji-tiny-title {
            @include font-size(10, 10, 700, 3);
            margin-bottom: 8px;
        }
        .emoji-tiny-paragraph {
            @include font-size(14, 22, 400, 0);
            margin-bottom: 32px;
            @include device-md(){
                @include font-size(12, 18, 400, 0);
                margin-bottom: 16px;
            }
        }

        @include device-md() {
            padding-top: 40px;
        }
    }

    // ------  Archivo: roadmap-testing / Clase: telecom-roadmap-testing ------
    &-roadmap-testing {
        margin-top: -120px;

        img {
            width: 100%;
            margin-top: 120px;

            @include device-md() {
                width: 100vw;
                margin-left: -16px;
                margin-right: -16px;
            }
        }
        .black-boxes{
            @include main-grid-cases();
            margin-top: 40px;
            align-items: start;

            div {
                background-color: #000000;
                color: #ffffff;
                padding: 40px;
                height: 100%;

                .number{
                    @include font-size(80, 92, 500, -3);
                    margin-bottom: 8px;
                }

                .text{
                    @include font-size(20, 28, 400, 0);
                }

                .number, .text {
                    margin: 0;
                    text-align: center;
                }
            }
            .box1 {
                grid-column: 1 / span 5;
            }
            .box2 {
                grid-column: 7 / span 5;
            }
            .box3 {
                grid-column: 13 / span 5;
            }
            .box4 {
                grid-column: 19 / span 5;
            }

            @include rwd(1024) {
                margin-top: 20px;
                div { 
                    padding: 24px;
                    .number {
                        @include font-size(64, 74, 500, -3)
                    }
                    .text {
                        @include font-size(18, 23, 400, 0)
                    }
                }
            }
            @include rwd(768) {
                div {
                    padding: 14px;
                }
            }
            @include device-md(){
                margin-top: 8px;
                
                div { 
                    padding: 19px;
                    .number {
                        @include font-size(32, 40, 500, -3)
                    }
                    .text {
                        @include font-size(14, 18, 400, 0)
                    }
                }
                .box1 {
                    grid-column: 1 / span 3;
                    grid-row: 1;
                }
                .box2 {
                    grid-column: 5 / span 3;
                    grid-row: 1;
                }
                .box3 {
                    grid-column: 1 / span 3;
                    grid-row: 2;
                }
                .box4 {
                    grid-column: 5 / span 3;
                    grid-row: 2;
                }
            }
        }

        @include device-md() {
            margin-top: 0px;
            padding-top: 40px;
            padding-bottom: 40px;

            img {
                margin-top: 60px;
            }
        }
    }

    // ------  Archivo: roadmap-results / Clase: telecom-roadmap-results ------
    &-roadmap-results {
        .results-grid {
            @include main-grid-cases();
            margin-top: 120px;
            p {
                margin: 0;
            }
            .circle, .circle1, .circle2, .circle3, .circle4 {
                border: 20px solid #9CFFA0;
                border-radius: 50%;
                width: 100%;
                aspect-ratio: 1 / 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 41px;
                padding-bottom: 65px;
                justify-content: center;
                .circle-letter {
                    @include font-size(100, 100, 500, -4);
                }
                .circle-text {
                    @include font-size(18, 22, 500, -2);
                }
                img {
                    width: 106px;
                    height: 106px;
                }
            }
            .circle {
                grid-column: 1 / span 11;
                grid-row: 1  / span 2;
                position: sticky;
                top: 21px;
                background-color: #9CFFA0;
                width: 620px;
                height: 620px;
                padding: 0;
                margin: auto;
                img {
                    width: 520px;
                    height: 520px;
                }
                .circle-letter {
                    position: absolute;
                    bottom: 265px;
                }
                .circle-text {
                    position: absolute;
                    bottom: 240px;
                }
            }
            .circle1{
                grid-column: 13 / span 5;
                grid-row: 1;
            }
            .circle2{
                grid-column: 19 / span 5;
                grid-row: 1;
            }
            .circle3{
                grid-column: 13 / span 5;
                grid-row: 2;
            }
            .circle4{
                grid-column: 19 / span 5;
                grid-row: 2;
            }

            .box1, .box2, .box3, .box4 {
                border: 4px solid #9CFFA0;
                box-shadow: 0px 16px 0px #9CFFA0;
                padding: 40px;
                border-radius: 32px;
                height: 100%;
                .box-title{
                    @include font-size(26, 43, 400, -2);
                }
                .box-number{
                    @include font-size(40, 43, 600, -2);
                    margin-bottom: 32px;
                }
                .box-text{
                    @include font-size(14, 22, 400, 0);
                }
            }
            .box1 {
                grid-column: 13 / span 5;
                grid-row: 3;
            }
            .box2 {
                grid-column: 19 / span 5;
                grid-row: 3;
            }
            .box3 {
                grid-column: 13 / span 5;
                grid-row: 4;
            }
            .box4 {
                grid-column: 19 / span 5;
                grid-row: 4;
            }

            @include rwd(1024) {
                .circle1, .circle2, .circle3, .circle4 {
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    padding-top: 28px;
                    padding-bottom: 42px;
                    .circle-letter {
                        @include font-size(80, 80, 500, -3);
                    }
                    .circle-text {
                        @include font-size(14, 22, 500, -2);
                    }
                    img {
                        width: 73.5px;
                        height: 73.5px;
                    }
                }
                .circle {
                    width: 422px;
                    height: 422px;
                    img {
                        width: 354px;
                        height: 354px;
                    }
                    .circle-letter {
                        @include font-size(80, 80, 500, -3);
                        bottom: 165px;
                    }
                    .circle-text {
                        @include font-size(14, 22, 500, -2);
                        bottom: 140px;
                    }
                }
                .box1, .box2, .box3, .box4 {
                    box-shadow: 0px 8px 0px #9CFFA0;
                    padding: 32px 24px;
                    height: 100%;
                    .box-title{
                        @include font-size(20, 30, 400, -2);
                    }
                }
            }

            @include rwd(768) {
                .circle {
                    grid-column: 1 / -1;
                    grid-row: 1;
                    position: relative;
                }
                .circle1 {
                    grid-column: 1 / span 11;
                    grid-row: 2;
                }
                .circle2 {
                    grid-column: 13 / span 11;
                    grid-row: 2;
                }
                .circle3 {
                    grid-column: 1 / span 11;
                    grid-row: 3;
                }
                .circle4 {
                    grid-column: 13 / span 11;
                    grid-row: 3;
                }
                .box1 {
                    grid-column: 1 / span 11;
                    grid-row: 4;
                }
                .box2 {
                    grid-column: 13 / span 11;
                    grid-row: 4;
                }
                .box3 {
                    grid-column: 1 / span 11;
                    grid-row: 5;
                }
                .box4 {
                    grid-column: 13 / span 11;
                    grid-row: 5;
                }
            }

            @include device-md() {
                margin-top: 60px;
                .circle1, .circle2, .circle3, .circle4 {
                    width: 100%;
                    aspect-ratio: 1 / 1;
                    padding-top: 28px;
                    padding-bottom: 38px;
                    border: 17px solid #9CFFA0;
                    .circle-letter {
                        @include font-size(50, 50, 500, -2);
                    }
                    .circle-text {
                        font-size: 12px;
                    }
                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
                .circle {
                    width: 343px;
                    height: 343px;
                    grid-column: 1 / -1;
                    grid-row: 1;
                    position: relative;
                    top: 0;
                    img {
                        width: 288px;
                        height: 288px;
                    }
                    .circle-letter {
                        @include font-size(50, 50, 500, -2);
                        bottom: 125px;
                    }
                    .circle-text {
                        bottom: 100px;
                    }
                }
                .circle1 {
                    grid-column: 1 / span 3;
                    grid-row: 2;
                }
                .circle2 {
                    grid-column: 5 / span 3;
                    grid-row: 2;
                }
                .circle3 {
                    grid-column: 1 / span 3;
                    grid-row: 3;
                }
                .circle4 {
                    grid-column: 5 / span 3;
                    grid-row: 3;
                }
                .box1 {
                    grid-column: 1 / span 3;
                    grid-row: 4;
                }
                .box2 {
                    grid-column: 5 / span 3;
                    grid-row: 4;
                }
                .box3 {
                    grid-column: 1 / span 3;
                    grid-row: 5;
                }
                .box4 {
                    grid-column: 5 / span 3;
                    grid-row: 5;
                }
                .box1, .box2, .box3, .box4 {
                    padding: 24px 16px;
                    height: 100%;
                    .box-title{
                        @include font-size(16, 26, 400, -2);
                    }
                    .box-number{
                        margin-bottom: 16px;
                    }
                }
            }
        }

        @include device-md() {
            padding-bottom: 60px;
        }
    }

    // ------  Archivo: designing-black / Clase: telecom-designing-black ------
    &-designing-black {
        background-color: #1C1C1C;
        color: #ffffff;
        .section-divider {
            border-color: #F1F1F1;
            margin-bottom: 150px;
        }
        img {
            width: 100%;
        }
        .img {
            margin-top: 120px;
            margin-bottom: 120px;
        }
        &-grid {
            @include main-grid-cases();
            margin-top: 120px;
            picture, img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .img1{
                grid-column: 1 / -1;
                grid-row: 1;
            }
            .img2{
                grid-column: 1 / span 11;
                grid-row: 2;
            }
            .img3{
                grid-column: 13 / span 11;
                grid-row: 2;
            }
            .img4{
                grid-column: 1 / span 11;
                grid-row: 3 / span 2;
            }
            .img5{
                grid-column: 13 / span 11;
                grid-row: 3;
            }
            .img6{
                grid-column: 13 / span 11;
                grid-row: 4;
            }
            .img7{
                grid-column: 1 / span 11;
                grid-row: 5 / span 2;
            }
            .img8{
                grid-column: 13 / span 11;
                grid-row: 5;
            }
            .img9{
                grid-column: 13 / span 11;
                grid-row: 6;
            }
        }

        @include device-md() {
            padding-top: 60px;
            padding-bottom: 60px;

            .telecom-center-text {
                text-align: left;
                h3 {
                    margin-bottom: 32px;
                }
            }
            .telecom-center-text-small {
                text-align: left;
            }
            .section-divider {
                margin-bottom: 80px;
            }
            .img {
                margin-bottom: 60px;
                margin-top: 60px;
            }
            &-grid {
                margin-top: 60px;
                
                .img2{
                    grid-column: 1 / span 3;
                }
                .img3{
                    grid-column: 5 / span 3;
                }
                .img4{
                    grid-column: 1 / span 3;
                }
                .img5{
                    grid-column: 5 / span 3;
                }
                .img6{
                    grid-column: 5 / span 3;
                }
                .img7{
                    grid-column: 1 / span 3;
                }
                .img8{
                    grid-column: 5 / span 3;
                }
                .img9{
                    grid-column: 5 / span 3;
                }
            }
        }
    }

    // ------  Archivo: designing-support y designing-client Clase: telecom-designing-support y telecom-designing-client ------
    &-designing-support, &-designing-client {
        background-color: #F1F1F1;
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        img {
            width: 100%;
        }
        .img {
            width: 352.72px;
            height: 350px;
            margin-bottom: 120px;
        }
        &-grid {
            @include main-grid-cases();
            margin-top: 120px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            .img1{
                grid-column: 1 / -1;
                grid-row: 1;
            }
            .img2{
                grid-column: 1 / span 11;
                grid-row: 2;
            }
            .img3{
                grid-column: 13 / span 11;
                grid-row: 2;
            }
            .img4{
                grid-column: 1 / span 11;
                grid-row: 3;
            }
            .img5{
                grid-column: 13 / span 11;
                grid-row: 3;
            }
            .img6{
                grid-column: 1 / span 11;
                grid-row: 4;
            }
            .img7{
                grid-column: 13 / span 11;
                grid-row: 4;
            }
        }
        @include rwd(1024) {
            .img {
                width: 350px;
                height: 350px;
            }
        }
        @include device-md() {
            padding-top: 60px;
            padding-bottom: 60px;

            .img {
                margin-bottom: 60px;
                margin-top: 0;
                width: 150px;
                height: 150px;
            }
            &-grid {
                margin-top: 60px;
                
                .img {
                    grid-column: 1 / -1;
                }
                .img2{
                    grid-column: 1 / span 3;
                }
                .img3{
                    grid-column: 5 / span 3;
                }
                .img4{
                    grid-column: 1 / span 3;
                }
                .img5{
                    grid-column: 5 / span 3;
                }
                .img6{
                    grid-column: 1 / span 3;
                }
                .img7{
                    grid-column: 5 / span 3;
                }
            }
        }
    }

    &-designing-client {
        background-color: #FFFFFF;

        @include device-md() {
            padding-bottom: 40px;
        }
    }
    
    // ------  Archivo: varios / Clase: telecom-center-text ------
    &-center-text, &-center-text-small  {
        text-align: center;
        @include main-grid-cases();
        row-gap: 0px !important;
        
        h3 {
            margin-bottom: 32px;
            grid-column: 5 / span 15;
            @include rwd(768) {
                grid-column: 1 / -1;
                padding-left: 16px;
                padding-right: 16px;
            }
            @include device-md() {
                padding: 0;
                margin-bottom: 16px;
            }
        }
        
        p {
            @include font-size(20, 34, 400, 0);
            margin: 0;
            grid-column: 5 / span 15;
            
            
            @include rwd(1024) {
                @include font-size(18, 32, 400, 0);
            }

            @include rwd(768) {
                @include font-size(16, 28, 400, 0);
                grid-column: 1 / -1;
                padding-left: 16px;
                padding-right: 16px;
            }
            @include device-md() {
                padding: 0;
            }
        }
    }
    &-center-text-small {
        h3 {
            @include font-size(60, 70, 400, -3);

            @include rwd(1024) {
                @include font-size(40, 50, 400, -2);
            }

            @include device-md() {
                @include font-size(24, 30, 400, -2);
            }
        }
    }

    // ------ Clase: telecom-text-align ------
    &-text-align {
        @include device-md() {
            text-align: left;
        }
    }
}

// ------  Archivo: challenge ------
.case-challenge__box-items-list {
    span {
        display: block;
        margin-top: 16px;
    }
}