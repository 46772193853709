.img-cta-section {
    padding-top: 46px;
    margin-bottom: 240px;

    @include rwd(1024){
        padding-top: 90px;
    }
    @include device-md(){
        padding-top: 110px;
        margin-bottom: 110px;
    }
}

.img-cta-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr auto;
    column-gap: 40px;
    margin-bottom: 0px;
    justify-content: space-between;

    a {
        color: inherit;
        display: flex;
        align-items: center;
        text-decoration: none;

        .img-box-anchor-img {
            height: 34px;
            padding-left: 8px;
            opacity: 0;
            margin-top: 16px;
        }
    }

    @include rwd(1024){
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
    }
    @include rwd(780){
        grid-template-columns: repeat(2, 1fr);
    }
    @include device-sm(){
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;

        // a {
        //     text-decoration: underline;

            .img-box-anchor-img {
                height: 20px;
                align-self: center;
            }
        // }
    }

    @include rwd(343){
        .img-box_text {
            margin-bottom: 10px;
        }
    }
}