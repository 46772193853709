.concentric-circles {
    background: url('../images/growth-back.jpg') no-repeat center center;
    background-size: cover;

    .circle {
        position: absolute;
        z-index: 0;
        inset: 0;
        border-radius: 50%;
        border: 1px solid $white;

        &[data-circle="1"] {
            height: 180px;
            width: 180px;
            background-color: $white;
            box-shadow: 0px 0px 4px 3px $white;
            filter: drop-shadow(0px 0px 8px $white);
        }

        &[data-circle="2"] {
            opacity:0.94;
        }

        &[data-circle="3"] {
            opacity:0.88;
        }

        &[data-circle="4"] {
            opacity:0.82;
        }

        &[data-circle="5"] {
            opacity:0.74;
        }

        &[data-circle="6"] {
            opacity:0.68;
            filter: blur(0.5px);
        }

        &[data-circle="7"] {
            opacity:0.62;
            filter: blur(1px);
        }

        &[data-circle="8"] {
            opacity:0.56;
            filter: blur(2px);
        }

        &[data-circle="9"] {
            opacity:0.50;
            filter: blur(3px);
        }

        &[data-circle="10"] {
            opacity:0.44;
            filter: blur(4px);
        }

        &[data-circle="11"] {
            opacity:0.38;
            filter: blur(5px);
        }

        &[data-circle="12"] {
            opacity:0.32;
            filter: blur(6px);
        }

        &[data-circle="13"] {
            opacity:0.26;
            filter: blur(7px);
        }

        &[data-circle="14"] {
            opacity:0.20;
            filter: blur(8px);
        }

        &[data-circle="15"] {
            opacity:0.14;
            filter: blur(9px);
        }
    }
}