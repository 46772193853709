.text-with-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
    @include device-md("min") {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    h2 {
        @include font-size(100, 100, 500, -4);
        margin-bottom: 40;

        @include device-xl {
            @include font-size(80, 80, 500, -3);
        }

        @include device-sm {
            @include font-size(50, 50, 500, -2);
            margin-bottom: 24px;
        }
    }

    @include device-sm {
        text-align: center;
    }
}
