.right-list {
    background-color: $white;
    &.section:first-of-type {
        padding-top: 0;
    }
    @include main-grid;
    &_title {
        margin-top: 0px;
        margin-bottom: 30px;
        grid-column: 13 / -1;
        grid-row: 2;

        p:last-of-type {
            margin-bottom: 0px;
        }

        @include device-md {
            grid-column: 1 / -1;
        }
    }

    hr {
        margin: 0;
        height: 1px;
        border: none;
        border-top: 1px solid $black;
    }

    .btn__list {
        grid-column: 13/-1;
        grid-row: 4;
        border: none;
        @include device-md {
            grid-column: 1/-1;
        }
    }

    .btn-arrow__title {
        @include font-size(20, 34, 400, 0);

        @include rwd(1024) {
            @include font-size(20, 34, 400, 0);
        }

        @include device-sm {
            @include font-size(16, 28, 400, 0);
        }
    }

    a {
        color: inherit;
    }

    &_separator {
        width: 100%;
        grid-column: 1 / -1;
        grid-row: 3;
        margin: 0 auto;
    }

    .tnd-badge:nth-child(1),
    .smile-badge:nth-child(1) {
        grid-column: 1/-1;
        margin: auto;
        margin-bottom: 120px;
        margin-top: 40px;
        @include device-md("min") {
            display: none;
        }
    }

    .tnd-badge:nth-child(2),
    .smile-badge:nth-child(2) {
        @include device-md {
            display: none;
        }

        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        margin: auto;

        @include device-md("min") {
            bottom: unset;
            right: unset;
            transform: translate(0, -50%);
            max-width: 240px;
            max-height: 240px;
        }

        @include device-lg("min") {
            transform: translate(-10px, -50%);
            max-width: 320px;
            max-height: 320px;
        }

        @include device-xl("min") {
            transform: translate(150px, -50%);
        }
    }
}

.--has-badge {
    &.section {
        @include device-sm {
            padding-bottom: 40px !important;
        }
    }
    .right-list {
        &_separator {
            @include device-md("min") {
                position: relative;
            }
        }
    }
}

.--bg-black {
    .right-list hr {
        border-top: 1px solid $white;
    }
}
