.join-us {
    @include main-grid;
    margin-top: 60px;
    padding-top: 140px;
    padding-bottom: 240px;
    @include device-sm{
        padding-top: 60px;
        padding-bottom: 120px;
    }

    h3 {
        grid-column: 2 / 23;
        grid-row: 1;
        @include device-sm{
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    p {
        margin-top: 100px;
        margin-bottom: 39px;
        grid-column: 14 / 23;
        grid-row: 2;
        @include rwd(1024){
            margin-top: 240px;
        }
        @include device-sm{
            margin-top: 120px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    hr {
        width: 90%;
        grid-column: 1 / 24;
        grid-row: 3;
        margin: 0 auto;
    }
    
    .btn__list {
        grid-column: 14 / 23;
        grid-row: 4;
        border: none;
        @include device-sm{
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    a {
        color: inherit;
    }
}