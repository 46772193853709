.people-are-saying {
    &__section{
        @include main-grid;
        padding-top: 140px;
        padding-bottom: 120px;
        @include rwd(1024){
            padding-top: 100px;
        }
        @include rwd(768){
            padding-top: 80px;
            padding-bottom: 40px;
        }
    }
    &__title{
        grid-row: 1;
        grid-column: 1/-1;
        margin-bottom: 60px;
        justify-self: center;
        @include rwd(768){
            margin-left: 16px;
            justify-self: flex-start;
        }
    }
    &__columns-container{
        &.--desktop{
            @include rwd(768){
                display: none;
            }
        }
        grid-row: 2;
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 40px;
        height: 1280px;
        overflow: hidden;
        margin-top: 60px;
        @include rwd(1024){
            column-gap: 20px;
        }
    }
    &__column{
        display: flex;
        flex-direction: column;
        max-width: 400px;
    }
    &__logo-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 80px;
        padding-bottom: 80px;
        background-color: #F1F1F1;
        margin-bottom: 40px;
        @include rwd(1024){
            margin-bottom: 20px;
        }
    }
    &__logo{
        max-width: 220px;
        height: auto;
    }
    
    &__item{
        width: 100%;
        padding: 40px;
        margin-bottom: 40px;
        height: auto;
        background-color: #F1F1F1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @include rwd(1024){
            padding: 25px;
            margin-bottom: 20px;
        }
    }
    &__score{
        margin-bottom: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            margin: 0 16px 0 0;
        }
    }
    &__item-text-container{
        margin-top: 18px;
        font-size: 20px;
        line-height: 34px;
        @include rwd(1024){
            font-size: 18px;
            line-height: 32px;
        }
        a{
            color:inherit;
        }
    }
    &__item-title{
        font-weight: 700;
        @include rwd(1024){
            font-size: 18px;
            line-height: 26px;
        }
        p{
            margin-bottom: 4px; 
        }  
    }
    &__item-description{
        font-weight: 400;
        p{
            margin-top: 4px;
            margin-bottom: 16px; 
        }
    }
    &__item-date{
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        margin-top: 16px;
    }
    &__mask{
        position:absolute;
        bottom: 0;
        z-index: 5;
        width: 100%;
        grid-column: 1/-1;
        height: 217px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &__slider{
        display: none;
        &.--mobile {
            @include rwd(768){
                display: flex;
                flex-direction: row;
                grid-column: 1/-1;
                overflow: auto;
            }
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
            .people-are-saying{
                &__logo-container{
                    min-width: 275px;
                    height: 416px;
                    margin: 0 8px 0 16px;
                }
                &__logo{
                    width: 180px;
                }
                &__item{
                    display: flex;
                    min-width: 275px;
                    height: 416px;
                    margin: 0 8px;
                    &-text-container{
                        margin: 16px 0;
                        height: 258px;
                    }
                    &-title{
                        display: block;
                        margin-bottom: 4px;
                        p{
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 28px;
                            margin: 0;
                        }
                    }
                    &-description{
                        display: block;
                        margin-top: 4px;
                        p{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 25px;
                            margin: 0;
                        }
                    }
                    &-date{
                        display: block;
                        p{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 16px;
                            margin: 0;
                        }
                    }
                }
                &__item:last-child{
                    margin-right: 16px;
                }
                &__score{
                    margin-bottom: 16px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    p{
                        margin: 0 16px 0 0;
                    }
                }
            }
        }
    }
}