.ucema {
    .case-challenge{
        &__img-box{
            position: relative;
            width: 100%;
            padding: 80px 0;
            @include device-md {
                padding: 60px 0;
            }
        }
        &__gradient{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(237.45deg, #7C1723 -0.03%, #B03258 100%);
        }
        &__img {
            @include image-responsive;
            height: auto;
            width: 100%;
        }
        &__sub-img{
            @include image-responsive;
            margin-top: 240px;
            @include device-md {
                margin-top: 120px;
            }
        }
    }
    &-roots{
      background-color: #F1F1F1;
    }
    &-discovering{
        @include main-grid-cases();
        @include main-grid-columns(
            (
                "xl": 2,
                "lg": 2,
                "md": 1,
                "sm": 1,
            )
        );

        &__box {
            img {
                @include image-responsive;
                margin: auto;
            }
            padding: 80px 70px;
            @include device-lg {
                padding: 50px 60px;
            }
            @include device-md {
                padding: 8px 16px;
            }
            text-align: center;
            background-color: #FFFFFF;
            height: 100%;
        }
    }
    &-users{
        &-section{
            padding-bottom: 0;
        }
        @include main-grid-cases();
        @include main-grid-columns(
            (
                "xl": 2,
                "lg": 2,
                "md": 1,
                "sm": 1,
            )
        );
        picture, img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include device-md{
                grid-row: auto;
            }
        }

        &__1{
            grid-row: 1 / 2;
        }
        &__2{
            grid-row: 1 / 3;
        }
        &__3{
            grid-row: 2 / 3;
        }
        &__4{
            grid-row: 3 / 4;
        }
        &__5{
            grid-row: 3 / 4;
        }
        &__6{
            grid-column: 1 / -1 !important;
            grid-row: 4 / 5;
        }
    }
    &-roadmap-findings {
        padding-top: 16px;
        @include device-md("min") {
            padding-top: 20px;
        }
        @include device-xl("min") {
            padding-top: 40px;
        }

        p {
            margin: 0;
        }

        .container1, .container2, .container3 {
            border-left: 4px solid #59BFEF;
            border-right: 4px solid #59BFEF;
            padding-left: 40px;
            padding-right: 40px;
            @include main-grid-cases();

            @include device-md {
                padding-left: 12px;
                padding-right: 12px;
                border-left: 4px solid #59BFEF;
                border-right: 4px solid #59BFEF;
                row-gap: 0px;
            }
        }
        .container1 {
            border-top: 4px solid #59BFEF;
            border-bottom: 4px solid #59BFEF;
            border-radius: 70px 0px 0px 0px;
            padding-top: 65.5px;
            padding-bottom: 69px;

            .big-text {
                @include font-size(20, 34, 400, 0);
                grid-column: 13 / -1;
                @include device-lg {
                    @include font-size(18, 32, 400, 0);
                }
                @include device-md {
                    @include font-size(16, 28, 400, 0);
                    grid-column: 1 / -1;
                }

            }

            @include device-md {
                padding-top: 44px;
                padding-bottom: 48px;
                border: 4px solid #59BFEF;
                border-radius: 35px 0px 0px 0px;
                .big-title {
                    margin-bottom: 16px;
                }
            }
        }
        .container2 {
            padding: 60px 40px;
            border-bottom: 2px solid #59BFEF;

            .img1 {
                grid-column: 1 / -1;
                width: 100%;
                height: auto;
            }

            @include device-md() {
                overflow: scroll;
                margin-top: 0;
                padding: 58px 12px;
                .img1 {
                    height: 180px;
                    width: auto;
                }
            }
        }
        .container3{
            border-top: 2px solid #59BFEF;
            border-bottom: 4px solid #59BFEF;
            padding-top: 60px;
            align-items: start;
            padding-bottom: 30px;
            background-color: #F1F1F1;
            .findings-box {
                border-radius: 32px;
                margin-bottom: 40px;
                padding: 40px 40px 8px 40px;
                border: 4px solid #59BFEF;
                box-shadow: 0px 16px 0px #59BFEF;
            }
            .column1 {
                grid-column: 1 / span 7;

                @include device-md() {
                    grid-column: 1 / -1;
                }
            }

            .column2 {
                grid-column: 9 / span 7;
                @include device-md() {
                    grid-column: 1 / -1;
                    padding-top: 0px;
                }
            }

            .column3 {
                grid-column: 17 / span 7;
                @include device-md() {
                    grid-column: 1 / -1;
                }
            }
            @include device-lg {
                .findings-box {
                    padding: 32px 24px;
                }
            }
            @include device-md() {
                border-top: none;
                padding-top: 40px;
                .findings-box {
                    padding: 24px;
                    padding-bottom: 8px;
                    margin-bottom: 32px;
                }
                .big-title {
                    margin-bottom: 32px;
                }
            }
        }
        
        .big-title {
            @include font-size(60, 70, 400, -3);
            grid-column: 1 / span 11;
            grid-row: 1;
            align-self: start;
            margin-bottom: 40px;
            @include device-lg{
                @include font-size(40, 50, 400, -2);
            }
            @include device-md(){
                @include font-size(24, 30, 400, -2);
            }
        }
        .findings-title {
            @include font-size(26, 32h, 400, 0);
            font-style: italic;
            margin-bottom: 32px;
            @include device-lg{
                @include font-size(24, 30, 400, 0);
            }
    
        }
        .findings-tiny-title {
            @include font-size(10, 10, 700, 3);
            margin-bottom: 8px;
            text-transform: uppercase;
        }
        .findings-tiny-paragraph {
            @include font-size(14, 22, 400, 0);
            margin-bottom: 16px;
            @include device-md(){
                @include font-size(12, 18, 400, 0);
            }
           
        }
        ul.findings-tiny-paragraph {
            list-style: disc;
            list-style-position: inside;
        }

        @include device-md() {
            padding-top: 40px;
        }
    }
    &-website{
        &-section{
            padding-bottom: 0
        }
        @include main-grid-cases();
        picture, img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            grid-auto-rows: auto;
            @include device-md {
                grid-column: 1 / -1;
            }
        }
        &__res {
            &__img-box{
                position: relative;
                width: 100%;
                padding: 80px 0;
                margin: 80px 0;
                @include device-md {
                    padding: 60px 0;
                    margin: 16px 0;
                }
            }
            &__gradient{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(89.76deg, #000000 0.15%, #00BBBD 99.74%);
                mix-blend-mode: normal;
                @include device-md {
                    background: linear-gradient(89.76deg, #000000 0.15%, #00BBBD 99.74%);
                }
            }
            &-img {
                @include image-responsive;
                height: auto;
                width: 100%;
            }
        }
        &__1 {
            grid-column: 1 / -1;
        }
        &__2, &__4, &__6 {
            grid-column: 1 / 12;
        }
        &__3{
            grid-column: 13 / -1;
        }
        &__5{
            grid-column: 13 / -1;
            grid-row: span 2;
        }
        
    
    }
    &-research{
        padding-top: 120px;
        @include device-md {
            padding-top: 80px
        }
        &__img-connect{
            @include image-responsive;
            max-height: 450px;
            margin: 0 auto 65px;
            @include device-md {
                max-width: 70%;
            }
        }
        &__header{
           text-align: center;
           max-width: 840px;
           margin: 0 auto 120px;
           @include device-lg{
            max-width: 720px;
           }
           @include device-md{
            margin-bottom: 60px;
            }
        }
        &__img-research{
            @include image-responsive;
        }
        &__middle-p{
            text-align: center;
            max-width: 840px;
            margin: 120px auto;
            @include device-lg{
                max-width: 720px;
               }
        }
    }
    &-storytelling{
        background-color: #F1F1F1;
        &__header{
            text-align: center;
            max-width: 840px;
            margin: 0 auto 120px;
            @include device-lg {
                padding: inherit 16px;
                max-width: 720px;
            }
        }
        &__img{
            @include image-responsive;
        }
    }
    &-ux{
        &-section{
            padding-bottom: 0;
            @include device-md("min"){
                padding-top: 0;
            }
        }
        padding-top: 80px;
        @include device-md("min"){
            padding-top: 240px;
        }
        @include main-grid-cases();
        picture, img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            grid-auto-rows: auto;
            @include device-md {
                grid-column: 1 / -1;
            }
        }
        &__1, &__3, &__5{
            grid-column: 1 / 12;
        }
        &__2, &__6{
            grid-column: 13 / -1; 
        }
        &__4, &__7{
            grid-column: 1 / -1;
        }
        &__2{
            grid-row: span 2;
        }
    }
}