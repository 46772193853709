.certified-b {
    &__container {
        overflow: hidden;
        .center-text{
            opacity: 0;
            margin-top: 80px;
            @include device-sm {
                margin-top: 32px;
            }
        }
    }
    &-top {
        position: relative;
        top: -80px;
    }
    &-logo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include device-sm {
            height: 160px;
        }
    }
    &__logo-box {
        position: relative;
        text-align: center;
        margin: auto;
        margin-top: 110px;
        height: 300px;
        @include device-sm {
            height: 160px;
        }
    }
    &__box {
        margin-top: 80px;
        background-color: $light-red;
        padding-bottom: 80px;
    }
    padding: 0;
    margin: 0 auto;
    margin-top: 100px;
    width: 300px;
    height: 300px;
    @include device-sm() {
        width: 165px;
        height: 165px;

        img {
            width: 165px;
            height: 165px;
        }
    }
}