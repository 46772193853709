.footer {
    background-color: $white;
    padding-top: 80px;
    padding-bottom: 80px;

    $footer: &;
    
    &-grid {
        @include main-grid;
        @include font-size(18, 31);
        align-items: flex-start;
        border-top: 1px solid $black;
        grid-template-areas:    'legal nav'
                                'slogan nav'
                                'social nav';

        &__slogan {
            grid-area: slogan;
            grid-column: 1 /span 11;
        }

        &__legal {
            grid-area: legal;
            grid-column: 1 /span 11;
            width: 100%;
            max-width: 420px;
            padding-top: 24px;

        }
        
        &__social {
            grid-area: social;
            grid-column: 1 /span 11;
            display: flex;
            flex-flow: row wrap;
            gap: 15px;
            margin-top: 37px;
        }

        &__nav {
            grid-area: nav;
            grid-column: 13 /span 11;

            .btn__list {
                border-width: 0;
            }
        }

        @include device-md {
            border: 0;
            grid-template-areas: 'slogan'
                                 'nav'
                                 'legal'
                                 'social';

            &__slogan {
                grid-column: 1 /span 7;
                margin-bottom: 14px;
                @include font-size(16, 28);
            }

            &__legal {
                grid-column: 1 /span 7;
                max-width: none;
                padding: 0;
                margin-top: 44px;
                @include font-size(14, 24);
            }
            
            &__social {
                grid-column: 1 /span 7;
                @include font-size(14, 24);
            }

            &__nav {
                grid-column: 1 /span 7;

                .btn__list {
                    border-width: 1px;
                }
            }
        }
    }

    &.--bg-black,
    &.--bg-red {
        color: $white;

        #{$footer}-grid {
            border-color: $white;

            &__nav {
                .btn__list {
                    border-color: $white;
        
                    .btn {
                        &-arrow {
                            border-color: $white;
                            color: $white;
        
                            .icon {
                                svg * {
                                    fill: $white;
                                }
                            }
        
                            &:hover, &:focus-within {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
}