.list-item {
    @include main-grid;
    @include main-grid-columns(
        (
            "xl": 4,
            "lg": 4,
            "md": 4,
            "sm": 1,
        )
    );
    row-gap: 32px;
    align-items: flex-start;

    &-container {
        @include flex-start;
        padding-top: 32px;
        gap: 16px;

        @include device-md(min) {
            gap: 16px;
            padding-top: 16px;
        }
        @include device-lg(min) {
            gap: 24px;
        }

        &:last-of-type {
            padding-right: 0;
        }

        border-top: 2px solid $black;
        &__title {
            font-weight: 700;
            @include device-lg() {
                @include font-size(18, 26, 700, 0);
            }
            @include device-sm() {
                @include font-size(16, 28, 700, 0);
            }
        }
        &__description {
            // @include font-size(20, 34);
            & a {
                text-decoration: underline;
                color: $black;
            }
            & > p:last-child {
                margin-bottom: 0;
            }
            @include rwd(1024){
                @include font-size(18, 32, 400, 0);
            }
            @include device-sm() {
                @include font-size(16, 28, 400, 0);
            }
        }
    }
}

.list-item-icon {

    &-container {
        padding-top: 60px;
        padding-bottom: 60px;
        @include device-md {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    &.--desk {
        @include main-grid;
        @include main-grid-columns(
            (
                "xl": 4,
                "lg": 4,
                "md": 4,
                "sm": 1,
            )
        );
        row-gap: 0;
        align-items: flex-start;
        @include device-sm {
            display: none;
        }
    }
    &.--mobile {
        @include device-sm("min") {
            display: none;
        }
        &.wrapper {
            padding-left: 0;
            padding-right: 0;
        }
        .swiper-slide {
            height: 100%
        }
    }
    &__box {
        border-top: 2px solid $black;
        border-bottom: 2px solid $black;

        padding-top: 32px;
        padding-bottom: 32px;

        display: flex;
        flex-direction: column;
        // gap: 40px;
        min-height: 240px ;
        height: 100%;
        justify-content: space-between;
        align-items: flex-start;

        @include device-md("min") {
            gap: 100px
        }
        @include device-sm("min"){
            &:nth-of-type(n+5) {
                /* Tus estilos CSS aquí */
                border-top: 0px;
            }
        }

        img {
            @include image-responsive;
            max-width: 24px;
        }
    }
    &__title {
        font-weight: 700;
        margin-bottom: 24px;
        @include device-md(min) {
            margin-bottom: 8px;
        }

        @include device-lg() {
            @include font-size(18, 26, 700, 0);
        }
        @include device-sm() {
            @include font-size(16, 28, 700, 0);
        }
    }
    &__description {
        & a {
            text-decoration: underline;
            color: $black;
        }
        margin-bottom: 0;
        @include rwd(1024){
            @include font-size(18, 32, 400, 0);
        }
        @include device-sm() {
            @include font-size(16, 28, 400, 0);
        }
    }
}