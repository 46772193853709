.block-list {
    // @include font-size(16, 28);
    // @include device-md("min") {
    //     @include font-size(20, 34);
    // }

    &-container {
        @include main-grid;
        align-items: flex-start;
        padding-top: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid $black;

        // &:first-child {
        //     padding-top: 0;
        // }

        &:last-child {
            border-bottom: 0;
        }



        &__title {
            grid-column: 1/-1;
            font-weight: 700 !important;
            line-height: 1.7;
            margin-bottom: 16px;
            @include device-md("min") {
                margin-bottom: 0px;
                grid-column: 1 / span 9;
                // grid-column: 1 / span 11;
            }
            &--show-arrows {
                @include device-md("min") {
                    grid-column: 1 / span 9;
                }
            }
            & p:last-child {
                margin-bottom: 0 !important;
            }
        }
        &__description {
            grid-column: 1/-1;
            @include device-md("min") {
                margin-bottom: 0px;
                grid-column: 13 / -1;
            }
            &--show-arrows {
                @include device-md("min") {
                    grid-column: 11 / -1;
                }
            }
            & ul {
                list-style: $block-list-item;
                @include device-md("max") {
                    padding-left: 25px;
                }
                & li {
                    line-height: 1.7;
                    margin-bottom: 24px;
                    padding-left: 16px;
                    @include device-md("min") {
                        padding-left: 20px;
                    }
                    @include device-lg("min") {
                        padding-left: 40px;
                    }
                }
            }
            & *:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}
