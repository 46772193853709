.to-manifesto_section{
    @include main-grid;
    max-width: 100vw;
    height: 1280px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 80px;
    margin-bottom: 240px;
    padding-bottom: 148px;
    // clip-path: circle(1% at center);

    div {
        grid-column: 14 / span 8;
        position: absolute;
        bottom: 100px;
        padding-bottom: 48px;
        @include device-lg {
            padding-left: 32px;
            padding-right: 32px;
        }
        @include device-md {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    h2 {
        margin-bottom: 40px;
    }

    p {
        width: 397px;
        margin-bottom: 40px;
        @include font-size(20, 34, 400, 0);
    }

    a {
        color: inherit;
        @include font-size(20, 36, 400, 0);
    } 

    @include rwd(1024){
        background-size: contain;
        height: 1025px;
        padding-bottom: 124px;
        p, a {
            @include font-size(18, 32, 400, 0);
        }
        div {
            padding-bottom: 15px;
            margin-top: 0;
        }
    }

    @include rwd(950) {
        p {
            width: auto;
        }

        div {
            right: 10%;
        }
    }

    @include rwd(768) {
        margin-bottom: 120px;
        margin-top: 60px;
        padding-top: 0;
        clip-path: circle(100%);
        background-size: 150%;
        background-position-x: center;
        position: relative;
        padding-bottom: 120px;
        height: 1160px;

        div {
            padding-bottom: 120px;
            margin-top: 0;
            position: absolute;
            top: 700px;
            grid-column: 1 / -1;
        }
        h2 {
            margin-bottom: 32px;
        }
        p, a {
            @include font-size(16, 28, 400, 0);
            padding-bottom: 0;
        }
        p {
            margin-bottom: 32px;
            width: 100%;
        }
    }

    @include rwd(660) {
        height: 870px;

        div {
            top: auto;
            bottom: 0;
            padding-bottom: 0;
        }
    }

    @include device-sm() {
        div {
            padding-bottom: 120px;
        }
    }
}