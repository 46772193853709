.team-climate{
    &__section{
        @include main-grid;
        padding-top: 120px;
        padding-bottom: 120px;
        @include rwd(768){
            padding-top: 80px;
            padding-bottom: 40px;
        }
    }
    &__title{
        grid-row: 1;
        grid-column: 1/-1;
        justify-self: center;
        max-width: 925px;
        text-align: center;
        margin-bottom: 120px;
        @include rwd(1024){
            max-width: 740px;
            margin-bottom: 80px;
        }
        @include rwd(768){
            margin-bottom: 40px;
            font-weight: 500;
            text-align: start;
        }
    }
    &__square-grafic{
        &.--like-ego{
            grid-row: 2;
            grid-column: 1/12;
            background-image: linear-gradient(to right, #FFFF00 1%, #F1F1F1 0);
            @include rwd(768){
                grid-column: 1/-1;
            }
        }
        &.--recommend-ego{
            grid-row: 2;
            grid-column: 13/-1;
            background-image: linear-gradient(to right, #FFFF00 1%, #F1F1F1 0);
            @include rwd(768){
                grid-column: 1/-1;
                grid-row: 3;
            }
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px;
        height: 620px;
        border-bottom: 2px solid #000;
        @include rwd(1024){
            height: 422px;
            padding: 32px;
        }
        @include rwd(768){
            height: 340px;
            margin-bottom: 16px;
        }
        &__text{
            align-self: flex-start;
            max-width: 400px;
            @include rwd(1024){
                max-width: 340px;
            }
            p{
                margin: 0;
                font-weight: 400;
                font-size: 40px;
                line-height: 50px;
                letter-spacing: -0.02em;
                @include rwd(1024){
                    font-size: 34px;
                    line-height: 40px;
                }
                @include rwd(768){
                    font-size: 30px;
                    line-height: 36px;
                }
            }
        }
        &__score{
            position: relative;
            height: 128px;
            width: 40%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            p{
                margin: 0;
                margin-right: 10px;
                font-weight: 400;
                font-size: 160px;
                line-height: 80%;
                letter-spacing: -0.03em;
                position: absolute;
                right: 0%;
                @include rwd(1024){
                    font-size: 100px;
                }
                @include rwd(768){
                    font-size: 70px;
                }
            }
        }
    }
    &__survey-info{
        grid-row: 3;
        grid-column: 1/-1;
        justify-self: flex-end;
        @include rwd(768){
            grid-row: 4;
            justify-self: flex-start;
        }
        p{
            margin: 0;
            margin-top: 20px;
            font-weight: 400;
            font-size: 20px;
            line-height: 34px;
            text-align: right;
            @include rwd(1024){
                font-size: 16px;
                line-height: 16px;
                margin-top: 40px;
            }
            @include rwd(768){
                text-align: start;
                margin-top: 8px;
                font-size: 12px;
            }
        }
    }
}
