.careers-text-slider {
    padding-bottom: 40px;
    
    .swiper-slide {
        width: auto;
    }

    .swiper-slide__box {
        width: max-content;
        height: 180px; 
        display: flex; 
        align-items: center;
        justify-content: center;
        h2 {
            margin-bottom: 0;
        }

        @include rwd(1024) {
            height: 150px;
        }
        
        @include device-md() {
            height: 98px;
        }
    }

    .swiper-text, .swiper-text--reverse {
        border-bottom: 1px solid $black;
        border-top: 1px solid $black;
    }

    .swiper-wrapper {
        transition-timing-function: linear !important;
    }
    
    .fixed-box, .border-bottom {
        height: 180px;
        display: flex;
        align-items: center;
        img {
            height: 100px;
        }
        
        @include rwd(1024) {
            height: 150px;
            
            img {
                height: 80px;
            }
        }
        
        @include device-md() {
            height: 98px;
            
            img {
                height: 50px;
            }
        }
    }

    .fixed-box {
        border-bottom: 1px solid $black;
        border-top: 1px solid $black;
    }
    
    .swiper-text:first-child {
        border-top: 2px solid $black;
        border-bottom: 1px solid $black;
    }
    .fixed-box:last-child {
        border-bottom: 2px solid $black;
        border-top: 1px solid $black;
    }
    @include device-md() {
        padding-bottom: 20px;
    }
}