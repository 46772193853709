.cta-box {
    border-top: 1px solid $black;
    max-width: 400px;
    height: 450px;
    min-height: 280px;
    position: relative;

    p {
        margin: 0;
    }

    a {
        background-color: #fff;
        color: $black;
        text-decoration: none;
        margin: 0;
        
        .cta-box_arrow {
            margin: 0;
            margin-top: 75px;
            height: 69px;
            width: auto;
            visibility: hidden;
            transition: visibility $timming ease-in-out;
            @include device-md() {
                visibility: visible;
            }
        }
        
        h4 {
            @include font-size(56, 48, 400, -6);
            font-style: italic;
            position: absolute;
            bottom: 60px;
            margin-bottom: 0;
            @include device-md() {
                @include font-size(32, 32, 400, -6);
            }
        }

        .cta-box-underline {
            width: calc(100%);
            background-image: linear-gradient(transparent calc(100% - 3px), $yellow 3px);
            background-repeat: no-repeat;
            background-size: 0% 90%;
            transition: background-size 1.5s;
        }
        
        .cta-box_text {
            @include font-size(20, 36, 400, 0);
            text-decoration: none;
            margin: 0;
            margin-top: 24px;
            position: absolute;
            bottom: 0;
            @include device-md() {
                @include font-size(14, 36, 400, 0);
                text-decoration: underline;
            }
        }
    }
    
    @include device-md() {
        height: 280px;

        a{
            .cta-box_arrow {
                margin-top: 20px;
                height: 45px;
            }

            h4 {
                bottom: 44px;
            }

            .cta-box_text {
                margin-top: 8px;
            }
        }
    }
}
.cta-box:hover, .cta-box:focus-within {
    .cta-box_arrow {
        visibility: visible;
    }
    .cta-box-h4, .cta-box-underline {
        background-size: 100% 90%;
    }
}