@use 'sass:math';

.ego-form {
    width: 100%;
    max-width: 620px;
    margin: 0 auto;

    $form: &;

    &__title {
        display: block;
        margin-bottom: 15px;

        h2 {
            @include font-size(40, 48, 400, -2);
        
            @include device-xl {
                @include font-size(34, 40, 400, -2);
            }
        
            @include device-sm {
                @include font-size(30, 36, 400);
            }

            text-align: center;
            margin-bottom: 0;

            img {
                width: 1em;
                vertical-align: baseline;
            }
        
            strong {
                font-weight: 600;
            }

            &:empty {
                display: none;
            }
        }

        h3 {
            @include font-size(20, 34, 400);
            text-align: center;
            margin-bottom: 0;

            &:empty {
                display: none;
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "." "footer";
        column-gap: 40px;

        &__column {
            display: flex;
            flex-flow: column nowrap;
            gap: 16px;
        }
    }

    &.--two-columns {
        max-width: 840px;

        #{$form}__grid {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: ". ." "footer footer";
        }
    }

    &__step {
        min-height: 100%;
        display: none;
        flex-flow: column nowrap;
        gap: 25px;

        &.--active {
            display: flex;
        }

        .step {
            @include font-size(12, 120, 400, 10);

            display: block;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }

    &__columns {
        height: 100%;
    }

    &__alert-wrapper {
        grid-area: alerts;
    }

    &__field {
        position: relative;

        @mixin active-label {
            top: 0.7rem;
            @include font-size(12);

            @include device-md {
                top: 0.5rem;
                @include font-size(10);
            }
        }

        @mixin active-field {
            border-color: $black;
            background-color: $white;
            z-index: 1;
        }

        @mixin hidden-input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        $field: &;

        &-grid {
            display: grid;
            grid-template-columns: 1.2fr .8fr;
            column-gap: 15px;
        }

        &__select {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            padding-right: 45px;

            &::-ms-expand {
                display: none;
            }
        }

        &__input,
        &__select,
        &__radio-label,
        &__file {
            font-family: 'Switzer', sans-serif;
            @include font-size(20);

            display: block;
            position: relative;
            z-index: 0;
            width: 100%;
            background-color: $gray-lighter;
            border: 1px solid transparent;
            outline: 1px solid transparent;
            padding: 1.8rem 1rem 0.8rem 1rem;
            transition: border-color $timming ease-in-out, background-color $timming ease-in-out, outline-color $timming ease-in-out;

            &:hover {
                border-color: $black;
            }

            &:focus {
                @include active-field;
                outline-color: $black;
            }

            &:focus + label {
                @include active-label;
            }
        }

        &__textarea {
            padding-top: 1.4rem;
            height: 100%;
            resize: none;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font-style: italic;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                font-style: italic;
            }
            &:-moz-placeholder { /* Firefox 18- */
                font-style: italic;
            }
        }

        &.--radio {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 1px;

            #{$field}__search {
                text-align: center;
            }

            #{$field}__radio {
                cursor: pointer;
                $radio: &;
    
                &-label {
                    text-align: center;
                    outline: none;
                    padding: .8rem;
                }
    
                &:hover {
                    &-label {
                        border-color: $black;
                    }
                }
    
                input {
                    @include hidden-input;
    
                    &:checked + .ego-form__field__radio-label {
                        @include active-field;
                    }
                }

                &.--hidden {
                    display: none;
                }
            }
        }

        &.--file {
            #{$field}__file {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                gap: 10px;
                align-items: center;
                padding: 1.3rem 1rem 1.3rem 1rem;
                cursor: pointer;

                .attachment-control {
                    display: flex;
                    align-items: center;
                    min-height: 22px;
                }

                .attachment-file-info {
                    display: grid;
                    grid-template-columns: auto minmax(60px, 1fr);
                    align-items: center;
                    gap: 10px;

                    .attachment-file-name {
                        @include font-size(16, 16);
                        text-decoration: underline;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .attachment-file-size {
                        @include font-size(12, 12);
                        color: $gray;
                        min-width: 60px;

                        i {
                            color: $red;
                            text-transform: uppercase;
                        }

                        &:empty {
                            &:before {
                              content: attr(data-placeholder);
                            }
                        }
                    }
                }

                .icon__clip {
                    height: 1rem;
                }

                .attachment-remove {
                    display: none;
                    cursor: pointer;
                    border: 0;
                    background: none;
                    outline: none;
                    padding: 0;
                    margin: 0 0 0 auto;
                    font-size: 23px;
                    line-height: .8;
                }
            }

            &.--has-file {
                #{$field}__file {
                    @include active-field;

                    .icon__clip {
                        display: none;
                    }
      
                    .attachment-remove {
                        display: block;
                    }
                }
            }
    
            input {
                @include hidden-input;
            }
        }

        &__label {
            @include font-size(20);

            color: $black;
            left: 1rem;
            top: 1.3rem;
            position: absolute;
            z-index: 2;
            transition: all $timming ease-in-out;
            pointer-events: none;
        }

        &__error {
            @include font-size(12, 12, 400, 10);
            text-transform: uppercase;
            position: absolute;
            inset: 1px 1px auto auto;
            z-index: 3;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            height: calc(100% - 2px);
            max-width: 50%;
            text-align: right;
            color: $black;
            background-color: $white;
            padding: 0 1.2rem;
            pointer-events: none;
            opacity: 0;
            transition: opacity $timming ease-in-out;

            strong {
                font-weight: 400;
            }

            &.--active {
                opacity: 1;
            }
        }

        &.--filled {
            #{$field}__input,
            #{$field}__select,
            &.--textarea {
                @include active-field;
            }
            #{$field}__label {
                @include active-label;
            }
        }

        &.--optional,
        &.--custom-assistive {
            &::after {
                @include font-size(12, 12, 400, 10);

                content: 'OPTIONAL';
                position: absolute;
                right: 1rem;
                top: 1.9rem;
                color: $black;
                pointer-events: none;

                @include device-md {
                    @include font-size(10, 10, 400, 10);
                    right: 1rem;
                    top: 1.3rem;
                }
            }
        }

        &.--optional {
            &::after {
                content: 'OPTIONAL';
            }
        }

        &.--custom-assistive {
            $value: attr(data-assistive);
            &::after {
                content: $value;
            }
        }

        &.--has-error {
            #{$field}__input,
            #{$field}__select,
            #{$field}__file,
            &.--textarea {
                background-color: $white;
                border-color: $red;
            }

            #{$field}__error {
                opacity: 1;
            }

            &.--optional:after {
                display: none !important;
            }
        }

        &.--full-height {
            height: 100%;
        }

        &.--textarea {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 200px;
            background-color: $gray-lighter;
            border: 1px solid transparent;
            outline: 1px solid transparent;
            transition: border-color $timming ease-in-out, background-color $timming ease-in-out, outline-color $timming ease-in-out;

            &:hover {
                border-color: $black;
            }

            &:focus-within {
                @include active-field;
                outline-color: $black;
            }

            textarea {
                display: block;
                font-family: 'Switzer', sans-serif;
                @include font-size(20);
                padding: 0;
                margin: 1.8rem 1rem 0 1rem;
                border: 0;
                background: transparent;
                outline: none !important;
                @include custom-scrollbars;
            }

            &.--big {
                min-height: 280px;
            }

            #{$field}__label {
                opacity: .5;
                font-style: italic;
            }

            #{$field}__error {
                inset: 1px 1px auto auto;
                height: auto;
                width: fit-content;
                background-color: none;
                max-width: none;
                min-height: 70px;
                max-height: 50%;
            }

            #{$field}__footer {
                position: relative;
                height: auto;
                width: calc(100% - 2px);
                max-width: none;
                min-height: 50px;
                max-height: 50%;
                padding: 0 1rem 1rem;
                display: grid;
                grid-template-columns: auto 95px;
                align-items: end;
                gap: 20px;

                #{$field}__attachment {
                    min-width: 0;
                    position: relative;
                    z-index: 1;

                    .attachment-label {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        gap: 10px;
                        align-items: center;
                        cursor: pointer;

                        .attachment-control {
                            display: flex;
                            align-items: center;
                            min-height: 22px;
                        }
                        
                        .attachment-file-info {
                            min-width: 0;
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .attachment-file-name {
                                @include font-size(16, 16);
                                max-width: 100%;
                                text-decoration: underline;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            .attachment-file-size {
                                @include font-size(12, 12);
                                color: $gray;
                                min-width: 60px;

                                i {
                                    color: $red;
                                    text-transform: uppercase;
                                }
                                
                                &:empty {
                                    &:before {
                                      content: attr(data-placeholder);
                                    }
                                }
                            }
                        }
    
                        .icon__clip {
                            display: block;
                            width: 8px;
                            height: 16px;
                        }

                        .attachment-remove {
                            display: none;
                            cursor: pointer;
                            border: 0;
                            background: none;
                            outline: none;
                            padding: 0;
                            margin: 0 0 0 auto;
                            font-size: 23px;
                            line-height: .6;
                        }
                    }

                    &.--has-file {
                        .icon__clip {
                            display: none;
                        }

                        .attachment-remove {
                            display: block;
                        }
                    }

                    input {
                        width: 0.1px;
                        height: 0.1px;
                        opacity: 0;
                        overflow: hidden;
                        position: absolute;
                        z-index: -1;
                    }
                }
    
                #{$field}__length-counter {
                    position: relative;
                    z-index: 1;
                    text-align: right;
                    margin-left: auto;
                    @include font-size(12, 15);
                    color: $black;
                }

                .ego-form__progress-bar {
                    content: '';
                    position: absolute;
                    inset: 0 auto 0 0;
                    width: 0%;
                    background-color: $yellow;
                }
            }
        }

        &.--select {
            $select_field: &;

            &::after {
                content: '\2193';
                position: absolute;
                z-index: 2;
                right: 1.2rem;
                top: calc(1.3rem + 10px);
                font-weight: 600;
                line-height: 1;
                font-size: 16px;
            }
        }

        &__toggle-visibility {
            position: absolute;
            z-index: 1;
            right: calc(1.1rem - 5px);
            top: calc(1.3rem + 5px);
            border: 0;
            background: none;
            outline: none;
            padding: 4px;
            margin: 0;
            cursor: pointer;

            .show {
                width: 15px;
                display: none;
            }

            .hide {
                width: 15px;
                display: block;
            }

            &.--hide {
                .hide {
                    display: none;
                }

                .show {
                    display: block;
                }
            }
        }

        &__instruction {
            display: block;
            padding: 0 1rem;
            margin: 0;
            text-align: center;
            @include font-size(16, 30);
        }

        &__search {
            position: relative;

            .placeholder {
                position: absolute;
                inset: 0;
                width: 100%;
                padding: 0.8rem;
                @include font-size(20);
                font-family: 'Switzer', sans-serif;
                color: $gray;
                pointer-events: none;

                svg {
                    display: inline-block;
                    margin-left: 5px;

                    * {
                        fill: $gray;
                    }
                }
            }

            input {
                text-align: center;
                width: 100%;
                padding: 0.8rem;
                border: 2px solid $gray-lighter;
                @include font-size(20);
                font-family: 'Switzer', sans-serif;

                &::-webkit-input-placeholder {
                    color: transparent;
                }
                &::-moz-placeholder {
                    color: transparent;
                }
                &:-moz-placeholder {
                    color: transparent;
                }

                &:focus + .placeholder,
                &:not(:placeholder-shown) + .placeholder {
                    display: none;
                }
            }

            .empty {
                display: none;
                margin-bottom: 0;
                padding: 1rem;
                @include font-size(16);
                color: $gray;

                &.--show {
                    display: block;
                }
            }
        }
    }

    &__footer {
        grid-area: footer;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        margin-top: 15px;
        text-align: right;
        &__privacy {
            grid-column: 1/3;
            text-align: left;
            display: flex;
            align-items: center;
            @include rwd(575){
                margin-bottom: 80px;
            }
            p{
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                margin-bottom: 0;
                color: #000;
                a{
                    color: inherit;
                }
                @include rwd (575){
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }
        .--small-form{
            @include rwd-min(1440){
                grid-column: 1;
            }
        }

        .col1 {
            grid-column: 1;
        }

        .col2 {
            grid-column: 2;
        }
    }

    &.--two-columns {
        #{$form}__footer {
            grid-template-columns: repeat(4, 1fr);
    
            .col1 {
                grid-column: 1;
            }
    
            .col2 {
                grid-column: 2;
            }

            .col3 {
                grid-column: 3;
            }
    
            .col4 {
                grid-column: 4;
            }
        }
    }

    &__message {
        display: none;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        inset: 0;
        z-index: 3;
        background-color: $white;

        &.--visible {
            display: flex;
        }

        img {
            margin-bottom: 40px;
            max-width: 132px;
        }

        p {
            @include font-size(20, 34);
            max-width: 650px;
            margin-bottom: 1em;
            
            &:last-of-type {
                margin-bottom: 2em;
            }
        }
    }

    @include device-md {
        max-width: 570px;

        &__title {
            margin-bottom: 0px;

            h2 {
                @include font-size(34, 40, 400, -2);
            }

            h3 {
                @include font-size(18, 32);
            }
        }

        &__grid {
            row-gap: 8px;

            &__column {
                gap: 8px;
            }
        }

        &.--two-columns {
            #{$form}__grid {
                grid-template-columns: 1fr;
                grid-template-areas: "." "footer";
                row-gap: 8px;
            }

            #{$form}__footer {
                grid-template-columns: repeat(2, 1fr);
        
                .col1,
                .col2 {
                    grid-column: 1;
                }
    
                .col3,
                .col4 {
                    grid-column: 2;
                }
            }
        }

        &__field {
            $field: &;

            &__input,
            &__textarea,
            &__select,
            &__radio-label,
            &__file {
                padding: 1.13rem .9rem .4rem .9rem;
                @include font-size(16);
            }

            &__textarea {
                padding-top: .8rem;
            }

            &.--textarea {
                
                textarea {
                    margin-top: 0.8rem;
                    @include font-size(18);
                }

                #{$field}__error {
                    min-height: 48px;
                }

                #{$field}__footer {
                    grid-template-columns: auto 80px;

                    #{$field}__length-counter {
                        @include font-size(10, 10);
                    }
                }
            }

            &[data-type=file] {
                #{$field}__file {
                    padding: .765rem .9rem .765rem .9rem;
                }
            }

            &.--optional {
                &::after {
                    @include font-size(10, 10);
                    top: 1.25rem;
                    right: 1rem;
                }
            }

            &__label {
                @include font-size(16);
                top: .8rem;
            }

            &__instruction {
                @include font-size(14, 20);
                margin-top: 8px;
            }
        }

        &__message {
            p {
                @include font-size(18, 32);
            }
        }
    }

    @include device-sm {
        min-height: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 25px;

        &__title {
            margin-bottom: 0;

            h2 {
                @include font-size(33, 36, 400);
            }

            h3 {
                @include font-size(16, 28);
            }
        }

        &__field {
            $field: &;
            
            &.--textarea {

                textarea {
                    @include font-size(16);
                }

                #{$field}__footer {
                    #{$field}__attachment {
                        .attachment-label {
                            .attachment-file-info {
                                gap: 5px;
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }

        &__footer {
            margin-top: auto;
            gap: 1rem;
        
            .col1,
            .col2 {
                grid-column: 1/-1;

                &:only-child {
                    grid-column: 1/span 2;
                }
            }
        }

        &.--two-columns {

            #{$form}__footer {
                grid-template-columns: 1fr;
        
                .col1,
                .col2,
                .col3,
                .col4 {
                    grid-column: auto;
                }
            }
        }

        &__message {
            br {
                display: none;
            }
            p {
                @include font-size(16, 28);
            }
        }
    }

    &__subscribe{
        min-height: 100vh;
        background-color: $black;
        padding-top: 120px;
        z-index: -15;
        position: relative;
        &__content{
            display: none;
            &.--show{
                display: flex;
                flex-direction: column;
                position: fixed;
                bottom: 120px;
                right: 50%;
                transform: translate(50%);
                z-index: -10;
            }
        }
        &__title{
            color: $white;
        }
        &__form{
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            width: 620px;
            row-gap: 16px;
            @include rwd(1024){
                width: 422px;
            }
            @include rwd(768){
                width: 100%;
            }
            &-input{
                width: 100%;
                height: 70px;
                background-color:  #F1F1F1;
                border: none;
                margin-bottom: 16px;
                padding-left: 16px;
                font-weight: 400;
                font-size: 20px;
                line-height: 34px;
                &:focus{
                    outline: none;
                }
            }
            &-button-container{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 12px;
                @include rwd(768){
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: 0;
                }
                p{
                    color: $white;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    margin: 0;
                    @include rwd(1024){
                        line-height: 130%;
                    }
                    @include rwd(768){
                        margin-bottom: 80px;
                    }                   
                    a{
                        color: $white;
                    }
                }
            }
            &-button{
                width: 290px;
                height: 60px;
                background-color: #F1F1F1;
                border: none;
                font-weight: 600;
                font-size: 18px;
                line-height: 60px;
                @include rwd(1024){
                    width: 200px;
                }
                @include rwd(768){
                    width: 100%;
                }
            }
        }
        
        &.--bg-white{
            background-color: $white;
            .ego-form__subscribe__title{
                color: $black;
            }
            .ego-form__subscribe__policy{
                color: $black;
                a{
                    color: $black;
                }
            }
        }
        &.--show-static{
            background-color: $white;
            padding-top: 0;
            padding-bottom: 40px;
            min-height: auto;
            .ego-form__subscribe__content{
                display: flex;
                flex-direction: column;
            }
            .ego-form__subscribe__title{
                color: $black;
                border-top: 1px solid $black;
                padding-top: 120px;
                @include rwd(1024){
                    margin-bottom: 80px;
                }
                @include rwd(768){
                    padding-top: 60px;
                }
            }
            .ego-form__subscribe__form-button-container{
                p{
                    color: $black;
                    a{
                        color: $black;
                    }
                }
            }
        }
    }
}

