.hr-text {
    hr {
        // margin-top: -40px;
        margin-bottom: 32px;

        border: 0;
        border-top: 1px solid $black;
        height: 1px;
    }
    p {
        // @include font-size(20, 34, 700, 0);
        margin-bottom: 32px;
        font-weight: 700;
    }
    // @include rwd(1024){
    //     p{
    //         @include font-size(18, 26, 700, 0);
    //     }
    // }
    // @include device-sm(){
    //     p{
    //         @include font-size(16, 28, 700, 0);
    //     }
    // }
}
