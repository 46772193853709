.onu-programme {
    @include main-grid;
    align-items: end;
    @include device-md {
        row-gap: 20px;
    }
    &__thumb {
        position: relative;
        overflow: hidden;
        &__mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &--red {
                background-color: $light-red;
            }
            &--white {
                background-color: $white;
            }

            &.--animated-init {
                animation: mask-onu-animation-init 0.2s ease-in-out forwards;
            }
        }

        img {
            @include image-responsive;
        }
        &:nth-of-type(1) {
            grid-column: 1 / span 3;
            grid-row: 1 / span 1;
            margin-bottom: 70px;
            @include device-md("min") {
                grid-column: 1 / span 7;
                grid-row: 1 / span 1;
            }
            @include device-xl("min") {
            }
        }
        &:nth-of-type(2) {
            grid-column: 3 / span 3;
            grid-row: 2 / span 1;
            @include device-md("min") {
                grid-column: 9 / span 7;
                grid-row: 1 / span 1;
                transform: translateY(70%);
            }
            @include device-lg("min") {
                transform: translateY(73%);
            }
        }
        &:nth-of-type(3) {
            grid-column: 5 / -1;
            grid-row: 1 / span 1;
            @include device-md("min") {
                grid-column: 17 / -1;
                align-self: center;
                grid-row: 1 / span 1;
            }
            @include device-md("min") {
            }
        }
        &:nth-of-type(4) {
            grid-column: span 3;
            grid-row: 3 / span 1;
            margin-bottom: 75%;
            @include device-md("min") {
                grid-column: 3 / span 5;
                grid-row: 2 / span 1;
                margin-top: 207px;
                margin-bottom: unset;
            }
            @include device-xl("min") {
                margin-top: 304px;
            }
        }
        &:nth-of-type(5) {
            grid-column: 3 / span 1;
            grid-row: 4 / span 1;
            @include device-md("min") {
                grid-column: 5 / span 3;
                grid-row: 3 / span 1;
                transform: translateY(50%);
            }
            @include device-xl("min") {
                transform: unset;
            }
        }
        &:nth-of-type(6) {
            grid-column: 5 / -1;
            grid-row: 3 / span 1;
            @include device-md("min") {
                grid-column: 19 / span 5;
                grid-row: 3 / span 1;
                margin-top: 40px;
            }
        }
    }
    &__copyright {
        grid-column: 5 / -1;
        align-self: start;
        grid-row: 1 / span 1;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        // opacity: 0.5; // Problems with accessibility
        @include device-md("min") {
            grid-column: 17 / -1;
            align-self: start;
            grid-row: 1 / span 1;
        }
        @include device-xl("min") {
            grid-column: 19 / -1;
        }
    }

    &--culture {
        // See certified-b component

        z-index: 20;
    }
}

@keyframes mask-onu-animation-init {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}
