.supervielle {
    // ------  Archivo: teambuilding-purple / Sección: supervielle-teambuilding-purple ------
    &-teambuilding-purple {

        .purple-section {
            background-color: #480526;
            color: #ffffff;
            padding-top: 120px;
            padding-bottom: 120px;
            margin-top: 32px;
            
            @include device-md() {
                margin-top: 16px;
                padding-bottom: 24px;
                padding-top: 60px;
            }
        }

        .started {
            background-color: #2F0218;
            padding: 100px 110px 130px 110px;
            margin: 120px auto 40px auto;
            border-radius: 16px;
            max-width: 1060px;

            .avatar-container {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                align-items: center;
                justify-items: center;

                .started-1 { grid-area: 1 / 1 / 2 / 2; }
                .started-2 { grid-area: 1 / 2 / 2 / 3; }
                .started-3 { grid-area: 1 / 3 / 2 / 4; }
                .started-4 { grid-area: 1 / 4 / 2 / 5; }
                .started-5 { grid-area: 1 / 5 / 2 / 6; }
                .started-6 { grid-area: 1 / 6 / 2 / 7; }

                .avatar-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                @include device-md() {
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: 1fr 1fr;

                    img {
                        max-width: 100px;
                        max-height: 100px;
                    }

                    .started-1 { grid-area: 1 / 1 / 2 / 2; }
                    .started-2 { grid-area: 1 / 2 / 2 / 3; }
                    .started-3 { grid-area: 1 / 3 / 2 / 4; }
                    .started-4 { grid-area: 2 / 1 / 3 / 2; }
                    .started-5 { grid-area: 2 / 2 / 3 / 3; }
                    .started-6 { grid-area: 2 / 3 / 3 / 4; }
                }
            }
            
            @include rwd(1024) {
                padding: 24px 16px;
                margin-left: 16px;
                margin-right: 16px;
                margin-top: 60px;
                margin: 60px 16px 16px 16px;
            }

            @include device-md() {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .ended {
            background-color: #69173F;
            border-radius: 16px;
            max-width: 1060px;
            padding: 100px 110px 130px 110px;
            margin: 40px auto 0px auto;
            
            @include rwd(1024) {
                padding: 24px 0px;
                margin: 0px 16px 0px 16px;
            }

            .big-container {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: repeat(4, 1fr);
                grid-column-gap: 40px;
                grid-row-gap: 80px;
                place-content: center;
                text-align: center;

                .part-one { grid-area: 1 / 1 / 2 / 4; }
                .part-two { grid-area: 1 / 4 / 2 / 7; }
                .part-three { grid-area: 2 / 1 / 3 / 4; }
                .part-four { grid-area: 2 / 4 / 3 / 5; }
                .part-five { grid-area: 2 / 5 / 3 / 7; }
                .part-six { grid-area: 3 / 1 / 4 / 7; }
                .part-seven { grid-area: 4 / 1 / 5 / 7; }

                @include device-md() {
                    grid-column-gap: 20px;
                    grid-row-gap: 40px; 
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(8, max-content);
                    place-content: center;

                    .avatar-container {
                        justify-content: space-around;
                        img {
                            width: max-100px;
                            height: max-100px;
                        }
                        .avatar-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                    }
                    .part-one {
                        grid-area: 1 / 1 / 2 / 4;
                    }
                    .part-two { grid-area: 2 / 1 / 3 / 4; }
                    .part-three { grid-area: 4 / 1 / 5 / 4; }
                    .part-four { grid-area: 3 / 1 / 4 / 2; }
                    .part-five { grid-area: 3 / 2 / 4 / 4; }
                    .part-six { 
                        grid-area: 5 / 1 / 7 / 4;

                        .avatar-container {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-template-rows: repeat(2, 1fr);
                            .ended-1 { grid-area: 1 / 1 / 2 / 2; }
                            .ended-2 { grid-area: 1 / 2 / 2 / 3; }
                            .ended-3 { grid-area: 1 / 3 / 2 / 4; }
                            .ended-4 { grid-area: 2 / 1 / 3 / 2; }
                            .ended-5 { grid-area: 2 / 2 / 3 / 3; }
                            .ended-6 { grid-area: 2 / 3 / 3 / 4; }
                        }
                    }
                    .part-seven { 
                        grid-area: 7 / 1 / 9 / 4;

                        .avatar-container {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-template-rows: repeat(2, 1fr);
                            .ended-7 { grid-area: 1 / 1 / 2 / 2; }
                            .ended-8 { grid-area: 1 / 2 / 2 / 3; }
                            .ended-9 { grid-area: 1 / 3 / 2 / 4; }
                            .ended-10 { grid-area: 2 / 1 / 3 / 2; }
                            .ended-11 { grid-area: 2 / 2 / 3 / 3; }
                            .ended-12 { grid-area: 2 / 3 / 3 / 4; }
                        }
                    }
                }
            }
        }

        .title {
            @include font-size(40, 50, 400, -2);
            margin-bottom: 24px;

            @include rwd(1024) {
                @include font-size(30, 36, 400, -2);
                margin-left: 16px;
                margin-bottom: 16px;
            }
        }

        .subtitle {
            @include font-size(15, 15, 600, 2);
            margin-bottom: 16px;
            text-align: left;

            @include rwd(1024) {
                @include font-size(12, 15, 600, 2);
                margin-left: 16px;
            }
        }

        hr {
            border: none;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            margin: 0;
            margin-bottom: 30px;
        }

        .avatar-container {
            display: flex;
            img {
                max-width: 110px;
                max-height: 110px;

                @include rwd(1024) {
                    width: 70px;
                    height: 70px;
                }
            }
            .avatar-tag {
                background-color: rgba(0, 0, 0, 0.3);
                @include font-size(8, 10, 600, 2);
                width: max-content;
                height: min-content;
                padding: 4px 8px;
                border-radius: 10px;
                display: inline-block;
                margin-top: 0;

                @include device-md(){
                    padding: 4px 6px;
                }
            }
        }

        @include rwd(768) {
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 120px;
        }

        @include device-md() {
            padding-bottom: 40px;
        }
    }

    // ------  Archivo: teambuilding-white / Sección: supervielle-teambuilding-white ------
    &-teambuilding-white {
        text-align: center;
        margin-top: -120px;

        img {
            width: auto;
            height: 178px;
            margin-bottom: 32px;
        }

        @include device-md() {
            margin-top: 0;
            padding-bottom: 120px;
            img {
                height: 142.4px;
                margin-bottom: 24px;
            }
        }
    }

    &-evolving {
        // ------  Archivo: evolving-yellow / Sección: supervielle-evolving-yellow ------
        &-yellow {

            .yellow-section {
                background-color: #FDB239;
                margin-top: 32px;
                padding-top: 120px;
                padding-bottom: 120px;

                @include device-md() {
                    margin-top: 16px;
                    padding-top: 60px;
                    padding-bottom: 60px;
                }
            }

            .yellow-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(2, auto);
                gap: 40px;

                div { 
                    @include main-grid-cases();
                    grid-template-rows: 1fr 0.4fr;
                    grid-area: 1 / 1 / 2 / 3;
                    background-color: #FDB239;
                    position: relative;
                    padding-bottom: 90px;
                    
                    img, picture {
                        width: 100%;
                        grid-column: 1 / -1;
                        grid-row: 1 / span 2;

                        @include device-md() {
                            display: none
                        }
                    }

                    p {
                        @include font-size(20, 34);
                        grid-column: 10 / span 12;
                        grid-row: 2;
                        align-self: center;
                        margin: 0;

                        @include rwd(1124) {
                            @include font-size(18, 32);
                        }

                        @include rwd(900) {
                            position: absolute;
                            bottom: 0px;
                        }

                        @include device-md() {
                            grid-column: 1 / -1;
                            align-self: center;
                            width: auto;
                            padding-left: 24px;
                            padding-right: 24px;
                            @include font-size(16, 28);
                            text-align: center;
                            background-color: #FF9F04;
                            position: inherit;
                        }
                    }
                }

                .img1 { 
                    grid-area: 2 / 1 / 3 / 2;
                    width: 100%;
                    object-fit: cover;
                }

                .img2 { 
                    grid-area: 2 / 2 / 3 / 3;
                    width: 100%;
                    object-fit: cover;
                }

                @include rwd(1024) {
                    gap: 20px;
                }

                @include device-md() {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(3, 1fr);
                    // margin-top: -60px;
                    .img1 { 
                        grid-area: 2 / 1 / 3 / 2;
                        height: 100%;
                    }
                    .img2 { 
                        grid-area: 1 / 1 / 2 / 2;
                        height: 100%;
                    }
                    div {
                        grid-area: 3 / 1 / 4 / 2;
                        grid-template-rows: repeat(3, auto);
                        background-color: #FF9F04;
                        margin-top: -20px;
                        height: max-content;
                        position: inherit;
                        padding-top: 44px;
                        padding-bottom: 44px;
                    }
                }
            }

            @include rwd(1024) {
                padding-top: 0;
            }

            @include device-md() {
                padding-bottom: 40px;
            }
        }

         // ------  Archivo: evolving-gray / Sección: supervielle-evolving-gray ------
        &-gray {
            margin-top: -120px;
            padding-bottom: 0px;

            .gray-box {
                background-color: #E7EEED;
                .gray-text {
                    padding: 60px 60px 0px 60px;
                    .gray-text-title {
                        @include font-size(26, 36, 600, -2);
                        margin-bottom: 16px;

                        @include rwd(1024){
                            @include font-size(24, 34, 600, -2);
                        }

                        @include device-sm{
                            @include font-size(20, 26, 600, -2);
                        }
                    }
    
                    .gray-text-description {
                        @include font-size(20, 34, 400, 0);
                        margin: 0;

                        @include rwd(1024){
                            @include font-size(18, 32);
                        }

                        @include device-sm{
                            @include font-size(16, 28);
                        }
                    }
                }
            } 
            
            &-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(4, 1fr);
                grid-gap: 40px;
                
                .gray-box1 { 
                    grid-area: 1 / 1 / 3 / 2; 
                }
                .gray-box2 { 
                    grid-area: 1 / 2 / 2 / 3;
                    background-color: transparent;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .gray-box3 { 
                    grid-area: 2 / 2 / 4 / 3; 
                }
                .gray-box4 { 
                    grid-area: 3 / 1 / 5 / 2; 
                }
                .gray-box5 { 
                    grid-area: 4 / 2 / 5 / 3;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .gray-box {
                    img {
                        object-fit: cover;
                        width: 100%;
                    }
                }

                @include rwd(1024) {
                    grid-gap: 20px;
                }

                @include device-md() {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(5, max-content);
                    

                    .gray-box1 { grid-area: 1 / 1 / 2 / 2; }
                    .gray-box2 { grid-area: 2 / 1 / 3 / 2; }
                    .gray-box3 { grid-area: 3 / 1 / 4 / 2; }
                    .gray-box4 { grid-area: 4 / 1 / 5 / 2; }
                    .gray-box5 { grid-area: 5 / 1 / 6 / 2; }

                    .gray-box {
                        .gray-text {
                            padding: 32px 32px 0px 32px;
                        }
                    }
                }
            }
            
            &-emojis {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr;
                column-gap: 40px;
                margin-top: 40px;

                .gray-box6 { grid-area: 1 / 1 / 2 / 2; }
                .gray-box7 { grid-area: 1 / 2 / 2 / 3; }
                .gray-box8 { 
                    grid-area: 1 / 3 / 2 / 4;
                    .gray-text {
                        padding-right: 57px;
                    }
                }
                .gray-box {
                    padding-bottom: 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    img {
                        width: 120px;
                        height: 120px;
                        margin-top: 60px;
                    }
                }

                @include rwd(1024) {
                    column-gap: 20px;
                    margin-top: 20px;

                    .gray-box {
                        .gray-text {
                            padding-left: 32px;
                            padding-right: 32px;
                        }
                    }
                }

                @include device-md() {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(3, 1fr);
                    row-gap: 16px;
                    

                    .gray-box6 { grid-area: 1 / 1 / 2 / 2; }
                    .gray-box7 { grid-area: 2 / 1 / 3 / 2; }
                    .gray-box8 { grid-area: 3 / 1 / 4 / 2; }

                    .gray-box {
                        flex-direction: row;
                        padding-bottom: 32px;
                        padding-right: 32px;

                        img {
                            width: 60px;
                            height: 60px;
                        }

                        .gray-text {
                            padding-top: 32px;
                        }
                    }
                }
            }

            @include device-md() {
                margin-top: 0;
                padding-top: 0;
                padding-bottom: 40px;
            }
        }

         // ------  Archivo: evolving-quote / Sección: supervielle-evolving-quote ------
        &-quote {
            padding-bottom: 360px;
            .case-quote__box {
                .big-list-title, .case-quote__author {
                    grid-column: 1 / 19;
                }
            }

            @include device-md() {
                padding-bottom: 120px;
            }
        }
    }

    // ------  Archivo: simple / Sección: supervielle-simple ------
    &-simple {
        background-color: #1D1D1D;
        color: #ffffff;

        .section-divider {
            border-color: #ffffff;
        }

        .simple-content {
            margin-top: 150px;
            .simple-black-grid {
                @include main-grid-cases();
                row-gap: 40px;

                picture ,img {
                    width: 100%;
                }
                .box {
                    grid-column: 1 / -1;
                    grid-row: 1;
                    margin-top: 120px;
                }
                .simple-paragraph {
                    grid-column: 5 / span 15;
                    grid-row: 2;
                    text-align: center;
                    padding-top: 80px;
                    padding-bottom: 80px;
                    margin: 0;
                    @include font-size(20, 34, 400, 0);

                    @include rwd(1024) {
                        @include font-size(18, 34, 400, 0);
                    }

                    @include device-md(){
                        @include font-size(16, 28, 400, 0);
                    }
                }
                .box1 {
                    grid-column: 1 / span 11;
                    grid-row: 3 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box2 {
                    grid-column: 13 / span 11;
                    grid-row: 3;
                    height: 100%;
                    object-fit: cover;
                }
                .box3 {
                    grid-column: 13 / span 11;
                    grid-row: 4;
                    height: 100%;
                    object-fit: cover;
                }
                .box4 {
                    grid-column: 1 / -1;
                    grid-row: 5;
                    height: 100%;
                    object-fit: cover;
                }
                .box5 {
                    grid-column: 1 / -1;
                    grid-row: 6;
                    height: 100%;
                    object-fit: cover;
                }
                .box6 {
                    grid-column: 1 / span 11;
                    grid-row: 7;
                    height: 100%;
                    object-fit: cover;
                }
                .box7 {
                    grid-column: 13 / span 11;
                    grid-row: 7;
                    height: 100%;
                    object-fit: cover;
                }

                @include rwd(1024) {
                    row-gap: 20px;
                }

                @include device-md() {
                    row-gap: 17px;
                    .box {
                        grid-column: 1 / -1;
                        grid-row: 1;
                        margin-top: 60px;
                    }
                    .simple-paragraph {
                        grid-column: 1 / -1;
                        grid-row: 2;
                        padding-top: 44px;
                        padding-bottom: 44px;
                    }
                    .box1 {
                        grid-column: 1 / -1;
                        grid-row: 3;
                    }
                    .box2 {
                        grid-column: 1 / -1;
                        grid-row: 4;
                    }
                    .box3 {
                        grid-column: 1 / -1;
                        grid-row: 5;
                    }
                    .box4 {
                        grid-column: 1 / -1;
                        grid-row: 6;
                    }
                    .box5 {
                        grid-column: 1 / -1;
                        grid-row: 7;
                    }
                    .box6 {
                        grid-column: 1 / span 3;
                        grid-row: 8;
                    }
                    .box7 {
                        grid-column: 5 / span 3;
                        grid-row: 8;
                    }
                }
            }
        }
        @include device-md() {
            padding-top: 120px;
            padding-bottom: 120px;
            .simple-content {
                margin-top: 60px;
            }
            
        }
    }

    // ------  Archivo: mobile-app / Sección: supervielle-mobile-app ------
    &-mobile-app {
        padding-bottom: 0px;

        .mobile-app-content {
            margin-top: 150px;

            .mobile-app-grid {
                @include main-grid-cases();
                margin-top: 120px;
                row-gap: 40px;

                img {
                    width: 100%;
                }

                .box1 {
                    grid-column: 1 / span 11;
                    grid-row: 1 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box2 {
                    grid-column: 13 / span 11;
                    grid-row: 1;
                    height: 100%;
                    object-fit: cover;
                }
                .box3 {
                    grid-column: 13 / span 11;
                    grid-row: 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box4 {
                    grid-column: 1 / span 11;
                    grid-row: 3 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box5 {
                    grid-column: 13 / span 11;
                    grid-row: 3 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box6 {
                    grid-column: 1 / span 11;
                    grid-row: 5;
                    height: 100%;
                    object-fit: cover;
                }
                .box7 {
                    grid-column: 1 / span 11;
                    grid-row: 6;
                    height: 100%;
                    object-fit: cover;
                }
                .box8 {
                    grid-column: 13 / span 11;
                    grid-row: 5 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box-text {
                    grid-column: 3 / span 19;
                    grid-row: 7;
                    text-align: center;
                    padding-top: 120px;
                    padding-bottom: 120px;
                    p {
                        @include font-size(20, 34, 400, 0);

                        @include rwd(1024) {
                            @include font-size(18, 32, 400, 0);
                        }

                        @include device-sm() {
                            @include font-size(16, 28, 400, 0);
                        }
                    }
                    img {
                        width: 184px;
                        height: 178px;

                        @include device-sm() {
                            width: 147px;
                            height: 142px;
                        }
                    }
                }
                .box9 {
                    grid-column: 1 / span 11;
                    grid-row: 8 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box9b {
                    grid-column: 13 / span 11;
                    grid-row: 8 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box10 {
                    grid-column: 1 / -1;
                    grid-row: 10 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box11 {
                    grid-column: 1 / span 11;
                    grid-row: 12 / span 2;
                    height: 100%;
                    object-fit: cover;
                }
                .box12 {
                    grid-column: 13 / span 11;
                    grid-row: 12;
                    height: 100%;
                    object-fit: cover;
                }
                .box13 {
                    grid-column: 13 / span 11;
                    grid-row: 13;
                    height: 100%;
                    object-fit: cover;
                }
                .box14 {
                    grid-column: 13 / span 11;
                    grid-row: 14 / span 2;
                }
                .box15 {
                    grid-column: 1 / span 11;
                    grid-row: 14 / span 2;
                }

                @include rwd(1024) {
                    row-gap: 20px;
                }

                @include device-md() {
                    row-gap: 17px;
                    .box1 {
                        grid-column: 1 / -1;
                        grid-row: 1;
                    }
                    .box2 {
                        grid-column: 1 / -1;
                        grid-row: 2;
                    }
                    .box3 {
                        grid-column: 1 / -1;
                        grid-row: 3;
                    }
                    .box4 {
                        grid-column: 1 / -1;
                        grid-row: 4;
                    }
                    .box5 {
                        grid-column: 1 / -1;
                        grid-row: 5;
                    }
                    .box8 {
                        grid-column: 1 / -1;
                        grid-row: 6;
                    }
                    .box6 {
                        grid-column: 1 / -1;
                        grid-row: 7;
                    }
                    .box7 {
                        grid-column: 1 / -1;
                        grid-row: 8;
                    }
                    .box-text {
                        grid-column: 1 / -1;
                        grid-row: 9;
                        padding-top: 103px;
                        padding-bottom: 103px;
                        p {
                            margin-bottom: 0;
                            margin-top: 24px;
                        }
                    }
                    .box9 {
                        grid-column: 1 / -1;
                        grid-row: 10;
                    }
                    .box9b {
                        grid-column: 1 / -1;
                        grid-row: 11;
                    }
                    .box10 {
                        grid-column: 1 / -1;
                        grid-row: 12;
                    }
                    .box11 {
                        grid-column: 1 / -1;
                        grid-row: 13;
                    }
                    .box12 {
                        grid-column: 1 / -1;
                        grid-row: 14;
                    }
                    .box13 {
                        grid-column: 1 / -1;
                        grid-row: 15;
                    }
                    .box14 {
                        grid-column: 1 / -1;
                        grid-row: 16;
                    }
                    .box15 {
                        grid-column: 1 / -1;
                        grid-row: 17;
                    }
                }
            }

            .mobile-app-quote {
                margin-top: 240px;
                padding-bottom: 0px;
                @include main-grid-cases();
                row-gap: 80px;
    
                h4 {
                    grid-column: 1 / span 19;
                }
    
                img {
                    grid-column: 1 / span 5;
                    grid-row: 2;
                    width: 299px;
                }
    
                @include device-md() {
                    row-gap: 32px;
                    margin-top: 120px;
                    padding-bottom: 40px;
    
                    img {
                        width: 198px;
                    }
                }
            }
        }

        @include device-md() {
            padding-top: 120px;
            padding-bottom: 40px;
            .mobile-app-content {
                margin-top: 75px;
                .mobile-app-grid {
                    margin-top: 60px;
                }
            }
        }
        
    // ------  Archivo: mobile-app-quote / Sección: supervielle-mobile-app-quote ------
        &-quote {
            padding-bottom: 120px;
            .case-quote__box {
                .big-list-title, .case-quote__author {
                    grid-column: 1 / 19;
                }
            }
        }
    }

    // ------  Archivo: transition / Sección: supervielle-transition ------
    &-transition {

        .transition-content {
            @include main-grid-cases();
            background-color: #F1F1F1;
            margin-top: 32px;
            padding-top: 120px;
            padding-bottom: 146px;
            
            .transition-box {
                grid-column: 5 / span 15;

                img {
                    width: 222px;
                    height: 178px;
                }

                h3 {
                    margin-top: 32px;
                    margin-bottom: 32px;
                }

                .transition-paragraph, .transition-values {
                    @include font-size(20, 34, 400, 0);
                    margin: 0;
    
                    @include rwd(1024) {
                        @include font-size(18, 32, 400, 0);
                    }
    
                    @include device-md(){
                        @include font-size(16, 28, 400, 0);
                    }
                }

                .transition-values {
                    margin-top: 120px;
                    margin-bottom: 20px;

                    @include device-md(){
                        margin-top: 60px;
                        margin-bottom: 32px;
                    }
                }
    
                .transition-list {
                    margin-bottom: 20px;
                    @include font-size(40, 56, 400, -2);
    
                    @include rwd(1024) {
                        @include font-size(34, 50, 400, -2);
                    }
    
                    @include device-sm(){
                        @include font-size(24, 36, 400, -2);
                        margin-bottom: 0;
                    }
                }
            }

            @include rwd(1024) {
                padding-bottom: 120px;
                .transition-box {
                    img {
                        width: 137px;
                        height: 110px;
                    }
                }
            }

            @include rwd(768) {
                margin-top: 16px;
                padding: 60px 40px 60px 40px;
                .transition-box {
                    grid-column: 1 / -1;
                }
            }
        }

        @include device-md() {
            padding-top: 0;
        }
    }

    // ------  Archivo: varios / Clase: supervielle-center-text ------
    &-center-text, &-center-text-small  {
        text-align: center;
        @include main-grid-cases();
        row-gap: 0px !important;
        
        h3 {
            margin-bottom: 32px;
            grid-column: 5 / span 15;
            @include rwd(1024) {
                grid-column: 3 / span 19;
            }
            @include rwd(768) {
                grid-column: 1 / -1;
                padding-left: 16px;
                padding-right: 16px;
            }
            @include device-md() {
                padding: 0;
            }
        }
        
        p {
            @include font-size(20, 34, 400, 0);
            margin: 0;
            grid-column: 5 / span 15;
            
            @include rwd(1024) {
                @include font-size(18, 32, 400, 0);
                grid-column: 3 / span 19;
            }

            @include rwd(768) {
                @include font-size(16, 28, 400, 0);
                grid-column: 1 / -1;
                padding-left: 16px;
                padding-right: 16px;
            }
            @include device-md() {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }
    &-center-text-small {
        h3 {
            @include font-size(60, 70, 400, -3);

            @include rwd(1024) {
                @include font-size(40, 50, 400, -2);
            }

            @include device-md() {
                @include font-size(24, 30, 400, -2);
                padding-left: 16px;
                padding-right: 16px;
                margin-bottom: 24px;
            }
        }
    }
    
    // ------ Clase: supervielle-text-align ------
    &-text-align {
        @include device-md() {
            text-align: left;
        }
    }

    // ------ Clase: supervielle-img ------
    &-img {
        @include device-md() {
            img {
                height: auto;
            }
        }
        .case-challenge__img {
            height: auto;
        }
    }

    // ------ Clase: supervielle-width ------
    &-width {
        max-width: 490px;
        @include rwd(1200) {
            width: 290px;
        }
        @include device-md(){
            letter-spacing: -0.02rem;
            width: 100%;
        }
    }

    // ------ Clase: supervielle-padding-bottom ------
    &-padding-bottom {
        padding-bottom: 0px;
    }

    &-mobile-without-padding {
        @include device-md() {
            h3, p {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
}