.--lock-scroll {
    overflow: hidden;
}
.--block {
    pointer-events: none;
}

.--bg-black {
    background-color: $black;
    color: $white;

    hr {
        color: $white;
        background-color: $white;
        border-color: $white;
    }
}
.--bg-yellow {
    background-color: $yellow;
    color: $black;
}
.--bg-red {
    background-color: $red;
    color: $white;

    hr {
        color: $white;
        background-color: $white;
        border-color: $white;
    }
}

.--bg-dark {
    color: $white;

    hr {
        color: $white;
        background-color: $white;
        border-color: $white;
    }
}

.--full-screen {
    height: 100%;
    min-height: 600px;
}

.--richtext {
    @include font-size(20, 34);

    b, strong {
        font-weight: 700;
    }
    
    a {
        color: inherit;
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            text-decoration: none;
        }
    }

    p {
        margin-bottom: 2rem;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 2rem 0;
    }

    hr {
        display: block;
        margin: 1.5rem 0;
        border-top: 1px solid black;
        border-bottom: 0;

        & ~ p {
            margin-top: 2rem;
        }
    }

    ul {
        padding-left: 25px;
        list-style: $block-list-item;
        
        li {
            margin-bottom: 24px;
            padding-left: 16px;
            
            @include device-md("min") {
                padding-left: 20px;
            }
            @include device-lg("min") {
                padding-left: 40px;
            }
        }
    }

    @include device-xl {
        @include font-size(18, 32);
    }

    @include device-sm {
        @include font-size(16, 28);
    }
}

.--custom-scrollbars {
    @include device-sm('min') {
        @include custom-scrollbars;
    }
}

.--center {
    margin: auto;
    text-align: center;
    align-items: center;
    &-sm {
        @include device-sm {
            margin: auto;
            text-align: center;
            align-items: center;
        }
    }
    &-lg {
        @include device-lg("min") {
            margin: auto;
            text-align: center;
            align-items: center;
        }
    }
}
