.service {
    &-hero {
        &--copy {
            position: absolute;
            inset: 0 0 auto 0;
            z-index: 1;
            clip-path: inset(0px 0px 0px 0px);
            &.section {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    &-services {
        padding-top: 0;
        &__list {
            @include main-grid();
            @include main-grid-columns(
                (
                    "xl": 3,
                    "lg": 3,
                    "md": 1,
                    "sm": 1,
                )
            );
            align-items: start;
            row-gap: 38px;
            @include device-xl("min") {
                min-height: 440px;
                align-items: unset;
            }
        }
    }
    &__badge {
        margin: 65px auto;
        display: none;
        @include device-md {
            @include flex-center();
        }
    }
    &-logo-slider {
        .swiper-logo--reverse {
            @include device-xl("min") {
                margin-top: -40px;
            }
        }
        img {
            @include image-responsive;
        }
        .swiper-wrapper {
            transition-timing-function: linear !important;
            align-items: center;
            align-content: center;

            @include device-md() {
                margin-bottom: 25px;
            }
        }
    }
    &-culture {
        padding-top: 120px;
        @include device-lg("min") {
            padding-top: 240px;
        }
        &__inner {
            @include flex-center(column);
            gap: 40px;
            max-width: 1024px;
            text-align: center;
            @include device-md() {
                gap: 32px;
                text-align: start;
                justify-content: start;
                align-items: start;
            }

            h2 {
                margin-bottom: 0;
            }

            @include device-lg {
                max-width: 650px;
                p {
                    max-width: 650px;
                }
            }
        }
        &__text {
            width: 100%;
            @include device-lg("min") {
                max-width: 570px;
            }
            @include device-xl("min") {
                max-width: 620px;
            }
            p:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &-explanation {
        &__container {
            &.section {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        &__inner {
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 16px;
            padding-right: 16px;

            @include device-md("min") {
                padding-top: 120px;
                padding-bottom: 120px;
                padding-left: 32px;
                padding-right: 32px;
            }
            @include device-md("min") {
                @include flex-center(column);
                gap: 40px;
            }

            @include device-xl("min") {
                padding-top: 160px;
                padding-bottom: 124px;
            }
            @include flex-start(column);
            gap: 32px;

            .btn-box {
                @include device-lg("min") {
                    margin-top: 20px;
                }
                @include device-lg("min") {
                    margin-top: 40px;
                }
            }
        }
        &__title {
            width: 100%;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.03em;
            @include device-md("min") {
                max-width: 720px;
                font-size: 64px;
                line-height: 74px;
                text-align: center;
            }
            @include device-xl("min") {
                max-width: 840px;
                font-size: 80px;
                line-height: 92px;
            }
        }
        &__text {
            width: 100%;
            @include device-md("min") {
                max-width: 620px;
                text-align: center;
            }
            p:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &-cms {
        $service-cms: &;
        &__box {
            @include main-grid;
            row-gap: 38px;

            @include device-md("min") {
                padding-right: 0;
                padding-left: 0;
                padding-top: 70px;
                padding-bottom: 70px;
            }
        }
        &__content {
            grid-column: 1 / -1;
            @include flex-start;
            gap: 32px;
            @include device-md("min") {
                grid-column: 1 / 12;
            }
            @include device-xl("min") {
                gap: 40px;
            }

            &-title {
                margin-bottom: 0;
            }
            &-text {
                p:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        &__thumb {
            img {
                max-width: 100%;
                margin: auto;
            }
            grid-column: 1 / -1;
            order: -1;
            @include device-md("min") {
                grid-column: 15 / -1;
                order: unset;
            }
            &-sm {
                display: block;
                @include device-md("min") {
                    display: none;
                }
            }
            &-lg {
                display: block;
                @include device-md {
                    display: none;
                }
            }
            &--all-devices {
                #{$service-cms}__thumb-lg {
                    display: block !important;
                }
            }
        }
    }
    &-dev-features {
        $dev-features: &;

        &__box {
            @include main-grid;
            row-gap: 35px;
        }

        &__content {
            & > * {
                margin-bottom: 0;
            }
        }

        &__thumb {
            max-width: 100%;
        }

        &--row {
            display: flex;
            flex-direction: column;
            gap: 60px;

            & > * {
                &:nth-child(odd) {
                    #{$dev-features}__content {
                        grid-column: 1 / -1;
                        grid-row: 2;
                        @include device-md("min") {
                            grid-column: 14 / -1;
                            grid-row: 1;
                        }
                    }

                    #{$dev-features}__thumb {
                        grid-column: 1 / -1;
                        grid-row: 1;
                        @include device-md("min") {
                            grid-column: 1 / span 12;
                            grid-row: 1;
                        }
                    }
                }
                &:nth-child(even) {
                    #{$dev-features}__content {
                        @include device-md("min") {
                            grid-column: 1 / span 12;
                            grid-row: 1;
                        }
                    }

                    #{$dev-features}__thumb {
                        @include device-md("min") {
                            grid-column: 14 / -1;
                            grid-row: 1;
                        }
                    }
                }
            }

            #{$dev-features}__box {
                align-items: center;
            }
            #{$dev-features}__thumb {
                max-width: 100%;
                grid-column: 1 / -1;
            }
            #{$dev-features}__content {
                display: flex;
                flex-direction: column;
                gap: 16px;

                grid-column: 1 / -1;

                @include device-xl("min") {
                    gap: 40px;
                }
            }
        }
        &--col {
            @include main-grid;
            @include main-grid-columns(
                (
                    "xl": 2,
                    "lg": 2,
                    "md": 1,
                    "sm": 1,
                )
            );
            align-items: unset;
            margin-top: 50px;
            row-gap: 50px;

            #{$dev-features}__box {
                @include flex-center(column);
                justify-content: space-between;
                align-items: unset;
                gap: 30px;

                border-top: 3px solid $black;
                padding-top: 40px;

                @include device-md("min") {
                    gap: 80px;
                }
            }

            #{$dev-features}__content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;

                @include device-md("min") {
                    @include flex-center(column);
                    gap: 12px;
                }

                & > * {
                    margin-bottom: 0;
                    @include device-md("min") {
                        text-align: center;
                    }
                }
            }
        }
    }
}
